import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from 'contexts/UserContext';
const LogoutHandler = () => {
    const { authService } = React.useContext(UserContext);
    const navigate = useNavigate();
    React.useEffect(() => {
        (async () => {
            try {
                await authService.removeUser();
                console.log('Remove User successful');
            }
            catch (error) {
                console.error('Error when trying to remove user in the LogoutHandler.  Error %o', error);
            }
            finally {
                navigate('/', { replace: true });
            }
        })();
    }, [authService, navigate]);
    return (React.createElement("div", null, "Loading"));
};
export default LogoutHandler;
