import * as React from 'react';
import PageWrapper from 'pages/PageWrapper';
import ToDo from '../to-do/ToDo';
import SystemAdmin from '../system-admin/SyndAdmin';
import Emails from '../emails/Emails';
import DailyEmails from '../daily-emails/DailyEmails';
import Live from '../live/Live';
import Transactions from '../transactions/Transactions';
import NotificationSettings from './NotificationSettings';
import Constants from '../constants/Constants';
import Parties from '../parties/Parties';
import Regexes from '../regexes/Regexes';
import OpenNLPTest from '../opennlp-test/OpenNLPTest';
import IFR from '../ifr/IFR';
const SyndicateApp = () => {
    const stored = localStorage.getItem('transactionView');
    return (React.createElement(PageWrapper, { pages: [
            {
                auth: 'ADMIN', defaultView: stored ?? 'OVERVIEW', element: (React.createElement(Transactions, null)), label: 'Transactions',
            },
            { defaultView: 'TABLE', element: (React.createElement(Live, null)), label: 'NI LIVE' },
            {
                auth: 'ADMIN', defaultView: 'TABLE', element: (React.createElement(Emails, null)), label: 'Emails',
            },
            {
                auth: 'ADMIN', defaultView: 'TABLE', element: (React.createElement(Parties, null)), label: 'Parties',
            },
            {
                auth: 'ADMIN', element: (React.createElement(Regexes, null)), label: 'Regexes',
            },
            {
                auth: 'ADMIN', element: (React.createElement(Constants, null)), label: 'Constants',
            },
            {
                auth: 'ADMIN', element: (React.createElement(DailyEmails, null)), label: 'Daily',
            },
            {
                auth: 'ADMIN', element: (React.createElement(ToDo, null)), label: 'To Do',
            },
            {
                auth: 'SUPER_ADMIN', element: (React.createElement(SystemAdmin, null)), label: 'SYSTEM',
            },
            {
                auth: 'ADMIN', element: (React.createElement(IFR, null)), label: 'IFR',
            },
            {
                auth: 'SUPER_ADMIN', element: (React.createElement(OpenNLPTest, null)), label: 'AI',
            },
        ], settings: [(React.createElement(NotificationSettings, { key: "NOTIFICATIONS" }))] }));
};
export default SyndicateApp;
