import * as React from 'react';
import { Dropdown, TextField, Validation, } from '@symphony-ui/uitoolkit-components';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';
import { getOptions } from 'utils/change';
import { errorMessage } from 'utils/initialValidation';
import CustomValidators from 'components/CustomValidators';
import './Tranche.css';
const Tranches = ({ benchmarkOptions, currencyOptions, disabled, top, tranche, setTranche, }) => {
    const [errorState, setErrorState] = React.useState({});
    const validators = React.useMemo(() => ({
        benchmarkId: CustomValidators.RequiredOption,
        currency: CustomValidators.RequiredOption,
        maturity: Validators.Required,
        spreadGuidance: Validators.Required,
        term: Validators.Required,
    }), []);
    const onChangeDropdown = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? [...new Set(value.map((v) => v.value))] : value.value;
        const newObject = Object.assign(tranche, { [name]: newValue });
        setTranche(newObject);
    }, [setTranche, tranche]);
    const onChangeInput = React.useCallback((name) => (event) => {
        const { value } = event.target;
        setTranche({ ...tranche, [name]: value });
    }, [setTranche, tranche]);
    const onValidationChanged = React.useCallback((name) => (isValid) => {
        const newErrorState = { ...errorState, [name]: isValid };
        setErrorState(newErrorState);
    }, [errorState]);
    return (React.createElement("div", { className: "co-item" },
        React.createElement("div", { style: { minWidth: '90px' } },
            React.createElement(Validation, { errorMessage: errorMessage, onValidationChanged: onValidationChanged('currency'), validator: validators.currency },
                React.createElement(Dropdown, { isDisabled: disabled, label: top ? 'Currency' : '', name: "currency", onChange: onChangeDropdown('currency'), options: currencyOptions, value: getOptions(tranche.currency, currencyOptions) }))),
        React.createElement(Validation, { errorMessage: errorMessage, onValidationChanged: onValidationChanged('term'), validator: validators.term },
            React.createElement(TextField, { disabled: disabled, label: top ? 'Term' : '', onChange: onChangeInput('term'), value: tranche.term || '' })),
        React.createElement(Validation, { errorMessage: errorMessage, onValidationChanged: onValidationChanged('maturity'), validator: validators.maturity },
            React.createElement(TextField, { disabled: disabled, label: top ? 'Maturity' : '', onChange: onChangeInput('maturity'), value: tranche.maturity || '' })),
        React.createElement(Validation, { errorMessage: errorMessage, onValidationChanged: onValidationChanged('spreadGuidance'), validator: validators.spreadGuidance },
            React.createElement(TextField, { disabled: disabled, label: top ? 'Spread Guidance' : '', onChange: onChangeInput('spreadGuidance'), value: tranche.spreadGuidance || '' })),
        React.createElement("div", { style: { minWidth: '200px' } },
            React.createElement(Validation, { errorMessage: errorMessage, onValidationChanged: onValidationChanged('benchmarkId'), validator: validators.benchmarkId },
                React.createElement(Dropdown, { isDisabled: disabled, label: top ? 'Benchmark' : '', name: "benchmarkId", onChange: onChangeDropdown('benchmarkId'), options: benchmarkOptions, value: getOptions(tranche.benchmarkId, benchmarkOptions) })))));
};
export default Tranches;
