import * as React from 'react';
import { Button, Card, Dropdown, Validation, } from '@symphony-ui/uitoolkit-components';
import CardHeaderForm from 'components/card-header-form/CardHeaderForm';
import { errorMessage, initialValidation } from 'utils/initialValidation';
import CustomValidators from 'components/CustomValidators';
import Ipo from '../models/Ipo';
import './IpoForm.css';
const IpoForm = ({ ipo, exchangeOptions, save }) => {
    const [data, setData] = React.useState(ipo);
    const [disabledButton, setButtonDisabled] = React.useState(true);
    const [errorState, setErrorState] = React.useState({});
    const validators = React.useMemo(() => ({
        exchange: CustomValidators.RequiredOption,
    }), []);
    React.useEffect(() => {
        const disabled = Object.values(errorState).some((v) => v === false);
        setButtonDisabled(disabled);
    }, [errorState]);
    React.useEffect(() => {
        (async () => {
            const newErrorObject = await initialValidation(validators, data);
            setErrorState(newErrorObject);
        })();
    }, [data, validators]);
    const onChangeDropdown = React.useCallback((event) => {
        const { value } = event.target;
        const newValue = value.value;
        const newObject = Object.assign(data ?? new Ipo(), { exchange: newValue });
        setData(newObject);
    }, [data]);
    const onClickSave = React.useCallback(() => {
        if (data !== undefined) {
            (async () => {
                await save(data);
            })();
        }
    }, [data, save]);
    const onValidationChanged = (name) => (isValid) => {
        setErrorState({ ...errorState, [name]: isValid });
    };
    return (React.createElement("div", { className: "ipo-form" },
        React.createElement(Card, { className: "co-card-stack" },
            React.createElement(CardHeaderForm, { title: data?.id ? 'Edit Ipo' : 'Create Ipo' }),
            React.createElement("div", { className: "base-form" },
                React.createElement(Validation, { validator: validators.exchange, errorMessage: errorMessage, onValidationChanged: onValidationChanged('exchange') },
                    React.createElement(Dropdown, { name: "exchange", label: "Exchange", onChange: onChangeDropdown, options: exchangeOptions, value: exchangeOptions.find((o) => 'value' in o && o.value === data?.exchange) })))),
        React.createElement("div", { className: "co-form-buttons co-item" },
            React.createElement(Button, { disabled: disabledButton, onClick: onClickSave }, data?.id ? 'Update' : 'Create'))));
};
export default IpoForm;
