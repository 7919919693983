import ViewContext from 'contexts/ViewContext';
import * as React from 'react';
import AITest from './AITest';
const OpenNLPTest = () => {
    const { view } = React.useContext(ViewContext);
    switch (view) {
        case 'AI':
            return (React.createElement(AITest, null));
        default:
            return (React.createElement(AITest, null));
    }
};
export default OpenNLPTest;
