import { UserManager, WebStorageStateStore, } from 'oidc-client-ts';
import pkceAuthConfig from 'pages/pkceAuthConfig';
import { appFromModule, moduleFromApp, } from 'utils/appUtils';
import { APP_URL } from 'utils/system/envs-constants';
class AuthService {
    userManager;
    constructor() {
        const newStore = new WebStorageStateStore({ store: window.sessionStorage });
        const settings = { userStore: newStore, ...pkceAuthConfig, silent_redirect_uri: `${APP_URL}/silent` };
        this.userManager = new UserManager(settings);
    }
    accessibleApps = async () => {
        const user = await this.userManager.getUser();
        if (user === null) {
            return [];
        }
        const claims = user.profile;
        return Object.keys(claims.authorityMap).map((name) => appFromModule(name));
    };
    authorizationHeader = async () => {
        const user = await this.userManager.getUser();
        if (user === null) {
            return null;
        }
        return `Bearer ${user.access_token}`;
    };
    isAuthenticated = async () => {
        const user = await this.userManager.getUser();
        return user !== null;
    };
    appAuthorities = async (app) => {
        const user = await this.userManager.getUser();
        if (user === null) {
            return undefined;
        }
        const claims = user.profile;
        const name = moduleFromApp(app);
        if (claims.authorityMap[name] === undefined) {
            return undefined;
        }
        const values = claims.authorityMap[name];
        return values;
    };
    hasAuthority = async (app, value) => {
        if (value === undefined) {
            return true;
        }
        const values = await this.appAuthorities(app);
        if (values === undefined) {
            return false;
        }
        return values.includes(value) || values.includes(`ROLE_${value}`);
    };
    canAccess = async (app) => {
        const claims = await this.appAuthorities(app);
        return claims === undefined;
    };
    signoutRedirect = () => {
        (async () => {
            try {
                await this.userManager.signoutRedirect();
            }
            catch (error) {
                // ignore in case no access to localStorage
            }
        })();
    };
    signinCallback = async () => this.userManager.signinCallback();
    signinRedirect = async () => this.userManager.signinRedirect({ state: '6c2a55953db34a86b876e9e40ac2a202' });
    /** TO DO: THIS SHOULD BE SignoutResponse | undefine, but the TS doesn't recognise this yet */
    signoutCallback = async () => this.userManager.signoutCallback();
    checkEditable = async (object) => {
        const user = await this.userManager.getUser();
        if (user === null) {
            return false;
        }
        const claims = user.profile;
        return (!('createdBy' in object) || (object.permissions.other.write
            || (object.permissions.group.write && object.createdBy.company === claims.company)
            || (object.permissions.user.write && object.createdBy?.id === claims.id)));
    };
    removeUser = async () => this.userManager.removeUser();
}
export default AuthService;
