import * as React from 'react';
import ServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import './EditableCell.scss';
import './EditableDateCell.scss';
import { DatePicker } from '@symphony-ui/uitoolkit-components';
import RenderingContext from 'contexts/RenderingContext';
import { datePattern } from 'components/custom-table/dateFormat';
import EditableCell from './EditableCell';
const EditableDateCell = ({ table, row, column, }) => {
    const { download } = React.useContext(DownloadContext);
    const { dateFormat, printDate } = React.useContext(RenderingContext);
    const editableCellRef = React.useRef(null);
    const [isDisabled, setDisabled] = React.useState(false);
    const [isEditing, setEditing] = React.useState(false);
    const [value, setValue] = React.useState();
    const [title, setTitle] = React.useState();
    const text = React.useMemo(() => {
        if (value === undefined) {
            return '';
        }
        try {
            return printDate(value);
        }
        catch (error) {
            return `ERROR: ${error}`;
        }
    }, [printDate, value]);
    const save = React.useCallback(async (date) => {
        const dateText = date instanceof Date
            ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString().substring(0, 10)
            : '';
        const url = new ServerURL('/syndicate/transactions/override');
        url.searchParams.append('value', dateText);
        url.searchParams.append('key', column.id);
        url.searchParams.append('transactionId', row.original.transactionId);
        url.searchParams.append('trancheIndex', row.original.trancheIndex.toString());
        const request = new ServerRequest(url, { method: 'PATCH' });
        setDisabled(true);
        try {
            const response = await download.jsonPlus(request, 'Unable to save override information');
            if (table.options.meta?.updateTable !== undefined) {
                table.options.meta.updateTable(response);
            }
        }
        catch (error) {
            //
        }
        setDisabled(false);
        setEditing(false);
    }, [column, download, row.original, table]);
    const onBlur = React.useCallback(() => {
        setValue(undefined);
        (async () => save(undefined))();
    }, [save]);
    const onChange = React.useCallback((event) => {
        const localDate = event.target.value ?? undefined;
        setValue(localDate);
        (async () => save(localDate))();
    }, [save]);
    const onKeyUp = React.useCallback((event) => {
        if (event.key === 'Enter') {
            (async () => save(value))();
        }
    }, [save, value]);
    React.useEffect(() => {
        setValue(column.accessorFn !== undefined ? column.accessorFn(row.original, row.index) : undefined);
    }, [column, row.original, row.index]);
    React.useEffect(() => {
        if (row.original.overrideMap?.[column.id] != null && column.accessorFn !== undefined) {
            const copy = { ...row.original };
            delete copy.overrideMap;
            try {
                const titleText = printDate(column.accessorFn(copy, row.index));
                setTitle(titleText);
            }
            catch (error) {
                setTitle('ERROR');
            }
        }
        else {
            setTitle(undefined);
        }
    }, [column, printDate, row.index, row.original]);
    if (column.accessorFn === undefined) {
        return React.createElement("p", null, "ERROR");
    }
    return (React.createElement(EditableCell, { ref: editableCellRef, isOverriden: row.original.overrideMap?.[column.id] != null, setEditing: setEditing, title: title }, isEditing
        ? (React.createElement("div", { className: "co-editable-date" },
            React.createElement(DatePicker, { disabled: isDisabled, format: datePattern(dateFormat), onBlur: onBlur, onChange: onChange, onKeyUp: onKeyUp, showRequired: false, showOverlay: true })))
        : (React.createElement("div", null, text))));
};
export default EditableDateCell;
