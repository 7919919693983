export const datePattern = (format) => {
    switch (format) {
        case 'iso':
            return 'yyyy-MM-dd';
        case 'normal': {
            const mapPart = (part) => {
                switch (part.type) {
                    case 'literal':
                        return part.value;
                    case 'day':
                        return 'dd';
                    case 'month':
                        return 'MM';
                    case 'year':
                        return 'yyyy';
                    default:
                        return null;
                }
            };
            return (new Intl.DateTimeFormat()).formatToParts(new Date()).map((p) => mapPart(p)).join('');
        }
        default:
            return 'unkown date pattern';
    }
};
export const printDate = (date, format) => {
    if (date === undefined) {
        return '';
    }
    switch (format) {
        case 'iso': {
            return date.toISOString().replace('T00:00:00.000Z', '');
        }
        case 'normal': {
            const { locale } = Intl.NumberFormat().resolvedOptions();
            if (date.toISOString().endsWith('T00:00:00.000Z')) {
                return date.toLocaleString(locale, {
                    day: 'numeric', month: 'numeric', year: 'numeric',
                });
            }
            return date.toLocaleString(locale, {
                day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', second: '2-digit',
            });
        }
        default:
            return 'unkown date pattern';
    }
};
