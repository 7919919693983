import { BannerType, Button, Dropdown, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import MessageContext from 'contexts/MessageContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import * as React from 'react';
const TableSettings = ({ onClose, loadState, settingsId, setSettingsId, tableStateOptions, setTableStateOptions, }) => {
    const { download } = React.useContext(DownloadContext);
    const { setMessage } = React.useContext(MessageContext);
    const [loadDisabled, setLoadDisabled] = React.useState();
    const [initialHeight, setInitialHeight] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    const dropdownRef = React.useRef(null);
    const onClickLoad = React.useCallback(() => {
        if (settingsId !== undefined) {
            const url = new ServerURL('/syndicate/table');
            url.searchParams.append('id', settingsId);
            const request = new ServerRequest(url);
            (async () => {
                try {
                    const body = await download.jsonPlus(request, 'unable to load table settings');
                    loadState(body);
                    onClose();
                }
                catch (error) {
                    setMessage({ content: 'Unable to find table options', variant: BannerType.ERROR });
                }
            })();
        }
    }, [download, loadState, onClose, settingsId, setMessage]);
    const onClickDelete = React.useCallback(() => {
        const request = new ServerRequest('/syndicate/table', { body: settingsId, method: 'DELETE' });
        (async () => {
            try {
                await download.noContentPlus(request, 'unable to delete table settings');
                const newOptions = tableStateOptions.filter((o) => 'value' in o && o.value !== settingsId);
                setTableStateOptions(newOptions);
                setSettingsId(undefined);
            }
            catch (error) {
                setMessage({ content: 'Unable to find table options', variant: BannerType.ERROR });
            }
        })();
    }, [download, setTableStateOptions, tableStateOptions, settingsId, setSettingsId, setMessage]);
    const onChange = React.useCallback((event) => {
        setSettingsId(event.target.value.value);
    }, [setSettingsId]);
    const onMenuClose = React.useCallback(() => {
        setHeight(0);
    }, []);
    React.useEffect(() => {
        setLoadDisabled(settingsId === undefined);
    }, [settingsId]);
    React.useLayoutEffect(() => {
        // Select the node that will be observed for mutations
        // Options for the observer (which mutations to observe)
        const config = { attributes: true, childList: true, subtree: true };
        const callback = (mutationList) => {
            mutationList.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    mutation.addedNodes.forEach((n) => {
                        if (n.nodeType === Node.ELEMENT_NODE) {
                            const element = n;
                            if (element.tagName === 'DIV' && element.classList.contains('tk-select__menu')) {
                                // Note: the 6 is 'margin-top' for 'tk-select__menu'
                                setHeight(element.clientHeight + 6);
                            }
                            if (element.tagName === 'DIV' && element.classList.contains('tk-dialog-backdrop')) {
                                const dropdownContainer = element.querySelector('.co-dropdown-container');
                                if (dropdownContainer !== null) {
                                    setInitialHeight(dropdownContainer.scrollHeight);
                                }
                            }
                        }
                    });
                }
            });
        };
        const observer = new MutationObserver(callback);
        const targetNode = document.body;
        if (targetNode !== null) {
            // Start observing the target node for configured mutations
            observer.observe(targetNode, config);
        }
    }, []);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, parentNode: document.body, show: true, size: "large" },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, "Table Settings")),
        React.createElement(ModalBody, null,
            React.createElement("div", { style: {
                    display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%',
                } },
                React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between', width: '100%' } },
                    React.createElement("div", { className: "co-dropdown-container", ref: dropdownRef, style: { height: `${initialHeight + height}px`, width: '100%' } },
                        React.createElement(Dropdown, { label: "Saved Table Settings", onChange: onChange, onMenuClose: onMenuClose, options: tableStateOptions, value: tableStateOptions.find((o) => 'value' in o && o.value === settingsId) })),
                    React.createElement("div", { style: { display: 'flex' } },
                        React.createElement(Button, { disabled: loadDisabled, onClick: onClickLoad, style: { marginLeft: '1rem', marginTop: '1.5rem' } }, "LOAD"),
                        React.createElement(Button, { disabled: loadDisabled, onClick: onClickDelete, style: { marginLeft: '1rem', marginTop: '1.5rem' } }, "DELETE"))))),
        React.createElement(ModalFooter, null)));
};
export default TableSettings;
