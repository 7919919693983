const printGovernmentBenchmark = (benchmark) => {
    switch (benchmark.type) {
        case 'BONO':
            return 'SPGB';
        case 'BTP':
            return 'BTP';
        case 'BUND':
            return 'B';
        case 'CAN':
            return 'CAN';
        case 'GILT':
            return 'UKT';
        case 'OAT':
            return 'OAT';
        case 'OBL':
            return 'B';
        case 'OLO':
            return 'OLO';
        case 'UST':
            return 'T';
        default:
        //
    }
    if (benchmark.onTheRun !== undefined) {
        return 'T';
    }
    return 'UNKNOWN';
};
export const printInterestRateBenchmark = (rate) => {
    const list = [];
    if (rate.designatedMaturity !== undefined) {
        if (rate.designatedMaturity.periodMultiplier !== undefined) {
            list.push(rate.designatedMaturity.periodMultiplier.toString());
        }
        if (rate.designatedMaturity.period !== undefined) {
            list.push(rate.designatedMaturity.period.toLowerCase());
        }
    }
    switch (rate.benchmark) {
        case 'BBSW':
            list.push('BBSW');
            break;
        case 'DM':
            list.push('DM');
            break;
        case 'EURIBOR':
            list.push('E');
            break;
        case 'NIBOR':
            list.push('N');
            break;
        case 'SOFR':
            list.push('SOFR');
            break;
        case 'SONIA':
            list.push('SONIA');
            break;
        case 'STIBOR':
            list.push('S');
            break;
        case 'WIBOR':
            list.push('W');
            break;
        default:
    }
    //
    return list.join('');
};
export const benchmarkName = (benchmark) => {
    if (benchmark.swap != null) {
        return benchmark.swap.side === 'ASW' ? 'ASW' : 'MS';
    }
    if (benchmark.governmentBond !== undefined) {
        return printGovernmentBenchmark(benchmark.governmentBond);
    }
    if (benchmark.interestRate !== undefined) {
        return printInterestRateBenchmark(benchmark.interestRate);
    }
    return '';
};
const printPrefix = (prefix) => {
    switch (prefix) {
        case 'HIGH':
            return 'high';
        case 'MID':
            return 'mid';
        case 'LOW':
            return 'low';
        case 'LOW_MID':
            return 'low/mid';
        case 'MID_HIGH':
            return 'mid/high';
        default:
            return '';
        //
    }
};
const printSpread = (spread) => {
    const items = [];
    if (spread.sofrEquivalent === true) {
        return 'SOFR Equiv.';
    }
    if (spread.benchmark !== undefined) {
        items.push(benchmarkName(spread.benchmark));
    }
    if (spread.range !== undefined) {
        items.push(`${spread.range.minimum > 0 ? '+' : ''}${spread.range.minimum}-${spread.range.maximum}`);
        if ('qualifier' in spread.range) {
            items.push(spread.range.qualifier === 'AREA' ? 'area' : spread.range.qualifier);
        }
    }
    else if (spread.value !== undefined) {
        if ('prefix' in spread.value) {
            const sign = 'value' in spread.value && spread.value.value > 0 ? '+' : '';
            const prefixText = printPrefix(spread.value.prefix);
            items.push(`${sign}${prefixText}`);
        }
        if ('value' in spread.value) {
            const extraS = 'prefix' in spread.value && spread.value.prefix.length > 0 ? '\'s' : '';
            const sign = spread.value.value > 0 && spread.value.prefix === undefined ? '+' : '';
            items.push(sign + spread.value.value + extraS);
        }
        if ('suffix' in spread.value) {
            items.push(spread.value.suffix === 'AREA' ? 'area' : spread.value.suffix);
        }
        if (spread.variation !== undefined) {
            items.push(`±${spread.variation.value}`);
            if ('suffix' in spread.variation) {
                items.push(spread.variation.suffix);
            }
        }
    }
    return items.join(' ');
};
const printYield = (yieldGuidance) => {
    const items = [];
    if (yieldGuidance.value !== undefined) {
        if ('prefix' in yieldGuidance.value) {
            const prefixText = printPrefix(yieldGuidance.value.prefix);
            items.push(prefixText);
        }
        if ('value' in yieldGuidance.value) {
            const extraS = 'prefix' in yieldGuidance.value && yieldGuidance.value.prefix.length > 0 ? 's' : '';
            items.push(`${yieldGuidance.value.value}%${extraS}`);
        }
        if ('suffix' in yieldGuidance.value) {
            items.push(yieldGuidance.value.suffix === 'AREA' ? 'area' : yieldGuidance.value.suffix);
        }
    }
    else if (yieldGuidance.range) {
        items.push(`${yieldGuidance.range.minimum} - ${yieldGuidance.range.maximum}%`);
    }
    return items.join(' ');
};
const printGuidance = (guidance) => {
    if (guidance === undefined) {
        return '';
    }
    if (guidance.spread !== undefined) {
        return printSpread(guidance.spread);
    }
    if (guidance.yield !== undefined) {
        return printYield(guidance.yield);
    }
    return '';
};
export default printGuidance;
