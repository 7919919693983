import { Modal, ModalBody, ModalHeader, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import * as React from 'react';
import { Source } from '../../../../nit/sources/sources';
import AnnaSecurity from '../../../../nit/models/security/AnnaSecurity';
import EsmaSecurity from '../../../../nit/models/security/EsmaSecurity';
import EuroclearSecurity from '../../../../nit/models/security/EuroclearSecurity';
import OtherSourcesRow from './OtherSourcesRow';
import './OtherSourcesModal.scss';
const OtherSourcesModal = ({ hideModal, show, title, isin, }) => {
    const { download } = React.useContext(DownloadContext);
    const [anna, setAnna] = React.useState();
    const [annaError, setAnnaError] = React.useState();
    const [annaLoading, setAnnaLoading] = React.useState(false);
    const [esma, setEsma] = React.useState();
    const [esmaError, setEsmaError] = React.useState();
    const [esmaLoading, setEsmaLoading] = React.useState(false);
    const [euroclear, setEuroclear] = React.useState();
    const [euroclearError, setEuroclearError] = React.useState();
    const [euroclearLoading, setEuroclearLoading] = React.useState(false);
    const getRequest = React.useCallback((type, path) => {
        const url = new ServerURL(`/nit/${type}/${path}`);
        url.searchParams.append('isin', isin);
        return new ServerRequest(url);
    }, [isin]);
    React.useEffect(() => {
        if (isin !== null) {
            (async () => {
                setEuroclearLoading(true);
                const request = getRequest('euroclear', 'find');
                try {
                    const response = await download.fetchWithCsrf(request);
                    if (response.status === 200) {
                        const security = await response.json();
                        setEuroclear(Object.assign(new EuroclearSecurity(), security));
                    }
                    else if (response.status === 204) {
                        setEuroclear(null);
                    }
                    else {
                        const problemDetail = await response.json();
                        setEuroclearError(problemDetail);
                    }
                }
                catch (error) {
                    //
                }
                finally {
                    setEuroclearLoading(false);
                }
            })();
            (async () => {
                setAnnaLoading(true);
                const request = getRequest('anna', 'find');
                try {
                    const response = await download.fetchWithCsrf(request);
                    if (response.status === 200) {
                        const security = await response.json();
                        setAnna(Object.assign(new AnnaSecurity(), security));
                    }
                    else if (response.status === 204) {
                        setAnna(null);
                    }
                    else {
                        const problemDetail = await response.json();
                        setAnnaError(problemDetail);
                    }
                }
                catch (error) {
                    //
                }
                finally {
                    setAnnaLoading(false);
                }
            })();
            (async () => {
                setEsmaLoading(true);
                const request = getRequest('esma', 'find-with-company');
                try {
                    const response = await download.fetchWithCsrf(request);
                    if (response.status === 200) {
                        const bondWithCompany = await response.json();
                        setEsma(Object.assign(new EsmaSecurity(), bondWithCompany.security, { issuername: bondWithCompany.company.entity.legalName.name }));
                    }
                    else if (response.status === 204) {
                        setEsma(null);
                    }
                    else {
                        const problemDetail = await response.json();
                        setEsmaError(problemDetail);
                    }
                }
                catch (error) {
                    //
                }
                finally {
                    setEsmaLoading(false);
                }
            })();
        }
    }, [download, getRequest, isin]);
    const onClose = React.useCallback(() => {
        setAnna(undefined);
        setEsma(undefined);
        setEuroclear(undefined);
        hideModal();
    }, [hideModal]);
    return (React.createElement(Modal, { size: "full-width", show: show, closeButton: true, onClose: onClose, parentNode: document.body },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, title)),
        React.createElement(ModalBody, null,
            React.createElement("table", { style: { tableLayout: 'fixed', width: '100%' } },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: "co-header", style: { height: '26px', width: '72px' } }, "Source"),
                        React.createElement("th", { className: "co-header" }, "Issuer"),
                        React.createElement("th", { className: "co-header", style: { width: '60px' } }, "Ccy"),
                        React.createElement("th", { className: "co-header", style: { width: '120px' } }, "Amount"),
                        React.createElement("th", { className: "co-header", style: { width: '100px' } }, "Maturity"))),
                React.createElement("tbody", null,
                    React.createElement(OtherSourcesRow, { source: Source.ANNA, security: anna, isLoading: annaLoading, error: annaError }),
                    React.createElement(OtherSourcesRow, { source: Source.ESMA, security: esma, isLoading: esmaLoading, error: esmaError }),
                    React.createElement(OtherSourcesRow, { source: Source.EUROCLEAR, security: euroclear, isLoading: euroclearLoading, error: euroclearError })),
                React.createElement("tbody", null)))));
};
export default OtherSourcesModal;
