import * as React from 'react';
import { Dropdown } from '@symphony-ui/uitoolkit-components';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import { getOptionsMulti } from 'utils/change';
const MultiInput = ({ label, name, onChange, values, }) => {
    const [options, setOptions] = React.useState([]);
    const asyncOptions = async (inputText) => {
        const url = new ServerURL('/nit/esma/options');
        url.searchParams.append('name', name);
        url.searchParams.append('q', inputText);
        url.searchParams.append('values', values.join(','));
        const request = new ServerRequest(url);
        const response = await fetch(request);
        const body = await response.json();
        setOptions(body);
        return body;
    };
    return (React.createElement(Dropdown, { asyncOptions: asyncOptions, label: label, isMultiSelect: true, onChange: onChange, value: getOptionsMulti(values, options) }));
};
export default MultiInput;
