import * as React from 'react';
import ConnectivityAlert from 'pages/ConnectivityAlert';
import TabContainer from 'components/TabContainer';
import LoadingPage from 'components/loading-page/LoadingPage';
import TabContext from 'contexts/TabContext';
import AppViewContext from 'contexts/AppViewContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { authorities } from 'models/authorityHelpers';
import NavMenu from './NavMenu';
import Settings from './settings/Settings';
const PageWrapper = ({ pages, settings, }) => {
    const { appView, userManager } = React.useContext(AppViewContext);
    const [tabId, setTabId] = React.useState();
    const [page, setPage] = React.useState(pages.find((p) => p.label === tabId));
    const [showSettings, setShowSettings] = React.useState(false);
    const [colorScheme, setColorScheme] = React.useState();
    const [tabs, setTabs] = React.useState([]);
    const tabValue = React.useMemo(() => ({ setTabId, tabId }), [tabId]);
    React.useEffect(() => {
        if (appView !== null && userManager !== undefined) {
            (async () => {
                const map = await authorities(userManager);
                const auth = map?.has(appView) ? map.get(appView) : undefined;
                const newTabs = pages
                    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                    .filter((p) => p.auth === undefined || auth?.includes(p.auth) || auth?.includes(`ROLE_${p.auth}`))
                    .map((p) => ({ id: p.label, label: p.label }));
                setTabs(newTabs);
            })();
        }
    }, [appView, pages, userManager]);
    React.useEffect(() => {
        if (tabId === undefined && tabs.length > 0) {
            setTabId(tabs[0].label);
        }
        const newPage = pages.find((p) => p.label === tabId);
        setPage(newPage);
    }, [pages, setPage, setTabId, tabId, tabs]);
    React.useEffect(() => {
        try {
            const storedTab = localStorage.getItem('tab');
            if (storedTab !== null && appView !== null) {
                const tabObject = JSON.parse(storedTab);
                if (tabObject[appView]) {
                    setTabId(tabObject[appView]);
                }
                else {
                    setTabId(tabs[0].label);
                }
            }
        }
        catch (error) {
            setTabId(tabs[0].label);
            // no access to localStorage
        }
    }, [appView, tabs]);
    React.useEffect(() => {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            setColorScheme('dark');
        }
        else {
            setColorScheme('light');
        }
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
            setColorScheme(event.matches ? 'dark' : 'light');
        });
    }, []);
    React.useEffect(() => {
        try {
            if (tabId !== undefined && appView !== null) {
                const stored = localStorage.getItem('tab');
                const tabObject = stored !== null ? JSON.parse(stored) : {};
                tabObject[appView] = tabId.toString();
                localStorage.setItem('tab', JSON.stringify(tabObject));
            }
        }
        catch (error) {
            // no access to localStorage
        }
    }, [appView, tabId]);
    const queryClient = React.useMemo(() => new QueryClient(), []);
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement(DndProvider, { backend: HTML5Backend },
            React.createElement(TabContext.Provider, { value: tabValue },
                React.createElement("div", { className: colorScheme === 'dark' ? 'dark' : '', style: { display: 'flex', flexDirection: 'column', height: '100vh' } },
                    React.createElement(ConnectivityAlert, null),
                    React.createElement(NavMenu, { setShowSettings: setShowSettings, tabs: tabs }),
                    page !== undefined ? (React.createElement(TabContainer, { defaultView: page.defaultView }, !showSettings
                        ? page.element
                        : (React.createElement(Settings, { onClose: () => setShowSettings(false) }, settings)))) : (React.createElement(LoadingPage, null)))))));
};
export default PageWrapper;
