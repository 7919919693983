import { Card, SelectionStatus, Switch, Typography, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import SectorSelector from './SectorSelector';
const NotificationSettings = () => {
    const { download } = React.useContext(DownloadContext);
    const [notification, setNotification] = React.useState(undefined);
    const pushId = React.useMemo(async () => {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;
        const subscription = await serviceWorkerRegistration.pushManager.getSubscription();
        if (subscription === null) {
            return undefined;
        }
        const key = subscription.getKey ? subscription.getKey('p256dh') : null;
        const auth = subscription.getKey ? subscription.getKey('auth') : null;
        if (key === null || auth === null) {
            return undefined;
        }
        const auth8Array = new Uint8Array(auth);
        const authText = String.fromCharCode.apply(null, Array.from(auth8Array));
        const key8Array = new Uint8Array(key);
        const keyText = String.fromCharCode.apply(null, Array.from(key8Array));
        return {
            auth: window.btoa(authText),
            endpoint: subscription.endpoint,
            key: window.btoa(keyText),
        };
    }, []);
    const saveNotification = React.useCallback((newNotification) => {
        setNotification(newNotification);
        (async () => {
            const p = await pushId;
            if (p !== undefined && newNotification !== undefined) {
                const formData = new FormData();
                formData.append('subscription', new Blob([JSON.stringify(p)], { type: 'application/json' }));
                formData.append('notifications', new Blob([JSON.stringify(newNotification)], { type: 'application/json' }));
                const request = new ServerRequest('/syndicate/notification-sub', { body: formData, method: 'POST' });
                try {
                    await download.noContentPlus(request, 'Error saving notification.');
                }
                catch (error) {
                    //
                }
            }
        })();
    }, [download, pushId]);
    const onChangeAdminNotifications = React.useCallback(() => {
        const not = notification !== undefined ? { ...notification } : {};
        not.admin = !not.admin;
        saveNotification(not);
    }, [notification, saveNotification]);
    const onChangeDevNotifications = React.useCallback(() => {
        const not = notification !== undefined ? { ...notification } : {};
        not.dev = !not.dev;
        saveNotification(not);
    }, [notification, saveNotification]);
    const saveSectors = React.useCallback((sectors) => {
        const not = notification !== undefined ? { ...notification, sectors } : { sectors };
        saveNotification(not);
    }, [notification, saveNotification]);
    React.useEffect(() => {
        (async () => {
            const p = await pushId;
            if (p !== undefined) {
                const url = new ServerURL('/syndicate/notification-sub');
                url.searchParams.append('key', p.key);
                url.searchParams.append('auth', p.auth);
                url.searchParams.append('endpoint', p.endpoint);
                const request = new ServerRequest(url);
                try {
                    const body = await download.jsonPlus(request, 'Error downloading notification subscription.');
                    setNotification(body);
                }
                catch (error) {
                    //
                }
            }
        })();
    }, [download, pushId]);
    return (React.createElement(Card, { className: "co-card-stack", key: "NOTIFICATION", style: { padding: '1rem', height: 'auto' } },
        React.createElement(Typography, { type: "h1" }, "Notifications"),
        React.createElement(Switch, { label: "Get Admin Notifications", name: "admin-notifications", onChange: onChangeAdminNotifications, status: notification?.admin ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: notification?.admin ? 'true' : 'false' }),
        React.createElement(Switch, { label: "Get Developer Notifications", name: "dev-notifications", onChange: onChangeDevNotifications, status: notification?.dev ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: notification?.dev ? 'true' : 'false' }),
        React.createElement(SectorSelector, { sectors: notification?.sectors ?? [], save: saveSectors })));
};
export default NotificationSettings;
