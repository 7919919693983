import TransactionWrapper from './TransactionWrapper';
class TrancheWrapper {
    data;
    deleted;
    meta;
    overrideMap;
    trancheIndex;
    transaction;
    constructor({ data, deleted, meta, overrideMap, trancheIndex, transaction, transactionId, }) {
        this.data = data;
        this.deleted = deleted;
        this.meta = meta;
        this.overrideMap = overrideMap;
        this.trancheIndex = trancheIndex;
        this.transaction = new TransactionWrapper(transaction);
        this.transaction.id = transactionId;
    }
}
export default TrancheWrapper;
