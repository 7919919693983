import * as React from 'react';
import { Button, Card, Typography, } from '@symphony-ui/uitoolkit-components';
import ActionCell from 'components/custom-table/action/ActionCell';
import CustomTable from 'components/custom-table/CustomTable';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
const CompanyTable = ({ create, deleteFn, edit, hasError, isLoading, companies, types, }) => {
    const { download } = React.useContext(DownloadContext);
    const columns = React.useMemo(() => [
        {
            cell: ActionCell,
            id: 'actions',
            meta: {
                actionsMenu: [
                    { callback: edit, label: 'Edit', type: 'info' },
                    { callback: deleteFn, label: 'Delete', type: 'error' },
                ],
            },
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'lei',
            header: 'LEI',
        },
        {
            accessorFn: (company) => types.filter((t) => company.types.includes(t.value)).map((t) => t.label).sort().join(', '),
            header: 'Types',
            id: 'types',
        },
    ], [deleteFn, edit, types]);
    const clickBloomberg = React.useCallback(() => {
        const request = new ServerRequest('/core/companies/tickers');
        try {
            (async () => download.noContentPlus(request, 'Unable to download Tickers'))();
        }
        catch (error) {
            //
        }
    }, [download]);
    const clickDownload = React.useCallback(() => {
        const request = new ServerRequest('/mic/download');
        try {
            (async () => download.noContentPlus(request, 'Unable to download MICs'))();
        }
        catch (error) {
            //
        }
    }, [download]);
    return (React.createElement(Card, null,
        React.createElement("div", { className: "card-header" },
            React.createElement(Typography, { type: "h1" }, "Companies"),
            React.createElement("div", { className: "co-dropdown-row" },
                React.createElement(Button, { disabled: hasError, onClick: clickBloomberg }, "Download Tickers"),
                React.createElement(Button, { disabled: hasError, onClick: clickDownload }, "Download MICs"),
                React.createElement(Button, { disabled: hasError, onClick: create }, "Create New"))),
        React.createElement(CustomTable, { columns: columns, data: companies, emptyMessage: "You have no companies yet", errorMessage: "Error downloading companies", hasError: hasError, isLoading: isLoading, name: "company" })));
};
export default CompanyTable;
