import * as React from 'react';
import { Navigate } from 'react-router-dom';
const LogoutHandler = ({ authenticated, setAuth, setUserInfo, userManager, }) => {
    console.log('Start CallbackHandler. Authenticated: %o', authenticated);
    const [isLoading, setLoading] = React.useState(true);
    React.useEffect(() => {
        (async () => {
            console.log('Started CallbackHandler. Setting user using SigninRedirectCallback');
            try {
                await userManager.removeUser();
            }
            catch (error) {
                console.error('Error when trying to get access token in the CallbackHandler.  Error %o', error);
            }
            finally {
                setLoading(false);
                setAuth(false);
                setUserInfo(null);
            }
        })();
    }, [setAuth, setUserInfo, userManager]);
    console.log('End CallbackHandler - Authenticated: %o. Is Loading: %o', authenticated, isLoading);
    if (isLoading) {
        return (React.createElement("div", null, "Loading"));
    }
    return (React.createElement(Navigate, { to: "/" }));
};
export default LogoutHandler;
