import * as React from 'react';
import { Button, Card, Dropdown, TextField, } from '@symphony-ui/uitoolkit-components';
import CardHeaderForm from 'components/card-header-form/CardHeaderForm';
import DownloadContext from 'contexts/DownloadContext';
import TabContext from 'contexts/TabContext';
import ViewContext from 'contexts/ViewContext';
import ServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import Party from '../../models/Party';
const PartyUnknowns = ({ parties, save, buttonLoading, }) => {
    const { download } = React.useContext(DownloadContext);
    const { setView } = React.useContext(ViewContext);
    const { setTabId } = React.useContext(TabContext);
    const [disabledButton, setDisabledButton] = React.useState(true);
    const [disabledSourceButton, setDisabledSourceButton] = React.useState(true);
    const [disabledParty, setDisabledParty] = React.useState(false);
    const [disabledNewName, setDisabledNewName] = React.useState(false);
    const [loadingSourceButton, setLoadingSourceButton] = React.useState(false);
    const [options, setPartyOptions] = React.useState([]);
    const [nameOptions, setNameOptions] = React.useState([]);
    const [party, setParty] = React.useState();
    const [name, setName] = React.useState();
    const [newName, setNewName] = React.useState('');
    const buttonRef = React.useRef(null);
    const onChangeName = React.useCallback((event) => {
        setName(event.target?.value);
        setNewName('');
        setParty(undefined);
    }, []);
    const onChangeInput = React.useCallback((event) => {
        setNewName(event.target?.value);
    }, []);
    const onChangeParty = React.useCallback((event) => {
        setParty(event.target?.value);
    }, []);
    const findSource = React.useCallback(() => {
        if (name !== undefined) {
            (async () => {
                setLoadingSourceButton(true);
                try {
                    const url = new ServerURL('/syndicate/transactions/find-with-manager');
                    url.searchParams.append('name', name.value);
                    const request = new ServerRequest(url);
                    const body = await download.jsonPlus(request, 'Unable to find source');
                    setLoadingSourceButton(false);
                    localStorage.setItem('transactionId', body.transactionId);
                    localStorage.setItem('index', body.index);
                    localStorage.setItem('view', 'UPDATE');
                    setView('UPDATE');
                    setTabId('Transactions');
                }
                catch (error) {
                    //
                }
                finally {
                    setLoadingSourceButton(false);
                }
            })();
        }
    }, [download, name, setTabId, setView]);
    const saveParty = React.useCallback(() => {
        if (name !== undefined) {
            const updatedParty = parties.find((p) => p.id === party?.value) ?? new Party();
            if (newName !== '') {
                updatedParty.name = newName;
                updatedParty.names = [];
            }
            updatedParty.names.push(name?.value);
            const newSet = new Set(updatedParty.names);
            updatedParty.names = [...newSet];
            (async () => {
                try {
                    await save(updatedParty);
                    const unknown = parties.find((p) => p.name === 'unknown');
                    if (unknown !== undefined) {
                        unknown.names = [...new Set(unknown.names.filter((n) => n !== name?.value))];
                        await save(unknown);
                    }
                    setParty(undefined);
                    setNewName('');
                }
                catch (error) {
                    //
                }
            })();
        }
    }, [name, newName, party, parties, save]);
    React.useEffect(() => {
        if (parties !== undefined) {
            const foundParty = parties.find((p) => p.name === 'unknown');
            if (foundParty !== undefined) {
                setNameOptions(foundParty.names.sort().map((t) => ({ label: t, value: t })));
            }
            setPartyOptions(parties.map((t) => ({ label: t.name, value: t.id })));
        }
    }, [parties]);
    React.useEffect(() => {
        setName(nameOptions[0]);
    }, [nameOptions]);
    React.useEffect(() => {
        setDisabledButton(name === undefined || (newName === '' && party === undefined));
    }, [name, newName, party]);
    React.useEffect(() => {
        setDisabledSourceButton(name === undefined);
    }, [name]);
    React.useEffect(() => {
        if (newName !== '') {
            setParty(undefined);
        }
        setDisabledParty(newName !== '');
    }, [newName]);
    React.useEffect(() => {
        setDisabledNewName(party !== undefined && party !== null);
    }, [party]);
    React.useEffect(() => {
        // if (party != null) {
        //   setNewName('');
        // }
        // if (buttonRef.current !== null) {
        //   buttonRef.current.getElementsByTagName('button')[0].focus();
        // }
    }, [party]);
    return (React.createElement(Card, null,
        React.createElement(CardHeaderForm, { newView: "TABLE", title: "Allocate unknown Bookrunner names" }),
        React.createElement("div", { className: "base-form" },
            React.createElement(Dropdown, { label: "Name to Allocate", name: "parties", onChange: onChangeName, options: nameOptions, value: name }),
            React.createElement(Dropdown, { isDisabled: disabledParty, isInputClearable: true, label: "Existing Party", name: "names", onChange: onChangeParty, options: options, value: party }),
            React.createElement(TextField, { disabled: disabledNewName, label: "New Name to Save", onChange: onChangeInput, value: newName || '' })),
        React.createElement("div", { className: "form-buttons", ref: buttonRef },
            React.createElement(Button, { disabled: disabledButton, loading: buttonLoading, onClick: saveParty },
                newName !== '' ? 'SAVE AS NEW' : undefined,
                party != null ? 'ADD TO EXISTING' : undefined,
                party == null && newName === '' ? 'SAVE AS NEW UNDER OWN NAME' : undefined),
            React.createElement(Button, { disabled: disabledSourceButton, loading: loadingSourceButton, onClick: findSource }, "SOURCE UPDATE"))));
};
export default PartyUnknowns;
