const mappie = (t) => {
    const newWrapper = {
        data: t.data,
        deleted: t.deleted,
        firstUpdate: new Date(t.firstUpdate),
        lastUpdate: new Date(t.lastUpdate),
        meta: t.meta,
        overrideMap: t.overrideMap,
        trancheIndex: t.trancheIndex,
        transactionId: t.transactionId,
    };
    if (t.meta?.pricingDate !== undefined) {
        newWrapper.meta.pricingDate = new Date(t.meta.pricingDate);
    }
    return newWrapper;
};
export default mappie;
