import { Button, Card, Dropdown, TextArea, TextField, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import * as React from 'react';
const AITest = () => {
    const { download } = React.useContext(DownloadContext);
    const [modelUrl, setModelUrl] = React.useState('https://dlcdn.apache.org/opennlp/models/langdetect/1.8.3/langdetect-183.bin');
    const [text, setText] = React.useState();
    const [languageResponse, setLanguageResponse] = React.useState();
    const options = React.useMemo(() => [{ label: 'language', value: 'language' }], []);
    const onChangeModelUrl = React.useCallback((event) => {
        setModelUrl(event.target.value);
    }, []);
    const onChangeText = React.useCallback((event) => {
        setText(event.target.value);
    }, []);
    const onClickLanguage = React.useCallback(() => {
        if (modelUrl !== undefined && text !== undefined) {
            const url = new ServerURL('/syndicate/ai/language');
            url.searchParams.append('modelUrl', modelUrl);
            url.searchParams.append('text', text);
            const request = new ServerRequest(url);
            (async () => {
                const response = await download.jsonPlus(request, "doesn't work");
                setLanguageResponse(response);
            })();
        }
    }, [download, modelUrl, text]);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement(Dropdown, { label: "Choose test", options: options, value: null }),
        React.createElement(TextArea, { label: "text", onChange: onChangeText, value: text }),
        React.createElement(TextField, { label: "model url", onChange: onChangeModelUrl, value: modelUrl }),
        React.createElement(Button, { onClick: onClickLanguage, size: "small" }, "GO"),
        languageResponse !== undefined
            ? (React.createElement(Card, { style: { marginTop: '1rem', padding: '1rem' } },
                "Language =",
                ' ',
                languageResponse.lang,
                ' ',
                "with",
                ' ',
                Math.round(languageResponse.confidence * 1000) / 10,
                "%",
                ' ',
                "confidence")) : ''));
};
export default AITest;
