import * as React from 'react';
import PageWrapper from 'pages/PageWrapper';
import NITSettings from './NITSettings';
import Results from './results/Results';
import Reports from './Reports';
import Companies from './Companies';
import Overview from './Overview';
import Searches from './Searches';
const NewIssueTrackerApp = () => (React.createElement(PageWrapper, { pages: [
        { element: (React.createElement(Overview, null)), label: 'Overview' },
        { defaultView: 'FORM', element: (React.createElement(Searches, null)), label: 'Search' },
        { element: (React.createElement(Results, null)), label: 'Results' },
        { defaultView: 'TABLE', element: (React.createElement(Reports, null)), label: 'Reports' },
        { element: (React.createElement(Companies, null)), label: 'Companies' }
    ], settings: ([React.createElement(NITSettings, null)]) }));
export default NewIssueTrackerApp;
