import * as React from 'react';
import { Button, Card, Typography, } from '@symphony-ui/uitoolkit-components';
import MetadataButtons from 'components/metadata/MetadataButtons';
import CardHeaderForm from 'components/card-header-form/CardHeaderForm';
import NewIssue from '../../model/NewIssue';
import TrancheOverview from './TrancheOverview';
import TrancheObject from '../../model/Tranche';
import NewIssueTask from './NewIssueTask';
const NewIssueManager = ({ edit, nextState, newIssue, states, }) => {
    const [data, setData] = React.useState(newIssue ?? new NewIssue());
    const [modalContent, setModalContent] = React.useState();
    const [task, setTask] = React.useState();
    const tasks = React.useMemo(() => [
        {
            header: 'Set up New Issue',
            label: 'Set Up',
            state: 'SET_UP',
            text: 'Set up',
        },
        {
            header: 'Send invite to Lead Managers',
            label: 'Send invite',
            state: 'PRE_LAUNCH',
            text: 'Send invite',
        },
        {
            header: 'Launch New Issue',
            label: 'Launch',
            state: 'LAUNCH',
            text: 'Send a message to all investors and news feeds',
        },
        {
            header: 'Open Order Books',
            label: 'Open Books',
            state: 'BOOKS_OPEN',
            text: 'Send a message to all investors that books are open.',
        },
        {
            header: 'Ask Investors to Confirm',
            label: 'Confirm Orders',
            state: 'CONFIRMATION',
            text: 'Send a message to all investors that put orders in asking to confirm their orders.',
        },
        {
            header: 'Close Order Books',
            label: 'Close Books',
            state: 'BOOKS_CLOSED',
            text: 'Send a message that books are closing',
        },
        {
            header: 'Allocate Order Books',
            label: 'Allocate',
            state: 'ALLOCATED',
            text: 'Send allocations to investors',
        },
        {
            header: 'Send pricing',
            label: 'Price',
            state: 'PRICED',
            text: 'Send pricing information to investors',
        },
    ], []);
    const tranches = React.useMemo(() => (newIssue?.tranches !== undefined
        ? newIssue.tranches.map((t, index) => Object.assign(new TrancheObject(), t, { key: index })) : []), [newIssue?.tranches]);
    React.useEffect(() => {
        const updated = Object.assign(new NewIssue(), newIssue, tranches);
        setData(updated);
    }, [newIssue, tranches]);
    React.useEffect(() => {
        if (newIssue !== undefined) {
            setData(newIssue);
            const stateIndex = tasks.findIndex((s) => s.state === newIssue.state);
            if (stateIndex + 1 < states.length) {
                setTask(tasks[stateIndex + 1]);
            }
        }
    }, [newIssue, states.length, tasks]);
    const onChangePermissions = React.useCallback((permissions) => {
        const updated = Object.assign(new NewIssue(), newIssue, permissions);
        setData(updated);
    }, [newIssue]);
    const onClickAction = React.useCallback(() => {
        if (newIssue !== undefined) {
            (async () => {
                await nextState(newIssue);
            })();
        }
    }, [newIssue, nextState]);
    const onClickEdit = React.useCallback(() => {
        if (newIssue !== undefined) {
            edit(newIssue);
        }
    }, [newIssue, edit]);
    const onClickTask = React.useCallback(() => {
        const hideModal = () => {
            setModalContent(undefined);
        };
        setModalContent(React.createElement(NewIssueTask, { bloombergMessage: "", hideModal: hideModal, nextState: onClickAction, task: task }));
    }, [onClickAction, task]);
    return (React.createElement(Card, null,
        modalContent,
        React.createElement(CardHeaderForm, { title: `${data.issuer} - ${data.name}` }),
        React.createElement("div", { className: "co-item" },
            React.createElement("div", null,
                React.createElement(Typography, { type: "h1" }, "Tranches"),
                React.createElement(Typography, { type: "h1" }, data.state ? states.find((s) => s.value === data.state)?.label : undefined)),
            tranches.length ? tranches.map((t) => (React.createElement(TrancheOverview, { key: t.key, tranche: t }))) : (React.createElement(Typography, { type: "h1" }, "No Tranches yet"))),
        React.createElement("div", { className: "co-form-buttons-plus co-item" },
            React.createElement("div", { className: "co-form-buttons" },
                task ? (React.createElement(Button, { onClick: onClickTask }, task.label)) : '',
                React.createElement(Button, { onClick: onClickEdit }, "Edit")),
            React.createElement(MetadataButtons, { data: data, onChange: onChangePermissions, disabled: true }))));
};
export default NewIssueManager;
