import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import FilterItemInput from './FilterItemInput';
import './FilterItemInput.scss';
const RemoteFilterItem = ({ disabled = false, label, onChange, path, value, }) => {
    const { download } = React.useContext(DownloadContext);
    const [options, setOptions] = React.useState([]);
    const [hasError, setError] = React.useState(false);
    const [placeholder, setPlaceholder] = React.useState();
    React.useEffect(() => {
        if (path !== null) {
            (async () => {
                setPlaceholder('Loading...');
                try {
                    await download.options(path, 'Error downloading filter', setOptions);
                    setError(false);
                    setPlaceholder('Select...');
                }
                catch (error) {
                    setError(true);
                    setPlaceholder('Error downloading options...');
                }
            })();
        }
    }, [download, options.length, path]);
    React.useEffect(() => {
        if (options.length === 0) {
            setError(true);
            setPlaceholder('None available yet...');
        }
    }, [options.length]);
    return (React.createElement(FilterItemInput, { disabled: disabled || hasError, label: label, onChange: onChange, options: options, placeholder: placeholder, value: value }));
};
export default RemoteFilterItem;
