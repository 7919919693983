import ViewContext from 'contexts/ViewContext';
import * as React from 'react';
import Stream from './Stream';
const Live = () => {
    const { view } = React.useContext(ViewContext);
    switch (view) {
        case 'STREAM':
            return (React.createElement(Stream, null));
        default:
            return (React.createElement(Stream, null));
    }
};
export default Live;
