import * as React from 'react';
import { DropdownMenu, DropdownMenuDivider, DropdownMenuItem } from '@symphony-ui/uitoolkit-components';
import AppViewContext from 'contexts/AppViewContext';
import { App } from 'utils/appUtils';
import UserContext from 'contexts/UserContext';
const MenuItems = ({ closeMenu, setShowSettings, show }) => {
    const { setAppView } = React.useContext(AppViewContext);
    const { authService } = React.useContext(UserContext);
    const [menuItems, setMenuItems] = React.useState([]);
    const onClickApp = React.useCallback((key) => () => {
        closeMenu();
        setAppView(key);
        localStorage.setItem('app', key.toString());
    }, [closeMenu, setAppView]);
    const onClickLogout = React.useCallback(() => {
        closeMenu();
        console.log('Log out clicked');
        authService.signoutRedirect();
    }, [authService, closeMenu]);
    const onClickSettings = React.useCallback(() => {
        closeMenu();
        setShowSettings(true);
    }, [closeMenu, setShowSettings]);
    React.useEffect(() => {
        const items = [
            (React.createElement(DropdownMenuItem, { key: "settings", onClick: onClickSettings }, "Settings")),
            (React.createElement(DropdownMenuDivider, { key: "divider1" })),
        ];
        (async () => {
            const apps = await authService.accessibleApps();
            apps.forEach((key) => {
                items.push((React.createElement(DropdownMenuItem, { key: key, onClick: onClickApp(key) }, App[key])));
            });
            items.push((React.createElement(DropdownMenuDivider, { key: "divider2" })));
            items.push((React.createElement(DropdownMenuItem, { key: "log-out", onClick: onClickLogout }, "Log Out")));
            setMenuItems(items);
        })();
    }, [authService, onClickApp, onClickLogout, onClickSettings]);
    return (React.createElement(DropdownMenu, { className: "co-menu-dropdown", show: show }, menuItems));
};
export default MenuItems;
