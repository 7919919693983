import ViewContext from 'contexts/ViewContext';
import * as React from 'react';
import Overview from './overview/Overview';
import TransactionViewer from './transaction-viewer/TransactionViewer';
import UpdateManager from './update-manager/UpdateManager';
const Transactions = () => {
    const { setView, view } = React.useContext(ViewContext);
    const [index, setIndex] = React.useState(() => {
        const saved = localStorage.getItem('index');
        return saved !== null ? Number.parseInt(saved, 10) : null;
    });
    const [transactionId, setTransactionId] = React.useState(localStorage.getItem('transactionId') ?? undefined);
    React.useEffect(() => {
        if (view === 'UPDATE' && transactionId === undefined) {
            setView('OVERVIEW');
        }
    }, [transactionId, setView, view]);
    switch (view) {
        case 'OVERVIEW':
            return (React.createElement(Overview, { setIndex: setIndex, setTransactionId: setTransactionId }));
        case 'TABLE':
            return (React.createElement(TransactionViewer, { setIndex: setIndex, setTransactionId: setTransactionId }));
        case 'UPDATE':
            if (transactionId !== undefined) {
                return (React.createElement(UpdateManager, { index: index, setIndex: setIndex, setTransactionId: setTransactionId, transactionId: transactionId }));
            }
            return (React.createElement(Overview, { setIndex: setIndex, setTransactionId: setTransactionId }));
        default:
            return (React.createElement(Overview, { setIndex: setIndex, setTransactionId: setTransactionId }));
    }
};
export default Transactions;
