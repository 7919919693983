import * as React from 'react';
import Companies from 'pages/companies/Companies';
import Currencies from 'pages/currencies/Currencies';
import Benchmarks from 'pages/benchmarks/Benchmarks';
import Scheduling from 'pages/scheduling/Scheduling';
import ImitateUser from './ImitateUser';
import PageWrapper from '../PageWrapper';
import Users from './Users';
const AdminApp = () => (React.createElement(PageWrapper, { pages: [
        {
            auth: 'SUPER_ADMIN', defaultView: 'TABLE', element: (React.createElement(Users, null)), label: 'Users',
        },
        { element: (React.createElement(Scheduling, null)), label: 'Scheduling' },
        { defaultView: 'TABLE', element: (React.createElement(Companies, null)), label: 'Companies' },
        { defaultView: 'TABLE', element: (React.createElement(Currencies, null)), label: 'Currencies' },
        { defaultView: 'TABLE', element: (React.createElement(Benchmarks, null)), label: 'Benchmarks' },
    ], settings: [(React.createElement(ImitateUser, { key: "IMITATE" }))] }));
export default AdminApp;
