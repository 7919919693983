import * as React from 'react';
import ViewContext from 'contexts/ViewContext';
import CrudService from 'services/CrudService';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import LoadingPage from 'components/loading-page/LoadingPage';
import NewIssue from '../../model/NewIssue';
import NewIssueTable from './NewIssueTable';
import NewIssueForm from './NewIssueForm';
import NewIssueManager from './NewIssueManager';
const NewIssues = () => {
    const { view, setView } = React.useContext(ViewContext);
    const [newIssue, setNewIssue] = React.useState();
    const [newIssues, setNewIssues] = React.useState([]);
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const [states, setStates] = React.useState();
    const [benchmarkOptions, setBenchmarkOptions] = React.useState();
    const [currencyOptions, setCurrencyOptions] = React.useState();
    const [issuerOptions, setIssuerOptions] = React.useState();
    const [stateOptions, setStateOptions] = React.useState();
    const { download } = React.useContext(DownloadContext);
    const service = React.useMemo(() => new CrudService({ download, name: 'New Issue', path: '/synd/new-issues' }), [download]);
    React.useEffect(() => {
        (async () => {
            try {
                const body = await service.getPlus();
                const downloadedNewIssues = body.map((n) => Object.assign(new NewIssue(), n));
                setNewIssues(downloadedNewIssues);
            }
            catch (error) {
                setError(true);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [download, service]);
    React.useEffect(() => {
        (async () => {
            const request = new ServerRequest('/core/companies/options/issuers');
            try {
                const body = await download.jsonPlus(request, 'Unable to get the issuer options');
                setIssuerOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [download, service]);
    React.useEffect(() => {
        (async () => {
            const request = new ServerRequest('/core/currencies/options');
            try {
                const body = await download.jsonPlus(request, 'Unable to get the currency options');
                setCurrencyOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [download, service]);
    React.useEffect(() => {
        (async () => {
            const request = new ServerRequest('/core/benchmarks/options');
            try {
                const body = await download.jsonPlus(request, 'Unable to get the benchmark options');
                setBenchmarkOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [download, service]);
    React.useEffect(() => {
        (async () => {
            const request = new ServerRequest('/synd/new-issues/state/dropdown');
            try {
                const body = await download.jsonPlus(request, 'Unable to get the state names');
                setStateOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    const nextState = React.useCallback(async (data) => {
        const request = new ServerRequest('/synd/new-issues/state', { body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, method: 'PATCH' });
        try {
            const body = await download.jsonPlus(request, 'Unable to change state');
            const updatedNewIssue = Object.assign(new NewIssue(), body);
            const updatedNewIssues = CrudService.updateData(newIssues, updatedNewIssue);
            setNewIssues(updatedNewIssues);
            setNewIssue(updatedNewIssue);
        }
        catch (error) {
            //
        }
    }, [download, newIssues]);
    const create = React.useCallback(() => {
        setNewIssue(new NewIssue());
        setView('FORM');
    }, [setView]);
    const deleteFn = React.useCallback(async ({ row: { original: item } }) => {
        try {
            await service.delete(item);
            const filteredNewIssues = (newIssues ?? []).filter((n) => n.id !== item.id);
            setNewIssues(filteredNewIssues);
        }
        catch (error) {
            //
        }
    }, [newIssues, service]);
    const edit = React.useCallback(() => {
        setView('FORM');
    }, [setView]);
    const manage = React.useCallback(({ row: { original: item } }) => {
        setNewIssue(item);
        setView('MANAGE');
    }, [setView]);
    const nextStateMenu = React.useCallback(async ({ row: { original: item } }) => {
        await nextState(item);
    }, [nextState]);
    const save = React.useCallback((data) => async () => {
        try {
            const body = await (data.id ? service.updatePlus(data) : service.createPlus(data));
            const updatedNewIssue = Object.assign(new NewIssue(), body);
            const updatedNewIssues = CrudService.updateData(newIssues, updatedNewIssue);
            setNewIssues(updatedNewIssues);
            setView('TABLE');
        }
        catch (error) {
            //
        }
    }, [newIssues, service, setView]);
    switch (view) {
        case 'FORM':
            return (React.createElement(NewIssueForm, { benchmarkOptions: benchmarkOptions ?? [], currencyOptions: currencyOptions ?? [], issuerOptions: issuerOptions ?? [], newIssue: newIssue, save: save }));
        case 'MANAGE':
            return (React.createElement(NewIssueManager, { edit: edit, newIssue: newIssue, nextState: nextState, states: stateOptions ?? [] }));
        case 'TABLE':
            return (React.createElement(NewIssueTable, { create: create, deleteFn: deleteFn, hasError: hasError, isLoading: isLoading, issuerOptions: issuerOptions ?? [], manage: manage, nextStateMenu: nextStateMenu, newIssues: newIssues ?? [], states: states ?? [] }));
        default:
            setView('TABLE');
            return (React.createElement(LoadingPage, null));
    }
};
export default NewIssues;
