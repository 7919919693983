import { Card, Dropdown, SelectionStatus, Switch, Typography, } from '@symphony-ui/uitoolkit-components';
import AppViewContext from 'contexts/AppViewContext';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import * as React from 'react';
import { getOptions } from 'utils/change';
const ImitateUser = () => {
    const { imitate, setImitate } = React.useContext(AppViewContext);
    const { download } = React.useContext(DownloadContext);
    const [options, setOptions] = React.useState([]);
    const [imitatedUser, setImitatedUser] = React.useState();
    const [imitatedUserId, setImitatedUserId] = React.useState();
    const [disabled, setDisabled] = React.useState();
    const onChangeUserId = React.useCallback((event) => {
        const { value } = event.target.value;
        setImitatedUserId(value);
    }, []);
    const onChange = React.useCallback(() => {
        setDisabled(imitate);
        setImitate(!imitate);
    }, [imitate, setImitate]);
    React.useEffect(() => {
        if (imitate && imitatedUser !== undefined) {
            console.error('Please set this up again');
        }
        else {
            //
        }
    }, [imitate, imitatedUser]);
    React.useEffect(() => {
        if (imitatedUserId !== undefined) {
            const url = new ServerURL('/admin/users/auth');
            url.searchParams.append('id', imitatedUserId);
            const request = new ServerRequest(url);
            (async () => {
                try {
                    const body = await download.jsonPlus(request, 'Error downloading user');
                    setImitatedUser(body);
                }
                catch (error) {
                    //
                }
            })();
        }
        else {
            setImitatedUser(undefined);
        }
    }, [download, imitatedUserId]);
    React.useEffect(() => {
        const request = new ServerRequest('/syndicate/subscriptions/user-options');
        (async () => {
            try {
                const body = await download.jsonPlus(request, 'Error downloading users');
                setOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    return (React.createElement(Card, { className: "co-card-stack", key: "IMITATE", style: { padding: '1rem', height: 'auto' } },
        React.createElement(Typography, { type: "h1" }, "Imitate User"),
        React.createElement("div", { className: "form-buttons" },
            React.createElement(Switch, { label: "Imitate User", name: "html", onChange: onChange, status: imitate ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: imitate ? 'true' : 'false' })),
        imitate
            ? (React.createElement("div", { style: { width: '100%' } },
                React.createElement(Dropdown, { isDisabled: disabled, isInputClearable: true, options: options, label: "Choose user to imitate", onChange: onChangeUserId, value: getOptions(imitatedUserId, options) }))) : undefined));
};
export default ImitateUser;
