import { Button, Icon } from '@symphony-ui/uitoolkit-components';
import ServerRequest from 'models/ServerRequest';
import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import TrancheWrapper from '../../../../models/TrancheWrapper';
const SectorPicker = ({ label, setTranche, tranche, type, updateTable, value, }) => {
    const { download } = React.useContext(DownloadContext);
    const [isLoading, setLoading] = React.useState(false);
    const [hasError, setError] = React.useState(false);
    const onClickMeta = React.useCallback(() => {
        const formData = new FormData();
        formData.append('transactionId', tranche.transaction.id);
        formData.append('trancheIndex', tranche.trancheIndex.toString());
        const request = new ServerRequest(`/syndicate/meta/${type}`, { method: 'POST', body: formData });
        (async () => {
            setLoading(true);
            const body = await download.jsonPlus(request, `Unable to save ${type}`);
            if (body.length > 0) {
                const newTranches = body.map((t) => new TrancheWrapper(t));
                setTranche(newTranches[tranche.trancheIndex]);
                updateTable(newTranches);
            }
            else {
                setError(true);
            }
            setLoading(false);
        })();
    }, [download, setTranche, tranche.trancheIndex, tranche.transaction.id, type, updateTable]);
    return (React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' } },
        React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
            React.createElement("div", { className: "tk-label" }, label),
            React.createElement("div", null, !hasError ? value
                : (React.createElement("div", { style: { fontStyle: 'italic', paddingLeft: '0.5rem', color: '#dd342e' } }, "None found for LEI")))),
        React.createElement(Button, { iconButton: true, loading: isLoading, onClick: onClickMeta, size: "small" },
            React.createElement(Icon, { iconName: "reload" }))));
};
export default SectorPicker;
