import * as React from 'react';
import ServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import './EditableCell.scss';
import './EditableDateCell.scss';
import { Button, DatePicker } from '@symphony-ui/uitoolkit-components';
import RenderingContext from 'contexts/RenderingContext';
import { datePattern } from 'components/custom-table/dateFormat';
import Popup from 'components/custom-table/action/Popup';
import TrancheWrapper from '../../../../models/TrancheWrapper';
import EditableCell from './EditableCell';
const MaturityCell = ({ table, row, column, }) => {
    const { download } = React.useContext(DownloadContext);
    const { dateFormat, printDate } = React.useContext(RenderingContext);
    const editableCellRef = React.useRef(null);
    const [isDisabled, setDisabled] = React.useState(false);
    const [isEditing, setEditing] = React.useState(false);
    // Value is the value return by the accessorFn, and it is either a Date, or a string equal to 'Perpetual', or of the form 'MM/YYYY' or 'YYYY';
    const [value, setValue] = React.useState();
    const [title, setTitle] = React.useState();
    const [text, setText] = React.useState();
    const [choosePerpOrDate, setChoosePerpOrDate] = React.useState('perp');
    const remove = React.useCallback(async () => {
        const url = new ServerURL('/syndicate/transactions/override');
        url.searchParams.append('key', column.id);
        url.searchParams.append('transactionId', row.original.transaction.id);
        url.searchParams.append('trancheIndex', row.original.trancheIndex.toString());
        const request = new ServerRequest(url, { method: 'DELETE' });
        setDisabled(true);
        try {
            const body = await download.jsonPlus(request, 'Unable to save override information');
            if (table.options.meta?.updateTable !== undefined) {
                const tranches = body.map((t) => new TrancheWrapper(t));
                table.options.meta.updateTable(tranches);
                // we need to manually update the value as it won't have fed through yet into the row.
                const rowClone = { ...row.original };
                if (rowClone.overrideMap !== undefined && column.id in rowClone.overrideMap) {
                    delete rowClone.overrideMap[column.id];
                }
                setValue(column.accessorFn !== undefined ? column.accessorFn(rowClone, row.index) : undefined);
            }
        }
        catch (error) {
            setText(`ERROR: ${error}`);
        }
        setDisabled(false);
        setEditing(false);
        setChoosePerpOrDate('perp');
    }, [column, download, row.index, row.original, table]);
    const save = React.useCallback(async (saveDate) => {
        const url = new ServerURL('/syndicate/transactions/override');
        url.searchParams.append('value', saveDate ?? '');
        url.searchParams.append('key', column.id);
        url.searchParams.append('transactionId', row.original.transaction.id);
        url.searchParams.append('trancheIndex', row.original.trancheIndex.toString());
        const request = new ServerRequest(url, { method: 'PATCH' });
        setDisabled(true);
        setValue(undefined);
        try {
            const body = await download.jsonPlus(request, 'Unable to save override information');
            if (table.options.meta?.updateTable !== undefined) {
                const tranches = body.map((t) => new TrancheWrapper(t));
                table.options.meta.updateTable(tranches);
            }
        }
        catch (error) {
            setText(`ERROR: ${error}`);
        }
        setDisabled(false);
        setEditing(false);
        setChoosePerpOrDate('perp');
    }, [column, download, row.original, table]);
    const onBlur = React.useCallback(() => {
        setValue(undefined);
        (async () => save(undefined))();
    }, [save]);
    const onChange = React.useCallback((event) => {
        const localDate = event.target.value !== null
            ? new Date(event.target.value.getFullYear(), event.target.value.getMonth(), event.target.value.getDate())
            : undefined;
        setValue(localDate);
        setChoosePerpOrDate(localDate !== undefined ? 'date' : 'perp');
    }, []);
    const onClickDate = React.useCallback((mode) => () => {
        if (value instanceof Date) {
            const year = value.getFullYear().toString();
            const month = (value.getMonth() + 1).toString().padStart(2, '0');
            const day = value.getDate().toString().padStart(2, '0');
            let dateString;
            switch (mode) {
                case 'OK':
                    dateString = `${year}-${month}-${day}`;
                    break;
                case 'M':
                    dateString = `${year}-${month}`;
                    break;
                case 'Y':
                    dateString = year;
                    break;
                default:
                    throw new Error('Unknown date mode (must be OK, M or Y)');
            }
            (async () => save(dateString))();
        }
    }, [value, save]);
    const onClickPerpetual = React.useCallback(() => {
        setValue('Perpetual');
        (async () => save('Perpetual'))();
    }, [save]);
    const onClickRemove = React.useCallback(() => {
        (async () => remove())();
    }, [remove]);
    const closePopup = React.useCallback(() => {
        //  setEditing(false);
    }, []);
    const onFocus = React.useCallback(() => {
        if (value !== undefined) {
            setChoosePerpOrDate('date');
        }
    }, [value]);
    React.useEffect(() => {
        setValue(column.accessorFn !== undefined ? column.accessorFn(row.original, row.index) : undefined);
    }, [column, row.index, row.original]);
    React.useEffect(() => {
        if (row.original.overrideMap?.[column.id] != null && column.accessorFn !== undefined) {
            const copy = { ...row.original };
            delete copy.overrideMap;
            try {
                const titleText = printDate(column.accessorFn(copy, row.index));
                setTitle(titleText);
            }
            catch (error) {
                setTitle('ERROR');
            }
        }
        else {
            setTitle(undefined);
        }
    }, [column, printDate, row.index, row.original]);
    React.useEffect(() => {
        if (value === undefined) {
            setText(undefined);
        }
        else if (typeof value === 'string') {
            setText(value);
        }
        else if (value instanceof Date) {
            try {
                setText(printDate(value));
            }
            catch (error) {
                setText(`ERROR: ${error}`);
            }
        }
    }, [printDate, value]);
    if (column.accessorFn === undefined) {
        return React.createElement("p", null, "ERROR");
    }
    return (React.createElement(EditableCell, { ref: editableCellRef, isOverriden: row.original.overrideMap?.[column.id] != null, setEditing: setEditing, title: title }, isEditing
        ? (React.createElement("div", { className: "co-editable-date" },
            React.createElement(Popup, { close: closePopup, style: {
                    height: '50px', marginTop: '-0.75rem', opacity: '100%', width: '256px',
                } },
                React.createElement(DatePicker
                // className="co-editable-input"
                , { 
                    // className="co-editable-input"
                    date: value instanceof Date ? value : undefined, disabled: isDisabled, format: datePattern(dateFormat), onBlur: onBlur, onChange: onChange, onFocus: onFocus, showRequired: false, showOverlay: false }),
                React.createElement("div", { style: { display: 'flex', justifyContent: 'center' } }, choosePerpOrDate === 'perp'
                    ? (React.createElement("div", { style: { display: 'flex', marginLeft: '10px' } },
                        React.createElement(Button, { onClick: onClickPerpetual, variant: "tertiary-accent" }, "PERPETUAL"),
                        React.createElement(Button, { iconButton: true, onClick: onClickRemove, variant: "tertiary-accent" }, "REMOVE")))
                    : (React.createElement("div", { style: { display: 'flex', marginLeft: '10px' } },
                        React.createElement(Button, { onClick: onClickDate('OK'), variant: "tertiary-accent" }, "OK"),
                        React.createElement(Button, { onClick: onClickDate('M'), variant: "tertiary" }, "Month"),
                        React.createElement(Button, { onClick: onClickDate('Y'), variant: "tertiary" }, "Year")))))))
        : (React.createElement("div", null, text))));
};
export default MaturityCell;
