/**
 * see https://stomp-js.github.io/guide/stompjs/rx-stomp/using-stomp-with-sockjs.html
 */
import * as SockJS from 'sockjs-client';
import { Client, } from '@stomp/stompjs';
import ServerURL from 'models/ServerUrl';
import WebsocketURL from 'models/WebsocketUrl';
const baseStompConfig = {
    brokerURL: new WebsocketURL('/ws').toString(),
    heartbeatIncoming: 10000,
    heartbeatOutgoing: 10000,
    reconnectDelay: 5000,
};
const getCsrfHeaders = async (accessToken) => {
    // const stored = sessionStorage.getItem('csrf');
    // if (stored !== null) {
    //   const storedCsrf = JSON.parse(stored) as CSRF;
    //   return { [storedCsrf.headerName]: storedCsrf.token } as StompHeaders;
    // }
    const url = new ServerURL('/csrf');
    const response = await fetch(url.toString(), { credentials: 'include', headers: { Authorization: `Bearer ${accessToken}` } });
    const csrf = await response.json();
    sessionStorage.setItem('csrf', JSON.stringify(csrf));
    return { [csrf.headerName]: csrf.token };
};
export const client = new Client(baseStompConfig);
export const initialiseWebSocket = async (userManager, func) => {
    const user = await userManager.getUser();
    if (user !== null) {
        try {
            const stompConfig = baseStompConfig;
            const url = new WebsocketURL('/ws');
            const connectHeaders = await getCsrfHeaders(user.access_token);
            connectHeaders.Authorization = `Bearer ${user.access_token}`;
            stompConfig.brokerURL = url.toString();
            stompConfig.debug = (e) => console.log('HI: %o', e);
            stompConfig.connectHeaders = connectHeaders;
            client.configure(stompConfig);
            // Fallback codeL
            if (typeof WebSocket !== 'function') {
                // For SockJS you need to set a factory that creates a new SockJS instance
                // to be used for each (re)connect
                client.webSocketFactory = () => new SockJS(new ServerURL('/stomp').toString());
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            client.onConnect = func;
            client.onStompError = (frame) => {
                // Will be invoked in case of error encountered at Broker
                // Bad login/passcode typically will cause an error
                // Complaint brokers will set `message` header with a brief message. Body may contain details.
                // Compliant brokers will terminate the connection after any error
                console.log(`Broker reported error: ${frame.headers.message}. Additional details: ${frame.body}`);
                // (async () => {
                //   await client.deactivate();
                //   stompConfig.connectHeaders = connectHeaders;
                //   client.configure(stompConfig);
                //   client.activate();
                // })();
            };
            client.onDisconnect = (frame) => {
                console.log('Disconnected: %o', frame);
            };
            client.onUnhandledMessage = (message) => {
                console.log('Unhandled Message: %o', message);
            };
            client.activate();
        }
        catch (e) {
            console.error('Cannot connect to websocket: %o', e);
        }
    }
};
export const websocketStatus = () => client.active;
