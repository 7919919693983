import * as React from 'react';
import SectorModal from './SectorModal';
import TypeModal from './TypeModal';
import DetailsModal from './DetailsModal';
const ServiceWorkerMessageHandler = () => {
    const [action, setAction] = React.useState();
    const [data, setData] = React.useState();
    const [content, setContent] = React.useState();
    // From your client pages:
    React.useEffect(() => {
        const channel = new BroadcastChannel('sw-messages');
        channel.addEventListener('message', (event) => {
            const messageData = event.data;
            setData(messageData.data);
            setAction(messageData.action);
        });
    }, []);
    React.useEffect(() => {
        if (data !== undefined) {
            switch (action) {
                case 'sector-ok' || 'type-ok':
                    setContent(undefined);
                    break;
                case 'sector-other': {
                    setContent(React.createElement(SectorModal, { data: data, onClose: () => setAction(undefined) }));
                    break;
                }
                case 'type-other': {
                    setContent(React.createElement(TypeModal, { data: data, onClose: () => setAction(undefined) }));
                    break;
                }
                case 'details':
                    setContent(React.createElement(DetailsModal, { data: data, onClose: () => setAction(undefined) }));
                    break;
                default:
                    setContent(undefined);
            }
        }
    }, [action, data]);
    return content ?? (React.createElement("div", null));
};
export default ServiceWorkerMessageHandler;
