import * as React from 'react';
import * as ReactDom from 'react-dom/client';
// import 'symphony-bdk-ui-toolkit/dist/index.css';
// import { handleOutline } from '../modules/core/utils/helpers/help-functions';
import MyRoutes from '../modules/core/pages/routes';
import '../modules/core/public/sass/main.scss';
import initServiceWorker from './init-service-worker';
// handleOutline(); // Accessibility
// const appWrapper = () => {
const rootElement = document.getElementById('root');
if (rootElement !== null) {
    console.log('in main, creating root');
    const root = ReactDom.createRoot(rootElement);
    root.render((React.createElement(React.StrictMode, null,
        React.createElement(MyRoutes, null))));
}
// };
// appWrapper();
window.addEventListener('load', initServiceWorker, false);
