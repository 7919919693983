export const checkEditable = async (object, userManager) => {
    const user = await userManager.getUser();
    if (user === null) {
        return false;
    }
    const userInfo = user.state;
    return (!('createdBy' in object) || (object.permissions.other.write
        || (object.permissions.group.write && object.createdBy.company === userInfo.company)
        || (object.permissions.user.write && object.createdBy?.id === userInfo.id)));
};
export const defaultPermissions = {
    group: { read: true, write: false },
    other: { read: false, write: false },
    user: { read: true, write: true },
};
