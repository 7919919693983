import * as React from 'react';
import { Dropdown, TextField, TimePicker, } from '@symphony-ui/uitoolkit-components';
import LabelTooltipDecorator from '@symphony-ui/uitoolkit-components/components/label-tooltip-decorator/LabelTooltipDecorator';
import { getOptions } from 'utils/change';
import './FrequencyInput.scss';
import ToggleDecorator from '../toggle-decorator/ToggleDecorator';
const FrequencyInput = ({ defaultPeriod = 'MINUTES', disabled, value: frequency, label, onChange, tooltip, }) => {
    const [toggled, setToggle] = React.useState(true);
    const [value, setValue] = React.useState({
        time: frequency?.time ?? '',
        type: frequency?.type ?? defaultPeriod ?? 'Y',
        value: frequency?.value !== undefined && Number.isInteger(frequency.value) ? frequency.value.toString() : '',
    });
    const options = [{ label: 'Mins', value: 'MINUTES' }, { label: 'Hours', value: 'HOURS' }];
    const valuePattern = '[1-9][0-9]{0,2}';
    React.useEffect(() => {
        if (toggled) {
            setValue((v) => (v.type === '' ? {
                ...v, time: '', type: defaultPeriod ?? 'Y', value: '',
            } : v));
        }
        else if (!toggled) {
            setValue((v) => ({ ...v, value: '' }));
        }
    }, [defaultPeriod, toggled]);
    React.useEffect(() => {
        let returnValue;
        if (value?.time !== '') {
            returnValue = { time: value.time, zoneId: 'Europe/London' };
        }
        else if (value?.type !== '' && value?.value !== '') {
            returnValue = { type: value.type, value: Number.parseInt(value.value, 10) };
        }
        else {
            returnValue = undefined;
        }
        if (returnValue !== undefined) {
            onChange({ target: { value: returnValue } });
        }
    }, [value]);
    const onChangeInput = React.useCallback((event) => {
        const regex = new RegExp(`^${valuePattern}$`, 'u');
        const newValue = event.target.value;
        if (regex.test(newValue) || newValue === '') {
            setValue({ ...value, value: newValue });
        }
    }, [value]);
    const onChangeTime = React.useCallback((event) => {
        document.activeElement.blur();
        setValue({ ...value, time: event.target.value });
    }, [value]);
    const onChangeType = React.useCallback((event) => {
        document.activeElement.blur();
        setValue({ ...value, type: event.target.value.value });
    }, [value]);
    return (React.createElement("div", { className: "tk-input-group" },
        React.createElement(LabelTooltipDecorator, { label: label, tooltip: tooltip }),
        React.createElement("div", { className: "tk-input__container select-container" },
            React.createElement(ToggleDecorator, { toggled: toggled, setToggle: setToggle }),
            toggled
                ? (React.createElement(TextField, { className: "input-with-addon", disabled: disabled, label: "", onChange: onChangeInput, rightDecorators: (React.createElement(Dropdown, { className: "select-addon", isDisabled: disabled, isTypeAheadEnabled: false, label: "", onChange: onChangeType, options: options, value: getOptions(value.type, options) })), value: value.value }))
                : '',
            !toggled
                ? (React.createElement(TimePicker, { className: "time-picker2", disabled: disabled, name: "frequency", value: value.time, onChange: onChangeTime }))
                : '')));
};
export default FrequencyInput;
