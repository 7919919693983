import * as React from 'react';
import { DropdownMenu, DropdownMenuDivider, DropdownMenuItem } from '@symphony-ui/uitoolkit-components';
import AppViewContext from 'contexts/AppViewContext';
import { App } from 'utils/appUtils';
import { appAccess } from 'models/authorityHelpers';
const MenuItems = ({ closeMenu, setShowSettings, show }) => {
    const { userManager, setAppView } = React.useContext(AppViewContext);
    const [menuItems, setMenuItems] = React.useState([]);
    const onClickApp = React.useCallback((key) => () => {
        closeMenu();
        setAppView(key);
        localStorage.setItem('app', key.toString());
    }, [closeMenu, setAppView]);
    const onClickLogout = React.useCallback(() => {
        closeMenu();
        console.log('Log out clicked');
        if (userManager !== undefined) {
            (async () => {
                try {
                    await userManager.signoutRedirect();
                }
                catch (error) {
                    // ignore in case no access to localStorage
                }
            })();
        }
    }, [closeMenu, userManager]);
    const onClickSettings = React.useCallback(() => {
        closeMenu();
        setShowSettings(true);
    }, [closeMenu, setShowSettings]);
    React.useEffect(() => {
        const items = [
            (React.createElement(DropdownMenuItem, { key: "settings", onClick: onClickSettings }, "Settings")),
            (React.createElement(DropdownMenuDivider, { key: "divider1" })),
        ];
        (async () => {
            const apps = await appAccess(userManager);
            apps.forEach((key) => {
                items.push((React.createElement(DropdownMenuItem, { key: key, onClick: onClickApp(key) }, App[key])));
            });
            items.push((React.createElement(DropdownMenuDivider, { key: "divider2" })));
            items.push((React.createElement(DropdownMenuItem, { key: "log-out", onClick: onClickLogout }, "Log Out")));
            setMenuItems(items);
        })();
    }, [onClickApp, onClickLogout, onClickSettings, userManager]);
    return (React.createElement(DropdownMenu, { className: "co-menu-dropdown", show: show }, menuItems));
};
export default MenuItems;
