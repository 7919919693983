import * as React from 'react';
import { Button, Card, Dropdown, Icon, Typography, } from '@symphony-ui/uitoolkit-components';
import ActionCell from 'components/custom-table/action/ActionCell';
import CustomTable from 'components/custom-table/CustomTable';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import { getOptions } from 'utils/change';
import Ipo from '../models/Ipo';
import columns from './exchanges';
import './IpoTable.css';
const IpoTable = ({ create, deleteFn, edit, hasError, isLoading, ipos, exchangeOptions, setExchange, }) => {
    const [buttonDisabled, setButtonDisabled] = React.useState(true);
    const [data, setData] = React.useState(new Ipo({}));
    const { download } = React.useContext(DownloadContext);
    const exchangeColumns = React.useMemo(() => {
        if (data.exchange === undefined) {
            return [];
        }
        const existing = columns.get(data.exchange);
        if (existing === undefined) {
            return [];
        }
        return [...existing, {
                cell: ActionCell,
                id: 'actions',
                meta: {
                    actionsMenu: [
                        { callback: edit, label: 'Edit', type: 'info' },
                        { callback: deleteFn, label: 'Delete', type: 'error' },
                    ],
                },
            }];
    }, [data, deleteFn, edit]);
    const onChangeDropdown = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? [...new Set(value.map((v) => v.value))] : value.value;
        const newObject = Object.assign(data, { [name]: newValue });
        setData(newObject);
    }, [data]);
    React.useEffect(() => {
        setButtonDisabled(!('exchange' in data) || data.exchange === undefined);
        setExchange(data.exchange);
    }, [data, setExchange]);
    const clickDownload = React.useCallback(() => {
        if (data.exchange !== undefined) {
            const url = new ServerURL('/bb/ipo/import');
            url.searchParams.append('exchange', data.exchange);
            const request = new ServerRequest(url, { method: 'POST' });
            (async () => {
                try {
                    await download.noContentPlus(request, 'Error updating');
                }
                catch (error) {
                    //
                }
            })();
        }
    }, [data.exchange, download]);
    return (React.createElement(Card, null,
        React.createElement("div", { className: "card-header" },
            React.createElement(Typography, { type: "h1" }, "Releases"),
            React.createElement("div", { className: "co-dropdown-row" },
                React.createElement(Button, { className: "co-button", disabled: hasError, onClick: create }, "Create New"),
                React.createElement(Dropdown, { id: "exchange-dropdown", isDisabled: hasError, name: "exchange", label: "Download Ipos from Exchange", onChange: onChangeDropdown('exchange'), options: exchangeOptions, value: getOptions(data.exchange, exchangeOptions) }),
                React.createElement(Button, { className: "co-button", disabled: buttonDisabled || hasError, iconButton: true, onClick: clickDownload },
                    React.createElement(Icon, { iconName: "download" })))),
        React.createElement(CustomTable, { columns: exchangeColumns, data: ipos || [], emptyMessage: "You have no ipos yet", errorMessage: "Error downloading ipos", hasError: hasError, isLoading: isLoading, name: "ipo" })));
};
export default IpoTable;
