const columns = new Map([['BORSA', [
            { accessorKey: 'security', header: 'Security' },
            { accessorKey: 'offeringPrice', header: 'Offering Price' },
            { accessorKey: 'market', header: 'Market' },
            { accessorKey: 'stockDescription', header: 'Stock Description' },
        ]],
    ['EURONEXT', [
            { accessorKey: 'iponiDisplayTitle.name', header: 'Name' },
            { accessorKey: 'iponiIpoDate', header: 'Date' },
            { accessorKey: 'iponiTickerSymbol', header: 'Ticker' },
            { accessorKey: 'issueType', header: 'Issue Type' },
            { accessorKey: 'iponiPriceRange', header: 'Price Range' },
            { accessorKey: 'tradingLocation', header: 'Location' },
            { accessorKey: 'listingSponsor', header: 'Listing Sponsor' },
            { accessorKey: 'iponiWebSiteAddress', header: 'Web Site Address' },
            { accessorKey: 'iponiIsinCode', header: 'ISIN' },
            { accessorKey: 'iponiLeiCode', header: 'LEI' },
        ]],
    ['HKSE', []],
    ['LSE', [
            { accessorKey: 'name', header: 'Name' },
            { accessorKey: 'priceRange', header: 'Price Range' },
            { accessorKey: 'market', header: 'Market' },
            { accessorKey: 'currency', header: 'Currency' },
            { accessorKey: 'type', header: 'Type' },
            { accessorKey: 'link', header: 'Link' },
        ]],
    ['NASDAQ', [
            { accessorKey: 'companyName', header: 'Name' },
            { accessorKey: 'expectedPriceDate', header: 'Expected Price Date' },
            { accessorKey: 'proposedSharePrice', header: 'Proposed Share Price' },
            { accessorKey: 'proposedExchange', header: 'Proposed Exchange' },
            { accessorKey: 'proposedTickerSymbol', header: 'Ticker Symbol' },
        ]],
    ['NYSE', [
            { accessorKey: 'issuerNm', header: 'Name' },
            { accessorKey: 'expected_dt_report', header: 'Expected Date' },
            { accessorKey: 'current_file_price_range_usd', header: 'Price Range' },
            { accessorKey: 'deal_status_desc', header: 'Deal Status' },
            { accessorKey: 'offer_px_usd', header: 'Offer Price' },
            { accessorKey: 'current_shares_filed', header: 'Shares Filed' },
        ]],
    ['XETRA', [
            { accessorKey: 'name', header: 'Name' },
            { accessorKey: 'date', header: 'Date' },
            { accessorKey: 'bookbuildingRange', header: 'Bookbuilding Range' },
            { accessorKey: 'issuingMethod', header: 'Issuing Method' },
        ]]]);
export default columns;
