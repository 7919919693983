import * as React from 'react';
import { Button, Dropdown, } from '@symphony-ui/uitoolkit-components';
import ActionCell from 'components/custom-table/action/ActionCell';
import CustomTable from 'components/custom-table/CustomTable';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import { getOptions } from 'utils/change';
const SubscriptionsTable = () => {
    const { download } = React.useContext(DownloadContext);
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [subscriptions, setSubscription] = React.useState([]);
    const [allOptions, setAllOptions] = React.useState([]);
    const [options, setOptions] = React.useState([]);
    const [isAddDisabled, setAddDisabled] = React.useState(false);
    const [isAddLoading, setAddLoading] = React.useState(false);
    const [newUser, setNewUser] = React.useState();
    React.useEffect(() => {
        (async () => {
            const request = new ServerRequest('/syndicate/subscriptions');
            try {
                setLoading(true);
                const downloadedSubscriptions = await download.jsonPlus(request, 'Error downloading subscriptions to daily');
                setSubscription(downloadedSubscriptions);
            }
            catch (error) {
                setError(true);
            }
            setLoading(false);
        })();
    }, [download]);
    const addSubscription = React.useCallback((subscription) => {
        setSubscription(subscriptions.concat(subscription));
    }, [subscriptions]);
    const deleteSubscription = React.useCallback(({ row: { original: { id } } }) => {
        (async () => {
            const request = new ServerRequest('/syndicate/subscriptions', { body: id, method: 'DELETE' });
            try {
                await download.noContentPlus(request, 'Error downloading subscriptions to daily');
                setSubscription(subscriptions.filter((s) => s.id !== id));
            }
            catch (error) {
                setError(true);
            }
        })();
    }, [download, subscriptions]);
    const changeUser = React.useCallback((event) => {
        setNewUser(event.target.value?.value);
    }, []);
    const add = React.useCallback(() => {
        (async () => {
            const request = new ServerRequest('/syndicate/subscriptions/add', { body: newUser, method: 'POST' });
            try {
                setAddLoading(true);
                const resposne = await download.jsonPlus(request, 'Error downloading users');
                addSubscription(resposne);
                setNewUser(undefined);
            }
            catch (error) {
                //
            }
            finally {
                setAddLoading(false);
            }
        })();
    }, [addSubscription, download, newUser]);
    const columns = React.useMemo(() => [
        {
            cell: ActionCell,
            id: 'actions',
            meta: {
                actionsMenu: [
                    { callback: deleteSubscription, label: 'Delete' },
                ],
            },
            size: 20,
        },
        {
            accessorKey: 'name',
            enableColumnFilter: true,
            header: 'Name',
            minSize: 280,
        },
        {
            accessorKey: 'company',
            enableColumnFilter: true,
            header: 'Company',
            minSize: 280,
        },
        {
            accessorKey: 'emailAddress',
            enableColumnFilter: true,
            header: 'Email',
            minSize: 280,
        },
    ], [deleteSubscription]);
    React.useEffect(() => {
        (async () => {
            const request = new ServerRequest('/syndicate/subscriptions/user-options');
            try {
                const downloadedOptions = await download.jsonPlus(request, 'Error downloading users');
                setAllOptions(downloadedOptions);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    React.useEffect(() => {
        const subscriptionEmails = subscriptions.map((s) => s.userId);
        setOptions(allOptions.filter((o) => !subscriptionEmails.includes(o.value)));
    }, [allOptions, subscriptions]);
    React.useEffect(() => {
        setAddDisabled(newUser == null);
    }, [newUser]);
    return (React.createElement(CustomTable, { actionElements: [(React.createElement("div", { style: { width: '20rem' } },
                React.createElement(Dropdown, { isInputClearable: true, options: options, label: "Add new Subscriber", onChange: changeUser, value: getOptions(newUser, options) }))),
            (React.createElement("div", { style: { alignSelf: 'self-end', marginLeft: '1rem' } },
                React.createElement(Button, { disabled: isAddDisabled, loading: isAddLoading, onClick: () => add() }, "ADD")))], columns: columns, data: subscriptions, emptyMessage: "", errorMessage: "", hasError: hasError, isLoading: isLoading, name: "subscriptions" }));
};
export default SubscriptionsTable;
