import { Button, Modal, ModalBody, ModalFooter, ModalHeader, TextField, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const TableStateSaveModal = ({ close, saveState }) => {
    const [name, setName] = React.useState('');
    const onChangeName = React.useCallback((value) => {
        setName(value.target.value);
    }, []);
    const onClickSave = React.useCallback(() => {
        if (name !== undefined) {
            saveState(name, undefined);
            close();
        }
    }, [name, close, saveState]);
    return (React.createElement(Modal, { closeButton: true, onClose: close, show: true, size: "small" },
        React.createElement(ModalHeader, null, "Save Current State"),
        React.createElement(ModalBody, null,
            React.createElement(TextField, { label: "Name", onChange: onChangeName, value: name })),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { onClick: onClickSave, style: { marginLeft: '1rem', marginTop: '1.5rem' } }, "SAVE"))));
};
export default TableStateSaveModal;
