import * as React from 'react';
import { Dropdown } from '@symphony-ui/uitoolkit-components';
import { getOptionsMulti } from 'utils/change';
import UserContext from 'contexts/UserContext';
import EuroclearFilter from '../../../models/filter/EuroclearFilter';
import EuroclearSearch from '../../../models/search/EuroclearSearch';
import QualifiedSearchInput from '../../qualified-search/QualifiedSearchInput';
import TimeRangeInput from '../../time-range-input/TimeRangeInput';
import RemoteFilterItem from '../../filter-item/RemoteFilterItem';
import Option from '../../../models/Option';
import currencyOptionsJson from '../../../public/assets/banksecurities.currency.json';
import marketOptionsJson from '../../../public/assets/banksecurities.market.json';
import ratetypeOptionsJson from '../../../public/assets/banksecurities.ratetype.json';
import securitytypeOptionsJson from '../../../public/assets/banksecurities.securitytype.json';
const EuroclearForm = ({ setSearch, search }) => {
    const { authService } = React.useContext(UserContext);
    const [disabled, setDisabled] = React.useState(false);
    const [currencyOptions, setCurrencyOptions] = React.useState([]);
    const [marketOptions, setMarketOptions] = React.useState([]);
    const [ratetypeOptions, setRatetypeOptions] = React.useState([]);
    const [securitytypeOptions, setSecuritytypeOptions] = React.useState([]);
    React.useEffect(() => {
        const loadedCurrencyOptions = Option.fixDoubles(currencyOptionsJson);
        setCurrencyOptions(loadedCurrencyOptions);
        const loadedMarketOptions = Option.fixDoubles(marketOptionsJson);
        setMarketOptions(loadedMarketOptions);
        const loadedRatetypeOptions = Option.fixDoubles(ratetypeOptionsJson);
        setRatetypeOptions(loadedRatetypeOptions);
        const loadedSecuritytypeOptions = Option.fixDoubles(securitytypeOptionsJson);
        setSecuritytypeOptions(loadedSecuritytypeOptions);
    }, []);
    React.useEffect(() => {
        if (search.id) {
            (async () => {
                const isEditable = await authService.checkEditable(search);
                setDisabled(!isEditable);
            })();
        }
    }, [authService, search]);
    const changeFilter = React.useCallback((name) => (event) => {
        const updatedSearch = Object.assign(new EuroclearSearch(), search);
        updatedSearch.filter[name] = event.target.value;
        setSearch(updatedSearch);
    }, [search, setSearch]);
    const changeQueryDropdown = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const updatedQuery = Object.assign(search.query, { [name]: Array.isArray(value) ? value.map((v) => v.value) : [value] });
        const updatedSearch = Object.assign(new EuroclearSearch(), search, { query: updatedQuery });
        setSearch(updatedSearch);
    }, [search, setSearch]);
    const changeQueryQualifiedSearch = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const updatedQuery = Object.assign(search.query, { [name]: value });
        const updatedSearch = Object.assign(new EuroclearSearch(), search, { query: updatedQuery });
        setSearch(updatedSearch);
    }, [search, setSearch]);
    const changeQueryTimeRange = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const updatedQuery = Object.assign(search.query, { [name]: value });
        const updatedSearch = Object.assign(new EuroclearSearch(), search, { query: updatedQuery });
        setSearch(updatedSearch);
    }, [search, setSearch]);
    const regex = {
        q: /''/,
        rate: /^((0(\\.[0-9]{1,5})?)|[1-9][0-9]+(\\.[0-9]{1,5})?)$/,
    };
    return (React.createElement("div", { className: "base-form" },
        React.createElement(QualifiedSearchInput, { disabled: disabled, label: "Shortname", value: search.query.q, onChange: changeQueryQualifiedSearch('q'), regex: regex.q, tooltip: "Search the shortname of the security. This typically starts with the legal name of the issuer. An example is 'NRW.BANK            0.20000 01/03/34'" }),
        React.createElement(Dropdown, { closeMenuOnSelect: true, isDisabled: disabled, isMultiSelect: true, label: "Currency", name: "currency", onChange: changeQueryDropdown('currency'), options: currencyOptions, value: getOptionsMulti(search.query.currency, currencyOptions) }),
        React.createElement(QualifiedSearchInput, { disabled: disabled, label: "Rate", value: search.query.rate, onChange: changeQueryQualifiedSearch('rate'), regex: regex.rate, tooltip: "Search by rate. The search is by text" }),
        React.createElement(Dropdown, { closeMenuOnSelect: true, isDisabled: disabled, isMultiSelect: true, label: "Market", name: "market", onChange: changeQueryDropdown('market'), options: marketOptions, value: getOptionsMulti(search.query.market, marketOptions) }),
        React.createElement(Dropdown, { closeMenuOnSelect: true, isDisabled: disabled, isMultiSelect: true, label: "Rate Type", name: "ratetype", onChange: changeQueryDropdown('ratetype'), options: ratetypeOptions, value: getOptionsMulti(search.query.ratetype, ratetypeOptions) }),
        React.createElement(Dropdown, { closeMenuOnSelect: true, isDisabled: disabled, isMultiSelect: true, label: "Security Type", name: "securitytype", onChange: changeQueryDropdown('securitytype'), options: securitytypeOptions, value: getOptionsMulti(search.query.securitytype, securitytypeOptions) }),
        React.createElement(RemoteFilterItem, { disabled: disabled, label: "Tags", onChange: changeFilter('tags'), path: "/nit/companies/tags/options/euroclear", value: search.filter.tags ?? new EuroclearFilter() }),
        React.createElement(RemoteFilterItem, { disabled: disabled, label: "Countries", onChange: changeFilter('countries'), path: "/nit/companies/euroclear/countries/options", value: search.filter.countries }),
        React.createElement(RemoteFilterItem, { disabled: disabled, label: "Categories", onChange: changeFilter('categories'), path: "/nit/companies/euroclear/categories/options", value: search.filter.categories }),
        React.createElement(RemoteFilterItem, { disabled: disabled, label: "CFI Groups", onChange: changeFilter('cfiGroups'), path: "/nit/security/cfi-groups/options", value: search.filter.cfiGroups }),
        React.createElement(TimeRangeInput, { defaultPeriod: "Y", disabled: disabled, label: "Maturity Date", onChange: changeQueryTimeRange('maturitydate'), tooltip: "", value: search.query.maturitydate }),
        React.createElement(TimeRangeInput, { defaultPeriod: "D", disabled: disabled, label: "Code Creation Date", onChange: changeQueryTimeRange('codecreationdate'), tooltip: "", value: search.query.codecreationdate })));
};
export default EuroclearForm;
