import { Button, Icon, Nav, } from '@symphony-ui/uitoolkit-components';
import MenuItems from 'components/menu-items-list/MenuItemsList';
import * as React from 'react';
import TabContext from 'contexts/TabContext';
const NavMenu = ({ setShowSettings, tabs, }) => {
    const { setTabId, tabId } = React.useContext(TabContext);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [mainMenuOpen, setMainMenuOpen] = React.useState(false);
    const onActiveTabChange = React.useCallback((navItem) => {
        setMenuOpen(false);
        setMainMenuOpen(false);
        setTabId(navItem.id);
    }, [setTabId]);
    const toggleMenu = React.useCallback(() => {
        setMenuOpen(!menuOpen);
    }, [menuOpen]);
    const toggleMainMenu = () => {
        setMainMenuOpen(!mainMenuOpen);
    };
    return (React.createElement("div", { className: "co-form-buttons-plus" },
        React.createElement("div", { className: "co-button-menu" },
            React.createElement("button", { className: "co-collapse-button", type: "button", onClick: toggleMainMenu }, "\uD83D\uDFF0"),
            React.createElement("div", { className: `co-fixed-top-row${mainMenuOpen ? ' co-open' : ''}` },
                React.createElement(Nav, { activeItemId: tabId, items: tabs, onActiveTabChange: onActiveTabChange }))),
        React.createElement("div", null,
            React.createElement(Button, { className: "co-menu-button", iconButton: true, onClick: toggleMenu, variant: "tertiary" },
                React.createElement(Icon, { iconName: "settings" })),
            React.createElement(MenuItems, { closeMenu: toggleMenu, setShowSettings: setShowSettings, show: menuOpen }))));
};
export default NavMenu;
