import { Dropdown } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import * as React from 'react';
import { getOptions } from 'utils/change';
import './DataViewerForm.css';
const CategoryPicker = ({ category, isLoading, setCategory, }) => {
    const { download } = React.useContext(DownloadContext);
    const [categoryOptions, setCategoryOptions] = React.useState([]);
    const [hasError, setError] = React.useState(false);
    React.useMemo(() => {
        const url = new ServerURL('/syndicate/regex/paragraphs/categoryoptions');
        const request = new ServerRequest(url);
        (async () => {
            try {
                const body = await download.jsonPlus(request, 'Unable to download Category options');
                setCategoryOptions(body);
            }
            catch (error) {
                setError(true);
            }
        })();
    }, [download]);
    const onChangeValue = React.useCallback((event) => {
        const newValue = event.target.value;
        if ('value' in newValue) {
            localStorage.setItem('category', newValue.value);
            setCategory(newValue.value);
        }
    }, [setCategory]);
    return (React.createElement("div", { className: "co-category-picker" },
        React.createElement(Dropdown, { isDisabled: hasError || isLoading, label: "Select a Category", name: "value", onChange: onChangeValue, options: categoryOptions, value: getOptions(category, categoryOptions) })));
};
export default CategoryPicker;
