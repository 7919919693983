import * as React from 'react';
import { useNavigate } from 'react-router-dom';
const CallbackHandler = ({ setAuth, userManager, }) => {
    console.log('Start CallbackHandler.');
    const navigate = useNavigate();
    React.useEffect(() => {
        (async () => {
            console.log('Setting user using SigninCallback, with settings: %o', userManager.settings);
            try {
                const user = await userManager.signinCallback();
                console.log('User coming back from SigninCallback: %o', user);
                setAuth(typeof user === 'object');
            }
            catch (error) {
                console.error('Error when trying to get user through SigninCallback in the CallbackHandler.  Error %o', error);
                setAuth(false);
            }
            finally {
                navigate('/', { replace: true });
            }
        })();
    }, [navigate, setAuth, userManager]);
    return (React.createElement("div", null, "Loading"));
};
export default CallbackHandler;
