import * as React from 'react';
import { Card, Dropdown, } from '@symphony-ui/uitoolkit-components';
import { getOptionsSource } from 'utils/change';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import './Companies.css';
import AnnaCompanyModal from '../components/companies/AnnaCompanyModal';
import CompanyTagsModal from '../components/companies/CompanyTagsModal';
import AnnaCompany from '../models/company/AnnaCompany';
import CompanyTag from '../models/company/CompanyTag';
import { getCompanySort } from '../utils/markupCurrencies';
import { fromSource, Source } from '../sources/sources';
import CompaniesTable from '../components/companies/CompaniesTable';
const Companies = () => {
    const { download } = React.useContext(DownloadContext);
    const [actualCompanyTags, setActualCompanyTags] = React.useState();
    const [allCompanyTags, setAllCompanyTags] = React.useState([]);
    const [companies, setCompanies] = React.useState([]);
    const [company, setCompany] = React.useState();
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const [source, setSource] = React.useState(() => {
        const saved = localStorage.getItem('nit-source');
        return saved !== null ? fromSource(saved) : Source.EUROCLEAR;
    });
    const [tagOptions, setTagOptions] = React.useState([]);
    const hideModal = () => {
        setActualCompanyTags(undefined);
        setCompany(undefined);
    };
    const updateCompany = (updatedCompany) => {
        const allCompanies = [...companies];
        const index = allCompanies.findIndex((c) => c.id === updatedCompany.id);
        allCompanies[index] = updatedCompany;
        setCompanies(allCompanies);
    };
    const upsertCompanyTag = (newCompanyTag) => {
        const allCompanyTagsCopy = [...allCompanyTags];
        const index = allCompanyTagsCopy.findIndex((c) => c.id === newCompanyTag.id);
        if (index >= 0) {
            allCompanyTagsCopy[index] = newCompanyTag;
        }
        else {
            allCompanyTagsCopy.push(newCompanyTag);
        }
        setAllCompanyTags(allCompanyTagsCopy);
    };
    const resultMap = (c) => ({
        ...c,
        tags: allCompanyTags.find((t) => t.companyId === c.id),
    });
    const changeSource = (event) => {
        const { value } = event.target;
        localStorage.setItem('nit-source', value.value.toString());
        setSource(value.value);
    };
    const allOptions = React.useMemo(() => [
        { label: 'Euroclear', value: Source.EUROCLEAR },
        { label: 'ESMA', value: Source.ESMA },
        { label: 'ANNA', value: Source.ANNA },
        { label: 'SEC', value: Source.SEC }
    ], []);
    React.useEffect(() => {
        (async () => {
            const request = new ServerRequest('/nit/companies/tags');
            try {
                const tags = await download.jsonPlus(request, 'Error downloading company tags');
                const loadedTags = [...new Set(tags.map((t) => Object.assign(new CompanyTag(), t)).filter((s) => s.source === source)?.flatMap((t) => t.tags))];
                const loadedTagOptions = loadedTags
                    .map((t) => ({ label: t, value: t }));
                setTagOptions(loadedTagOptions);
                setAllCompanyTags(tags);
            }
            catch (error) {
                //
            }
        })();
        (async () => {
            setLoading(true);
            const request = new ServerRequest(`/nit/${source.toLowerCase()}/companies`);
            try {
                const body = await download.jsonPlus(request, 'Error downloading companies');
                setLoading(false);
                const sort = getCompanySort(source);
                const newCompanies = body.sort(sort);
                setCompanies(newCompanies);
            }
            catch (error) {
                setError(true);
            }
        })();
    }, [download, source]);
    return (React.createElement(Card, { key: "NIT" },
        company instanceof AnnaCompany ? React.createElement(AnnaCompanyModal, { company: company, hideModal: hideModal, updateCompany: updateCompany }) : '',
        actualCompanyTags ? (React.createElement(CompanyTagsModal, { companyTags: actualCompanyTags, hideModal: hideModal, updateTags: upsertCompanyTag, tagOptions: tagOptions })) : '',
        React.createElement("div", { className: "co-container co-item" },
            React.createElement(Dropdown, { className: "co-dropdown", isDisabled: hasError, isTypeAheadEnabled: false, label: "Source", onChange: changeSource, options: allOptions, value: getOptionsSource(source, allOptions) })),
        React.createElement(CompaniesTable, { companies: companies.map(resultMap) ?? [], hasError: hasError, isLoading: isLoading, setActualCompanyTags: setActualCompanyTags, setCompany: setCompany, source: source })));
};
export default Companies;
