import * as React from 'react';
import { Button, Card, Icon, Typography, } from '@symphony-ui/uitoolkit-components';
import AppViewContext from 'contexts/AppViewContext';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
const SettingsHeader = ({ children, onClose }) => {
    const { setAppView, userManager } = React.useContext(AppViewContext);
    const { download } = React.useContext(DownloadContext);
    const clickLogout = React.useCallback(() => {
        if (userManager !== undefined) {
            console.log('LOGOUT CLICKED');
            const request = new ServerRequest('/logout', { method: 'POST' });
            (async () => {
                try {
                    await userManager.signoutCallback();
                    await download.noContentPlus(request, 'Error downloading');
                    localStorage.removeItem('user');
                    window.location.reload();
                }
                catch (error) {
                    // ignore in case no access to localStorage
                }
            })();
        }
    }, [download, userManager]);
    const clickMenu = React.useCallback(() => {
        localStorage.removeItem('app');
        setAppView(null);
    }, [setAppView]);
    return (React.createElement(Card, { className: "co-card-stack", style: { padding: '1rem' } },
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
            React.createElement(Typography, { type: "h1" }, "Settings"),
            React.createElement(Button, { iconButton: true, onClick: onClose, variant: "tertiary" },
                React.createElement(Icon, { iconName: "cross" }))),
        children,
        React.createElement("div", { className: "form-buttons" },
            React.createElement(Button, { onClick: clickLogout }, "Log out"),
            React.createElement(Button, { onClick: clickMenu }, "Apps Menu"),
            React.createElement(Button, { onClick: onClose }, "Close"))));
};
export default SettingsHeader;
