import { Dropdown, Icon, Loader, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import * as React from 'react';
import Popup from 'components/custom-table/action/Popup';
import TransactionWrapper from '../../../models/TransactionWrapper';
import EditableCell from '../overview/table/EditableCell';
const TransactionCellDropdown = ({ table, row, column, }) => {
    const { download } = React.useContext(DownloadContext);
    const [content, setContent] = React.useState();
    const [isDisabled, setDisabled] = React.useState(false);
    const [isEditing, setEditing] = React.useState(false);
    const [value, setvalue] = React.useState();
    const [isLoading, setLoading] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [hasError, setError] = React.useState(false);
    const [className, setClassName] = React.useState('');
    const save = React.useCallback(async (newValue) => {
        if (table.options.meta !== undefined && typeof table.options.meta.updateTable === 'function') {
            const url = new ServerURL('/syndicate/transactions/meta-update');
            url.searchParams.append('transactionId', row.original.id);
            url.searchParams.append('key', column.id);
            if (newValue !== undefined) {
                url.searchParams.append('value', newValue);
            }
            setLoading(true);
            setDisabled(true);
            const request = new ServerRequest(url, { method: newValue !== undefined ? 'PATCH' : 'DELETE' });
            try {
                const body = await download.jsonPlus(request, 'Unable to save override information');
                const transaction = new TransactionWrapper(body);
                table.options.meta.updateTable(transaction);
                setvalue(transaction[column.id]);
            }
            catch (error) {
                setError(true);
                //
            }
            finally {
                setLoading(false);
                setEditing(false);
            }
        }
    }, [column.id, download, row.original.id, table]);
    const onBlur = React.useCallback(() => {
        setEditing(false);
    }, []);
    const onChange = React.useCallback((event) => {
        const eventValue = event.target.value;
        if (eventValue !== null) {
            setEditing(false);
            const newValue = eventValue.value;
            (async () => save(newValue))();
        }
    }, [save]);
    const closeDropdown = React.useCallback(() => {
        setDisabled(true);
        if (value !== undefined) {
            (async () => {
                await save(value);
                setEditing(false);
            })();
        }
        else {
            setEditing(false);
        }
    }, [save, value]);
    React.useEffect(() => {
        setvalue(row.original[column.id]);
    }, [column.id, row.original]);
    React.useEffect(() => {
        if (column.columnDef.meta !== undefined) {
            const { dropdown } = column.columnDef.meta;
            if (dropdown) {
                setOptions(dropdown.options);
            }
        }
    }, [column.columnDef.meta]);
    React.useEffect(() => {
        if (isLoading) {
            setContent(React.createElement(Loader, null));
        }
        else if (hasError) {
            setContent(React.createElement(Icon, { className: "co-red", iconName: "alert-triangle" }));
            setClassName('co-centered-icon-box');
        }
        else if (isEditing) {
            setContent(React.createElement(Popup, { close: closeDropdown, style: { width: `${(column.columnDef.size ?? 150) + 20}px` } },
                React.createElement(Dropdown, { displayArrowIndicator: false, disabled: isDisabled, placeholder: "Select...", enableTermSearch: false, isTypeAheadEnabled: false, closeMenuOnSelect: true, menuIsOpen: true, onChange: onChange, options: options, value: options.find((o) => 'value' in o && value === o.value) })));
        }
        else {
            setClassName('co-centered-box');
            const text = options.filter((o) => 'value' in o && value === o.value)?.map((v) => v.label).join(', ') ?? '??';
            setContent(text);
        }
    }, [closeDropdown, column.columnDef.size, column.id, hasError, isDisabled, isEditing, isLoading,
        setContent, onBlur, onChange, options, row.original, value]);
    return (React.createElement(EditableCell, { className: className, setEditing: setEditing }, content));
};
export default TransactionCellDropdown;
