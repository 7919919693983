/* eslint-disable no-void */
import * as React from 'react';
import { Dropdown } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import OptionRenderer from './OptionRenderer';
import './DataViewerForm.css';
const KeyPicker = ({ isLoading, myKey, setMyKey }) => {
    const { download } = React.useContext(DownloadContext);
    const valueFn = React.useCallback((key) => ({
        data: key.id !== undefined && key.id !== null ? {
            id: key.id,
            number: key.number,
        } : undefined,
        label: key.name,
        value: key.name,
    }), []);
    const [hasError, setError] = React.useState(false);
    const [value, setValue] = React.useState();
    const [options, setOptions] = React.useState((myKey === undefined ? [] : [valueFn(myKey)]));
    const [inputValue, setInputValue] = React.useState();
    const onChangeValue = ({ target: { value: newValue } }) => {
        if (newValue.data !== undefined) {
            const stored = { id: newValue.data.id, name: newValue.value, number: newValue.data.number };
            localStorage.setItem('key', JSON.stringify(stored));
            setMyKey(stored);
        }
    };
    React.useEffect(() => {
        setValue(myKey !== undefined ? valueFn(myKey) : undefined);
    }, [myKey, valueFn]);
    React.useEffect(() => {
        const inputName = inputValue ?? myKey?.name;
        if (inputName === undefined) {
            return undefined;
        }
        const timeout = setTimeout(() => {
            if (inputName !== '') {
                (async () => {
                    const url = new ServerURL('/syndicate/regex/keyvalues/keyoptions');
                    url.searchParams.append('key', inputName);
                    const request = new ServerRequest(url);
                    try {
                        const body = await download.jsonPlus(request, 'Error downloading Key Options');
                        setOptions(body);
                    }
                    catch (error) {
                        setError(true);
                        setOptions([]);
                    }
                })();
            }
        }, 250);
        return () => clearTimeout(timeout);
    }, [download, inputValue, myKey]);
    return (React.createElement("div", { className: "co-key-picker" },
        React.createElement(Dropdown, { enableTermSearch: true, options: options, isDisabled: hasError || isLoading, label: "Select a Key", name: "value", onInputChange: (e) => setInputValue(e), onChange: onChangeValue, optionRenderer: (OptionRenderer), placeholder: "Type or select key...", showRequired: true, value: value })));
};
export default KeyPicker;
