import * as React from 'react';
import { Button, Card, Dropdown, Icon, TextField, Typography, Validation, } from '@symphony-ui/uitoolkit-components';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';
import MetadataButtons from 'components/metadata/MetadataButtons';
import { getOptions } from 'utils/change';
import { errorMessage, initialValidation } from 'utils/initialValidation';
import CustomValidators from 'components/CustomValidators';
import CardHeaderForm from 'components/card-header-form/CardHeaderForm';
import NewIssue from '../../model/NewIssue';
import Tranche from './Tranches';
import TrancheObject from '../../model/Tranche';
const NewIssueForm = ({ benchmarkOptions, currencyOptions, issuerOptions, newIssue, save, }) => {
    const [data, setData] = React.useState(newIssue ?? new NewIssue());
    const [disabledButton, setButtonDisabled] = React.useState(true);
    const [errorState, setErrorState] = React.useState();
    const validators = React.useMemo(() => ({
        issuer: CustomValidators.RequiredArray,
        name: [Validators.Required, Validators.MaxLength(40)],
    }), []);
    React.useEffect(() => {
        (async () => {
            const newErrorObject = await initialValidation(validators, data);
            setErrorState(newErrorObject);
        })();
    }, [data, validators]);
    React.useEffect(() => {
        const disabled = errorState === undefined || Object.values(errorState).some((v) => v === false);
        setButtonDisabled(disabled);
    }, [errorState]);
    const setTranche = React.useCallback((key) => (value) => {
        const trancheIndex = data.tranches.findIndex((t) => t.key === key);
        const newTranches = [...data.tranches];
        newTranches[trancheIndex] = Object.assign(new TrancheObject(), value);
        setData(data.update('tranches', newTranches));
    }, [data]);
    const onChangeDropdown = React.useCallback((name) => (event) => {
        const { value } = event.target.value;
        setData(data.update(name, value));
    }, [data]);
    const onChangeInput = React.useCallback((name) => (event) => {
        const { value } = event.target;
        setData(data.update(name, value));
    }, [data]);
    const onChangePermissions = React.useCallback((permissions) => {
        const updated = Object.assign(new NewIssue(), data, { permissions });
        setData(updated);
    }, [data]);
    const onClickAddTranche = React.useCallback(() => {
        setData(data.addTranche());
    }, [data]);
    const onValidationChanged = React.useCallback((name) => (isValid) => {
        const newErrorState = { ...errorState, [name]: isValid };
        setErrorState(newErrorState);
    }, [errorState]);
    return (React.createElement(Card, null,
        React.createElement(CardHeaderForm, { title: data.id ? 'Update New issue' : 'Create New Issue' }),
        React.createElement("div", { className: "base-form", id: "new-issue-form" },
            React.createElement(Validation, { errorMessage: errorMessage, onValidationChanged: onValidationChanged('issuerId'), validator: validators.issuer },
                React.createElement(Dropdown, { name: "issuer", label: "Issuer", onChange: onChangeDropdown('issuerId'), options: issuerOptions, value: getOptions(data.issuerId, issuerOptions) })),
            React.createElement(Validation, { errorMessage: errorMessage, onValidationChanged: onValidationChanged('name'), validator: validators.name },
                React.createElement(TextField, { label: "Name", onChange: onChangeInput('name'), value: data.name || '', placeholder: "Required" }))),
        React.createElement("div", { className: "co-item" },
            React.createElement("div", { className: "co-container" },
                React.createElement(Typography, { type: "h2" }, "Tranches"),
                React.createElement(Button, { className: "co-button", iconButton: true, onClick: onClickAddTranche },
                    React.createElement(Icon, { iconName: "plus" }))),
            data.tranches?.length ? data.tranches.map((tranche, index) => (React.createElement(Tranche, { disabled: false, key: tranche.key, top: index === 0, tranche: tranche, setTranche: setTranche(tranche.key), benchmarkOptions: benchmarkOptions, currencyOptions: currencyOptions }))) : (React.createElement(Typography, { type: "h2" }, "No Tranches Yet"))),
        React.createElement("div", { className: "co-form-buttons-plus co-item" },
            React.createElement(Button, { disabled: disabledButton, onClick: save(data) }, "Save"),
            React.createElement(MetadataButtons, { data: data, onChange: onChangePermissions, disabled: false }))));
};
export default NewIssueForm;
