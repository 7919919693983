import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import TabContext from 'contexts/TabContext';
import ViewContext from 'contexts/ViewContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import KeyValuesData from '../../../models/regexResponses/KeyValuesData';
import ItemData from '../../../models/regexResponses/ItemData';
import LineData from '../../../models/regexResponses/LineData';
import SubjectData from '../../../models/regexResponses/SubjectData';
import DataViewerForm from './data-viewer-form/DataViewerForm';
import DataTable from './data-table/DataTable';
import ContentModal from '../ContentModal';
const DataViewer = ({ category, clearRegexes, create, myKey, setCategory, setType, setMyKey, type, }) => {
    const { download } = React.useContext(DownloadContext);
    const { setView } = React.useContext(ViewContext);
    const { setTabId } = React.useContext(TabContext);
    const [update, setUpdate] = React.useState();
    const [showModal, setShowModal] = React.useState(false);
    const [urlPage, setUrlPage] = React.useState();
    const [urlTotal, setUrlTotal] = React.useState();
    const [name, setName] = React.useState();
    const [isLoading, setLoading] = React.useState(false);
    const goToUpdate = React.useCallback(({ row: { original: { messageId } } }) => {
        const url = new ServerURL('/syndicate/transactions/get-id');
        url.searchParams.append('messageId', messageId.id);
        url.searchParams.append('index', messageId.index.toString());
        (async () => {
            const request = new ServerRequest(url);
            const updateId = await download.jsonPlus(request, 'unable to find updateId');
            localStorage.setItem('transactionId', updateId.transactionId);
            localStorage.setItem('index', updateId.index.toString());
            setView('UPDATE');
            setTabId('Transactions');
        })();
    }, [download, setTabId, setView]);
    const view = React.useCallback(({ row: { original: { messageId } } }) => {
        (async () => {
            const url = new ServerURL('/syndicate/transactions/update-message');
            url.searchParams.append('messageId', messageId.id);
            url.searchParams.append('index', messageId.index.toString());
            const request = new ServerRequest(url);
            const htmlUrl = new ServerURL('/syndicate/emails/html');
            htmlUrl.searchParams.append('messageId', messageId.id);
            htmlUrl.searchParams.append('index', messageId.index.toString());
            const htmlRequest = new ServerRequest(htmlUrl);
            try {
                const updateDownload = download.jsonPlus(request, 'Error downloading update');
                const htmlDownload = download.textPlus(htmlRequest, 'Error downloading html');
                const [downloadedUpdate, downloadedHtml] = await Promise.all([updateDownload, htmlDownload]);
                setUpdate({ ...downloadedUpdate, html: downloadedHtml });
                setShowModal(true);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    const transformData = React.useCallback((downloadedData) => {
        switch (type) {
            case 'keyvalues':
                return Object.assign(new KeyValuesData(), downloadedData);
            case 'listitems':
                return Object.assign(new ItemData(), downloadedData);
            case 'paragraphs':
                return Object.assign(new LineData(), downloadedData);
            case 'subject':
                return Object.assign(new SubjectData(), downloadedData);
            default:
                throw new Error('Unknown type');
        }
    }, [type]);
    React.useEffect(() => {
        switch (type) {
            case 'keyvalues':
                setName(`data-keyvalues-${myKey?.name}`);
                break;
            case 'listitems':
                setName('data-lists');
                break;
            case 'paragraphs':
                setName(`data-paragrphs-${category}`);
                break;
            case 'subject':
                setName('data-subject');
                break;
            default:
                throw new Error('Unknown type');
        }
    }, [category, myKey?.name, type]);
    const dataTransform = React.useCallback((sourceData) => sourceData.map((d) => transformData(d)), [transformData]);
    React.useEffect(() => {
        switch (type) {
            case 'keyvalues': {
                if (myKey !== undefined) {
                    const bytes = new TextEncoder().encode(myKey.name);
                    const binString = String.fromCodePoint(...bytes);
                    const escapedName = window.btoa(binString);
                    setUrlPage(`/syndicate/regex/${type}/${escapedName}/page`);
                    setUrlTotal(`/syndicate/regex/${type}/${escapedName}/total`);
                }
                break;
            }
            case 'paragraphs':
                setUrlPage(`/syndicate/regex/${type}/${category}/page`);
                setUrlTotal(`/syndicate/regex/${type}/${category}/total`);
                break;
            default:
                setUrlPage(`/syndicate/regex/${type}/page`);
                setUrlTotal(`/syndicate/regex/${type}/total`);
        }
    }, [category, myKey, type]);
    return (React.createElement(React.Fragment, null,
        typeof update !== 'undefined'
            ? React.createElement(ContentModal, { update: update, hideModal: () => setShowModal(false), show: showModal })
            : null,
        React.createElement("div", { style: { gridArea: 'dataForm' } },
            React.createElement(DataViewerForm, { category: category, clearRegexes: clearRegexes, isLoading: isLoading, myKey: myKey, type: type, setCategory: setCategory, setType: setType, setMyKey: setMyKey })),
        React.createElement("div", { style: {
                gridArea: 'dataTable', display: 'flex', flexDirection: 'column', overflow: 'hidden',
            } },
            (urlPage !== undefined && urlTotal !== undefined && name !== undefined
                ? (React.createElement(DataTable, { create: create, dataTransform: dataTransform, emptyMessage: "No Data. Have you selected a key?", errorMessage: "Error downloading data", fetchSize: type === 'paragraphs' ? 10 : 50, view: view, name: name, update: goToUpdate, setLoading: setLoading, urlPage: urlPage, urlTotal: urlTotal }))
                : undefined),
            ";")));
};
export default DataViewer;
