import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import { optionSort } from 'utils/table';
import Regex from '../../../models/regexes/Regex';
import { unescapeRegex } from '../../../models/regexes/regexUtils';
import RegexCrudService from '../RegexCrudService';
import MarkerDropdown from './MarkerDropdown';
import './RegexFormModal.scss';
const RegexFormModal = ({ callback, hideModal, originalRegex, regex, show, title, type, }) => {
    const { download } = React.useContext(DownloadContext);
    const [canSave, setCanSave] = React.useState(true);
    const [isSaveLoading, setSaveLoading] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [markerList, setMarkerList] = React.useState();
    const [testList, setTestList] = React.useState([]);
    const [newRegex, setNewRegex] = React.useState(regex);
    const [height, setHeight] = React.useState(500);
    React.useEffect(() => {
        setNewRegex(regex !== undefined ? new Regex(regex) : undefined);
        setTestList([]);
    }, [regex]);
    React.useEffect(() => {
        setHeight(400 + (markerList?.length ?? 0) * 48);
    }, [markerList]);
    const service = React.useMemo(() => new RegexCrudService({ download, type }), [download, type]);
    React.useEffect(() => {
        const path = '/syndicate/regexes/markers';
        (async () => {
            const request = new ServerRequest(path);
            try {
                const body = await download.jsonPlus(request, 'Error downloading markers');
                setOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    React.useEffect(() => {
        setCanSave(newRegex?.markers.every((m) => m !== undefined) ?? false);
    }, [newRegex]);
    const onChangeMarker = React.useCallback((m) => (event) => {
        const { value } = event.target.value;
        if (newRegex !== undefined) {
            const newRegex2 = newRegex.updateMarker(m, value);
            setNewRegex(newRegex2);
        }
        const newTestList = [...testList];
        newTestList[m].className = '';
        setTestList(newTestList);
        (async () => {
            const url = new ServerURL('/syndicate/regexes/test');
            url.searchParams.append('text', newTestList[m].text);
            url.searchParams.append('marker', value);
            const request = new ServerRequest(url);
            try {
                const body = await download.jsonPlus(request, 'Error testing marker');
                const newNewTestList = [...testList];
                if (body.fieldOptions !== undefined) {
                    newNewTestList[m] = {
                        className: 'co-test-warning',
                        field: body.field,
                        fieldId: undefined,
                        options: body.fieldOptions.sort(optionSort),
                        text: testList[m].text,
                    };
                }
                else {
                    newNewTestList[m].className = body.result ? 'co-test-success' : 'co-test-error';
                }
                newNewTestList[m].text = testList[m].text;
                setTestList(newNewTestList);
            }
            catch (error) {
                //
            }
        })();
    }, [download, newRegex, setNewRegex, testList]);
    const onClickDeleteMarker = React.useCallback((m) => () => {
        const regexClone = newRegex?.removeMarker(m, testList[m].text);
        const cloneTest = [...testList];
        cloneTest.splice(m, 1);
        setTestList(cloneTest);
        setNewRegex(regexClone);
    }, [newRegex, testList]);
    const removeFieldData = React.useCallback((m) => () => {
        const newTestList = [...testList];
        delete newTestList[m].fieldId;
        delete newTestList[m].options;
        newTestList[m].className = 'co-test-success';
        setTestList(newTestList);
    }, [setTestList, testList]);
    const onChangeDropdown = React.useCallback((m) => (event) => {
        const newTestList = [...testList];
        newTestList[m].fieldId = event.target.value.value;
        setTestList(newTestList);
    }, [setTestList, testList]);
    const modalRef = React.useRef(null);
    const modalTextRef = React.useRef(null);
    const checkSelection = React.useCallback(() => {
        const selection = window.getSelection();
        // If the selection is across nodes we ignore it
        if (selection?.type === 'Range' && selection.anchorNode === selection.focusNode) {
            // if selection is the subject's full text, we amend the regex. Oth
            if (selection.anchorNode?.parentElement === modalTextRef.current) {
                const newRegex2 = new Regex({ template: selection.toString() });
                setNewRegex(newRegex2);
            }
            else if (selection.anchorNode?.parentElement === modalRef.current) {
                if (selection.toString().endsWith('{')) {
                    selection.modify('extend', 'right', 'character');
                }
                if (selection.toString().startsWith('}')) {
                    selection.modify('extend', 'left', 'character');
                }
                let startOffset = Math.min(selection.anchorOffset, selection.focusOffset);
                let endOffset = Math.max(selection.anchorOffset, selection.focusOffset);
                const startNode = selection.anchorOffset < selection.focusOffset
                    ? selection.anchorNode : selection.focusNode;
                const endNode = selection.anchorOffset > selection.focusOffset
                    ? selection.anchorNode : selection.focusNode;
                if (startNode !== null && endNode !== null) {
                    if (selection.toString().endsWith(' ')) {
                        endOffset -= 1;
                        selection.setBaseAndExtent(startNode, startOffset, endNode, endOffset);
                    }
                    if (selection.toString().startsWith(' ')) {
                        startOffset += 1;
                        selection.setBaseAndExtent(startNode, startOffset, endNode, endOffset);
                    }
                }
                const text = selection.toString();
                const oldText = unescapeRegex(newRegex?.template ?? '');
                const value = `${oldText.substring(0, startOffset)}{}${oldText.substring(endOffset)}`;
                const countPreceding = (newRegex?.template.substring(0, startOffset).match(/{}/g) ?? []).length;
                // if 'text' contains {} then we need to replace those
                let newText = text;
                const countIncluded = (text.match(/{}/g) ?? []).length;
                (text.match(/{}/g) ?? []).forEach((t, index) => {
                    newText = newText.replace(t, testList[countPreceding + index].text);
                });
                const newTextList = [...testList];
                newTextList.splice(countPreceding, countIncluded, { className: '', text: newText });
                if (newRegex !== undefined) {
                    const newRegex2 = newRegex.updateValue(unescapeRegex(value));
                    setNewRegex(newRegex2);
                }
                else {
                    //
                }
                setTestList(newTextList);
            }
        }
    }, [newRegex, setNewRegex, testList]);
    React.useEffect(() => {
        if (show) {
            document.onmouseup = checkSelection;
        }
    }, [checkSelection, show]);
    const saveRegex = React.useCallback(() => {
        if (newRegex !== undefined) {
            setSaveLoading(true);
            (async () => {
                const updatedRegex = await service.save(newRegex, originalRegex);
                callback(updatedRegex);
                document.dispatchEvent(new Event('reloaddata'));
                setSaveLoading(false);
                hideModal();
            })();
        }
    }, [callback, hideModal, originalRegex, newRegex, service]);
    React.useEffect(() => {
        const indexedMarkers = newRegex?.markers
            .map((m, index) => ({ index, value: m }));
        setMarkerList(indexedMarkers?.map((m) => (React.createElement(MarkerDropdown, { index: m.index, key: `marker${m.index}`, onChangeDropdown: onChangeDropdown(m.index), onChangeMarker: onChangeMarker(m.index), onClickDeleteMarker: onClickDeleteMarker(m.index), options: options, removeFieldData: removeFieldData(m.index), testListItem: testList[m.index], value: m.value }))));
    }, [onChangeDropdown,
        onChangeMarker, onClickDeleteMarker, removeFieldData, options, newRegex, testList]);
    return (React.createElement(Modal, { size: "large", show: show, style: { userSelect: 'none' }, closeButton: true, onClose: hideModal, onMouseUp: () => { }, parentNode: document.body },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, title)),
        React.createElement(ModalBody, null,
            React.createElement("div", { style: { height: `${height}px` } },
                type === 'subject' ? (React.createElement("div", { className: "co-text-container" },
                    React.createElement("p", { ref: modalTextRef }, unescapeRegex(regex?.template ?? '')))) : null,
                React.createElement("div", { className: "co-text-container" },
                    React.createElement("p", { ref: modalRef }, unescapeRegex(newRegex?.template ?? ''))),
                markerList)),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { disabled: !canSave, loading: isSaveLoading, onClick: saveRegex }, "SAVE"))));
};
export default RegexFormModal;
