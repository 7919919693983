import * as React from 'react';
import { Button } from '@symphony-ui/uitoolkit-components';
import TableContext from 'components/custom-table/TableContext';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import TransactionMeta from '../../../models/TransactionMeta';
const TransactionMergeButton = () => {
    const { download } = React.useContext(DownloadContext);
    const [disabledMerge, setDisabledMerge] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    const [loadingMerge, setLoadingMerge] = React.useState(false);
    const { selection, resetRowExpansion, resetRowSelection, tableUpdateFn, } = React.useContext(TableContext);
    React.useEffect(() => {
        if (selection !== undefined) {
            setDisabled(selection.length < 2);
        }
    }, [selection]);
    const clickMerge = React.useCallback(() => {
        if (selection !== undefined && resetRowSelection !== undefined && resetRowExpansion !== undefined && tableUpdateFn !== undefined) {
            const selectedIndexedTransactions = selection;
            const idSelections = selection.map((t) => t.id);
            const idSelection = idSelections.join(',');
            resetRowSelection();
            setLoadingMerge(true);
            const formData = new FormData();
            formData.append('ids', idSelection);
            const request = new ServerRequest('/syndicate/transactions/merge', { body: formData, method: 'POST' });
            (async () => {
                try {
                    const oldestId = await download.textPlus(request, 'Error Merging transactions');
                    const newestTransaction = selectedIndexedTransactions
                        .sort((t0, t1) => (t1.timestamp.getTime() - t0.timestamp.getTime()))[0];
                    const updatedTransaction = new TransactionMeta({
                        id: oldestId,
                        name: newestTransaction.name,
                        timestamp: newestTransaction.timestamp.toString(),
                    });
                    updatedTransaction.fixDate();
                    const updateFn = (data) => ({
                        pageParams: data.pageParams,
                        pages: data.pages.map((p) => p
                            .filter((t) => (t.id === newestTransaction.id || !idSelections.includes(t.id)))
                            .map((t) => (t.id === newestTransaction.id ? updatedTransaction : t))),
                    });
                    tableUpdateFn(updateFn);
                    /**
                 * we remove the older ones. As there are now two transactions with the same 'oldestId',
                 * we cannot select the ones to remove based on Id.
                 */
                    resetRowExpansion(selection.map((s) => s));
                }
                catch (error) {
                    //
                }
                setLoadingMerge(false);
                setDisabledMerge(false);
            })();
        }
    }, [download, resetRowSelection, resetRowExpansion, selection, tableUpdateFn]);
    return (React.createElement(Button, { disabled: disabledMerge || disabled, loading: loadingMerge, name: "merge", onClick: clickMerge }, "MERGE"));
};
export default TransactionMergeButton;
