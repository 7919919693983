import { Button, Dropdown, Modal, ModalBody, ModalFooter, ModalHeader, TextField, } from '@symphony-ui/uitoolkit-components';
import TableContext from 'components/custom-table/TableContext';
import * as React from 'react';
import ServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
const TransactionMergeModal = ({ onClose, save }) => {
    const { download } = React.useContext(DownloadContext);
    const { selection } = React.useContext(TableContext);
    const [isDisabled, setDisabled] = React.useState(true);
    const [sector, setSector] = React.useState();
    const [region, setRegion] = React.useState();
    const [types, setTypes] = React.useState();
    const [companyName, setCompanyName] = React.useState('');
    const [regionOptions, setRegionOptions] = React.useState([]);
    const [sectorOptions, setSectorOptions] = React.useState([]);
    const [typeOptions, setTypeOptions] = React.useState([]);
    const onChangeCompanyName = React.useCallback((event) => {
        setCompanyName(event.target.value);
    }, []);
    const onChangeRegion = React.useCallback((event) => {
        const eventValue = event.target.value;
        const newValue = eventValue.value;
        setRegion(newValue);
    }, []);
    const onChangeSector = React.useCallback((event) => {
        const eventValue = event.target.value;
        const newValue = eventValue.value;
        setSector(newValue);
    }, []);
    const onChangeTypes = React.useCallback((event) => {
        const eventValue = event.target.value;
        let newValue = [];
        if (Array.isArray(eventValue)) {
            newValue = eventValue.map((e) => e.value);
        }
        else if (typeof eventValue === 'string') {
            newValue = [eventValue];
        }
        setTypes(newValue);
    }, []);
    const onClickSave = React.useCallback(() => {
        if (region !== undefined && sector !== undefined && types !== undefined && companyName.length > 2) {
            (async () => {
                await save(sector, region, types, companyName);
                onClose();
            })();
        }
    }, [companyName, onClose, region, save, sector, types]);
    React.useEffect(() => {
        (async () => {
            const url = new ServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'sector');
            const request = new ServerRequest(url);
            try {
                const body = await download.jsonPlus(request, 'Error downloading sector options');
                setSectorOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    React.useEffect(() => {
        (async () => {
            const url = new ServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'region');
            const request = new ServerRequest(url);
            try {
                const body = await download.jsonPlus(request, 'Error downloading region options');
                setRegionOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    React.useEffect(() => {
        (async () => {
            const url = new ServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'type-new');
            const request = new ServerRequest(url);
            try {
                const body = await download.jsonPlus(request, 'Error downloading type options');
                setTypeOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    React.useEffect(() => {
        setDisabled(sector === undefined || region === undefined || types === undefined || types.length === 0 || companyName.length < 3);
    }, [companyName.length, region, sector, types]);
    const onClickButton = React.useCallback((type, value) => () => {
        switch (type) {
            case 'SECTOR':
                setSector(value);
                break;
            case 'REGION':
                setRegion(value);
                break;
            case 'COMPANY':
                setCompanyName(value);
                break;
            case 'TYPES':
                setTypes(value);
                break;
            default:
        }
    }, []);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, show: true, size: "full-width" },
        React.createElement(ModalHeader, null, "Meta data merged transaction"),
        React.createElement(ModalBody, null,
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "Sector"),
                        React.createElement("th", null, "Region"),
                        React.createElement("th", null, "Company"),
                        React.createElement("th", null, "Types"))),
                React.createElement("tbody", null,
                    selection?.map((t) => (React.createElement(React.Fragment, null,
                        ' ',
                        React.createElement("tr", { key: `${t.id}n` },
                            React.createElement("td", { colSpan: 4, style: { fontSize: 'smaller', fontStyle: 'italic' } }, t.name)),
                        React.createElement("tr", { key: t.id, style: { textAlign: 'center' } },
                            React.createElement("td", null, t.sector !== undefined ? React.createElement(Button, { onClick: onClickButton('SECTOR', t.sector) }, sectorOptions.find((o) => 'value' in o && t.sector === o.value)?.label) : undefined),
                            React.createElement("td", null, t.region !== undefined ? React.createElement(Button, { onClick: onClickButton('REGION', t.region) }, regionOptions.find((o) => 'value' in o && t.region === o.value)?.label) : undefined),
                            React.createElement("td", null, t.company?.name !== undefined ? React.createElement(Button, { onClick: onClickButton('COMPANY', t.company.name) }, t.company.name) : undefined),
                            React.createElement("td", null, t.types?.length > 0 ? React.createElement(Button, { onClick: onClickButton('TYPES', t.types) }, typeOptions.filter((o) => 'value' in o && t.types.includes(o.value)).map((o) => o.label).join(', ')) : undefined))))),
                    React.createElement("tr", { style: { height: '2rem', verticalAlign: 'baseline' } },
                        React.createElement("td", { style: { width: '8.5rem' } },
                            React.createElement(Dropdown, { className: "co-drop-8", displayArrowIndicator: false, placeholder: "Select...", enableTermSearch: false, isTypeAheadEnabled: false, closeMenuOnSelect: true, onChange: onChangeSector, options: sectorOptions, value: sectorOptions.find((o) => 'value' in o && sector === o.value) })),
                        React.createElement("td", { style: { width: '8.5rem' } },
                            React.createElement(Dropdown, { className: "co-drop-8", displayArrowIndicator: false, placeholder: "Select...", enableTermSearch: false, isTypeAheadEnabled: false, closeMenuOnSelect: true, onChange: onChangeRegion, options: regionOptions, value: regionOptions.find((o) => 'value' in o && region === o.value) })),
                        React.createElement("td", { style: { width: '11.5rem' } },
                            React.createElement(TextField, { onChange: onChangeCompanyName, value: companyName })),
                        React.createElement("td", { style: { minWidth: 'calc(100% - 19.5rem)' } },
                            React.createElement(Dropdown, { className: "co-drop-10", displayArrowIndicator: false, placeholder: "Select...", enableTermSearch: false, isMultiSelect: true, isTypeAheadEnabled: false, closeMenuOnSelect: true, onChange: onChangeTypes, options: typeOptions, value: typeOptions.filter((o) => 'value' in o && types?.includes(o.value)) })))))),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { disabled: isDisabled, onClick: onClickSave }, "SAVE"))));
};
export default TransactionMergeModal;
