import * as React from 'react';
import { Button } from '@symphony-ui/uitoolkit-components';
import TableContext from 'components/custom-table/TableContext';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import TransactionWrapper from '../../../models/TransactionWrapper';
import TransactionMergeModal from './TransactionMergeModal';
import './test.scss';
const TransactionMergeButton = () => {
    const { download } = React.useContext(DownloadContext);
    const { selection, resetRowExpansion, resetRowSelection, tableUpdateFn, } = React.useContext(TableContext);
    const [disabledMerge, setDisabledMerge] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    const [loadingMerge, setLoadingMerge] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    React.useEffect(() => {
        if (selection !== undefined) {
            setDisabled(selection.length < 2);
        }
    }, [selection]);
    const saveMerge = React.useCallback(async (sector, region, types, name) => {
        if (selection !== undefined && resetRowSelection !== undefined && resetRowExpansion !== undefined && tableUpdateFn !== undefined) {
            const selectedIndexedTransactions = selection;
            const idSelections = selection.map((t) => t.id);
            const idSelection = idSelections.join(',');
            resetRowSelection();
            setLoadingMerge(true);
            const formData = new FormData();
            formData.append('ids', idSelection);
            formData.append('sector', sector);
            formData.append('region', region);
            formData.append('types', types.join(','));
            formData.append('name', name);
            const request = new ServerRequest('/syndicate/transactions/merge', { body: formData, method: 'POST' });
            try {
                const responseTransction = await download.jsonPlus(request, 'Error Merging transactions');
                const returnTransaction = new TransactionWrapper(responseTransction);
                const newestTransaction = selectedIndexedTransactions
                    .sort((t0, t1) => (t1.lastUpdate.getTime() - t0.lastUpdate.getTime()))[0];
                const updateFn = (data) => ({
                    pageParams: data.pageParams,
                    pages: data.pages.map((p) => p
                        .filter((t) => (t.id === newestTransaction.id || !idSelections.includes(t.id)))
                        .map((t) => (t.id === newestTransaction.id ? returnTransaction : t))),
                });
                tableUpdateFn(updateFn);
                /**
               * we remove the older ones. As there are now two transactions with the same 'oldestId',
               * we cannot select the ones to remove based on Id.
               */
                resetRowExpansion(selection.map((s) => s));
            }
            catch (error) {
                //
            }
            setLoadingMerge(false);
            setDisabledMerge(false);
        }
    }, [download, resetRowExpansion, resetRowSelection, selection, tableUpdateFn]);
    const clickMerge = React.useCallback(() => {
        if (selection !== undefined && selection.length > 1) {
            const uniqueSectors = [...new Set(selection.filter((t) => t.sector !== undefined).map((t) => t.sector))];
            const uniqueRegions = [...new Set(selection.filter((t) => t.region !== undefined).map((t) => t.region))];
            const uniqueTypes = [...new Set(selection.filter((t) => t.types?.length > 0).map((t) => t.types.join(',')))];
            const uniqueNames = [...new Set(selection.filter((t) => t.name !== undefined).map((t) => t.name))];
            if (uniqueSectors.length === 1 && uniqueRegions.length === 1 && uniqueTypes.length === 1 && uniqueNames.length === 1) {
                (async () => {
                    await saveMerge(uniqueSectors[0], uniqueRegions[0], uniqueTypes[0].split(','), uniqueNames[0]);
                })();
            }
            else {
                setShowModal(true);
            }
        }
    }, [saveMerge, selection]);
    return (React.createElement(React.Fragment, null,
        showModal
            ? React.createElement(TransactionMergeModal, { onClose: () => setShowModal(false), save: saveMerge }) : undefined,
        React.createElement(Button, { disabled: disabledMerge || disabled, loading: loadingMerge, name: "merge", onClick: clickMerge }, "MERGE")));
};
export default TransactionMergeButton;
