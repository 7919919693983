import ServerRequest from '../models/ServerRequest';
const replacer = (key, value) => {
    if (value instanceof Map) {
        const object = {};
        Array.from(value.entries()).forEach(([k, v]) => { object[k] = v; });
        return object;
    }
    return value;
};
class CrudService {
    name;
    path;
    download;
    constructor({ name, path, download, }) {
        this.name = name;
        this.path = path;
        this.download = download;
    }
    async delete(data) {
        const requestInit = { body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, method: 'DELETE' };
        const request = new ServerRequest(this.path, requestInit);
        await this.download.noContentPlus(request, `Error deleting ${this.name}`);
    }
    async createPlus(data) {
        const requestInit = { body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, method: 'POST' };
        const request = new ServerRequest(this.path, requestInit);
        return this.download.jsonPlus(request, `Error saving ${this.name}`);
    }
    async updatePlus(data) {
        const requestInit = { body: JSON.stringify(data, replacer), headers: { 'Content-Type': 'application/json' }, method: 'PUT' };
        const request = new ServerRequest(this.path, requestInit);
        return this.download.jsonPlus(request, `Error saving ${this.name}`);
    }
    async getPlus() {
        const request = new ServerRequest(this.path);
        return this.download.jsonPlus(request, `Error downloading ${this.name}`);
    }
    static updateData(data, item) {
        const index = data.findIndex((d) => d.id === item.id);
        const newData = [...data];
        if (index === -1) {
            newData.push(item);
        }
        else {
            newData[index] = item;
        }
        return newData;
    }
}
export default CrudService;
