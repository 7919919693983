class Ipo {
    exchange;
    id;
    constructor(ipo) {
        this.exchange = ipo.exchange;
        this.id = ipo.id;
    }
    updateExchange(newExchange) {
        const newIpo = new Ipo(this);
        newIpo.exchange = newExchange;
        return newIpo;
    }
}
export default Ipo;
