class TransactionMeta {
    id;
    name;
    timestamp;
    constructor({ id, name, timestamp, }) {
        this.id = id;
        this.name = name;
        this.timestamp = new Date(timestamp);
    }
    fixDate() {
        if (typeof this.timestamp === 'string') {
            this.timestamp = new Date(this.timestamp);
        }
    }
}
export default TransactionMeta;
