import * as React from 'react';
import { TextField } from '@symphony-ui/uitoolkit-components';
import DateTime from 'components/date-time/DateTime';
const ObservationInput = ({ hasLabel, value }) => {
    const [data, setData] = React.useState(value);
    const [date, setDate] = React.useState();
    React.useEffect(() => {
        const regex = /^(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})T(?<hour>\d{2}):(?<minute>\d{2}):(?<second>\d{2})$/u;
        const match = data.time.match(regex);
        const matchGroups = match?.groups;
        if (matchGroups === undefined) {
            setDate(undefined);
        }
        else {
            setDate(new Date(Number.parseInt(matchGroups.year, 10), Number.parseInt(matchGroups.month, 10), Number.parseInt(matchGroups.day, 10), Number.parseInt(matchGroups.hour, 10), Number.parseInt(matchGroups.minute, 10), Number.parseInt(matchGroups.second, 10)));
        }
    }, [data.time]);
    const changeInput = React.useCallback((name) => (event) => {
        const { value: newValue } = event.target;
        setData({ ...data, [name]: newValue });
    }, [data]);
    const changeTime = React.useCallback((name) => (event) => {
        const { value: newValue } = event.target;
        setData({ ...data, [name]: newValue });
    }, [data]);
    return (React.createElement("div", { className: "single-row co-item", id: "release-form" },
        React.createElement(TextField, { id: "name", label: hasLabel ? 'Reference Period' : '', onChange: changeInput('referencePeriod'), value: data.referencePeriod, placeholder: "required", "data-required": true, "data-maxlength": "24" }),
        React.createElement(DateTime, { label: hasLabel ? 'Time' : '', onChange: changeTime('time'), value: date }),
        React.createElement(TextField, { id: "name", label: hasLabel ? 'Value' : '', onChange: changeInput('value'), value: data.value || '', placeholder: "optional", "data-required": true, "data-maxlength": "24" })));
};
export default ObservationInput;
