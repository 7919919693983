import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, } from '@symphony-ui/uitoolkit-components';
import AppViewContext from 'contexts/AppViewContext';
import Contact from './Contact';
import './RightPanel.scss';
const RightPanel = ({ handleLoginRequest }) => {
    const { jwt, offline } = React.useContext(AppViewContext);
    const [showContact, setShowContact] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const clickContact = React.useCallback(() => {
        setShowContact(true);
    }, []);
    const clickLogin = React.useCallback(() => {
        (async () => {
            try {
                await handleLoginRequest();
            }
            catch (error) {
                setShowModal(true);
            }
        })();
    }, [handleLoginRequest]);
    return (React.createElement("div", { id: "right-panel" },
        React.createElement(Modal, { closeButton: true, onClose: () => setShowModal(false), show: showModal, size: "small" },
            React.createElement(ModalBody, null, "Unable to contact Authentication Server, please check your connection."),
            React.createElement(ModalFooter, null,
                React.createElement(Button, { onClick: () => setShowModal(false), variant: "primary-destructive" }, "OK"))),
        showContact
            ? React.createElement(Contact, { close: () => setShowContact(false) }) : '',
        React.createElement(Button, { disabled: offline, onClick: clickContact }, "REQUEST A DEMO"),
        !jwt ? (React.createElement(Button, { disabled: offline, onClick: clickLogin }, "SIGN IN")) : ''));
};
export default RightPanel;
