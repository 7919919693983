import * as React from 'react';
import { Button, Card, Icon } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import TransactionWrapper from '../../models/TransactionWrapper';
import IFRStreamItem from './IFRStreamItem';
import IFRStoryModal from './IFRStoryModal';
import IFRFilterModal from './IFRFilterModal';
import StreamItem from '../live/StreamItem';
import MesssagesModal from '../transactions/overview/MessagesModal';
const IFR = () => {
    const { download } = React.useContext(DownloadContext);
    const [items, setItems] = React.useState([]);
    const [cmoItems, setCmoItems] = React.useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [story, setStory] = React.useState();
    const [detailsId, setDetailsId] = React.useState();
    const [filter, setFilter] = React.useState(() => {
        const stored = localStorage.getItem('ifr-filter');
        return stored !== null ? JSON.parse(stored) : undefined;
    });
    const hideStory = React.useCallback((hide) => {
        const newItems = items.map((i) => {
            if (i.data.storyid === hide.data.storyid) {
                const copyStory = { ...hide };
                copyStory.hide = true;
                return copyStory;
            }
            return i;
        });
        setItems(newItems);
    }, [items]);
    const onClickFilter = React.useCallback(() => {
        setShowModal(true);
    }, []);
    React.useEffect(() => {
        (async () => {
            const url = new ServerURL('/syndicate/ifr/all');
            if (filter !== undefined) {
                url.searchParams.append('region', filter.region?.join(','));
                url.searchParams.append('assetClass', filter.assetClass?.join(','));
            }
            const request = new ServerRequest(url);
            try {
                const body = await download.jsonPlus(request, 'Error downloading users.');
                setItems(body);
            }
            catch (error) {
                //
            }
        })();
    }, [download, filter]);
    React.useEffect(() => {
        (async () => {
            const url = new ServerURL('/syndicate/transactions/page');
            url.searchParams.append('start', '0');
            url.searchParams.append('end', '100');
            const request = new ServerRequest(url);
            try {
                const body = await download.jsonPlus(request, 'Error downloading users.');
                const transactions2 = body.map((t) => new TransactionWrapper(t));
                setCmoItems(transactions2);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement(IFRFilterModal, { filter: filter, onClose: () => setShowModal(false), setFilter: setFilter, show: showModal }),
        React.createElement(MesssagesModal, { hideModal: () => setDetailsId(undefined), show: detailsId !== undefined, splitScreen: false, transactionId: detailsId }),
        story !== undefined
            ? (React.createElement(IFRStoryModal, { onClose: () => setStory(undefined), story: story })) : undefined,
        React.createElement("div", { style: { display: 'flex' } },
            React.createElement(Button, { iconButton: true, onClick: onClickFilter },
                React.createElement(Icon, { iconName: "filter" }))),
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-evenly', overflowY: 'auto' } },
            React.createElement("div", { style: { overflowY: 'scroll', width: '50%' } }, items.filter((m) => !m.hide).map((m) => (React.createElement(IFRStreamItem, { hideStory: () => hideStory(m), story: m, setStory: () => setStory(m) })))),
            React.createElement("div", { style: { overflowY: 'scroll', width: '50%' } }, cmoItems.map((m) => (React.createElement(StreamItem, { messages: m, setDetailsId: setDetailsId })))))));
};
export default IFR;
