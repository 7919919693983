import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
const createDataRequest = (urlPage, { start, fetchSize, sorting, columnFilters, }) => {
    if (urlPage === undefined) {
        throw new Error('No URL Page yet');
    }
    const url = new ServerURL(urlPage);
    url.searchParams.append('start', start.toString());
    if (fetchSize !== undefined) {
        url.searchParams.append('fetchSize', fetchSize.toString());
    }
    if (sorting !== undefined && sorting.length > 0) {
        url.searchParams.append('sortId', sorting.map((s) => s.id).join(','));
        url.searchParams.append('sortDesc', sorting.map((s) => s.desc).join(','));
    }
    if (columnFilters !== undefined && columnFilters.length > 0) {
        url.searchParams.append('filterId', columnFilters.map((s) => s.id).join(','));
        url.searchParams.append('filterValue', columnFilters.map((f) => (Array.isArray(f.value) ? f.value.join('|') : f.value)).join(','));
    }
    return new ServerRequest(url);
};
export default createDataRequest;
