import * as React from 'react';
import { Dropdown } from '@symphony-ui/uitoolkit-components';
import { getOptionsMulti } from 'utils/change';
import LabelTooltipDecorator from '@symphony-ui/uitoolkit-components/components/label-tooltip-decorator/LabelTooltipDecorator';
import FilterItem from '../../models/FilterItem';
import './FilterItemInput.scss';
const FilterItemInput = ({ disabled = false, label, options, placeholder = 'Select...', value, onChange, }) => {
    const [data, setData] = React.useState(value ?? { exclude: true, values: [] });
    const flagOptions = React.useMemo(() => ([{ label: 'Exclude', value: true }, { label: 'Include', value: false }]), []);
    const onChangeDropdownExclude = React.useCallback((event) => {
        const { value: value2 } = event.target;
        const newObject = { exclude: value2.value, values: data.values };
        setData(newObject);
        const newObject2 = { target: { value: new FilterItem(newObject) } };
        onChange(newObject2);
    }, [data, onChange]);
    const onChangeDropdownValues = React.useCallback((event) => {
        const { value: value2 } = event.target;
        const newObject = { exclude: data.exclude, values: Array.isArray(value2) ? [...new Set(value2.map((v) => v.value))] : [value2.value] };
        setData(newObject);
        const newObject2 = { target: { value: new FilterItem(newObject) } };
        onChange(newObject2);
    }, [data, onChange]);
    const onMenuClose = React.useCallback(() => {
        document.activeElement.blur();
    }, []);
    return (React.createElement("div", { className: "tk-input-group tag-input" },
        React.createElement(LabelTooltipDecorator, { label: label }),
        React.createElement("div", { className: "co-input-container", id: "tag-group" },
            React.createElement(Dropdown, { className: "first-one", isDisabled: disabled, isTypeAheadEnabled: false, name: "exclude", onChange: onChangeDropdownExclude, onMenuClose: onMenuClose, options: flagOptions, size: "large", value: flagOptions.find((o) => o.value === data?.exclude) }),
            React.createElement(Dropdown, { className: "second-one", isDisabled: disabled, isMultiSelect: true, name: "values", onChange: onChangeDropdownValues, options: options, placeholder: placeholder, value: getOptionsMulti(data?.values ?? [], options) }))));
};
export default FilterItemInput;
