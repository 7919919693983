/* eslint-disable react/no-danger */
import { Button, Icon, Loader, Typography, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import pretty from 'pretty';
import { printAddress, printTimestampString } from 'utils/table';
import './EmailGridViewer.scss';
import ViewContext from 'contexts/ViewContext';
import DownloadContext from 'contexts/DownloadContext';
import ServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import TabContext from 'contexts/TabContext';
const EmailPartViewer = ({ hasError, index: index2, isHtml, isLoading, messageId, part, }) => {
    const { download } = React.useContext(DownloadContext);
    const { setView } = React.useContext(ViewContext);
    const { setTabId } = React.useContext(TabContext);
    const clickOpen = React.useCallback(() => {
        const url = new ServerURL('/syndicate/update/from-email');
        url.searchParams.append('messageId', messageId);
        url.searchParams.append('index', index2.toString());
        const request = new ServerRequest(url);
        (async () => {
            try {
                const updateId = await download.jsonPlus(request, 'Unable to get UpdateId');
                localStorage.setItem('transactionId', updateId.transactionId);
                localStorage.setItem('index', updateId.index.toString());
                localStorage.setItem('view', 'UPDATE');
                setTabId('Transactions');
                setView('UPDATE');
            }
            catch (error) {
                console.error(error);
            }
        })();
    }, [download, index2, setView, messageId, setTabId]);
    if (hasError || isLoading) {
        return (React.createElement("div", null, isLoading ? React.createElement(Loader, { className: "co-loader", type: "spinner" }) : 'Error in Content download'));
    }
    return (React.createElement("div", { className: index2 % 2 === 1 ? 'co-email-part-odd' : '' },
        React.createElement("div", { style: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' } },
            part.subject ? React.createElement(Typography, { type: "h2" }, part.subject) : null,
            React.createElement(Button, { iconButton: true, onClick: clickOpen, size: "small" },
                React.createElement(Icon, { iconName: "search" }))),
        React.createElement("div", { style: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' } },
            part.from ? React.createElement(Typography, { type: "h3" }, printAddress(part.from)) : null,
            part.timestamp ? React.createElement(Typography, { type: "h3" }, printTimestampString(part.timestamp)) : null),
        !isHtml
            ? React.createElement("div", { dangerouslySetInnerHTML: { __html: part.body } })
            : (React.createElement("pre", null, pretty(part.body, { ocd: false }))),
        part.footer ? (React.createElement(React.Fragment, null,
            React.createElement("hr", null),
            !isHtml
                ? React.createElement("div", { className: "co-email-footer", dangerouslySetInnerHTML: { __html: part.footer } })
                : (React.createElement("div", { className: "co-email-footer" }, pretty(part.footer, { ocd: false }))))) : null));
};
export default EmailPartViewer;
