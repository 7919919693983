import { APP_URL, AUTH_URL } from 'utils/system/envs-constants';
const pkceAuthConfig = {
    authority: AUTH_URL,
    client_id: 'public-client',
    redirect_uri: `${APP_URL}/callback`,
    response_type: 'code',
    loadUserInfo: true,
    scope: 'openid profile email',
    post_logout_redirect_uri: `${APP_URL}/sign-out`,
    response_mode: 'query',
};
export default pkceAuthConfig;
export const userinfoEndpoint = `${AUTH_URL}/userinfo`;
