import * as React from 'react';
import { Button, Icon } from '@symphony-ui/uitoolkit-components';
const StreamItem = ({ messages, setDetailsId }) => {
    const onClick = React.useCallback(() => {
        setDetailsId(messages.id);
    }, [messages.id, setDetailsId]);
    const timeString = React.useMemo(() => {
        if (messages.timestamp !== undefined) {
            return new Date(messages.timestamp).toLocaleTimeString();
        }
        return 'NONE';
    }, [messages.timestamp]);
    return (React.createElement("div", { style: {
            display: 'flex', justifyContent: 'flex-start', marginBottom: '0.5rem', width: '100%',
        } },
        React.createElement("div", { style: { marginRight: '1rem' } },
            React.createElement(Button, { onClick: onClick, size: "small" },
                React.createElement(Icon, { iconName: "plus" }))),
        React.createElement("p", null,
            timeString,
            "-",
            messages.name)));
};
export default StreamItem;
