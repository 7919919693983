const compareDates = (date1, date2) => {
    if (date1 > date2) {
        return 1;
    }
    if (date1 < date2) {
        return -1;
    }
    return 0;
};
export default {
    date: (field) => (rowA, rowB) => ((field in rowA.original && field in rowB.original)
        ? rowA.original[field] - rowB.original[field] : 0),
    lastUpdate: (rowA, rowB) => compareDates(rowA.original.lastUpdate, rowB.original.lastUpdate),
    maturity: (rowA, rowB) => {
        if (!('maturity' in rowA.original.data)) {
            return -1;
        }
        if (!('maturity' in rowB.original.data)) {
            return 1;
        }
        const maturityA = rowA.original.data.maturity.date;
        const maturityB = rowB.original.data.maturity.date;
        const dateA = new Date(maturityA.year, maturityA.monthValue, maturityA.dayOfMonth);
        const dateB = new Date(maturityB.year, maturityB.monthValue, maturityB.dayOfMonth);
        return compareDates(dateA, dateB);
    },
};
