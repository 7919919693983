/* eslint-disable react/no-danger */
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import { printAddress, printTimestampString } from 'utils/table';
import './JoinModal.scss';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import ServerURL from 'models/ServerUrl';
const JoinModal = ({ hideModal, isLoading, join, show, updates, }) => {
    const { download } = React.useContext(DownloadContext);
    const [updatesWithHtml, setUpdateWithHtml] = React.useState([]);
    const width = React.useMemo(() => {
        if (updatesWithHtml !== undefined) {
            return `${100 / updatesWithHtml.length}%`;
        }
        return undefined;
    }, [updatesWithHtml]);
    const getHtml = React.useCallback(async (update) => {
        const url = new ServerURL('/syndicate/emails/html');
        url.searchParams.append('messageId', update.emailId.id);
        url.searchParams.append('index', update.emailId.index.toString());
        const request = new ServerRequest(url);
        try {
            const html = await download.textPlus(request, 'Unable to download html');
            return { ...update, html };
        }
        catch (error) {
            //
        }
        return undefined;
    }, [download]);
    React.useEffect(() => {
        if (show) {
            const topCheckedUpdates = updates.filter((u) => u.checked);
            const copyCheckedUpdates = updates.filter((u) => 'copies' in u).flatMap((u) => u.copies).filter((u) => u.checked);
            const checkedUpdates = topCheckedUpdates.concat(copyCheckedUpdates.map((u) => u))
                .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
            const withHtml = checkedUpdates.map((u) => getHtml(u));
            (async () => Promise.all(withHtml).then((list) => setUpdateWithHtml(list)))();
        }
    }, [getHtml, show, updates]);
    return (React.createElement(Modal, { size: "full-width", show: show, closeButton: true, onClose: hideModal, parentNode: document.body },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, "Join several of the same ")),
        React.createElement(ModalBody, null,
            React.createElement("div", { style: { display: 'flex' } }, updatesWithHtml.map((update) => (React.createElement("div", { className: "co-update", key: `${update.timestamp}-${update.subject}`, style: { width } },
                React.createElement("h4", null, update.subject),
                React.createElement("div", null, printAddress(update.from)),
                React.createElement("div", { className: "co-update-timestamp" }, printTimestampString(update.timestamp)),
                React.createElement("div", { className: "co-update-html", dangerouslySetInnerHTML: { __html: update.html } })))))),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { loading: isLoading, onClick: join }, "OK"))));
};
export default JoinModal;
