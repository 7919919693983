/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { Link, Typography } from '@symphony-ui/uitoolkit-components';
import './BottomPanel.scss';
const BottomPanel = () => {
    const link = 'https://symphony.com/2021/01/06/cedar-bot-a-symphony-hackathon-winner/';
    return (React.createElement("div", { id: "bottom-panel" },
        React.createElement("div", null,
            React.createElement(Link, { url: link }, "Winners of 2020 Symphony London Hackathon")),
        React.createElement("div", null,
            React.createElement(Typography, { type: "h1" }, "Most Progressive Business Workflow"))));
};
export default BottomPanel;
