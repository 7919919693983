import * as React from 'react';
import { Navigate } from 'react-router-dom';
const CallbackSilentHandler = ({ authenticated, setAuth, userManager, }) => {
    console.log('Start CallbackHandler. Authenticated: %o', authenticated);
    const [isLoading, setLoading] = React.useState(true);
    React.useEffect(() => {
        (async () => {
            console.log('Started SilentCallbackHandler. Setting user using SigninRedirectCallback');
            try {
                const user = await userManager.signinCallback();
                console.log('SILENT CALLBACK DONE!!! User coming back: %o', user);
                if (user) {
                    setAuth(true);
                }
                else {
                    setAuth(false);
                }
            }
            catch (error) {
                console.error('Error when trying to get access token in the SilentCallbackHandler.  Error %o', error);
                setAuth(false);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [setAuth, userManager]);
    console.log('End SilentCallbackHandler - Authenticated: %o. Is Loading: %o', authenticated, isLoading);
    if (isLoading) {
        return (React.createElement("div", null, "Loading"));
    }
    return (React.createElement(Navigate, { to: "/" }));
};
export default CallbackSilentHandler;
