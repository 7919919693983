import * as React from 'react';
import { Card, Switch, Typography } from '@symphony-ui/uitoolkit-components';
import SelectionStatus from '@symphony-ui/uitoolkit-components/components/selection/SelectionStatus';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import KeyFormModal from '../regexes/key-form-modal/KeyFormModal';
import RegexFormModal from '../regexes/regex-modal/RegexFormModal';
import ToDoTable from './TodDoTable';
const ToDo = () => {
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [name, setName] = React.useState();
    const [originalRegex, setOriginalRegex] = React.useState();
    const [regex, setRegex] = React.useState();
    const [title, setTitle] = React.useState('');
    const [showKey, setShowKey] = React.useState(false);
    const [showRegex, setShowRegex] = React.useState(false);
    const [type, setType] = React.useState('keyvalues');
    const { download } = React.useContext(DownloadContext);
    const [viewAll, setViewAll] = React.useState(false);
    const [toDo, setToDo] = React.useState([]);
    React.useEffect(() => {
        setLoading(true);
        const request = new ServerRequest('/syndicate/regex/keyvalues/to-do');
        (async () => {
            try {
                const result = await download.jsonPlus(request, 'Error downloading To Do list');
                setToDo(result);
            }
            catch (error) {
                setError(true);
            }
            setLoading(false);
        })();
    }, [download]);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement(KeyFormModal, { hideModal: () => setShowKey(false), name: name, show: showKey, title: title }),
        React.createElement(RegexFormModal, { callback: () => document.dispatchEvent(new Event('reloadcontent')), hideModal: () => setShowRegex(false), originalRegex: originalRegex, regex: regex, show: showRegex, title: title, type: type }),
        React.createElement("div", { className: "co-header-main" },
            React.createElement(Typography, { type: "h1" }, "Regex To Do List")),
        React.createElement(ToDoTable, { actionElements: [
                (React.createElement(Switch, { label: "View all", name: "switch", onChange: () => setViewAll(!viewAll), status: viewAll ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: viewAll ? 'true' : 'false' }))
            ], data: toDo.filter((t) => viewAll || (!t.known || !t.parsable)), hasError: hasError, isLoading: isLoading, setName: setName, setOriginalRegex: setOriginalRegex, setRegex: setRegex, setShowKey: setShowKey, setShowRegex: setShowRegex, setTitle: setTitle, setType: setType })));
};
export default ToDo;
