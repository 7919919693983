import * as React from 'react';
import ServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import './EditableCell.scss';
import RenderingContext from 'contexts/RenderingContext';
import EditableCell from './EditableCell';
const EditableTextCell = ({ table, row, column, }) => {
    const { download } = React.useContext(DownloadContext);
    const { printDate, printNumber } = React.useContext(RenderingContext);
    const [isDisabled, setDisabled] = React.useState(false);
    const [isEditing, setEditing] = React.useState(false);
    const [value, setValue] = React.useState();
    const [originalValue, setOriginalValue] = React.useState();
    const print = React.useCallback((calculated) => {
        if (typeof calculated === 'string') {
            return calculated;
        }
        if (typeof calculated === 'number') {
            return printNumber(calculated);
        }
        if (typeof calculated === 'object' && calculated instanceof Date) {
            try {
                return printDate(calculated);
            }
            catch (error) {
                return 'error';
            }
        }
        return '';
    }, [printDate, printNumber]);
    React.useEffect(() => {
        if (typeof column.accessorFn === 'function') {
            const calculated = column.accessorFn(row.original, row.index);
            setValue(print(calculated));
        }
        else {
            setValue('');
        }
    }, [column, print, row.index, row.original]);
    const save = React.useCallback(async () => {
        if (table.options.meta !== undefined && typeof table.options.meta.updateTable === 'function' && value !== undefined) {
            const url = new ServerURL('/syndicate/transactions/override');
            url.searchParams.append('value', value);
            url.searchParams.append('key', column.id);
            url.searchParams.append('transactionId', row.original.transactionId);
            url.searchParams.append('trancheIndex', row.original.trancheIndex.toString());
            const request = new ServerRequest(url, { method: 'PATCH' });
            setDisabled(true);
            try {
                const response = await download.jsonPlus(request, 'Unable to save override information');
                table.options.meta.updateTable(response);
            }
            catch (error) {
                //
            }
            setDisabled(false);
            setEditing(false);
        }
    }, [column.id, download, row.original.trancheIndex, row.original.transactionId, table, value]);
    const onBlur = React.useCallback(() => {
        (async () => save())();
    }, [save]);
    const inputRef = React.useRef(null);
    const onChange = React.useCallback((event) => {
        setValue(event.target.value);
    }, []);
    const onKeyUp = React.useCallback((event) => {
        if (event.key === 'Enter') {
            (async () => save())();
        }
    }, [save]);
    React.useLayoutEffect(() => {
        if (isEditing && inputRef.current !== null) {
            inputRef.current.focus();
        }
    }, [isEditing]);
    const overrideLess = React.useMemo(() => {
        const copy = { ...row.original };
        if (copy.overrideMap !== undefined) {
            if ('overrideMap' in copy) {
                delete copy.overrideMap;
            }
        }
        return copy;
    }, [row.original]);
    React.useEffect(() => {
        if (typeof column.accessorFn === 'function') {
            const calculated = column.accessorFn(overrideLess, row.index);
            setOriginalValue(print(calculated));
        }
    }, [column, overrideLess, print, row.index]);
    if (originalValue === undefined) {
        return React.createElement("div", null);
    }
    return (React.createElement(EditableCell, { isOverriden: row.original.overrideMap?.[column.id] !== undefined, setEditing: setEditing, title: row.original.overrideMap?.[column.id] !== undefined ? originalValue : undefined }, isEditing
        ? React.createElement("input", { className: "co-editable-input", disabled: isDisabled, ref: inputRef, onBlur: onBlur, onChange: onChange, onKeyUp: onKeyUp, value: value })
        : value));
};
export default EditableTextCell;
