import * as React from 'react';
import InfiniteTable from 'components/custom-table/InfiniteTable';
import TableContext from 'components/custom-table/TableContext';
import { BannerType } from '@symphony-ui/uitoolkit-components';
import ServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import MessageContext from 'contexts/MessageContext';
import ExpanderCell from './ExpanderCell';
import TransactionCheckbox from './TransactionCheckbox';
import CollapseCell from './CollapseCell';
import TransactionEditCell from '../TransactionCellText';
import TransactionCellDropdown from '../TransactionCellDropdown';
import TransactionCellDropdownMultiple from '../TransactionCellDropdownMultiple';
const TransactionTable = ({ actionElements, dataTransform, subComponent, }) => {
    const { download } = React.useContext(DownloadContext);
    const { resetRowExpansion, tableUpdateFn } = React.useContext(TableContext);
    const { setMessage } = React.useContext(MessageContext);
    const [regionOptions, setRegionOptions] = React.useState([]);
    const [sectorOptions, setSectorOptions] = React.useState([]);
    const [typeOptions, setTypeOptions] = React.useState([]);
    /**
     * Update function used in cells. It is added to the meta data of the table. This is used an many of the update functions below.
     */
    const updateTable = React.useCallback((response) => {
        if (tableUpdateFn !== undefined) {
            const updateFn = (data) => {
                const updated = {
                    pageParams: data.pageParams,
                    pages: data.pages,
                };
                let pageNumber = -1;
                let index = -1;
                while (index === -1) {
                    pageNumber += 1;
                    index = data.pages[pageNumber]?.findIndex((d) => d.id === response.id);
                }
                if (index > -1) {
                    updated.pages[pageNumber].splice(index, 1, response);
                }
                return updated;
            };
            tableUpdateFn(updateFn);
        }
        else {
            setMessage({ content: 'No table update function.', variant: BannerType.ERROR });
        }
    }, [setMessage, tableUpdateFn]);
    const onClickCollapse = React.useCallback(() => {
        if (resetRowExpansion !== undefined) {
            resetRowExpansion([]);
        }
    }, [resetRowExpansion]);
    const test = React.useCallback(() => (React.createElement(CollapseCell, { onClick: onClickCollapse })), [onClickCollapse]);
    React.useEffect(() => {
        (async () => {
            const url = new ServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'sector');
            const request = new ServerRequest(url);
            try {
                const body = await download.jsonPlus(request, 'Error downloading sector options');
                setSectorOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    React.useEffect(() => {
        (async () => {
            const url = new ServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'region');
            const request = new ServerRequest(url);
            try {
                const body = await download.jsonPlus(request, 'Error downloading region options');
                setRegionOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    React.useEffect(() => {
        (async () => {
            const url = new ServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'type-new');
            const request = new ServerRequest(url);
            try {
                const body = await download.jsonPlus(request, 'Error downloading type options');
                setTypeOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    const meta = React.useMemo(() => ({ idFunction: (d) => d.id, updateTable }), [updateTable]);
    const columns = React.useMemo(() => [
        {
            cell: (ExpanderCell), header: test, id: 'expander', size: 28,
        },
        {
            cell: TransactionCheckbox, header: () => null, id: 'transactionTickbox', size: 28,
        },
        {
            accessorFn: (t) => t.sector,
            cell: TransactionCellDropdown,
            header: 'Sector',
            id: 'sector',
            meta: { className: 'co-centered', dropdown: { isMulti: false, options: sectorOptions } },
            size: 80,
        },
        {
            accessorFn: (t) => t.region,
            cell: TransactionCellDropdown,
            header: 'Region',
            id: 'region',
            meta: { className: 'co-centered', dropdown: { isMulti: false, options: regionOptions } },
            size: 80,
        },
        {
            accessorFn: (t) => t.types,
            cell: TransactionCellDropdownMultiple,
            header: 'Type(s)',
            id: 'types',
            meta: { className: 'co-centered', dropdown: { isMulti: true, options: typeOptions } },
            size: 80,
        },
        {
            accessorFn: (t) => t.company,
            cell: TransactionEditCell,
            header: 'Company',
            id: 'company',
            size: 240,
        },
        {
            accessorKey: 'name', header: 'Name', id: 'name', size: 9999,
        },
        {
            accessorFn: (t) => t.firstUpdate.toLocaleString(),
            enableColumnFilter: false,
            header: 'First Update',
            id: 'firstUpdate',
            meta: { className: 'co-centered' },
            size: 200,
        },
        {
            accessorFn: (t) => t.lastUpdate.toLocaleString(),
            enableColumnFilter: false,
            header: 'Last Update',
            id: 'lastUpdate',
            meta: { className: 'co-centered' },
            size: 200,
        },
    ], [regionOptions, sectorOptions, test, typeOptions]);
    return (React.createElement(InfiniteTable, { actionElements: actionElements, columns: columns, dataTransform: dataTransform, emptyMessage: "You have no transactions yet", errorMessage: "Unable to connect to server to load the data. Please check connection.", fetchSize: 100, meta: meta, name: "transactions", subComponent: subComponent, urlPage: "/syndicate/transactions/page", urlTotal: "/syndicate/transactions/total" }));
};
export default TransactionTable;
