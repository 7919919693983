import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, TextArea, } from '@symphony-ui/uitoolkit-components';
const NewIssueTask = ({ bloombergMessage, hideModal, nextState, task, }) => {
    const [bloombergInput, setBloombergInput] = React.useState(bloombergMessage);
    const changeBloombergInput = React.useCallback((event) => {
        setBloombergInput(event.target.value);
    }, []);
    const clickNextState = React.useCallback(() => {
        nextState();
        hideModal();
    }, [hideModal, nextState]);
    return (React.createElement(Modal, { closeButton: true, onClose: hideModal, parentNode: document.body, show: true, size: "medium" },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, task !== undefined ? task.text : 'No task defined')),
        React.createElement(ModalBody, null, bloombergMessage
            ? React.createElement(TextArea, { label: "Copy to use elsewhere", onChange: changeBloombergInput, tooltip: "Click to copy, then paste into Bloomberg chat", value: bloombergInput }) : ''),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { onClick: clickNextState, variant: "destructive" }, "yes"),
            React.createElement(Button, { onClick: hideModal, variant: "secondary" }, "No"))));
};
export default NewIssueTask;
