import { Card, Dropdown, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import * as React from 'react';
import ConstantViewer from './ConstantViewer';
import './Constants.scss';
const Constants = () => {
    const { download } = React.useContext(DownloadContext);
    const [field, setField] = React.useState(localStorage.getItem('field'));
    const [fieldoptions, setFieldOptions] = React.useState([]);
    const [hasError, setError] = React.useState(false);
    const changeField = React.useCallback((event) => {
        setField(event.target.value.value);
        localStorage.setItem('field', event.target.value.value);
    }, []);
    React.useEffect(() => {
        (async () => {
            const request = new ServerRequest('/syndicate/constant/fieldoptions');
            try {
                const downloadedOptions = await download.jsonPlus(request, 'Error downloading field options');
                setFieldOptions(downloadedOptions);
            }
            catch (error) {
                setError(true);
            }
        })();
    }, [download]);
    return (React.createElement(Card, { className: "co-constants-card grid-container" },
        React.createElement(Dropdown, { isDisabled: hasError, label: "Field", onChange: changeField, options: fieldoptions, value: fieldoptions.find((o) => 'value' in o && o.value === field) }),
        field !== null && !hasError ? React.createElement(ConstantViewer, { field: field }) : null));
};
export default Constants;
