import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import * as React from 'react';
import { Icon, Loader } from '@symphony-ui/uitoolkit-components';
import TransactionWrapper from '../../../models/TransactionWrapper';
import EditableCell from '../overview/table/EditableCell';
const TransactionEditCell = ({ table, row, column }) => {
    const { download } = React.useContext(DownloadContext);
    const [content, setContent] = React.useState();
    const [isDisabled, setDisabled] = React.useState(false);
    const [isEditing, setEditing] = React.useState(false);
    const [value, setValue] = React.useState('');
    const [isLoading, setLoading] = React.useState(false);
    const [hasError, setError] = React.useState(false);
    const [className, setClassName] = React.useState('');
    const inputRef = React.useRef(null);
    React.useEffect(() => {
        const classname = column.columnDef.meta?.className;
        if (classname !== undefined) {
            setClassName(classname);
        }
    }, [column.columnDef.meta?.className]);
    const save = React.useCallback(async () => {
        if (table.options.meta !== undefined && typeof table.options.meta.updateTable === 'function') {
            const url = new ServerURL('/syndicate/transactions/meta-update');
            url.searchParams.append('transactionId', row.original.id);
            url.searchParams.append('key', column.id);
            if (value !== '') {
                url.searchParams.append('value', value);
            }
            setLoading(true);
            setDisabled(true);
            const request = new ServerRequest(url, { method: value !== '' ? 'PATCH' : 'DELETE' });
            try {
                const body = await download.jsonPlus(request, 'Unable to save override information');
                const b = new TransactionWrapper(body);
                table.options.meta.updateTable(b);
                setValue(b.company?.name);
            }
            catch (error) {
                setError(true);
                //
            }
            finally {
                setLoading(false);
                setEditing(false);
            }
        }
    }, [column.id, download, row.original.id, table, value]);
    const onBlur = React.useCallback(() => {
        (async () => save())();
    }, [save]);
    const onKeyUp = React.useCallback((event) => {
        if (event.key === 'Enter') {
            (async () => save())();
        }
    }, [save]);
    const onChange = React.useCallback((event) => {
        setValue(event.target.value);
    }, []);
    React.useEffect(() => {
        setValue(row.original.company?.name ?? '');
    }, [column.id, row.original]);
    React.useLayoutEffect(() => {
        if (isEditing && inputRef.current !== null) {
            inputRef.current.focus();
        }
    }, [isEditing]);
    React.useEffect(() => {
        if (isLoading) {
            setContent(React.createElement(Loader, null));
        }
        else if (hasError) {
            setContent(React.createElement(Icon, { className: "co-red", iconName: "alert-triangle" }));
            setClassName('co-centered-icon-box');
        }
        else if (isEditing) {
            setContent(React.createElement("input", { className: "co-editable-input", disabled: isDisabled, ref: inputRef, onBlur: onBlur, onChange: onChange, onKeyUp: onKeyUp, value: value }));
        }
        else {
            setClassName('co-centered-box');
            setContent(value);
        }
    }, [column.columnDef.size, column.id, hasError, isDisabled, isEditing, isLoading,
        setContent, onBlur, onChange, onKeyUp, row.original, value]);
    return (React.createElement(EditableCell, { className: className, setEditing: setEditing }, content));
};
export default TransactionEditCell;
