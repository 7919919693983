import * as React from 'react';
import AppViewContext from 'contexts/AppViewContext';
import { App } from 'utils/appUtils';
import { Icon } from '@symphony-ui/uitoolkit-components';
import ImageCard from '../../components/ImageCard';
import './main.css';
import { localIconUrl as nitIconUrl } from '../../../modules/nit/utils/iconUrls';
const Main = () => {
    const { setAppView, userInfo } = React.useContext(AppViewContext);
    const onClickCard = React.useCallback((destination) => () => {
        localStorage.setItem('app', destination.toString());
        setAppView(destination);
    }, [setAppView]);
    return (React.createElement("div", { id: "main-page" },
        React.createElement("div", { id: "image-grid" },
            userInfo?.canAccess(App.SYND)
                ? React.createElement(ImageCard, { id: "synd", onClick: onClickCard(App.SYND), text: "Syndicate App" },
                    React.createElement("img", { className: "image-icon", src: nitIconUrl(240), alt: "nana" })) : undefined,
            userInfo?.canAccess(App.NIT)
                ? React.createElement(ImageCard, { id: "nit", onClick: onClickCard(App.NIT), text: "New Issue Tracker" },
                    React.createElement("img", { className: "image-icon", src: nitIconUrl(240), alt: "nana" })) : undefined,
            userInfo?.canAccess(App.IPO)
                ? React.createElement(ImageCard, { id: "ipo", onClick: onClickCard(App.IPO), text: "IPO Tracker" },
                    React.createElement("img", { className: "image-icon", src: nitIconUrl(240), alt: "nana" })) : undefined,
            userInfo?.canAccess(App.MACRO)
                ? React.createElement(ImageCard, { id: "release", onClick: onClickCard(App.MACRO), text: "Economic Releases" },
                    React.createElement("img", { className: "image-icon", src: nitIconUrl(240), alt: "nana" })) : undefined,
            userInfo?.canAccess(App.MTN)
                ? React.createElement(ImageCard, { id: "mtn", onClick: onClickCard(App.MTN), text: "MTN Workflow App" },
                    React.createElement("img", { className: "image-icon", src: nitIconUrl(240), alt: "nana" })) : undefined,
            userInfo?.canAccess(App.BOOK)
                ? React.createElement(ImageCard, { id: "mtn", onClick: onClickCard(App.BOOK), text: "Syndicate Workflow App" },
                    React.createElement("img", { className: "image-icon", src: nitIconUrl(240), alt: "nana" })) : undefined,
            userInfo?.canAccess(App.ADMIN)
                ? React.createElement(ImageCard, { id: "admin", onClick: onClickCard(App.ADMIN), text: "Admin" },
                    React.createElement(Icon, { className: "main-settings-icon", iconName: "settings" })) : undefined)));
};
export default Main;
