import AppViewContext from 'contexts/AppViewContext';
import LocationRouter from 'pages/location-router';
import * as React from 'react';
import UserContext from 'contexts/UserContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import FullPageLoader from 'pages/FullPageLoader';
import FrontPage from './pages/main-page/FrontPage';
const MainRoute = () => {
    const { authService } = React.useContext(UserContext);
    const [appView, setAppView] = React.useState(null);
    const [offline, setOffline] = React.useState(false);
    const [imitate, setImitate] = React.useState(false);
    const [isAuthenticated, setAuthenticated] = React.useState();
    React.useEffect(() => {
        const logout = () => {
            (async () => {
                await authService.removeUser();
            })();
        };
        window.addEventListener('logout', logout, false);
    }, [authService]);
    const appViewValue = React.useMemo(() => ({
        appView: appView ?? null,
        imitate: imitate ?? false,
        offline,
        setAppView,
        setImitate,
        setOffline,
    }), [appView, imitate, offline, setAppView, setImitate, setOffline]);
    React.useEffect(() => {
        (async () => {
            const is = await authService.isAuthenticated();
            setAuthenticated(is);
        })();
    }, [authService]);
    if (isAuthenticated === undefined) {
        return (React.createElement(FullPageLoader, null));
    }
    return (React.createElement(AppViewContext.Provider, { value: appViewValue },
        React.createElement(DndProvider, { backend: HTML5Backend },
            React.createElement("div", null, isAuthenticated ? React.createElement(LocationRouter, null) : React.createElement(FrontPage, null)))));
};
export default MainRoute;
