import * as React from 'react';
const EditableCell = React.forwardRef(({ children, className, isOverriden = false, setEditing, title, }, ref) => {
    const onClick = React.useCallback(() => {
        setEditing(true);
    }, [setEditing]);
    const onKeyDiv = React.useCallback((event) => {
        if (event.key === 'Enter') {
            setEditing(true);
        }
    }, [setEditing]);
    return (React.createElement("div", { className: `co-editable${isOverriden ? ' co-overriden' : ''}${className ? ` ${className}` : ''}${title && isOverriden ? ' co-exists' : ''}`, onClick: onClick, onKeyUp: onKeyDiv, ref: ref, role: "button", tabIndex: 0, title: title }, children));
});
export default EditableCell;
