import * as React from 'react';
import { Button, Card, Dropdown, TextField, } from '@symphony-ui/uitoolkit-components';
import CardHeaderForm from 'components/card-header-form/CardHeaderForm';
import MetadataButtons from 'components/metadata/MetadataButtons';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import Order from '../../model/Order';
const OrderForm = ({ order, save }) => {
    const { download } = React.useContext(DownloadContext);
    const [data, setData] = React.useState(order);
    const [options, setOptions] = React.useState([]);
    React.useEffect(() => {
        (async () => {
            const request = new ServerRequest('/synd/new-issues/options');
            try {
                const body = await download.jsonPlus(request, 'Error downloading options');
                setOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    const onChangeInput = React.useCallback((name) => (event) => {
        const { value } = event.target;
        setData({ ...data, [name]: value });
    }, [data]);
    const onChangePermissions = React.useCallback((event) => {
        const updated = Object.assign(new Order(), order, { permissions: event });
        setData(updated);
    }, [order]);
    const onChangeSelect = React.useCallback((event) => {
        const { value } = event.target.value;
        const regex = /^(?<newIssueId>[0-9a-f]{24})\\|(?<trancheId>.*)/;
        const regexResult = regex.exec(value);
        if (regexResult?.groups !== undefined) {
            const { newIssueId, trancheId } = regexResult.groups;
            setData({ ...data, newIssueId, trancheId });
        }
    }, [data]);
    const onClickUpdate = React.useCallback(() => {
        save(data);
    }, [data, save]);
    return (React.createElement(Card, null,
        React.createElement(CardHeaderForm, { title: data.id ? 'Edit Order' : 'Create Order' }),
        React.createElement("div", { className: "base-form", id: "order-form" },
            React.createElement(TextField, { label: "Amount", onChange: onChangeInput('amount'), value: data.amount.toString(), placeholder: "Required" }),
            React.createElement(TextField, { label: "investorName", onChange: onChangeInput('investorName'), value: data.investorName, placeholder: "Required" }),
            React.createElement(Dropdown, { label: "New Issue", name: "tranche", onChange: onChangeSelect, options: options, value: options.find((o) => 'value' in o && o.value === `${data.newIssueId}|${data.trancheId}`) })),
        React.createElement("div", { className: "co-form-buttons-plus co-item" },
            React.createElement(Button, { onClick: onClickUpdate }, data.id ? 'Edit Order' : 'Create Order'),
            React.createElement(MetadataButtons, { data: data, onChange: onChangePermissions, disabled: false }))));
};
export default OrderForm;
