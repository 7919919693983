import Tranche from './Tranche';
class NewIssue {
    createdBy;
    createdDate;
    id;
    issuer;
    issuerId;
    lastModifiedBy;
    lastModifiedDate;
    name;
    permissions;
    tranches;
    state;
    updatePermissions(permissions) {
        this.permissions = permissions;
        return this;
    }
    addTranche() {
        this.tranches = [];
        const newTranche = new Tranche();
        newTranche.key = this.tranches.length + 1;
        const tranches = [...this.tranches, newTranche];
        return Object.assign(new NewIssue(), { ...this, tranches });
    }
    update(name, value) {
        return Object.assign(new NewIssue(), { ...this, [name]: value });
    }
}
export default NewIssue;
