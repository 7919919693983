import * as React from 'react';
import ViewContext from 'contexts/ViewContext';
import CrudService from 'services/CrudService';
import DownloadContext from 'contexts/DownloadContext';
import LoadingPage from 'components/loading-page/LoadingPage';
import Benchmark from '../../models/Benchmark';
import BenchmarkTable from './BenchmarkTable';
import BenchmarkForm from './BenchmarkForm';
const Benchmarks = () => {
    const { view, setView } = React.useContext(ViewContext);
    const { download } = React.useContext(DownloadContext);
    const [benchmarks, setBenchmarks] = React.useState([]);
    const [details, setDetails] = React.useState();
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const service = React.useMemo(() => new CrudService({ download, name: 'Benchmark', path: '/core/benchmarks' }), [download]);
    React.useEffect(() => {
        (async () => {
            try {
                const body = await service.getPlus();
                const downloadedBenchmarks = body.map((u) => Object.assign(new Benchmark(), u));
                setBenchmarks(downloadedBenchmarks);
            }
            catch (error) {
                setError(true);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [service]);
    const create = React.useCallback(() => {
        setDetails(new Benchmark());
        setView('FORM');
    }, [setView]);
    const deleteFn = React.useCallback(async ({ row: { original: benchmark } }) => {
        try {
            await service.delete(benchmark);
            const filteredBenchmarks = benchmarks.filter((u) => u.id !== benchmark.id);
            setBenchmarks(filteredBenchmarks);
        }
        catch (error) {
            //
        }
    }, [benchmarks, service]);
    const edit = React.useCallback(({ row: { original: benchmark } }) => {
        setDetails(benchmark);
        setView('FORM');
    }, [setView]);
    const save = React.useCallback(async (data) => {
        try {
            const body = await (data.id ? service.updatePlus(data) : service.createPlus(data));
            const updatedBenchmark = Object.assign(new Benchmark(), body);
            const updatedCurrencies = CrudService.updateData(benchmarks, updatedBenchmark);
            setBenchmarks(updatedCurrencies);
            setView('TABLE');
        }
        catch (error) {
            //
        }
    }, [benchmarks, service, setView]);
    switch (view) {
        case 'FORM':
            return (React.createElement(BenchmarkForm, { benchmark: details, save: save }));
        case 'TABLE':
            return (React.createElement(BenchmarkTable, { create: create, deleteFn: deleteFn, edit: edit, hasError: hasError, isLoading: isLoading, benchmarks: benchmarks }));
        default:
            setView('TABLE');
            return (React.createElement(LoadingPage, null));
    }
};
export default Benchmarks;
