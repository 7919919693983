import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import ViewContext from 'contexts/ViewContext';
import CrudService from 'services/CrudService';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import Ipo from '../models/Ipo';
import IpoTable from './IpoTable';
import IpoForm from './IpoForm';
const Ipos = () => {
    const { download } = React.useContext(DownloadContext);
    const { view, setView } = React.useContext(ViewContext);
    const [details, setDetails] = React.useState();
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const [ipos, setIpos] = React.useState();
    const [exchangeOptions, setExchangeOptions] = React.useState([]);
    const [exchange, setExchange] = React.useState();
    const service = React.useMemo(() => new CrudService({ download, name: 'Ipo', path: '/bb/ipo' }), [download]);
    React.useEffect(() => {
        (async () => {
            await download.options('/bb/ipo/options', 'Error getting source options', setExchangeOptions);
        })();
    }, [download]);
    React.useEffect(() => {
        if (exchange !== undefined) {
            (async () => {
                const url = new ServerURL('/bb/ipo');
                url.searchParams.append('exchange', exchange);
                const request = new ServerRequest(url);
                try {
                    const body = await download.jsonPlus(request, 'Error downloading ipos');
                    const downloadedIpos = body.map((u) => new Ipo(u));
                    setIpos(downloadedIpos);
                }
                catch (error) {
                    setError(true);
                }
                setLoading(false);
            })();
        }
    }, [download, exchange]);
    const create = React.useCallback(() => {
        setDetails(new Ipo({}));
        setView('FORM');
    }, [setView]);
    const deleteFn = React.useCallback(({ row: { original: item } }) => {
        (async () => {
            try {
                await service.delete(item);
                const filteredIpos = ipos !== undefined ? ipos.filter((u) => u.id !== item.id) : [];
                setIpos(filteredIpos);
            }
            catch (error) {
                //
            }
        })();
    }, [ipos, service]);
    const edit = React.useCallback(({ row: { original: item } }) => {
        setDetails(item);
        setView('FORM');
    }, [setView]);
    const save = React.useCallback(async () => {
        if (details !== undefined) {
            try {
                const body = await (details.id ? service.updatePlus(details) : service.createPlus(details));
                const updatedIpo = new Ipo(body);
                const updatedIpos = CrudService.updateData(ipos ?? [], updatedIpo);
                setIpos(updatedIpos);
                setView('TABLE');
            }
            catch (error) {
                //
            }
        }
    }, [details, ipos, service, setView]);
    switch (view) {
        case 'FORM':
            return (React.createElement(IpoForm, { ipo: details, exchangeOptions: exchangeOptions, save: save }));
        default:
            return (React.createElement(IpoTable, { create: create, deleteFn: deleteFn, edit: edit, hasError: hasError, isLoading: isLoading, ipos: ipos ?? [], exchangeOptions: exchangeOptions, setExchange: setExchange }));
    }
};
export default Ipos;
