import * as React from 'react';
import { Button, Card, Typography } from '@symphony-ui/uitoolkit-components';
import ViewContext from 'contexts/ViewContext';
import { TableContextProvider } from 'components/custom-table/TableContext';
import TransactionMeta from '../../../models/TransactionMeta';
import TransactionMergeButton from './TransactionMergeButton';
import TransactionTable from './transaction-table/TransactionTable';
import SubComponent from './transaction-table/sub-component/SubComponent';
const TransactionViewer = ({ setIndex, setTransactionId, }) => {
    const { setView } = React.useContext(ViewContext);
    const dataTransform = React.useCallback((response) => response.map((r) => new TransactionMeta(r)), []);
    React.useEffect(() => {
        setTransactionId(undefined);
        localStorage.removeItem('transactionId');
    }, [setTransactionId]);
    const createSubcomponent = React.useCallback((row) => {
        const newTransactionId = row.original.id;
        const onClick = (index) => () => {
            localStorage.setItem('index', index.toString());
            setIndex(index);
            localStorage.setItem('transactionId', newTransactionId);
            setTransactionId(newTransactionId);
            setView('UPDATE');
        };
        return (React.createElement(SubComponent, { colSpan: row.getVisibleCells().length, onClick: onClick, transactionId: newTransactionId }));
    }, [setIndex, setTransactionId, setView]);
    return (React.createElement(TableContextProvider, null,
        React.createElement(Card, { className: "co-card-main" },
            React.createElement("div", { className: "co-header-main" },
                React.createElement(Typography, { type: "h1" }, "Transaction Management"),
                React.createElement("div", null,
                    React.createElement(Button, { name: "overview", onClick: () => setView('OVERVIEW'), size: "small" }, "OVERVIEW"))),
            React.createElement(TransactionTable, { actionElements: [(React.createElement(TransactionMergeButton, { key: "tmb" }))], dataTransform: dataTransform, subComponent: createSubcomponent }))));
};
export default TransactionViewer;
