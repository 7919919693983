/* eslint-disable react/no-danger */
import { BannerType, Button, Card, Dropdown, Icon, TextArea, TextField, Typography, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import MessageContext from 'contexts/MessageContext';
import ViewContext from 'contexts/ViewContext';
import ServerRequest from 'models/ServerRequest';
import * as React from 'react';
import ServerURL from 'models/ServerUrl';
import SubscriptionsTable from './SubscriptionsTable';
import EmailGridAutoModal from './EmailGridAutoModal';
import EmailGridManualModal from './EmailGridManualModal';
const DailyEmailCreator = () => {
    const { download } = React.useContext(DownloadContext);
    const { setMessage } = React.useContext(MessageContext);
    const { setView } = React.useContext(ViewContext);
    const fileInputRef = React.useRef(null);
    const [content, setContent] = React.useState();
    const [contentNew, setContentNew] = React.useState();
    const [emailContent, setEmailContent] = React.useState(localStorage.getItem('default-content') ?? '');
    const [from, setFrom] = React.useState();
    const [fromOptions, setFromOptions] = React.useState([]);
    const [isAutoDisabled, setAutoDisabled] = React.useState(true);
    const [isManualDisabled, setManualDisabled] = React.useState(true);
    const [isLoading, setLoading] = React.useState(false);
    const [subject, setSubject] = React.useState(localStorage.getItem('default-subject') ?? 'Capital Markets Online Daily New Issue Summary');
    const [tableState, setTableState] = React.useState(localStorage.getItem('auto-table-state'));
    const [tableStateOptions, setTableStateOptions] = React.useState([]);
    const [showAutoModal, setShowAutoModal] = React.useState(false);
    const [showTestModal, setShowTestModal] = React.useState(false);
    const createFormData = React.useCallback(() => {
        const form = new FormData();
        form.append('subject', subject);
        form.append('content', emailContent);
        form.append('from', new Blob([JSON.stringify(from)], { type: 'application/json' }));
        return form;
    }, [emailContent, from, subject]);
    const onChangeEmailContent = React.useCallback((event) => {
        setEmailContent(event.target.value);
    }, []);
    const onChangeFiles = React.useCallback(() => {
        if (fileInputRef.current?.files != null) {
            for (let i = 0; i < fileInputRef.current.files.length; i += 1) {
                const file = fileInputRef.current.files.item(i);
                if (file !== null && file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                    setMessage({ content: `Please upload an .xlsx spread sheet rather than a ${file.type}`, variant: BannerType.ERROR });
                    fileInputRef.current.value = '';
                }
            }
        }
        setManualDisabled(fileInputRef.current?.files == null || fileInputRef.current.files.length === 0 || subject.length === 0 || from === undefined || emailContent.length === 0);
    }, [emailContent.length, from, setMessage, subject.length]);
    const onChangeFrom = React.useCallback((event) => {
        const { value } = event.target;
        setFrom(value.value);
    }, []);
    const onChangeSubject = React.useCallback((event) => {
        setSubject(event.target.value);
    }, []);
    const onChangeTableState = React.useCallback((event) => {
        setTableState(event.target.value.value);
        localStorage.setItem('auto-table-state', event.target.value.value);
    }, []);
    const saveDefaultSubject = React.useCallback(() => {
        localStorage.setItem('default-subject', subject);
    }, [subject]);
    const saveDefaultEmailContent = React.useCallback(() => {
        localStorage.setItem('default-content', emailContent);
    }, [emailContent]);
    const onClickManual = React.useCallback((live) => () => {
        const formData = createFormData();
        if (fileInputRef.current?.files != null) {
            formData.append('file', fileInputRef.current.files[0]);
        }
        setLoading(true);
        const request = new ServerRequest(`/syndicate/subscriptions/manual/${live ? 'send' : 'view'}`, { body: formData, method: 'POST' });
        (async () => {
            try {
                const text = await download.textPlus(request, `Unable to ${live ? 'send' : 'test'} manual daily email`);
                setContent(text);
                setShowTestModal(true);
            }
            catch (error) {
                //
            }
            finally {
                setLoading(false);
            }
        })();
    }, [createFormData, download]);
    const onClickAuto = React.useCallback((live) => () => {
        const stored = localStorage.getItem('overview-table-settings');
        const formData = createFormData();
        if (tableState === 'current' && stored !== null) {
            formData.append('state', new Blob([stored], { type: 'application/json' }));
        }
        else if (tableState !== 'current' && tableState !== null) {
            formData.append('stateId', tableState);
        }
        setLoading(true);
        const request = new ServerRequest(`/syndicate/subscriptions/auto/${live ? 'send' : 'view'}`, { body: formData, method: 'POST' });
        (async () => {
            try {
                const text = await download.textPlus(request, `Unable to ${live ? 'send' : 'test'} automated daily email`);
                setContentNew(text);
                setShowAutoModal(true);
            }
            catch (error) {
                //
            }
            finally {
                setLoading(false);
            }
        })();
    }, [createFormData, download, tableState]);
    React.useEffect(() => {
        const request = new ServerRequest('/syndicate/subscriptions/sender-options');
        (async () => {
            try {
                const body = await download.jsonPlus(request, 'Error downloading senders');
                setFromOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    React.useEffect(() => {
        const url = new ServerURL('/syndicate/table/options');
        url.searchParams.append('name', 'overview');
        const request = new ServerRequest(url);
        (async () => {
            try {
                const body = await download.jsonPlus(request, 'unable to download table options');
                const newOptions = [{ label: '-- current --', value: 'current' }];
                setTableStateOptions(newOptions.concat(body));
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    React.useEffect(() => {
        setManualDisabled(fileInputRef.current?.files == null || fileInputRef.current.files.length === 0 || subject.length === 0 || from === undefined || emailContent.length === 0);
        setAutoDisabled(subject.length === 0 || from === undefined || emailContent.length === 0);
    }, [emailContent.length, from, subject.length]);
    React.useEffect(() => {
        if (typeof fromOptions === 'object' && fromOptions.length > 0 && 'value' in fromOptions[0]) {
            setFrom(fromOptions[0].value);
        }
    }, [fromOptions]);
    return (React.createElement(Card, { style: { padding: '1rem' } },
        React.createElement("div", { style: {
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1rem',
            } },
            React.createElement(Typography, { type: "h1" }, "Send Daily Email to subscribers"),
            React.createElement("div", { style: { display: 'flex' } },
                React.createElement(Button, { name: "overview", onClick: () => setView('INTERPRICE'), size: "small" }, "INTERPRICE"),
                React.createElement(Button, { name: "overview", onClick: () => setView('TRACKER'), size: "small", style: { marginLeft: '0.25rem' } }, "TRACKER ANALYSIS"))),
        React.createElement("div", { style: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' } },
            React.createElement("div", { style: { width: '12rem' } },
                React.createElement(Dropdown, { label: "From", onChange: onChangeFrom, options: fromOptions, value: fromOptions?.find((o) => 'value' in o && o.value === from) })),
            React.createElement("div", { style: { marginTop: '1.25rem' } },
                React.createElement("input", { onChange: onChangeFiles, ref: fileInputRef, type: "file" }))),
        React.createElement(TextField, { label: "subject", onChange: onChangeSubject, value: subject, rightDecorators: (React.createElement(Button, { iconButton: true, onClick: saveDefaultSubject, size: "small", style: { marginTop: '0.25rem' }, title: "Save as default" },
                React.createElement(Icon, { iconName: "pin-on" }))) }),
        React.createElement("div", { style: { display: 'flex', flexDirection: 'row', justifyContent: 'right' } },
            React.createElement("div", { style: { width: '100%' } },
                React.createElement(TextArea, { label: "content", onChange: onChangeEmailContent, size: "medium", value: emailContent })),
            React.createElement("div", { style: { margin: '4rem 1rem 0rem 1rem' } },
                React.createElement(Button, { iconButton: true, onClick: saveDefaultEmailContent, title: "Save as default" },
                    React.createElement(Icon, { iconName: "pin-on" })))),
        React.createElement("p", null,
            "Note: use",
            ' ',
            React.createElement("b", null, "/NAME/"),
            ' ',
            "for the first name of the subscriber, and",
            ' ',
            React.createElement("b", null, "/LINK/"),
            ' ',
            "for the hyper link to the bot"),
        React.createElement("div", { style: { display: 'flex' } },
            React.createElement(Button, { disabled: isManualDisabled, loading: isLoading, onClick: onClickManual(false), variant: "primary" }, "VIEW MANUAL"),
            React.createElement("div", { style: { marginLeft: '1rem' } },
                React.createElement(Button, { disabled: isAutoDisabled, loading: isLoading, onClick: onClickAuto(false) }, "VIEW AUTO")),
            React.createElement("div", { style: { marginLeft: '1rem', width: '48rem' } },
                React.createElement(Dropdown, { onChange: onChangeTableState, options: tableStateOptions, value: tableStateOptions.find((o) => 'value' in o && o.value === tableState) }))),
        React.createElement("hr", null),
        showAutoModal && contentNew !== undefined ? (React.createElement(EmailGridAutoModal, { content: contentNew, onClose: () => setShowAutoModal(false), send: onClickAuto(true) })) : undefined,
        showTestModal && content !== undefined ? (React.createElement(EmailGridManualModal, { content: content, onClose: () => setShowTestModal(false), send: onClickManual(true) })) : undefined,
        React.createElement(SubscriptionsTable, null)));
};
export default DailyEmailCreator;
