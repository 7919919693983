import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import ViewContext from 'contexts/ViewContext';
import ServerRequest from 'models/ServerRequest';
import CrudService from 'services/CrudService';
import LoadingPage from 'components/loading-page/LoadingPage';
import Order from '../../model/Order';
import OrderForm from './OrderForm';
import OrderTable from './OrderTable';
const Orders = () => {
    const { download } = React.useContext(DownloadContext);
    const { view, setView } = React.useContext(ViewContext);
    const [order, setOrder] = React.useState();
    const [orders, setOrders] = React.useState([]);
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const service = React.useMemo(() => new CrudService({ download, name: 'Order', path: '/synd/orders' }), [download]);
    React.useEffect(() => {
        (async () => {
            const request = new ServerRequest('/synd/orders');
            try {
                const body = await download.jsonPlus(request, 'Error getting orders from server.');
                setOrders(body.map((d) => Object.assign(new Order(), d)));
            }
            catch (error) {
                setError(true);
            }
            setLoading(false);
        })();
    }, [download]);
    const create = React.useCallback(() => {
        setOrder(new Order());
        setView('FORM');
    }, [setView]);
    const deleteFn = React.useCallback(async ({ row: { original: item } }) => {
        try {
            await service.delete(item);
            const filteredOrders = orders.filter((n) => n.id !== item.id);
            setOrders(filteredOrders);
        }
        catch (error) {
            //
        }
    }, [orders, service]);
    const edit = React.useCallback(({ row: { original: item } }) => {
        setOrder(item);
        setView('FORM');
    }, [setView]);
    const save = React.useCallback((data) => async () => {
        try {
            const body = await (data.id ? service.updatePlus(data) : service.createPlus(data));
            const updatedOrder = Object.assign(new Order(), body);
            const updatedOrders = CrudService.updateData(orders, updatedOrder);
            setOrders(updatedOrders);
            setView('TABLE');
        }
        catch (error) {
            //
        }
    }, [orders, service, setView]);
    switch (view) {
        case 'FORM':
            return (React.createElement(OrderForm, { order: order ?? new Order(), save: save }));
        case 'TABLE':
            return (React.createElement(OrderTable, { create: create, deleteFn: deleteFn, edit: edit, hasError: hasError, isLoading: isLoading, orders: orders }));
        default:
            setView('TABLE');
            return (React.createElement(LoadingPage, null));
    }
};
export default Orders;
