import { Dropdown, Icon, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import * as React from 'react';
import './SectorCell.scss';
import Popup from 'components/custom-table/action/Popup';
import TrancheWrapper from '../../../../models/TrancheWrapper';
import EditableCell from './EditableCell';
const DocumentationCell = ({ table, row: { original }, column, }) => {
    const { download } = React.useContext(DownloadContext);
    const [content, setContent] = React.useState();
    const [isDisabled, setDisabled] = React.useState(false);
    const [isEditing, setEditing] = React.useState(false);
    const [value, setvalue] = React.useState(() => {
        const field = column.id;
        const overrideValue = original.overrideMap?.[field];
        if (overrideValue !== undefined && typeof overrideValue !== 'string') {
            throw new Error(`Documentation cell must be a string but is ${typeof overrideValue}`);
        }
        const isMulti = column.columnDef.meta?.dropdown?.isMulti;
        if (overrideValue !== undefined && isMulti !== undefined) {
            return (isMulti && typeof overrideValue === 'string') ? overrideValue.split(',') : overrideValue;
        }
        return original.data?.documentation !== undefined ? original.data?.documentation[field] : undefined;
    });
    const [isMultiSelect, setMultiSelect] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [hasError, setError] = React.useState(false);
    const [className, setClassName] = React.useState();
    const [title, setTitle] = React.useState();
    React.useEffect(() => {
        if (column.columnDef.meta !== undefined) {
            const { dropdown } = column.columnDef.meta;
            if (dropdown) {
                setOptions(dropdown.options);
                setMultiSelect(dropdown.isMulti ?? false);
            }
        }
    }, [column.columnDef.meta]);
    React.useEffect(() => {
        const id = column.id;
        if (original.overrideMap?.[column.id] !== undefined
            && original.data?.documentation?.[id] !== undefined) {
            const titleValue = original.data.documentation[id];
            const newTitle = Array.isArray(titleValue)
                ? options.filter((o) => 'value' in o && titleValue.includes(o.value)).map((o) => o.label).join(', ')
                : options.find((o) => 'value' in o && titleValue === o.value)?.label;
            setTitle(newTitle);
        }
        else {
            setTitle(undefined);
        }
    }, [column.id, options, original.overrideMap, original.data?.documentation]);
    const save = React.useCallback(async (newValue) => {
        const url = new ServerURL('/syndicate/transactions/override');
        url.searchParams.append('value', Array.isArray(newValue) ? newValue.join(',') : newValue ?? '');
        url.searchParams.append('key', column.id);
        url.searchParams.append('transactionId', original.transaction.id);
        url.searchParams.append('trancheIndex', original.trancheIndex.toString());
        setDisabled(true);
        const request = new ServerRequest(url, { method: 'PATCH' });
        try {
            const body = await download.jsonPlus(request, 'Unable to save override information');
            if (typeof table.options.meta?.updateTable === 'function') {
                const tranches = body.map((t) => new TrancheWrapper(t));
                table.options.meta.updateTable(tranches);
            }
        }
        catch (error) {
            setError(true);
        }
        finally {
            setDisabled(false);
        }
    }, [column.id, download, original.trancheIndex, original.transaction.id, table]);
    const onBlur = React.useCallback(() => {
        setEditing(false);
    }, []);
    const onChange = React.useCallback((event) => {
        const labelValue = event.target.value;
        let newValue;
        if (labelValue !== null) {
            if (Array.isArray(labelValue)) {
                newValue = labelValue.map((v) => v.value);
            }
            else if ('value' in labelValue) {
                newValue = [labelValue.value];
            }
            setvalue(newValue);
            (async () => save(newValue))();
        }
        else {
            (async () => {
                await save(newValue);
                setEditing(false);
            })();
        }
    }, [save]);
    const closeDropdown = React.useCallback(() => {
        setDisabled(true);
        if (value !== undefined) {
            (async () => {
                await save(value);
                setEditing(false);
            })();
        }
        else {
            setEditing(false);
        }
    }, [save, value]);
    React.useEffect(() => {
        if (hasError) {
            setContent(React.createElement(Icon, { className: "co-red", iconName: "alert-triangle" }));
            setClassName('co-centered-icon-box');
        }
        else if (isEditing) {
            setContent(React.createElement(Popup, { close: closeDropdown, style: { width: '200px' } }, isMultiSelect
                ? (React.createElement(Dropdown, { displayArrowIndicator: false, disabled: isDisabled, placeholder: "Select...", enableTermSearch: true, isMultiSelect: true, isTypeAheadEnabled: true, closeMenuOnSelect: false, menuIsOpen: true, onChange: onChange, options: options, value: options.filter((o) => 'value' in o && Array.isArray(value) && value.includes(o.value)) })) : (React.createElement(Dropdown, { displayArrowIndicator: false, disabled: isDisabled, placeholder: "Select...", enableTermSearch: true, isInputClearable: true, isTypeAheadEnabled: true, closeMenuOnSelect: true, menuIsOpen: true, onChange: onChange, options: options, value: options.find((o) => 'value' in o && value === o.value) }))));
            setClassName('co-editable-dropdown');
        }
        else if (original.overrideMap?.[column.id] !== undefined || (original.data?.documentation !== undefined && column.id in original.data.documentation)) {
            setClassName('co-centered-box');
            const field = column.id;
            const print = (tranche) => {
                if (tranche.overrideMap && field in tranche.overrideMap) {
                    const val = tranche.overrideMap[field];
                    if (typeof val === 'string') {
                        return val.split(',');
                    }
                }
                const { data } = tranche;
                if (data !== undefined && 'documentation' in data && field in data.documentation) {
                    const docValue = data.documentation[field];
                    return Array.isArray(docValue) ? docValue : [docValue];
                }
                return [];
            };
            if (column.columnDef.meta?.dropdown !== undefined) {
                const { dropdown } = column.columnDef.meta;
                const text = dropdown.options.filter((o) => 'value' in o && print(original).includes(o.value)).map((o) => o.label).join(', ');
                setContent(text);
            }
            else {
                setContent(print(original).join(', '));
            }
        }
        else {
            setContent(undefined);
        }
    }, [closeDropdown, column.id, column.columnDef.meta, hasError, isDisabled, isEditing, isMultiSelect,
        options, setContent, onBlur, onChange, original, table.options.meta, value]);
    return (React.createElement(EditableCell, { className: className, isOverriden: original.overrideMap != null && column.id in original.overrideMap, setEditing: setEditing, title: title }, content));
};
export default DocumentationCell;
