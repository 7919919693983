import { BannerType, Button, Card, Typography, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import MessageContext from 'contexts/MessageContext';
import ServerRequest from 'models/ServerRequest';
import * as React from 'react';
import ViewContext from 'contexts/ViewContext';
import ServerURL from 'models/ServerUrl';
import { optionSort } from 'utils/table';
import createDataRequest from 'components/custom-table/infinite-data-request';
import TrancheWrapper from '../../models/TrancheWrapper';
import transformDataForInterprice from './spreadsheet-create';
import CsvTable from './CsvTable';
const Interprice = () => {
    const { download } = React.useContext(DownloadContext);
    const { setMessage } = React.useContext(MessageContext);
    const { setView } = React.useContext(ViewContext);
    const [esgMap, setEsgMap] = React.useState(new Map());
    const fileInputRef = React.useRef(null);
    const [disabledSend, setDisabledSend] = React.useState(true);
    const [csvContent, setCsvContent] = React.useState([]);
    const [rawData, setRawData] = React.useState([]);
    const onChangeFiles = React.useCallback(() => {
        if (fileInputRef.current?.files != null) {
            for (let i = 0; i < fileInputRef.current.files.length; i += 1) {
                const file = fileInputRef.current.files.item(i);
                if (file !== null && file.type !== 'text/csv') {
                    setMessage({ content: `Please upload an .csv file rather than a ${file.type}`, variant: BannerType.ERROR });
                    fileInputRef.current.value = '';
                }
            }
        }
        setDisabledSend(fileInputRef.current?.files == null || fileInputRef.current.files.length === 0);
    }, [setMessage]);
    const onClickOpen = React.useCallback(() => {
        const lines = [];
        csvContent.forEach((l) => {
            const lineContent = l.join(',');
            lines.push(lineContent);
        });
        const content = lines.join('\n');
        const csvFile = new Blob([content], { type: 'text/csv' });
        const downloadLink = document.createElement('a');
        downloadLink.download = `${(new Date()).toISOString().substring(0, 10)} Interprice`;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }, [csvContent]);
    const onClickAutoSend = React.useCallback(() => {
        const request = new ServerRequest('/syndicate/subscriptions/interprice-auto', { body: JSON.stringify(csvContent), headers: { 'Content-Type': 'application/json' }, method: 'POST' });
        (async () => {
            try {
                await download.noContentPlus(request, 'Unable to send to Interprice automatically');
                setMessage({ content: 'Automated Email sent to Interprice', variant: BannerType.SUCCESS });
            }
            catch (error) {
                //
            }
        })();
    }, [csvContent, download, setMessage]);
    const onClickAutoTest = React.useCallback(() => {
        const request = new ServerRequest('/syndicate/subscriptions/interprice-test', { body: JSON.stringify(csvContent), headers: { 'Content-Type': 'application/json' }, method: 'POST' });
        (async () => {
            try {
                await download.noContentPlus(request, 'Unable to send to Interprice automatically');
                setMessage({ content: 'Automated Test Email (not) sent to Interprice', variant: BannerType.SUCCESS });
            }
            catch (error) {
                //
            }
        })();
    }, [csvContent, download, setMessage]);
    const onClickSend = React.useCallback(() => {
        if (fileInputRef.current?.files != null) {
            const formData = new FormData();
            formData.append('file', fileInputRef.current.files[0]);
            const request = new ServerRequest('/syndicate/subscriptions/interprice', { body: formData, method: 'POST' });
            (async () => {
                try {
                    await download.noContentPlus(request, 'Unable to send to Interprice');
                    setMessage({ content: 'Uploaded Email sent to Interprice', variant: BannerType.SUCCESS });
                }
                catch (error) {
                    //
                }
            })();
        }
        if (fileInputRef.current?.files != null) {
            for (let i = 0; i < fileInputRef.current.files.length; i += 1) {
                const file = fileInputRef.current.files.item(i);
                if (file !== null && file.type !== 'text/csv') {
                    setMessage({ content: `Please upload an .csv file rather than a ${file.type}`, variant: BannerType.ERROR });
                    fileInputRef.current.value = '';
                }
            }
        }
    }, [download, setMessage]);
    React.useEffect(() => {
        (async () => {
            const url = new ServerURL('/syndicate/constant/map');
            url.searchParams.append('field', 'ESG');
            const request = new ServerRequest(url);
            try {
                const body = await download.jsonPlus(request, 'Error downloading options');
                const downloadedEsgOptions = Object.entries(body).map(([key, v]) => ({ label: v, value: key })).sort(optionSort);
                const map = new Map();
                downloadedEsgOptions.forEach((v) => {
                    map.set('value' in v ? v.value : v.label, v.label);
                });
                setEsgMap(map);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    React.useEffect(() => {
        const filter = [{ id: 'sector', value: 'CORPORATE|HY' }, { id: 'pricingDate', value: 'T_0' }];
        const request = createDataRequest('/syndicate/overview/page', { start: 0, fetchSize: 200, columnFilters: filter });
        (async () => {
            const body = await download.jsonPlus(request, 'Unable to download data for CSV');
            const tranches = body.map((t) => new TrancheWrapper(t));
            setRawData(tranches);
        })();
    }, [download, esgMap]);
    React.useEffect(() => {
        const allArrays = transformDataForInterprice(rawData, esgMap);
        setCsvContent(allArrays);
    }, [esgMap, rawData]);
    return (React.createElement(Card, { style: { display: 'flex', flexDirection: 'column', padding: '1rem' } },
        React.createElement("div", { style: {
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1rem',
            } },
            React.createElement(Typography, { type: "h1" }, "Send Daily Email to Interprice"),
            React.createElement("div", null,
                React.createElement(Button, { name: "creator", onClick: () => setView('CREATOR'), size: "small" }, "EMAIL CREATOR"))),
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' } },
            React.createElement("div", { style: { display: 'flex' } },
                React.createElement(Button, { onClick: onClickAutoSend, style: { marginRight: '1rem' } }, "SEND AUTO"),
                React.createElement(Button, { onClick: onClickAutoTest }, "SEND TEST")),
            React.createElement("div", { style: { display: 'flex' } },
                React.createElement(Button, { onClick: onClickOpen, style: { marginRight: '1rem' } }, "VIEW CSV"),
                React.createElement("label", { htmlFor: "file-input", style: { display: 'flex', flexDirection: 'column' } },
                    "Manually upload csv file",
                    React.createElement("input", { id: "file-input", onChange: onChangeFiles, ref: fileInputRef, type: "file" })),
                React.createElement(Button, { disabled: disabledSend, onClick: onClickSend }, "SEND"))),
        React.createElement("div", { style: { display: 'flex', overflowX: 'scroll' } },
            React.createElement(CsvTable, { data: csvContent }))));
};
export default Interprice;
