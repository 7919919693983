import { appFromModule, moduleFromApp, } from 'utils/appUtils';
export const authorities = async (userManager) => {
    if (userManager === undefined) {
        return null;
    }
    const user = await userManager.getUser();
    if (user === null) {
        return null;
    }
    const claims = user.profile;
    const record = new Map();
    Object.entries(claims.authorityMap).forEach(([name, value]) => {
        record.set(appFromModule(name), value);
    });
    return record;
};
export const appAccess = async (userManager) => {
    const auth = await authorities(userManager);
    if (auth === null) {
        return [];
    }
    return [...auth.keys()];
};
export const hasAuthority = async (userManager, app, value) => {
    if (value === undefined) {
        return true;
    }
    const user = await userManager.getUser();
    if (user === null) {
        return false;
    }
    const claims = user.profile;
    const name = moduleFromApp(app);
    if (claims[name] === undefined) {
        return false;
    }
    const values = claims[name];
    return values.includes(value) || values.includes(`ROLE_${value}`);
};
export const canAccess = async (userManager, app) => {
    const user = await userManager.getUser();
    if (user === null) {
        return false;
    }
    const claims = user.profile;
    const name = moduleFromApp(app);
    return claims[name] !== undefined;
};
export const accessibleApps = async (userManager) => {
    if (userManager === undefined) {
        return [];
    }
    const user = await userManager.getUser();
    if (user === null) {
        return [];
    }
    const claims = user.profile;
    return Object.keys(claims.authorityMap).map((name) => appFromModule(name));
};
