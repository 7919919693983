import * as React from 'react';
import { Card } from '@symphony-ui/uitoolkit-components';
import CardHeaderTable from 'components/card-header/CardHeaderTable';
import CustomTable from 'components/custom-table/CustomTable';
import ActionButton from 'components/custom-table/action/ActionButton';
const NewIssueTable = ({ create, deleteFn, hasError, isLoading, issuerOptions, manage, nextStateMenu, newIssues, states, }) => {
    const NewIssueActionButton = React.useCallback((context) => {
        const newIssue = context.getValue();
        const stateIndex = states.findIndex((s) => s.value === newIssue.state);
        const actionsMenu = [];
        if (stateIndex + 1 < states.length) {
            const newLabel = stateIndex > -1 ? `Change to ${states[stateIndex + 1].label}` : '?';
            actionsMenu.push({ callback: nextStateMenu, label: newLabel });
        }
        actionsMenu.push({ callback: manage, label: 'Manage' });
        actionsMenu.push({ callback: deleteFn, label: 'Delete' });
        return (React.createElement(ActionButton, { actionsMenu: actionsMenu, context: context }));
    }, [deleteFn, manage, nextStateMenu, states]);
    const IssuerCell = React.useCallback((cell) => {
        const issuerOption = issuerOptions.find((t) => cell.row.original.issuerId === t.value);
        return (React.createElement("div", { className: "cell-wrapper" },
            React.createElement("div", { className: "custom-cell" }, issuerOption ? issuerOption.label : '')));
    }, [issuerOptions]);
    const columns = React.useMemo(() => [
        {
            cell: NewIssueActionButton,
            id: 'actions',
        },
        {
            cell: IssuerCell,
            header: 'Issuer',
            id: 'issuer',
        },
        { accessorKey: 'name', header: 'Name' },
        {
            accessorFn: (newIssue) => {
                const stateIndex = states.findIndex((s) => s.value === newIssue.state);
                return stateIndex > -1 ? states[stateIndex].label : '';
            },
            header: 'State',
            id: 'stateText',
        },
    ], [IssuerCell, NewIssueActionButton, states]);
    return (React.createElement(Card, null,
        React.createElement(CardHeaderTable, { create: create, disabled: hasError, title: "New Issues" }),
        React.createElement(CustomTable, { columns: columns, data: newIssues, emptyMessage: "You have no new issues yet", errorMessage: "Error getting New Issues", hasError: hasError, isLoading: isLoading, name: "newissue" })));
};
export default NewIssueTable;
