import * as React from 'react';
import { DatePicker, TimePicker } from '@symphony-ui/uitoolkit-components';
import './DateTime.css';
/**
 * The first time TimePicker opens, a change event is sent. To avoid an infinite loop, we check
 * if we are running it for the first time. This can be removed if the TimePicker has been
 * fixed. See:
 *
 *  https://github.com/SymphonyPlatformSolutions/symphony-bdk-ui-toolkit-components/tree/master/src/components/time-picker
 *
 * @param {*} param0
 * @returns
 */
const DateTime = ({ label, onChange, value, }) => {
    const [data, setData] = React.useState({ date: value, time: value?.toLocaleTimeString() });
    const onChangeDate = React.useCallback((event) => {
        const { value: newValue } = event.target;
        setData({ ...data, date: newValue });
        const newValue2 = data.date && data.time
            ? new Date(data.date.getFullYear(), data.date.getMonth(), data.date.getDate(), Number.parseInt(data.time.substring(0, 2), 10), Number.parseInt(data.time.substring(3, 5), 10), Number.parseInt(data.time.substring(6, 8), 10), 10) : undefined;
        onChange({
            target: { value: newValue2 },
        });
    }, [data, onChange]);
    const onChangeTime = React.useCallback((event) => {
        const { value: newValue } = event.target;
        setData({ ...data, time: newValue });
        const newValue2 = data.date && data.time
            ? new Date(data.date.getFullYear(), data.date.getMonth(), data.date.getDate(), Number.parseInt(data.time.substring(0, 2), 10), Number.parseInt(data.time.substring(3, 5), 10), Number.parseInt(data.time.substring(6, 8), 10), 10) : undefined;
        onChange({
            target: { value: newValue2 },
        });
    }, [data, onChange]);
    return (React.createElement("div", { className: `datetime-container custom-input-group ${label !== '' ? 'co-has-label' : ''}` },
        React.createElement(DatePicker, { date: data.date, format: "dd/MM/yyyy", label: label, name: "date", onChange: onChangeDate }),
        React.createElement(TimePicker, { label: "", name: "time", onChange: onChangeTime, value: data.time ?? '' })));
};
export default DateTime;
