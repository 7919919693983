class Tranche {
    benchmark;
    benchmarkId;
    currency;
    key;
    maturity;
    spreadGuidance;
    term;
}
export default Tranche;
