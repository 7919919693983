import * as React from 'react';
import { Button, Card, Dropdown, Icon, TextField, Typography, Validation, } from '@symphony-ui/uitoolkit-components';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';
import CardHeaderForm from 'components/card-header-form/CardHeaderForm';
import CustomValidators from 'components/CustomValidators';
import { getOptions } from 'utils/change';
import { errorMessage, initialValidation } from 'utils/initialValidation';
import ObservationInput from './Observation';
import Release from '../models/Release';
import './ReleaseForm.css';
const ReleaseForm = ({ release, save, sourceOptions, }) => {
    const [data, setData] = React.useState(release ?? new Release());
    const [disabledButton, setButtonDisabled] = React.useState(true);
    const [errorState, setErrorState] = React.useState({});
    const validators = React.useMemo(() => ({
        dataSet: [Validators.Required, Validators.MaxLength(10)],
        name: [Validators.Required, Validators.MaxLength(64)],
        source: CustomValidators.RequiredOption,
    }), []);
    React.useEffect(() => {
        (async () => {
            const newErrorObject = await initialValidation(validators, data);
            setErrorState(newErrorObject);
        })();
    }, [data, validators]);
    React.useEffect(() => {
        const disabled2 = Object.values(errorState).some((v) => v === false);
        setButtonDisabled(disabled2);
    }, [errorState]);
    const onChangeDropdown = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? [...new Set(value.map((v) => v.value))] : value.value;
        const newObject = Object.assign(data, { [name]: newValue });
        setData(newObject);
    }, [data]);
    const onChangeInput = React.useCallback((name) => (event) => {
        const { value } = event.target;
        setData({ ...data, [name]: value });
    }, [data]);
    const observations = data.observations ? data.observations.map((o) => {
        const index = data.observations.indexOf(o);
        return (React.createElement(ObservationInput, { value: o, key: `${index} - ${o.referencePeriod}`, hasLabel: index === 0 }));
    }) : undefined;
    const addObservation = () => {
        const oldObservations = data.observations || [];
        oldObservations.push({ referencePeriod: '', time: '', value: '' });
        setData({ ...data, observations: oldObservations });
    };
    const changevalidation = (name) => (isValid) => {
        setErrorState({ ...errorState, [name]: isValid });
    };
    return (React.createElement("div", { className: "release-form" },
        React.createElement(Card, { className: "co-card-stack" },
            React.createElement(CardHeaderForm, { title: data.id ? 'Edit Release' : 'Create Release' }),
            React.createElement("div", { className: "base-form" },
                React.createElement(Validation, { validator: validators.name, errorMessage: errorMessage, onValidationChanged: changevalidation('name') },
                    React.createElement(TextField, { id: "name", label: "Name", onChange: onChangeInput('name'), value: data.name || '', placeholder: "required", tooltip: "Name of the release, such as 'Non-Farm Payrolls'" })),
                React.createElement(Validation, { validator: validators.source, errorMessage: errorMessage, onValidationChanged: changevalidation('source') },
                    React.createElement(Dropdown, { name: "source", label: "Source", onChange: onChangeDropdown('source'), options: sourceOptions, value: getOptions(data.source, sourceOptions) })),
                React.createElement(Validation, { validator: validators.dataSet, errorMessage: errorMessage, onValidationChanged: changevalidation('dataSet') },
                    React.createElement(TextField, { label: "Dataset", onChange: onChangeInput('dataSet'), value: data.dataSet || '', placeholder: "required" })))),
        React.createElement(Card, null,
            React.createElement("div", { className: "co-dropdown-row co-item observation-row" },
                React.createElement(Typography, { type: "h2" }, "Observations"),
                React.createElement(Button, { iconButton: true, onClick: addObservation },
                    React.createElement(Icon, { iconName: "plus" }))),
            observations),
        React.createElement("div", { className: "co-form-buttons co-item" },
            React.createElement(Button, { disabled: disabledButton, onClick: save(data) }, data.id ? 'Update' : 'Create'))));
};
export default ReleaseForm;
