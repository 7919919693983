import { Button, Card, Icon, TextField, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import * as React from 'react';
import LEIRecord from './LEIRecord';
import LeiDropdown from './LeiDropdown';
const LEIPicker = ({ name, saveLei, setLoading }) => {
    const { download } = React.useContext(DownloadContext);
    const [value, setValue] = React.useState(name);
    const [options, setOptions] = React.useState();
    const [lei, setLei] = React.useState();
    const onChange = React.useCallback((event) => {
        setValue(event.target.value);
    }, []);
    const onClickSave = React.useCallback(() => {
        if (lei !== undefined) {
            saveLei(lei);
        }
    }, [lei, saveLei]);
    const onClickSearch = React.useCallback(() => {
        const url = new ServerURL('/gleif/lei-records/all');
        url.searchParams.append('name', value);
        const request = new ServerRequest(url);
        (async () => {
            try {
                setLoading(true);
                const body = await download.jsonPlus(request, 'Unable to download ticker information');
                const newOptions = body.map((nl) => ({ label: nl.name.name, value: nl.lei, data: nl.alternativeNames }));
                setOptions(newOptions);
            }
            catch (error) {
                //
            }
            finally {
                setLoading(false);
            }
        })();
    }, [download, setLoading, value]);
    return (React.createElement(Card, { style: { marginBottom: '1rem', padding: '0.25rem' } },
        React.createElement(TextField, { onChange: onChange, rightDecorators: (React.createElement("div", { style: { display: 'flex', flexDirection: 'column', justifyContent: 'center' } },
                React.createElement(Button, { iconButton: true, onClick: onClickSearch, size: "small", variant: "tertiary" },
                    React.createElement(Icon, { iconName: "search" })))), value: value }),
        React.createElement(LeiDropdown, { lei: lei, name: value, options: options, setLei: setLei }),
        lei !== undefined ? (React.createElement(React.Fragment, null,
            React.createElement(LEIRecord, { lei: lei }),
            React.createElement(Button, { iconButton: true, onClick: onClickSave, size: "small" },
                React.createElement(Icon, { iconName: "enter" })))) : undefined));
};
export default LEIPicker;
