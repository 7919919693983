import * as React from 'react';
import CrudService from 'services/CrudService';
import ServerRequest from 'models/ServerRequest';
import ViewContext from 'contexts/ViewContext';
import DownloadContext from 'contexts/DownloadContext';
import LoadingPage from 'components/loading-page/LoadingPage';
import Report from '../models/Report';
import ReportForm from '../components/reports/ReportForm';
import ReportTable from '../components/reports/ReportTable';
const Reports = () => {
    const { download } = React.useContext(DownloadContext);
    const { view, setView } = React.useContext(ViewContext);
    const [data, setData] = React.useState([]);
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [report, setReport] = React.useState();
    const [searchOptions, setSearchOptions] = React.useState([]);
    const service = React.useMemo(() => new CrudService({ download, name: 'Report', path: '/nit/report' }), [download]);
    React.useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const body = await service.getPlus();
                setData(body);
            }
            catch (error) {
                setError(true);
            }
            setLoading(false);
        })();
        (async () => {
            const request = new ServerRequest('/nit/search/options');
            try {
                const body = await download.jsonPlus(request, 'Error downloading searches');
                setSearchOptions(body);
            }
            catch (error) {
                //
            }
        })();
    }, [download, service]);
    const create = React.useCallback(() => {
        setReport(new Report());
        setView('FORM');
    }, [setView]);
    const deleteFn = React.useCallback(async ({ row: { original: item } }) => {
        try {
            await service.delete(item);
            const filteredData = data.filter((i) => i.id !== item.id);
            setData(filteredData);
        }
        catch (error) {
            //
        }
    }, [data, service]);
    const edit = React.useCallback(({ row: { original: item } }) => {
        setReport(item);
        setView('FORM');
    }, [setView]);
    const save = React.useCallback((item) => async () => {
        let updateEntry;
        try {
            if (item.id) {
                updateEntry = await service.updatePlus(item);
            }
            else {
                updateEntry = await service.createPlus(item);
            }
            const newReport = Object.assign(new Report(), updateEntry);
            const updatedData = CrudService.updateData(data, newReport);
            setData(updatedData);
            setView('TABLE');
        }
        catch (error) {
            //
        }
    }, [data, service, setView]);
    // const toggleActive = React.useCallback(async (id: string, value: string) => {
    //   const formData = new FormData();
    //   formData.append('id', id);
    //   formData.append('active', value);
    //   const request = new ServerRequest(
    //     '/nit/report/activate',
    //     { body: formData, method: 'PATCH' },
    //   );
    //   try {
    //     const body = await download.jsonPlus(request, 'Error (de)activating report');
    //     const newReport = Object.assign(new Report(), body);
    //     const updatedData = CrudService.updateData(data, newReport);
    //     setData(updatedData);
    //   } catch (error) {
    //     //
    //   }
    // }, [data, download]);
    switch (view) {
        case 'FORM':
            return (React.createElement(ReportForm, { options: searchOptions, report: report, save: save }));
        case 'TABLE':
            return (React.createElement(ReportTable, { create: create, deleteFn: deleteFn, edit: edit, hasError: hasError, isLoading: isLoading, options: searchOptions, reports: data }));
        default:
            setView('TABLE');
            return React.createElement(LoadingPage, null);
    }
};
export default Reports;
