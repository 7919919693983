import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from 'contexts/UserContext';
import FullPageLoader from 'pages/FullPageLoader';
const CallbackHandler = () => {
    const { authService } = React.useContext(UserContext);
    const navigate = useNavigate();
    const navigateHome = React.useCallback(() => {
        const options = { replace: true };
        navigate('/', options);
    }, [navigate]);
    const checkIsStillLoading = React.useCallback(() => {
        setTimeout(() => {
            const stored = window.sessionStorage.getItem('loading');
            if (stored === 'done') {
                window.sessionStorage.removeItem('loading');
                navigateHome();
            }
            else {
                checkIsStillLoading();
            }
        }, 100);
    }, [navigateHome]);
    React.useEffect(() => {
        const storedLoadingValue = window.sessionStorage.getItem('loading');
        if (storedLoadingValue === null) {
            window.sessionStorage.setItem('loading', 'true');
            (async () => {
                try {
                    const user = await authService.signinCallback();
                    if (typeof user === 'object') {
                        window.sessionStorage.setItem('loading', 'done');
                        navigateHome();
                    }
                    else {
                        console.error('Error when trying to get user through SigninCallback in the CallbackHandler.  User is void?');
                    }
                }
                catch (error) {
                    console.error('Error when trying to get user through SigninCallback in the CallbackHandler.  Error %o', error);
                }
            })();
        }
        else if (storedLoadingValue === 'done') {
            window.sessionStorage.removeItem('loading');
            navigateHome();
        }
        else {
            checkIsStillLoading();
        }
    }, [authService, checkIsStillLoading, navigateHome]);
    return (React.createElement(FullPageLoader, null));
};
export default CallbackHandler;
