import RenderingContext from 'contexts/RenderingContext';
import * as React from 'react';
const DateTimeCell = ({ row, column, }) => {
    const { printDate } = React.useContext(RenderingContext);
    const text = React.useMemo(() => {
        if (column.accessorFn !== undefined) {
            const value = column.accessorFn(row.original, row.index);
            try {
                return printDate(value);
            }
            catch (error) {
                console.warn('Unable to print date %o, index: %o', row.original, row.index);
            }
        }
        return '';
    }, [column, printDate, row.original, row.index]);
    return (React.createElement("div", null, text));
};
export default DateTimeCell;
