import * as React from 'react';
import { Card, Modal, ModalBody, ModalHeader, ModalTitle, Radio, SelectionStatus, Switch, Typography, } from '@symphony-ui/uitoolkit-components';
import { datePattern } from './dateFormat';
const TableSettingsModal = ({ close, dateFormat, exportHeaders, groupData, setDateFormat, setExportHeaders, setGroupData, }) => {
    const onchange = React.useCallback((value) => () => {
        setDateFormat(value);
    }, [setDateFormat]);
    const onchangeExportHeaders = React.useCallback(() => {
        setExportHeaders(!exportHeaders);
    }, [exportHeaders, setExportHeaders]);
    const onchangeGroupData = React.useCallback(() => {
        setGroupData(!groupData);
    }, [groupData, setGroupData]);
    return (React.createElement(Modal, { closeButton: true, onClose: close, show: true, size: "medium" },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, "Table Settings")),
        React.createElement(ModalBody, null,
            React.createElement(Card, { style: { marginBottom: '1rem', padding: '1rem' } },
                React.createElement(Typography, { type: "h3" }, "Date Formatting"),
                React.createElement(Radio, { label: `Standard local format (${datePattern('normal')})`, name: "normal", onChange: onchange('normal'), status: dateFormat === 'normal' ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, value: dateFormat === 'normal' ? 'true' : 'false' }),
                React.createElement(Radio, { label: `ISO format (${datePattern('iso')})`, name: "normal", onChange: onchange('iso'), status: dateFormat === 'iso' ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, value: dateFormat === 'iso' ? 'true' : 'false' })),
            React.createElement(Card, { style: { padding: '1rem' } },
                React.createElement(Typography, { type: "h3" }, "Export Headers"),
                React.createElement(Switch, { label: "on/off", name: "exportheaders", onChange: onchangeExportHeaders, status: exportHeaders ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: exportHeaders ? 'true' : 'false' })),
            React.createElement(Card, { style: { padding: '1rem' } },
                React.createElement(Typography, { type: "h3" }, "Group Data"),
                React.createElement(Switch, { label: "on/off", name: "groupdata", onChange: onchangeGroupData, status: groupData ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: groupData ? 'true' : 'false' })))));
};
export default TableSettingsModal;
