import { BannerType, Button, Card, Typography, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import * as React from 'react';
import ViewContext from 'contexts/ViewContext';
import { optionSort } from 'utils/table';
import TableContext from 'components/custom-table/TableContext';
import AppViewContext from 'contexts/AppViewContext';
import MessageContext from 'contexts/MessageContext';
import { client, initialiseWebSocket } from '../../../../../site/init-websocket';
import MesssagesModal from './MessagesModal';
import OverviewTable from './table/OverviewTable';
import OtherSourcesModal from './OtherSourcesModal';
import mappie from '../transaction-utils';
import MetaModal from './MetaModal';
const OverviewBody = ({ setIndex, setTransactionId }) => {
    const { download } = React.useContext(DownloadContext);
    const { setView } = React.useContext(ViewContext);
    const { setMessage } = React.useContext(MessageContext);
    const { userManager } = React.useContext(AppViewContext);
    const [showDetailsModal, setShowDetailsModal] = React.useState(false);
    const [showMetaModal, setShowMetaModal] = React.useState(false);
    const [showOtherModal, setShowOtherModal] = React.useState(false);
    const [trancheWrapper, setTrancheWrapper] = React.useState();
    const [isin, setIsin] = React.useState();
    const [currencyOptions, setCurrencyOptions] = React.useState([]);
    const [esgOptions, setEsgOptions] = React.useState([]);
    const [sectorOptions, setSectorOptions] = React.useState([]);
    const [statusOfNotesOptions, setStatusOfNotesOptions] = React.useState([]);
    const [statusOptions, setStatusOptions] = React.useState([]);
    const [typeOptions, setTypeOptions] = React.useState([]);
    const [overviewClass, setOverviewClass] = React.useState();
    const { tableUpdateFn } = React.useContext(TableContext);
    /**
     * Update function used in cells. It is added to the meta data of the table. This is used an many of the update functions below.
     */
    const updateTable = React.useCallback((response) => {
        if (tableUpdateFn !== undefined) {
            const tranches = response.map((r) => mappie(r));
            const updateFn = (data) => {
                const updated = {
                    pageParams: data.pageParams,
                    pages: data.pages.map((p) => [...p]),
                };
                let pageNumber = -1;
                let index = -1;
                while (index === -1) {
                    pageNumber += 1;
                    index = data.pages[pageNumber].findIndex((d) => d.transactionId === tranches[0].transactionId);
                }
                if (index > -1) {
                    const currentLength = data.pages[pageNumber].filter((d) => d.transactionId === tranches[0].transactionId).length;
                    updated.pages[pageNumber].splice(index, currentLength, ...tranches);
                }
                return updated;
            };
            tableUpdateFn(updateFn);
        }
        else {
            setMessage({ content: 'No table update function.', variant: BannerType.ERROR });
        }
    }, [setMessage, tableUpdateFn]);
    const meta = React.useMemo(() => ({ className: 'co-centered', idFunction: (d) => d.transactionId, updateTable }), [updateTable]);
    const addTranche = React.useCallback(({ row: { original: { transactionId } } }) => {
        const url = new ServerURL('/syndicate/transactions/add-tranche');
        url.searchParams.append('transactionId', transactionId);
        const request = new ServerRequest(url, { method: 'PATCH' });
        (async () => {
            try {
                const response = await download.jsonPlus(request, 'Unable to add new tranche');
                updateTable(response);
            }
            catch (error) {
                //
            }
        })();
    }, [download, updateTable]);
    const details = React.useCallback(({ row: { original } }) => {
        setTrancheWrapper(original);
        setShowDetailsModal(true);
    }, []);
    const manageMeta = React.useCallback(({ row: { original } }) => {
        setTrancheWrapper(original);
        setShowMetaModal(true);
    }, []);
    const removeOverrides = React.useCallback(({ row: { original: { transactionId, trancheIndex } } }) => {
        const url = new ServerURL('/syndicate/transactions/override');
        url.searchParams.append('transactionId', transactionId);
        url.searchParams.append('trancheIndex', trancheIndex.toString());
        const request = new ServerRequest(url, { method: 'DELETE' });
        (async () => {
            try {
                const response = await download.jsonPlus(request, 'Unable to save override information');
                updateTable(response);
            }
            catch (error) {
                //
            }
        })();
    }, [download, updateTable]);
    // const recalcMeta = React.useCallback(({ row: { original: { transactionId } } }: CellContext<TrancheWrapper, unknown>) => {
    //   const url = new ServerURL('/syndicate/transactions/meta');
    //   url.searchParams.append('transactionId', transactionId);
    //   const request = new ServerRequest(url, { method: 'GET' });
    //   (async () => {
    //     try {
    //       const response = await download.jsonPlus(request, 'Unable to save override information') as TrancheWrapper[];
    //       updateTable(response);
    //     } catch (error) {
    //       //
    //     }
    //   })();
    // }, [download, updateTable]);
    const seeUpdate = React.useCallback(({ row: { original: { transactionId } } }) => {
        setTransactionId(transactionId);
        setIndex(-1);
        localStorage.setItem('transactionId', transactionId);
        setView('UPDATE');
    }, [setIndex, setTransactionId, setView]);
    const updateWebSocket = React.useCallback((e) => {
        const tranches = JSON.parse(e.body);
        updateTable(tranches);
    }, [updateTable]);
    const viewOthers = React.useCallback(({ row: { original: { overrideMap, data } } }) => {
        if ('identifiers' in data) {
            const i = data?.identifiers.find((id) => id.type === 'ISIN');
            setIsin(i?.value);
        }
        else if (overrideMap?.isin !== undefined) {
            const isinRegex = /(?<isin>[A-Z]{2}[A-Z0-9]{12})/;
            const match = overrideMap.isin.match(isinRegex);
            setIsin(match?.groups?.isin);
        }
        else {
            setIsin(undefined);
        }
        setShowOtherModal(true);
    }, []);
    React.useEffect(() => {
        if (userManager !== undefined) {
            (async () => initialiseWebSocket(userManager, () => client.subscribe('/topic/newissue.data', updateWebSocket)))();
        }
        else {
            setMessage({ content: 'No UserManager defined so unable to initialise WebSocket.', variant: BannerType.ERROR });
        }
    }, [setMessage, updateWebSocket, userManager]);
    React.useEffect(() => {
        setTransactionId(undefined);
        localStorage.removeItem('transactionId');
    }, [setTransactionId]);
    React.useEffect(() => {
        const showAmendments = !(localStorage.getItem('show-amendments') === 'false');
        setOverviewClass(showAmendments ? 'admin' : undefined);
    }, []);
    React.useEffect(() => {
        (async () => {
            const url = new ServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'currencies');
            const request = new ServerRequest(url);
            try {
                const downloadedOptions = await download.jsonPlus(request, 'Error downloading options');
                setCurrencyOptions(downloadedOptions);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    React.useEffect(() => {
        (async () => {
            const url = new ServerURL('/syndicate/constant/map');
            url.searchParams.append('field', 'ESG');
            const request = new ServerRequest(url);
            try {
                const downloadedOptions = await download.jsonPlus(request, 'Error downloading options');
                const downloadedEsgOptions = Object.entries(downloadedOptions).map(([key, v]) => ({ label: v, value: key })).sort(optionSort);
                setEsgOptions(downloadedEsgOptions);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    React.useEffect(() => {
        (async () => {
            const url = new ServerURL('/syndicate/constant/map');
            url.searchParams.append('field', 'STATUS_NOTES');
            const request = new ServerRequest(url);
            try {
                const downloadedOptions = await download.jsonPlus(request, 'Error downloading options');
                const downloadedStatusOptions = Object.entries(downloadedOptions).map(([key, value]) => ({ label: value, value: key })).sort(optionSort);
                setStatusOfNotesOptions(downloadedStatusOptions);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    React.useEffect(() => {
        (async () => {
            const url = new ServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'sector');
            const request = new ServerRequest(url);
            try {
                const downloadedOptions = await download.jsonPlus(request, 'Error downloading options');
                setSectorOptions(downloadedOptions);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    React.useEffect(() => {
        (async () => {
            const url = new ServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'status');
            const request = new ServerRequest(url);
            try {
                const downloadedOptions = await download.jsonPlus(request, 'Error downloading options');
                setStatusOptions(downloadedOptions);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    React.useEffect(() => {
        (async () => {
            const url = new ServerURL('/syndicate/overview/map');
            url.searchParams.append('field', 'type');
            const request = new ServerRequest(url);
            try {
                const downloadedOptions = await download.jsonPlus(request, 'Error downloading options');
                setTypeOptions(downloadedOptions);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    return (React.createElement(Card, { className: overviewClass, style: { display: 'flex', flexDirection: 'column', padding: '1rem' } },
        React.createElement("div", { style: {
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1rem',
            } },
            React.createElement(Typography, { type: "h1" }, "Deal Overview"),
            React.createElement("div", null,
                React.createElement(Button, { name: "overview", onClick: () => setView('TABLE'), size: "small" }, "TABLE"))),
        trancheWrapper !== undefined && showMetaModal
            ? (React.createElement(MetaModal, { hideModal: () => setShowMetaModal(false), setTranche: setTrancheWrapper, tranche: trancheWrapper, updateTable: updateTable })) : undefined,
        isin !== undefined && showOtherModal
            ? (React.createElement(OtherSourcesModal, { hideModal: () => setShowOtherModal(false), show: showOtherModal, title: "Other sources", isin: isin })) : undefined,
        React.createElement("div", { style: { display: 'flex', overflow: 'hidden' } },
            trancheWrapper !== undefined && showDetailsModal ? (React.createElement(MesssagesModal, { hideModal: () => setShowDetailsModal(false), show: showDetailsModal, splitScreen: true, transactionId: trancheWrapper.transactionId })) : undefined,
            React.createElement(OverviewTable, { addTranche: addTranche, details: details, currencyOptions: currencyOptions, esgOptions: esgOptions, manageMeta: manageMeta, meta: meta, removeOverrides: removeOverrides, sectorOptions: sectorOptions, seeUpdate: seeUpdate, statusOfNotesOptions: statusOfNotesOptions, statusOptions: statusOptions, typeOptions: typeOptions, viewOthers: viewOthers }))));
};
export default OverviewBody;
