import { BannerType } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import MessageContext from 'contexts/MessageContext';
import ViewContext from 'contexts/ViewContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import * as React from 'react';
import EmailManager from './email-manager/EmailManager';
import EmailOverview from './email-overview/EmailsOverview';
import EmailStatsOverview from './EmailStats';
const Emails = () => {
    const { download } = React.useContext(DownloadContext);
    const { setMessage } = React.useContext(MessageContext);
    const { view } = React.useContext(ViewContext);
    const [emailId, setEmailId] = React.useState(localStorage.getItem('emailId'));
    const [isNextLoading, setNextLoading] = React.useState(false);
    const select = React.useCallback((path, errorMessage) => async () => {
        if (emailId !== null) {
            const url = new ServerURL(`/syndicate/emails/${path}`);
            url.searchParams.append('id', emailId);
            const request = new ServerRequest(url);
            setNextLoading(true);
            const body = await download.textPlus(request, `Unable to find attachment with id ${emailId}`);
            setNextLoading(false);
            if (body.length > 0) {
                setEmailId(body);
                localStorage.setItem('emailId', body);
            }
            else {
                setMessage({ content: errorMessage, variant: BannerType.WARNING });
            }
        }
    }, [download, emailId, setMessage]);
    switch (view) {
        case 'OVERVIEW':
            return (React.createElement(EmailOverview, { setEmailId: setEmailId }));
        case 'MANAGER':
            return (React.createElement(EmailManager, { emailId: emailId, isNextLoading: isNextLoading, next: select('next', 'Already the latest one; no next one available yet'), previous: select('previous', 'Already the first one; no previous one available'), setEmailId: setEmailId }));
        case 'STATS':
            return (React.createElement(EmailStatsOverview, null));
        default:
            return (React.createElement(EmailOverview, { setEmailId: setEmailId }));
    }
};
export default Emails;
