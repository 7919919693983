import printGuidance, { benchmarkName, printInterestRateBenchmark } from './printGuidance';
export default {
    amount: ({ data, overrideMap }) => {
        if (overrideMap?.amount && typeof overrideMap.amount === 'string') {
            return overrideMap.amount;
        }
        if (data !== undefined && 'nominal' in data && 'tapAmount' in data.nominal && 'size' in data.nominal.tapAmount) {
            if (data.nominal.tapAmount.size.value !== undefined) {
                return `${data.nominal.tapAmount.size.value / 1000000} (tap)`;
            }
        }
        if (data !== undefined && 'nominal' in data && 'amount' in data.nominal && 'size' in data.nominal.amount) {
            const { size } = data.nominal.amount;
            if (size.value !== undefined) {
                return size.value / 1000000;
            }
            if ('range' in size && size.range) {
                const { minimum, maximum } = size.range;
                return `${minimum ? (minimum / 1000000).toString() : '?'} - ${maximum ? (maximum / 1000000).toString() : '?'}`;
            }
            if ('isBenchmark' in size && size.isBenchmark) {
                return 'Benchmark';
            }
            if ('isSubBenchmark' in size && size.isSubBenchmark) {
                return 'Sub Benchmark';
            }
        }
        return '';
    },
    book: ({ data, overrideMap }) => {
        if (overrideMap?.books && typeof overrideMap.books === 'string') {
            return overrideMap.books;
        }
        if (data !== undefined && 'management' in data && 'books' in data.management && 'size' in data.management.books) {
            const { size } = data.management.books;
            return `${size.value / 1000000}`;
        }
        return '';
    },
    bookrunners: ({ meta, overrideMap }) => {
        if (overrideMap?.bookrunners && typeof overrideMap.bookrunners === 'string') {
            return overrideMap.bookrunners;
        }
        if (meta?.bookrunners !== undefined) {
            if (meta.bookrunners.length > 8) {
                const jgcs = meta.bookrunners.filter((b) => b.roles?.includes('GLOBAL_COORDINATOR'));
                if (jgcs.length > 0) {
                    return `JGCs: ${jgcs.map((m) => m.name).sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())).join(', ')}`;
                }
            }
            return meta.bookrunners.map((m) => m.name).sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())).join(', ');
        }
        return '';
    },
    coupon: ({ data, overrideMap }) => {
        if (overrideMap?.coupon && typeof overrideMap.coupon === 'string') {
            return overrideMap.coupon;
        }
        if (data !== undefined && 'coupons' in data) {
            const [coupon] = data.coupons;
            if ('fixedRate' in coupon && 'value' in coupon.fixedRate) {
                const items = [];
                items.push(coupon.fixedRate.value);
                if (coupon.fixedRate.indicative === true) {
                    items.push('(indic.)');
                }
                return items.join(' ');
            }
            if ('floatingRate' in coupon && 'referenceRate' in coupon.floatingRate) {
                const items = [];
                items.push(printInterestRateBenchmark(coupon.floatingRate.referenceRate));
                const margin = ` ${coupon.floatingRate.margin > 0 ? '+' : ''}${coupon.floatingRate.margin || 'flat'}`;
                items.push(margin);
                return items.join('');
            }
        }
        if (data !== undefined && 'profitRate' in data) {
            const [coupon] = data.profitRates;
            if ('fixedRate' in coupon && 'value' in coupon.fixedRate) {
                const items = [];
                items.push(coupon.fixedRate.value);
                return items.join(' ');
            }
        }
        return '';
    },
    currency: ({ data, overrideMap }) => {
        if (overrideMap?.currency && typeof overrideMap.currency === 'string') {
            return overrideMap.currency;
        }
        if (data !== undefined && 'nominal' in data && 'amount' in data.nominal && 'currency' in data.nominal.amount) {
            return data.nominal.amount.currency;
        }
        if (data !== undefined && 'nominal' in data && 'tapAmount' in data.nominal && 'currency' in data.nominal.tapAmount) {
            return data.nominal.tapAmount.currency;
        }
        return '';
    },
    documentation: (map, field) => ({ data, overrideMap }) => {
        if (map === null) {
            return 'tbc';
        }
        if (overrideMap && field in overrideMap) {
            const val = overrideMap[field];
            if (typeof val === 'string') {
                return map.get(val) ?? '';
            }
        }
        if (data !== undefined && 'documentation' in data && field in data.documentation) {
            return map.get(data.documentation[field]) ?? '';
        }
        return '';
    },
    documentationArray: (map, field) => ({ data, overrideMap }) => {
        if (overrideMap && field in overrideMap && typeof overrideMap[field] === 'string') {
            const val = overrideMap[field];
            if (typeof val === 'string') {
                return val.split(',').map((c) => map.get(c)).join(' ');
            }
        }
        if (data !== undefined && 'documentation' in data && field in data.documentation) {
            return data.documentation[field].map((c) => map.get(c)).join(' ');
        }
        return '';
    },
    guidance: (type) => ({ data, overrideMap }) => {
        if (overrideMap?.[type]) {
            return overrideMap[type];
        }
        if (data === undefined) {
            return '';
        }
        if (type === 'initialPriceThoughts') {
            if (data.initialPriceThoughts !== undefined) {
                return printGuidance(data.initialPriceThoughts);
            }
            if (data.guidance !== undefined && data.revisedGuidance !== undefined) {
                return printGuidance(data.guidance);
            }
        }
        if (type === 'guidance') {
            const items = [];
            if (data.revisedGuidance === undefined || data.initialPriceThoughts !== undefined) {
                items.push(printGuidance(data.guidance));
            }
            if (data.revisedGuidance !== undefined) {
                items.push(`revised to ${printGuidance(data.revisedGuidance)}`);
            }
            return items.join(' ');
        }
        return '';
    },
    interestBasis: ({ data, overrideMap }) => {
        if (overrideMap?.interestBasis) {
            return overrideMap.interestBasis;
        }
        if (overrideMap?.coupon) {
            return Number.isNaN(Number.parseFloat(overrideMap.coupon)) ? 'FLOATING' : 'FIXED';
        }
        if (data !== undefined && 'coupons' in data) {
            const [coupon] = data.coupons;
            if ('interestBasis' in coupon && coupon.interestBasis != null) {
                return coupon.interestBasis;
            }
            if ('fixedRate' in coupon) {
                return 'FIXED';
            }
            if ('floatingRate' in coupon) {
                return 'FLOATING';
            }
        }
        if (data !== undefined && 'profitRate' in data) {
            const [coupon] = data.profitRates;
            if ('interestBasis' in coupon && coupon.interestBasis != null) {
                return coupon.interestBasis;
            }
            if ('fixedRate' in coupon) {
                return 'FIXED';
            }
            if ('floatingRate' in coupon) {
                return 'FLOATING';
            }
        }
        return undefined;
    },
    iptMove: ({ data, overrideMap }) => {
        if (overrideMap?.iptMove) {
            return overrideMap.iptMove;
        }
        if (data !== undefined && 'pricing' in data) {
            if ('spread' in data.pricing && 'value' in data.pricing.spread) {
                const { value } = data.pricing.spread;
                const reoffer = Number(value);
                if ('initialPriceThoughts' in data && 'spread' in data.initialPriceThoughts) {
                    if (data.initialPriceThoughts.spread?.value !== undefined) {
                        return reoffer - Number(data.initialPriceThoughts.spread.value.value);
                    }
                    if (data.initialPriceThoughts.spread?.range !== undefined) {
                        return reoffer - Number(data.initialPriceThoughts.spread.range.minimum);
                    }
                }
                if ('guidance' in data && 'spread' in data.guidance) {
                    if (data.guidance.spread?.value !== undefined) {
                        return reoffer - Number(data.guidance.spread.value.value);
                    }
                    if (data.guidance.spread?.range !== undefined) {
                        return reoffer - Number(data.guidance.spread.range.minimum);
                    }
                }
                if ('revisedGuidance' in data && 'spread' in data.revisedGuidance) {
                    if (data.revisedGuidance.spread?.value !== undefined) {
                        return reoffer - Number(data.revisedGuidance.spread.value.value);
                    }
                    if (data.revisedGuidance.spread?.range !== undefined) {
                        return reoffer - Number(data.revisedGuidance.spread.range.minimum);
                    }
                }
            }
            if ('yield' in data.pricing && 'value' in data.pricing.yield) {
                const reofferYield = Number(data.pricing.yield.value);
                if ('initialPriceThoughts' in data && 'yield' in data.initialPriceThoughts) {
                    if (data.initialPriceThoughts.yield?.value !== undefined) {
                        return `${Math.round(100 * (reofferYield - Number(data.initialPriceThoughts.yield.value.value)))}`;
                    }
                    if (data.initialPriceThoughts.yield?.range !== undefined) {
                        return `${Math.round(100 * (reofferYield - Number(data.initialPriceThoughts.yield.range.minimum)))}`;
                    }
                }
                if ('guidance' in data && 'yield' in data.guidance) {
                    if (data.guidance.yield?.value) {
                        return `${Math.round(100 * (reofferYield - Number(data.guidance.yield.value.value)))} `;
                    }
                    if (data.guidance.yield?.range !== undefined) {
                        return `${Math.round(100 * (reofferYield - Number(data.guidance.yield.range.minimum)))}`;
                    }
                }
                if ('revisedGuidance' in data && 'yield' in data.revisedGuidance) {
                    if (data.revisedGuidance.yield?.value !== undefined) {
                        return `${Math.round(100 * (reofferYield - Number(data.revisedGuidance.yield.value.value)))} `;
                    }
                    if (data.revisedGuidance.yield?.range !== undefined) {
                        return `${Math.round(100 * (reofferYield - Number(data.revisedGuidance.yield.range.minimum)))}`;
                    }
                }
            }
        }
        return '';
    },
    isins: ({ data, overrideMap }) => {
        if (overrideMap?.isin && typeof overrideMap.isin === 'string') {
            return overrideMap.isin;
        }
        if (data !== undefined && 'identifiers' in data) {
            return data.identifiers.filter((i) => i.type === 'ISIN' && 'value' in i)
                .map((i) => i.value + ('regulation' in i ? ` (${i.regulation === 'REGS' ? 'RegS' : '144A'})` : '')).join(', ');
        }
        return '';
    },
    issuer: ({ data, overrideMap }) => {
        if (overrideMap?.issuer && typeof overrideMap.issuer === 'string') {
            return overrideMap.issuer;
        }
        if (data !== undefined && 'obligor' in data && data.obligor.name != null) {
            return `${data.obligor.name.replace(/, acting through.*/, '')} (Obligor)`;
        }
        if (data !== undefined && 'issuer' in data) {
            const text = [];
            if (data.issuer.name != null) {
                text.push(data.issuer.name.replace(/, acting through.*/, ''));
            }
            else if (data.issuer.shortName != null) {
                text.push(data.issuer.shortName);
            }
            if (data.issuer.branche != null) {
                text.push(`(${data.issuer.branche})`);
            }
            return text.join(' ');
        }
        return '';
    },
    issuerRaw: ({ data, overrideMap }) => {
        if (data !== undefined && 'obligor' in data && data.obligor.name != null) {
            return `${data.obligor.name.replace(/, acting through.*/, '')} (Obligor)`;
        }
        if (data !== undefined && 'issuer' in data) {
            const text = [];
            if (data.issuer.name != null) {
                text.push(data.issuer.name.replace(/, acting through.*/, ''));
            }
            else if (data.issuer.shortName != null) {
                text.push(data.issuer.shortName);
            }
            if (data.issuer.branche != null) {
                text.push(`(${data.issuer.branche})`);
            }
            if (text.length > 0) {
                return text.join(' ');
            }
        }
        if (overrideMap?.issuer && typeof overrideMap.issuer === 'string') {
            return overrideMap.issuer;
        }
        return '';
    },
    lei: ({ meta, overrideMap }) => {
        if (overrideMap?.lei && typeof overrideMap.lei === 'string') {
            return overrideMap.lei;
        }
        return meta?.lei ?? '';
    },
    maturity: ({ data, overrideMap }) => {
        if (overrideMap?.maturity) {
            const dateRegex = /(?<year>[0-9]{4})-(?<month>[0-9]{2})-(?<day>[0-9]{2})/;
            const dateMatchArray = overrideMap.maturity.match(dateRegex);
            const overrideDateGroups = dateMatchArray?.groups;
            if (overrideDateGroups !== undefined) {
                const { year, month, day } = overrideDateGroups;
                if (year !== undefined && month !== undefined && day !== undefined) {
                    return new Date(Date.UTC(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10)));
                }
            }
            else {
                const yearMonthRegex = /(?<year>[0-9]{4})-(?<month>[0-9]{2})/;
                const yearMonthMatchArray = overrideMap.maturity.match(yearMonthRegex);
                const overrideYearMonthGroups = yearMonthMatchArray?.groups;
                if (overrideYearMonthGroups !== undefined) {
                    const { year, month } = overrideYearMonthGroups;
                    if (year !== undefined && month !== undefined) {
                        return `${month}/${year}`;
                    }
                }
            }
            return overrideMap.maturity;
        }
        if (data !== undefined) {
            if ('maturity' in data && 'scheduledDissolutionDate' in data.maturity) {
                return new Date(data.maturity.scheduledDissolutionDate);
            }
            if ('maturity' in data && 'date' in data.maturity && 'dayOfMonth' in data.maturity.date && 'monthValue' in data.maturity.date && 'year' in data.maturity.date) {
                return new Date(Date.UTC(data.maturity.date.year, data.maturity.date.monthValue - 1, data.maturity.date.dayOfMonth));
            }
            if ('maturity' in data && 'date' in data.maturity && 'monthValue' in data.maturity.date && 'year' in data.maturity.date) {
                return `${data.maturity.date.monthValue.toString().padStart(2, '0')}/${data.maturity.date.year}`;
            }
            if ('maturity' in data && 'date' in data.maturity && 'year' in data.maturity.date) {
                return data.maturity.date.year.toString();
            }
            if ('maturity' in data && (data.maturity.perpetual === true || data.maturity.tenor?.perpetual === true)) {
                return 'Perpetual';
            }
        }
        return undefined;
    },
    oversubscription: ({ data, overrideMap }) => {
        if (overrideMap?.oversubscription && typeof overrideMap.oversubscription === 'string') {
            return overrideMap.oversubscription;
        }
        let bookSize;
        if (overrideMap && 'books' in overrideMap && typeof overrideMap.books === 'string') {
            const overrideBookSize = Number.parseInt(overrideMap.books, 10) * 1000000;
            if (!Number.isNaN(overrideBookSize)) {
                bookSize = overrideBookSize;
            }
        }
        if (bookSize === undefined && data !== undefined && 'management' in data && 'books' in data.management && 'size' in data.management.books && 'value' in data.management.books.size) {
            bookSize = data.management.books.size.value;
        }
        let amount;
        if (overrideMap && 'amount' in overrideMap && typeof overrideMap.amount === 'string') {
            const overrideAmount = Number.parseInt(overrideMap.amount, 10) * 1000000;
            if (!Number.isNaN(overrideAmount)) {
                amount = overrideAmount;
            }
        }
        if (amount === undefined && data !== undefined && 'nominal' in data && 'amount' in data.nominal && 'size' in data.nominal.amount && 'value' in data.nominal.amount.size) {
            amount = data.nominal.amount.size.value;
        }
        if (amount !== undefined && bookSize !== undefined) {
            return (Math.round((10 * bookSize) / amount) / 10).toString();
        }
        return '';
    },
    pricing: ({ data, overrideMap }) => {
        if (overrideMap?.pricing && typeof overrideMap.pricing === 'string') {
            return overrideMap.pricing;
        }
        if (data !== undefined) {
            const isYieldBase = (('initialPriceThoughts' in data && data.initialPriceThoughts.spread == null && data.initialPriceThoughts.yield != null)
                || ('guidance' in data && data.guidance.spread == null && data.guidance.yield != null));
            if ('pricing' in data) {
                const items = [];
                if (!isYieldBase) {
                    if (data.pricing.spread != null) {
                        const { spread } = data.pricing;
                        if ('benchmark' in spread) {
                            items.push(benchmarkName(spread.benchmark));
                        }
                        if ('value' in spread) {
                            items.push((spread.value > 0 ? '+' : '') + spread.value);
                        }
                    }
                    else if (data.pricing.yield != null) {
                        const { yield: yieldPricing } = data.pricing;
                        if (yieldPricing.value !== undefined) {
                            items.push(`${yieldPricing.value}% `);
                        }
                    }
                    return items.join(' ');
                }
                if (data.pricing.yield != null) {
                    const { yield: yieldPricing } = data.pricing;
                    if (yieldPricing.value !== undefined) {
                        items.push(`${yieldPricing.value}% `);
                    }
                    return items.join(' ');
                }
            }
        }
        return '';
    },
    pricingDate: ({ meta, overrideMap }) => {
        if (overrideMap?.pricingDate) {
            return new Date(overrideMap.pricingDate);
        }
        return meta?.pricingDate;
    },
    ratings: ({ data, overrideMap }) => {
        if (overrideMap?.ratings && typeof overrideMap.ratings === 'string') {
            return overrideMap.ratings;
        }
        const print = (ratings) => {
            if (ratings.unrated) {
                return 'Unrated';
            }
            const values = ratings?.values || [];
            if (values.filter((r) => r.agency != null).length === 0) {
                const text = values.map((r) => r.value);
                return text.join('/');
            }
            const moodys = values.find((r) => r.agency === 'MOODYS');
            const sp = values.find((r) => r.agency === 'S_P');
            const fitch = values.find((r) => r.agency === 'FITCH');
            const text = [];
            if (typeof moodys !== 'undefined') {
                text.push(moodys.value);
            }
            if (typeof sp !== 'undefined') {
                const suffix = typeof fitch === 'undefined' && typeof moodys === 'undefined' ? ' (S&P)' : '';
                text.push(sp.value + suffix);
            }
            else if (typeof fitch !== 'undefined' && typeof moodys !== 'undefined') {
                text.push('-');
            }
            if (typeof fitch !== 'undefined') {
                const suffix = typeof sp === 'undefined' && typeof moodys === 'undefined' ? ' (Fitch)' : '';
                text.push(fitch.value + suffix);
            }
            else if (typeof sp !== 'undefined' && typeof moodys !== 'undefined') {
                text.push('-');
            }
            return text.join('/');
        };
        if (data !== undefined && 'ratings' in data && (('values' in data.ratings && data.ratings.values.length > 0) || data.ratings.unrated)) {
            return print(data.ratings);
        }
        if (data !== undefined && 'issuer' in data && 'ratings' in data.issuer && (('values' in data.issuer.ratings && data.issuer.ratings.values.length > 0) || data.issuer.ratings.unrated)) {
            return print(data.issuer.ratings);
        }
        return '';
    },
    sector: ({ meta, overrideMap }) => {
        switch (overrideMap?.sector ?? meta?.sector) {
            case 'ABS':
                return 'ABS';
            case 'CORPORATE':
                return 'Corp';
            case 'COVERED':
                return 'Covered';
            case 'EM':
                return 'EM';
            case 'FINANCIAL':
                return 'FI';
            case 'HY':
                return 'HY';
            case 'SSA':
                return 'SSA';
            default:
                return 'tbc';
        }
    },
    settlementDate: ({ data, overrideMap }) => {
        if (overrideMap?.settlementDate) {
            return new Date(overrideMap.settlementDate);
        }
        if (data !== undefined && 'settlement' in data && data.settlement.date !== null) {
            return new Date(data.settlement.date);
        }
        return undefined;
    },
    spread: ({ data, overrideMap }) => {
        if (overrideMap?.spread) {
            return overrideMap.spread;
        }
        if (overrideMap?.pricing && typeof overrideMap.pricing === 'string') {
            return overrideMap.pricing;
        }
        if (data !== undefined) {
            const isYieldBase = (('initialPriceThoughts' in data && data.initialPriceThoughts.spread == null && data.initialPriceThoughts.yield != null)
                || ('guidance' in data && data.guidance.spread == null && data.guidance.yield != null));
            if ('pricing' in data && !isYieldBase && data.pricing.spread != null) {
                const { spread } = data.pricing;
                if ('value' in spread) {
                    return (spread.value > 0 ? '+' : '') + spread.value;
                }
            }
        }
        return undefined;
    },
    tap: ({ overrideMap }) => {
        if (overrideMap?.tap && typeof overrideMap.tap === 'string') {
            return overrideMap.tap;
        }
        return 'FALSE';
    },
    tenor: ({ data, overrideMap }) => {
        if (overrideMap?.tenor && typeof overrideMap.tenor === 'string') {
            return overrideMap.tenor;
        }
        const text = [];
        if (data !== undefined && 'maturity' in data) {
            const { maturity } = data;
            if ('tenor' in maturity && maturity.tenor != null) {
                if (maturity.tenor.qualifier === 'LONG') {
                    text.push('Long ');
                }
                else if (maturity.tenor.qualifier === 'SHORT') {
                    text.push('Short ');
                }
                if (maturity.tenor.value != null) {
                    const { value } = maturity.tenor;
                    text.push(value.periodMultiplier.toString());
                }
                else if (maturity.tenor.perpetual === true) {
                    text.push('Perp');
                }
                if (maturity.tenor.nonCall != null) {
                    text.push(`NC${maturity.tenor.nonCall.periodMultiplier}`);
                }
                else if (maturity.tenor.value != null) {
                    text.push(maturity.tenor.value.period);
                }
            }
            if (maturity.weightedAverageLife !== undefined) {
                return `${maturity.weightedAverageLife.periodMultiplier}${maturity.weightedAverageLife.period} WAL`;
            }
            if (text.length === 0 && 'date' in maturity && 'monthValue' in maturity.date && 'year' in maturity.date) {
                if ('nominal' in data && 'tapAmount' in data.nominal && 'size' in data.nominal.tapAmount && 'value' in data.nominal.tapAmount.size) {
                    return new Date(maturity.date.year, maturity.date.monthValue - 1, 1).toLocaleDateString((new Intl.DateTimeFormat()).resolvedOptions().locale, { month: 'short', year: '2-digit' });
                }
                const today = new Date();
                const years = (maturity.date.year - today.getFullYear())
                    + (maturity.date.monthValue - today.getMonth() - 1) / 12;
                const rounded = Math.round(years * 10) / 10;
                return `[${rounded}Y]`;
            }
        }
        return text.join('');
    },
    ticker: ({ meta, overrideMap }) => {
        if (overrideMap?.ticker && typeof overrideMap.ticker === 'string') {
            return overrideMap.ticker;
        }
        return meta?.ticker ?? '';
    },
    timing: (type) => ({ data }) => {
        if (data !== undefined && 'management' in data) {
            const text = [];
            const value = data.management[type];
            if (value?.times !== undefined) {
                const time = value.times.find((t) => t.time !== undefined);
                if (time !== undefined) {
                    text.push(time.time.substring(0, 5));
                    if ('zone' in time) {
                        text.push(time.zone);
                    }
                    if (value.times[0].time === undefined) {
                        text.push('?!');
                    }
                    return text.join(' ');
                }
            }
        }
        return '';
    },
    type: ({ meta, overrideMap }) => {
        switch (overrideMap?.type ?? meta?.type) {
            case 'NEW_ISSUE':
                return 'New Issue';
            case 'TENDER_OFFER':
                return 'Tender Offer';
            case 'EXCHANGE_OFFER':
                return 'Exchange offer';
            case 'CONSENT_SOLICITATION':
                return 'Consent Solicitation';
            case 'NON_DEAL_ROADSHOW':
                return 'Non-deal Roadshow';
            case 'NEW_ISSUE_TENDER_OFFER':
                return 'New Issue & TO';
            default:
                return 'tbc';
        }
    },
    yield: ({ data, overrideMap }) => {
        if (overrideMap?.yield && typeof overrideMap.yield === 'string') {
            return overrideMap.yield;
        }
        return data?.pricing?.yield?.value?.toString();
    },
};
