/* eslint-disable react/function-component-definition */
import { Checkbox } from '@symphony-ui/uitoolkit-components';
import SelectionStatus from '@symphony-ui/uitoolkit-components/components/selection/SelectionStatus';
import * as React from 'react';
import './CheckFilterPanel.scss';
import CloseIcon from './CloseIcon';
const test = (checked) => {
    if (checked === undefined) {
        return SelectionStatus.CHECKED;
    }
    if (checked.length === 0) {
        return SelectionStatus.UNCHECKED;
    }
    return SelectionStatus.MIXED;
};
const CheckFilterPanel = function CheckFilterPanelElement({ close, column, options, }) {
    const allRef = React.useRef(null);
    const [checked, setChecked] = React.useState(column.getFilterValue());
    const [allStatus, setAllStatus] = React.useState(test(checked));
    const optionsWithEmpty = React.useMemo(() => [{ label: '-', value: 'EMPTY' }].concat(options), [options]);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            if (column.getFilterValue() !== checked) {
                column.setFilterValue(checked);
            }
        }, 1000);
        return () => clearTimeout(timeout);
    }, [column, checked]);
    const onClick = React.useCallback((event) => {
        if (event.type === 'click' && event.isTrusted) {
            const target = event.target;
            const name = target.getAttribute('name');
            if (name !== null) {
                const clone = checked ? [...checked] : [...optionsWithEmpty.map((c) => c.value)];
                const cleanName = name.replace('A_', '');
                const index = clone.indexOf(cleanName);
                if (index > -1) { // only splice array when item is found
                    clone.splice(index, 1); // 2nd parameter means remove one item only
                }
                else {
                    clone.push(cleanName);
                }
                const value = clone.length < optionsWithEmpty.length ? clone : undefined;
                setChecked(value);
                //        column.setFilterValue(value);
            }
        }
    }, [checked, optionsWithEmpty]);
    const onClickAll = React.useCallback(() => {
        if (allStatus === SelectionStatus.UNCHECKED || allStatus === SelectionStatus.MIXED) {
            setChecked(undefined);
        }
    }, [allStatus]);
    React.useLayoutEffect(() => {
        const refElement = allRef.current;
        if (refElement !== null) {
            optionsWithEmpty.forEach((v) => {
                const name = v.value;
                const checkboxElement = refElement.querySelector(`input[name=A_${name}]`);
                if (checkboxElement !== null) {
                    const inputElement = checkboxElement;
                    const on = (checked === undefined) || checked.includes(name);
                    if (inputElement.checked !== on) {
                        inputElement.click();
                    }
                }
            });
            const allCheckboxElement = refElement.querySelector('input[name=all]');
            if (allCheckboxElement !== null) {
                const inputElement = allCheckboxElement;
                if ((!inputElement.checked && allStatus === SelectionStatus.CHECKED) || (inputElement.checked && SelectionStatus.UNCHECKED)) {
                    inputElement.click();
                }
            }
        }
    });
    React.useEffect(() => {
        const status = test(checked);
        setAllStatus(status);
    }, [checked, column]);
    return (React.createElement("div", { className: "co-check-panel", ref: allRef },
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
            React.createElement(Checkbox, { label: "", name: "all", onClick: onClickAll, status: allStatus, value: "" }),
            React.createElement(CloseIcon, { onClick: close })),
        optionsWithEmpty.map((v) => (React.createElement(Checkbox, { key: typeof v === 'object' && 'label' in v ? v.label : v, label: typeof v === 'object' && 'label' in v ? v.label : v, name: `A_${v.value}`, onClick: onClick, value: (typeof checked === 'undefined' || checked.includes(v)) ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED })))));
};
export default CheckFilterPanel;
