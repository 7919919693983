import * as React from 'react';
import PageWrapper from 'pages/PageWrapper';
import Orders from './orders/Orders';
import NewIssues from './newIssues/NewIssues';
const OrderBookApp = () => (React.createElement(PageWrapper, { pages: [{
            auth: 'ADMIN', defaultView: 'TABLE', element: (React.createElement(NewIssues, null)), label: 'New Issues',
        }, {
            auth: 'ADMIN', defaultView: 'TABLE', element: (React.createElement(Orders, null)), label: 'Orders',
        }] }));
export default OrderBookApp;
