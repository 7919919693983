import { Button, Card, Icon, Switch, Typography, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import DownloadContext from 'contexts/DownloadContext';
import ViewContext from 'contexts/ViewContext';
import SelectionStatus from '@symphony-ui/uitoolkit-components/components/selection/SelectionStatus';
import LabelPlacements from '@symphony-ui/uitoolkit-components/components/selection/LabelPlacements';
import './UpdateManager.scss';
import UpdatePagination from './UpdatePagination';
import JsonModal from './JsonModal';
import GridViewer from './GridViewer';
import DataModal from './DataModal';
import SearchModal from './SearchModal';
const UpdateManager = ({ index, setIndex, setTransactionId, transactionId, }) => {
    const { download } = React.useContext(DownloadContext);
    const { setView } = React.useContext(ViewContext);
    const [content, setContent] = React.useState('');
    const [data, setData] = React.useState();
    const [tranches, setTranches] = React.useState([]);
    const [errorMessage, setErrorMessage] = React.useState(transactionId === null ? 'You need to choose a transaction first' : undefined);
    const [hasContentError, setContentError] = React.useState(false);
    const [hasError, setError] = React.useState(transactionId === null);
    const [html, setHtml] = React.useState('');
    const [hasHtmlError, setHtmlError] = React.useState(false);
    const [isContentLoading, setContentLoading] = React.useState(false);
    const [isHtmlLoading, setHtmlLoading] = React.useState(false);
    const [isDataLoading, setDataLoading] = React.useState(false);
    const [isLoading, setLoading] = React.useState(transactionId !== null);
    const [prettify, setPrettify] = React.useState(localStorage.getItem('prettify') === 'true' || false);
    // const [updates, setUpdates] = React.useState<Update[]>([]);
    const [showDataModal, setShowDataModal] = React.useState(false);
    const [showJsonModal, setShowJsonModal] = React.useState(false);
    const [showSearchModal, setShowSearchModal] = React.useState(false);
    const [numberOfUpdates, setNumberOfUpdates] = React.useState(0);
    const [update, setUpdate] = React.useState();
    const [touchStart, setTouchStart] = React.useState();
    const [touchEnd, setTouchEnd] = React.useState();
    const [abortController, setAbortController] = React.useState(new AbortController());
    const setMessageId = React.useCallback((messageId) => {
        setIndex(messageId.index);
        setTransactionId(messageId.id);
    }, [setIndex, setTransactionId]);
    React.useEffect(() => {
        if (transactionId !== null) {
            setLoading(true);
            (async () => {
                const url = new ServerURL('/syndicate/transactions/no-updates');
                url.searchParams.append('id', transactionId);
                const request = new ServerRequest(url, { method: 'GET' });
                try {
                    const body = await download.jsonPlus(request, 'Error downloading transactions');
                    setNumberOfUpdates(body);
                }
                catch (error) {
                    //
                }
                finally {
                    setLoading(false);
                }
            })();
        }
        else {
            setError(true);
        }
    }, [download, transactionId]);
    React.useEffect(() => {
        if (transactionId !== null && index !== null) {
            setLoading(true);
            setHtmlLoading(true);
            (async () => {
                const url = new ServerURL('/syndicate/transactions/update');
                url.searchParams.append('transactionId', transactionId);
                url.searchParams.append('index', index.toString());
                const request = new ServerRequest(url, { method: 'GET' });
                try {
                    const body = await download.jsonPlus(request, 'Error downloading transactions');
                    setUpdate(body);
                }
                catch (error) {
                    //
                }
                finally {
                    setHtmlLoading(false);
                    setLoading(false);
                }
            })();
        }
        else {
            setError(true);
        }
    }, [download, index, transactionId]);
    React.useEffect(() => {
        if (numberOfUpdates > 0 && index === -1) {
            setIndex(numberOfUpdates - 1);
        }
    }, [numberOfUpdates, index, setIndex]);
    React.useEffect(() => {
        localStorage.setItem('index', index !== null ? index.toString() : '0');
    }, [index]);
    const backgroundUpdate = React.useCallback(async (id) => {
        const url = new ServerURL('/syndicate/transactions/background-update');
        url.searchParams.append('id', id);
        const request = new ServerRequest(url);
        try {
            await download.noContentPlus(request, 'unable to do background loading');
        }
        catch (error) {
            //      setErrorMessage(error as string);
        }
    }, [download]);
    React.useEffect(() => {
        if (transactionId !== null) {
            (async () => backgroundUpdate(transactionId))();
        }
    }, [backgroundUpdate, transactionId]);
    const refresh = React.useCallback((messageId, send) => {
        setContentLoading(true);
        const url = new ServerURL('/syndicate/transactions/content');
        url.searchParams.append('messageId', messageId.id);
        url.searchParams.append('index', messageId.index.toString());
        url.searchParams.append('type', 'true');
        url.searchParams.append('send', send.toString());
        const request = new ServerRequest(url);
        (async () => {
            try {
                const body = await download.textPlus(request, 'Unable to download content');
                setContent(body);
            }
            catch (error) {
                setContentError(true);
            }
            setContentLoading(false);
        })();
    }, [download]);
    const copyId = React.useCallback(() => {
        const emailId = {
            'updates.emailId': {
                _id: update?.emailId.id,
                index: update?.emailId.index,
            },
        };
        const t = { type: 'text/plain' };
        const p = JSON.stringify(emailId);
        const blob = new Blob([p], t);
        (async () => navigator.clipboard.write([new ClipboardItem({ 'text/plain': blob })]))();
    }, [update]);
    const backgroundParse = React.useCallback((type) => {
        if (update !== undefined) {
            const url = new ServerURL(`/syndicate/transactions/parse-${type}`);
            url.searchParams.append('messageId', update.emailId.id);
            url.searchParams.append('index', update.emailId.index.toString());
            const request = new ServerRequest(url);
            (async () => {
                try {
                    setDataLoading(true);
                    await download.noContentPlus(request, `Unable to parse ${type}`);
                }
                catch (error) {
                    //
                }
                finally {
                    setDataLoading(false);
                }
            })();
        }
    }, [download, update]);
    React.useEffect(() => {
        if (update !== undefined) {
            setHtmlLoading(true);
            const url = new ServerURL('/syndicate/emails/html');
            url.searchParams.append('messageId', update.emailId.id);
            url.searchParams.append('index', update.emailId.index.toString());
            const request = new ServerRequest(url);
            (async () => {
                try {
                    const body = await download.textPlus(request, 'Unable to download content');
                    setHtml(body);
                }
                catch (error) {
                    setHtmlError(true);
                }
                setHtmlLoading(false);
            })();
        }
    }, [download, update]);
    const reloadFunction = React.useCallback((messageId) => () => {
        if (transactionId !== null) {
            (async () => backgroundUpdate(transactionId))();
        }
        refresh(messageId, false);
    }, [backgroundUpdate, refresh, transactionId]);
    React.useEffect(() => {
        if (update !== undefined && 'emailId' in update) {
            document.addEventListener('reloadcontent', reloadFunction(update.emailId), { once: false, signal: abortController.signal });
        }
    }, [abortController, reloadFunction, update]);
    React.useEffect(() => {
        if (index !== null && numberOfUpdates > index && update !== undefined) {
            refresh(update.emailId, false);
        }
    }, [index, numberOfUpdates, update, refresh]);
    React.useEffect(() => {
        if (transactionId === null) {
            setErrorMessage('You need to choose a transaction first');
            setError(true);
        }
    }, [transactionId]);
    React.useEffect(() => {
        if (index !== null && numberOfUpdates > 0 && numberOfUpdates < index) {
            setIndex(0);
        }
    }, [index, numberOfUpdates, setIndex]);
    React.useEffect(() => {
        setError(numberOfUpdates === 0);
    }, [numberOfUpdates]);
    const handleStart = React.useCallback((e) => {
        //  e.preventDefault();
        setTouchEnd(undefined); // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX);
    }, []);
    const handleEnd = React.useCallback(() => {
        //  e.preventDefault();
        if (!touchStart || !touchEnd || index == null)
            return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > 100;
        const isRightSwipe = distance < -100;
        if (isLeftSwipe && index < numberOfUpdates - 1) {
            setIndex(index + 1);
        }
        else if (isRightSwipe && index > 0) {
            setIndex(index - 1);
        }
    }, [index, setIndex, touchEnd, touchStart, numberOfUpdates]);
    const handleCancel = React.useCallback(() => {
        //    e.preventDefault();
    }, []);
    const handleMove = React.useCallback((e) => {
        //  e.preventDefault();
        setTouchEnd(e.targetTouches[0].clientX);
    }, []);
    const handleData = React.useCallback(() => {
        if (transactionId !== null && index !== null) {
            const url = new ServerURL('/syndicate/transactions/data');
            url.searchParams.append('id', transactionId);
            url.searchParams.append('index', index.toString());
            const request = new ServerRequest(url);
            (async () => {
                const body = await download.jsonPlus(request, 'Unable to download');
                setData(body);
                setShowDataModal(true);
            })();
        }
    }, [download, index, transactionId]);
    const handleJson = React.useCallback(() => {
        if (transactionId !== null && index !== null) {
            const url = new ServerURL('/syndicate/transactions/tranches');
            url.searchParams.append('id', transactionId);
            url.searchParams.append('index', index.toString());
            const request = new ServerRequest(url);
            (async () => {
                const body = await download.jsonPlus(request, 'Unable to download');
                setTranches(body);
                setShowJsonModal(true);
            })();
        }
    }, [download, index, transactionId]);
    return (React.createElement(Card, { style: { padding: '1rem' } },
        React.createElement("div", { style: {
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1rem',
            } },
            React.createElement(Typography, { type: "h1" }, "Update Management"),
            React.createElement("div", { style: { display: 'flex' } },
                React.createElement(Button, { name: "overview", onClick: () => setView('OVERVIEW'), size: "small" }, "OVERVIEW"),
                React.createElement(Button, { name: "transaction", onClick: () => setView('TABLE'), size: "small", style: { marginLeft: '0.25rem' } }, "TRANSACTIONS"))),
        !hasError && !isLoading && index !== null
            ? (React.createElement("div", null,
                React.createElement(DataModal, { onClose: () => setShowDataModal(false), show: showDataModal, title: update?.subject ?? 'no subject', data: data }),
                React.createElement(JsonModal, { onClose: () => setShowJsonModal(false), show: showJsonModal, title: update?.subject ?? 'no subject', tranches: tranches }),
                React.createElement(SearchModal, { onClose: () => setShowSearchModal(false), show: showSearchModal, setMessageId: setMessageId }),
                React.createElement("div", { onTouchCancel: handleCancel, onTouchEnd: handleEnd, onTouchStart: handleStart, onTouchMove: handleMove, className: "co-update-header" },
                    React.createElement(Typography, { type: "h1" }, update?.subject),
                    React.createElement(Typography, { type: "h2" }, update?.from.map((f) => `${f.personal} <${f.value}>`).join(','))),
                React.createElement("div", { className: "co-update-buttons", style: { display: 'flex', justifyContent: 'space-between' } },
                    React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between', marginBottom: '0.25rem' } },
                        React.createElement(Button, { onClick: handleData, size: "small" }, "DATA"),
                        React.createElement(Button, { onClick: handleJson, size: "small" }, "JSON"),
                        React.createElement(Button, { loading: isDataLoading, onClick: () => backgroundParse('syntax'), size: "small" }, "PARSE SYNTAX"),
                        React.createElement(Button, { loading: isDataLoading, onClick: () => backgroundParse('data'), size: "small" }, "PARSE DATA")),
                    React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
                        React.createElement(UpdatePagination, { callback: () => {
                                setUpdate(undefined);
                                abortController.abort();
                                setAbortController(new AbortController());
                            }, disabled: isLoading || isContentLoading, index: index, numberOfUpdates: numberOfUpdates, setIndex: setIndex }),
                        React.createElement("div", { style: { display: 'flex' } },
                            React.createElement(Button, { iconButton: true, onClick: () => setShowSearchModal(true), size: "small", title: "Search Update" },
                                React.createElement(Icon, { iconName: "search" })),
                            React.createElement(Button, { iconButton: true, onClick: () => copyId(), size: "small", title: "Copy MessageId to Clipboard" },
                                React.createElement(Icon, { iconName: "copy" })),
                            React.createElement(Switch, { label: "Prettify", labelPlacement: LabelPlacements.BOTTOM, name: "prettify", onChange: () => setPrettify(!prettify), status: prettify ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: prettify.toString() })))),
                React.createElement(GridViewer, { html: html, hasHtmlError: hasHtmlError, isHtmlLoading: isHtmlLoading, content: content, hasContentError: hasContentError, isContentLoading: isContentLoading, noStyle: false, prettify: prettify })))
            : React.createElement("h4", null, errorMessage)));
};
export default UpdateManager;
