/* eslint-disable react/function-component-definition */
import * as React from 'react';
import { getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getSortedRowModel, getPaginationRowModel, useReactTable, flexRender, getCoreRowModel, } from '@tanstack/react-table';
import { Loader, Typography, } from '@symphony-ui/uitoolkit-components';
import './CustomTable.scss';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import DraggableColumnHeader from './DraggableColumnHeader';
import ColumnPicker from './ColumnPicker';
import ScrollContext from './ScrollContext';
import SettingsButton from './buttons/SettingsButton';
import ExportButton from './buttons/ExportButton';
const sizingDefault = {
    columnSizingStart: [],
    deltaOffset: null,
    deltaPercentage: null,
    isResizingColumn: false,
    startOffset: 0,
    startSize: 150,
};
const CustomTable = function CustomTableElement({ actionElements = [], columns, data, emptyMessage, errorMessage, hasError, isLoading, meta = {}, name, setSelection, }) {
    const { download } = React.useContext(DownloadContext);
    const [columnFilters, setColumnFilters] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).columnFilters : [];
    });
    const [columnOrder, setColumnOrder] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).columnOrder : [];
    });
    const [columnPinning, setColumnPinning] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).columnPinning : {};
    });
    const [columnSizing, setColumnSizing] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).columnSizing : {};
    });
    const [columnSizingInfo, setColumnSizingInfo] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).columnSizingInfo : {
            columnSizingStart: [],
            deltaOffset: null,
            deltaPercentage: null,
            isResizingColumn: false,
            startOffset: null,
            startSize: null,
        };
    });
    const [columnVisibility, setColumnVisibility] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).columnVisibility : {};
    });
    const [expanded, setExpanded] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).expanded : {};
    });
    const [globalFilter, setGlobalFilter] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return stored !== null ? JSON.parse(stored).globalFilter : null;
    });
    const [grouping, setGrouping] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).grouping : [];
    });
    const [pagination, setPagination] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).pagination : { pageIndex: 0, pageSize: 10000 };
    });
    const [rowPinning, setRowPinning] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).rowPinning : {};
    });
    const [rowSelection, setRowSelection] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).rowSelection : {};
    });
    const [sorting, setSorting] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).sorting : [];
    });
    const [dateFormat, setDateFormat] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).dateFormat : 'normal';
    });
    const [exportHeaders, setExportHeaders] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).exportHeaders : false;
    });
    const [groupData, setGroupData] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).groupData : false;
    });
    const [tableStateName, setTableStateName] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).name : undefined;
    });
    const [tableStateId, setTableStateId] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).id : undefined;
    });
    const [height, setHeight] = React.useState(200);
    const [width, setWidth] = React.useState(600);
    const [showColumnPicker, setShowColumnPicker] = React.useState(false);
    const tableContainerRef = React.useRef(null);
    const bodyRef = React.useRef(null);
    const headRef = React.useRef(null);
    const [tbodyX, setTbodyX] = React.useState(0);
    const [tbodyY, setTbodyY] = React.useState(0);
    const [theadX, setTheadX] = React.useState(0);
    const [theadY, setTheadY] = React.useState(0);
    const [scrollX, setScrollX] = React.useState(0);
    const [scrollY, setScrollY] = React.useState(0);
    React.useEffect(() => {
        const tableState = {
            columnFilters,
            columnOrder,
            columnPinning,
            columnSizing,
            columnSizingInfo,
            columnVisibility,
            expanded,
            globalFilter,
            grouping,
            pagination,
            rowPinning,
            rowSelection,
            sorting,
        };
        localStorage.setItem(`${name}-table-settings`, JSON.stringify(tableState));
    }, [columnFilters, columnOrder, columnPinning, columnSizing, columnSizingInfo,
        columnVisibility, expanded, globalFilter, grouping, pagination, rowPinning, rowSelection,
        sorting, name]);
    const checkFilter = (row, columnId, filterValue) => {
        const value = row.getValue(columnId);
        if (Array.isArray(filterValue)) {
            return filterValue.includes(value);
        }
        return (typeof filterValue === 'string' && typeof value === 'string') ? value.toUpperCase().includes(filterValue.toUpperCase()) : true;
    };
    React.useEffect(() => {
        const tableState = {
            columnFilters,
            columnOrder,
            columnPinning,
            columnSizing,
            columnSizingInfo,
            columnVisibility,
            dateFormat,
            expanded,
            exportHeaders,
            globalFilter,
            groupData,
            grouping,
            id: tableStateId,
            name: tableStateName,
            pagination,
            rowPinning,
            rowSelection,
            sorting,
            table: name,
        };
        // first stringify and then save, as otherwise the text becomes recursive (seems like a bug)
        const stringified = JSON.stringify(tableState);
        localStorage.setItem(`${name}-table-settings`, stringified);
    }, [columnFilters, columnOrder, columnPinning, columnSizing, columnSizingInfo,
        columnVisibility, dateFormat, expanded, exportHeaders, globalFilter, groupData,
        grouping, pagination, rowPinning, rowSelection, sorting, name, tableStateId, tableStateName]);
    const resetTableState = React.useCallback(() => {
        setColumnFilters([]);
        setColumnOrder([]);
        setColumnPinning({});
        setColumnSizing({});
        setColumnSizingInfo(sizingDefault);
        setColumnVisibility({});
        setDateFormat('normal');
        setExpanded({});
        setGlobalFilter(undefined);
        setGrouping([]);
        setPagination({ pageIndex: 0, pageSize: 10000 });
        setRowPinning({});
        setRowSelection({});
        setSorting([]);
        setTableStateId(undefined);
        setTableStateName(undefined);
        localStorage.removeItem(`${name}-table-settings`);
    }, [name]);
    const loadTableState = React.useCallback((state) => {
        setColumnFilters(state.columnFilters);
        setColumnOrder(state.columnOrder);
        setColumnPinning(state.columnPinning);
        setColumnSizing(state.columnSizing);
        setColumnSizingInfo(state.columnSizingInfo);
        setColumnVisibility(state.columnVisibility);
        setDateFormat(state.dateFormat);
        setExpanded(state.expanded);
        setGlobalFilter(state.globalFilter);
        setGrouping(state.grouping);
        setPagination(state.pagination);
        setRowPinning(state.rowPinning);
        setRowSelection(state.rowSelection);
        setSorting(state.sorting);
        setTableStateId(state.id);
        setTableStateName(state.name);
    }, []);
    const saveTableState = React.useCallback((stateName, stateId) => {
        const tableState = {
            columnFilters,
            columnOrder,
            columnPinning,
            columnSizing,
            columnSizingInfo,
            columnVisibility,
            dateFormat,
            expanded,
            exportHeaders,
            globalFilter,
            groupData,
            grouping,
            id: stateId,
            name: stateName,
            pagination,
            rowPinning,
            rowSelection,
            sorting,
            table: name,
        };
        (async () => {
            const init = { body: JSON.stringify(tableState), headers: { 'Content-Type': 'application/json' }, method: 'POST' };
            const request = new ServerRequest('/syndicate/table', init);
            try {
                await download.noContentPlus(request, 'problems saving');
            }
            catch (error) {
                //
            }
        })();
    }, [columnFilters, columnOrder, columnPinning, columnSizing, columnSizingInfo, columnVisibility,
        dateFormat, download, expanded, exportHeaders, globalFilter, groupData, grouping,
        name, pagination, rowPinning, rowSelection, sorting]);
    const table = useReactTable({
        //      columnResizeMode: 'onChange',
        columns,
        data,
        filterFns: {
            checkFilter,
        },
        getCoreRowModel: getCoreRowModel(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        //  globalFilterFn: fuzzyFilter,
        meta,
        onColumnFiltersChange: setColumnFilters,
        onColumnOrderChange: setColumnOrder,
        onColumnPinningChange: setColumnPinning,
        onColumnSizingChange: setColumnSizing,
        onColumnSizingInfoChange: setColumnSizingInfo,
        onColumnVisibilityChange: setColumnVisibility,
        onExpandedChange: setExpanded,
        onGlobalFilterChange: setGlobalFilter,
        onGroupingChange: setGrouping,
        onPaginationChange: setPagination,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        state: {
            columnFilters,
            columnOrder,
            columnPinning,
            columnSizing,
            columnSizingInfo,
            columnVisibility,
            expanded,
            globalFilter,
            grouping,
            pagination,
            rowPinning,
            rowSelection,
            sorting,
        },
    });
    const arrowKeyEvent = React.useCallback((e) => {
        if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            if (document.activeElement !== null && document.activeElement.tagName !== 'INPUT') {
                let active = document.activeElement.classList.contains('co-editable') ? document.activeElement : document.querySelector('.co-active');
                if (document.activeElement !== active) {
                    active.focus();
                }
                while (active !== null && !active.className.includes('co-editable')) {
                    active = active.parentElement;
                }
                const tableBody = bodyRef.current;
                if (active === null && tableBody !== null) {
                    const cell = tableBody.querySelector('.co-editable');
                    if (cell !== null) {
                        const body = document.querySelector('body');
                        if (body !== null) {
                            body.classList.add('tab-clicked');
                        }
                        cell.focus();
                    }
                }
                else if (active?.classList.contains('co-editable')) {
                    e.preventDefault();
                    let row = active;
                    while (row !== null && row.tagName !== 'TR') {
                        row = row.parentElement;
                    }
                    if (row !== null && tableBody !== null) {
                        let columnIndex = 0;
                        const allEditable = row.querySelectorAll('.co-editable');
                        while (allEditable.item(columnIndex) !== active) {
                            columnIndex += 1;
                        }
                        let rowIndex = 0;
                        const allRows = tableBody.querySelectorAll('tr');
                        while (allRows.item(rowIndex) !== row) {
                            rowIndex += 1;
                        }
                        if (e.key === 'ArrowLeft') {
                            if (columnIndex > 0) {
                                allEditable.item(columnIndex - 1).focus();
                            }
                        }
                        if (e.key === 'ArrowRight') {
                            if (columnIndex < allEditable.length - 1) {
                                allEditable.item(columnIndex + 1).focus();
                            }
                        }
                        if (e.key === 'ArrowUp') {
                            if (rowIndex > 0) {
                                const newRow = allRows.item(rowIndex - 1);
                                const newAllEditable = newRow.querySelectorAll('.co-editable');
                                newAllEditable.item(columnIndex).focus();
                            }
                        }
                        if (e.key === 'ArrowDown') {
                            if (rowIndex < allRows.length - 1) {
                                const newRow = allRows.item(rowIndex + 1);
                                const newAllEditable = newRow.querySelectorAll('.co-editable');
                                newAllEditable.item(columnIndex).focus();
                            }
                        }
                    }
                }
            }
        }
    }, []);
    const focusInEvent = React.useCallback((event) => {
        const eventTarget = event.target;
        if (eventTarget.tagName === 'DIV' && eventTarget.classList.contains('co-editable')) {
            document.querySelectorAll('.co-active-parent').forEach((e) => {
                e.classList.remove('co-active-parent');
            });
            document.querySelectorAll('.co-active').forEach((e) => {
                e.classList.remove('co-active');
            });
            const parent = eventTarget.parentElement;
            if (parent !== null) {
                parent.classList.add('co-active-parent');
            }
            eventTarget.classList.add('co-active');
        }
    }, []);
    React.useEffect(() => {
        window.addEventListener('keydown', arrowKeyEvent);
        window.addEventListener('focusin', focusInEvent);
    }, [arrowKeyEvent, focusInEvent]);
    const resetTableHeight = React.useCallback(() => {
        const tableElement = tableContainerRef.current;
        if (typeof tableElement !== 'undefined' && tableElement !== null) {
            const { left, top } = tableElement.getBoundingClientRect();
            const rem = Number.parseFloat(getComputedStyle(document.documentElement).fontSize);
            setHeight(window.innerHeight - top - 2 * rem);
            setWidth(window.innerWidth - left);
        }
    }, []);
    React.useEffect(() => {
        resetTableHeight();
        window.onresize = resetTableHeight;
    }, [resetTableHeight]);
    React.useLayoutEffect(() => {
        document.querySelectorAll('td').forEach((elem) => {
            if (elem.offsetWidth < elem.scrollWidth && elem.textContent !== null) {
                elem.setAttribute('title', elem.textContent);
            }
        });
    }, [isLoading]);
    const onScroll = React
        .useCallback((event) => {
        setScrollX(event.currentTarget.scrollLeft || 0);
        setScrollY(event.currentTarget.scrollTop || 0);
    }, []);
    React.useEffect(() => {
        if (bodyRef.current !== null) {
            setTbodyX(bodyRef.current.getBoundingClientRect().x);
            setTbodyY(bodyRef.current.getBoundingClientRect().y);
        }
        if (headRef.current !== null) {
            setTheadX(headRef.current.getBoundingClientRect().x);
            setTheadY(headRef.current.getBoundingClientRect().y);
        }
    }, []);
    React.useEffect(() => {
        if (setSelection !== undefined) {
            const selectedData = Object.keys(rowSelection)
                .map((index) => {
                const indexNumber = Number.parseInt(index, 10);
                return { index: indexNumber, value: data[indexNumber] };
            });
            setSelection(selectedData);
        }
    }, [data, rowSelection, setSelection]);
    const scrollValue = React.useMemo(() => ({
        scrollX, scrollY, tbodyX, tbodyY, theadX, theadY,
    } || {
        scrollX: 0, scrollY: 0, tbodyX: 0, tbodyY: 0, theadX: 0, theadY: 0,
    }), [scrollX, scrollY, tbodyX, tbodyY, theadX, theadY]);
    return (
    // <DndProvider backend={HTML5Backend}>
    React.createElement(React.Fragment, null,
        showColumnPicker
            ? React.createElement(ColumnPicker, { onClose: () => setShowColumnPicker(false), table: table }) : null,
        React.createElement("div", { style: { display: 'flex', flexDirection: 'column', overflowY: 'auto' } },
            React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' } },
                React.createElement("div", { style: { display: 'flex' } }, actionElements),
                React.createElement("div", { style: { display: 'flex', flexDirection: 'column', justifyContent: 'end' } },
                    React.createElement("div", { style: { display: 'flex' } },
                        React.createElement(SettingsButton, { dateFormat: dateFormat, exportHeaders: exportHeaders, groupData: groupData, loadTableState: loadTableState, name: name, resetState: resetTableState, saveState: saveTableState, setDateFormat: setDateFormat, setExportHeaders: setExportHeaders, setGroupData: setGroupData, table: table }),
                        React.createElement(ExportButton, { exportHeaders: exportHeaders, groupData: groupData, table: table })))),
            React.createElement("div", { className: "co-table-container tk-theme-condensed", onScroll: onScroll, ref: tableContainerRef, style: { maxHeight: height, minHeight: height } },
                isLoading || table.getRowModel().rows.length === 0
                    ? (React.createElement("div", { style: { marginLeft: width / 2, marginTop: (height / 2 - Number.parseFloat(getComputedStyle(document.documentElement).fontSize)), position: 'fixed' } }, isLoading ? React.createElement(Loader, null) : React.createElement(Typography, { type: "h1" }, hasError ? errorMessage : emptyMessage)))
                    : null,
                React.createElement(ScrollContext.Provider, { value: scrollValue },
                    React.createElement("table", { className: `co-table ${meta?.className ?? ''}` },
                        React.createElement("thead", { ref: headRef }, table.getHeaderGroups().map((headerGroup) => (React.createElement("tr", { className: "co-header-row", key: headerGroup.id }, headerGroup.headers.map((header) => (React.createElement(DraggableColumnHeader, { header: header, isNumber: typeof table.getFilteredRowModel().flatRows[0]?.getValue(header.column.id) === 'number', key: header.id, table: table }))))))),
                        React.createElement("tbody", { ref: bodyRef, style: { position: 'relative' } }, !isLoading && table.getRowModel().rows.length > 0
                            ? table.getRowModel().rows.map((row) => (React.createElement("tr", { className: "co-row", key: row.index }, row.getVisibleCells().map((cell) => (React.createElement("td", { className: "co-table-cell", key: cell.id, style: { maxWidth: cell.column.getSize(), width: cell.column.getSize() } }, flexRender(cell.column.columnDef.cell, cell.getContext())))))))
                            : null))))))
    // </DndProvider>
    );
};
export default CustomTable;
