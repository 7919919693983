import * as React from 'react';
import { Button, Card, Dropdown, Icon, Typography, } from '@symphony-ui/uitoolkit-components';
import ActionButton from 'components/custom-table/action/ActionButton';
import CustomTable from 'components/custom-table/CustomTable';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import releaseSources from './releaseSources';
import './ReleaseTable.css';
const ReleaseTable = ({ create, deleteFn, edit, hasError, isLoading, releases, sourceOptions, setSource, }) => {
    const [buttonDisabled, setButtonDisabled] = React.useState(true);
    const [data, setData] = React.useState();
    const { download } = React.useContext(DownloadContext);
    const actionCell = React.useCallback((context) => {
        const actionsMenu = [
            { callback: edit, label: 'Edit' },
            { callback: deleteFn, label: 'Delete' },
        ];
        return (React.createElement(ActionButton, { actionsMenu: actionsMenu, context: context }));
    }, [deleteFn, edit]);
    const columns = React.useMemo(() => (data !== undefined
        ? [...releaseSources[data], { cell: actionCell, id: 'actions', size: 20 }]
        : []), [actionCell, data]);
    const onChangeDropdown = React.useCallback((event) => {
        const { value } = event.target;
        const newValue = value.value;
        setData(newValue);
    }, []);
    React.useEffect(() => {
        setButtonDisabled(data === undefined);
        setSource(data);
    }, [data, setSource]);
    const onClickDownload = React.useCallback(() => {
        if (data !== undefined) {
            const url = new ServerURL('/bb/release/import');
            url.searchParams.append('source', data);
            const request = new ServerRequest(url, { method: 'POST' });
            (async () => {
                try {
                    await download.noContentPlus(request, 'Error updating');
                }
                catch (error) {
                    //
                }
            })();
        }
    }, [data, download]);
    return (React.createElement(Card, null,
        React.createElement("div", { className: "card-header" },
            React.createElement(Typography, { type: "h1" }, "Releases"),
            React.createElement("div", { className: "co-dropdown-row" },
                React.createElement(Button, { className: "co-button", disabled: hasError, onClick: create }, "Create New"),
                React.createElement(Dropdown, { isDisabled: hasError, id: "source-dropdown", name: "source", label: "Download Releases from Source", onChange: onChangeDropdown, options: sourceOptions, value: sourceOptions.find((o) => 'value' in o && o.value === data) }),
                React.createElement(Button, { className: "co-button", disabled: buttonDisabled, iconButton: true, onClick: onClickDownload },
                    React.createElement(Icon, { iconName: "download" })))),
        React.createElement(CustomTable, { columns: columns, data: releases, emptyMessage: "You have no releases yet", errorMessage: "Error downloading release", hasError: hasError, isLoading: isLoading, name: "release-table" })));
};
export default ReleaseTable;
