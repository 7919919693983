import * as React from 'react';
import { App } from 'utils/appUtils';
import UserContext from 'contexts/UserContext';
import IpoApp from '../../ipo/IpoApp';
import MacroApp from '../../macro/MacroApp';
import SyndicateApp from '../../syndicate/pages/syndicate-app/SyndicateApp';
import Main from '../../../site/pages/main-page/Main';
import NewIssueTrackerApp from '../../nit/pages/NewIssueTrackerApp';
import AdminApp from './admin-app/AdminApp';
import MTNApp from '../../mtn/pages/MTNApp';
import OrderBookApp from '../../orderBook/pages/OrderBookApp';
import AppViewContext from '../contexts/AppViewContext';
import './generic.scss';
const LocationRouter = () => {
    const { appView, setAppView } = React.useContext(AppViewContext);
    const { authService } = React.useContext(UserContext);
    React.useEffect(() => {
        (async () => {
            const access = await authService.accessibleApps();
            if (access?.length === 1) {
                setAppView(access[0]);
                localStorage.setItem('app', access[0]);
            }
            setAppView(App.SYND);
        })();
    }, [authService, setAppView]);
    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('app')) {
            switch (params.get('app')) {
                case 'CMO':
                    setAppView(App.SYND);
                    localStorage.setItem('app', App.SYND.toString());
                    break;
                default:
                //
            }
        }
    }, [setAppView]);
    switch (appView) {
        case App.SYND:
            return React.createElement(SyndicateApp, null);
        case App.NIT:
            return React.createElement(NewIssueTrackerApp, null);
        case App.IPO:
            return React.createElement(IpoApp, null);
        case App.MACRO:
            return React.createElement(MacroApp, null);
        case App.ADMIN:
            return React.createElement(AdminApp, null);
        case App.MTN:
            return React.createElement(MTNApp, null);
        case App.BOOK:
            return React.createElement(OrderBookApp, null);
        default:
            return React.createElement(Main, null);
    }
};
export default LocationRouter;
