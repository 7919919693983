import { App } from 'utils/appUtils';
class UserInfo {
    sub;
    id;
    company;
    authorityMap;
    constructor(response) {
        this.sub = response.sub;
        this.authorityMap = response.authorityMap;
    }
    authorities(app) {
        switch (app) {
            case App.ADMIN:
                return this.authorityMap['cmo.core'];
            case App.NIT:
                return this.authorityMap['cmo.nit'];
            case App.SYND:
                return this.authorityMap['cmo.syndicate'];
            case App.IPO:
                return this.authorityMap['cmo.ipo'];
            case App.MACRO:
                return this.authorityMap['cmo.release'];
            default:
                return undefined;
        }
    }
    hasAuthority(app, value) {
        if (value === undefined) {
            return true;
        }
        const values = this.authorities(app);
        return values !== undefined ? values.includes(value) || values.includes(`ROLE_${value}`) : false;
    }
    canAccess(app) {
        return this.authorities(app) !== undefined;
    }
    appAccess() {
        const access = [];
        if (this.authorityMap['cmo.syndicate'] !== undefined && this.authorityMap['cmo.syndicate'].length > 0) {
            access.push(App.SYND);
        }
        if (this.authorityMap['cmo.nit'] !== undefined && this.authorityMap['cmo.nit'].length > 0) {
            access.push(App.NIT);
        }
        if (this.authorityMap['cmo.ipo'] !== undefined && this.authorityMap['cmo.ipo'].length > 0) {
            access.push(App.IPO);
        }
        if (this.authorityMap['cmo.release'] !== undefined && this.authorityMap['cmo.release'].length > 0) {
            access.push(App.MACRO);
        }
        if (this.authorityMap['cmo.core'] !== undefined && this.authorityMap['cmo.core'].length > 0) {
            access.push(App.ADMIN);
        }
        return access;
    }
}
export default UserInfo;
