import { Dropdown } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import * as React from 'react';
import { optionSort } from 'utils/table';
import ConstantList from './ConstantList';
const ConstantViewer = ({ field }) => {
    const { download } = React.useContext(DownloadContext);
    const [constantOptions, setConstantOptions] = React.useState([]);
    const [constantId, setConstantId] = React.useState();
    const [hasError, setError] = React.useState(false);
    React.useEffect(() => {
        if (typeof field !== 'undefined') {
            (async () => {
                const url = new ServerURL('/syndicate/constant/options');
                url.searchParams.append('field', field);
                const request = new ServerRequest(url);
                try {
                    const downloadedOptions = await download.jsonPlus(request, 'Error downloading options');
                    setConstantOptions(downloadedOptions.sort(optionSort));
                    setConstantId(downloadedOptions.length > 0 && 'value' in downloadedOptions[0] ? downloadedOptions[0].value : undefined);
                }
                catch (error) {
                    setError(true);
                }
            })();
        }
    }, [download, field]);
    const changeConstant = React.useCallback((event) => {
        console.log('Constant changed to: %o', event.target);
        // eslint-disable-next-line no-underscore-dangle
        if (!constantOptions.includes(event.target.value)) {
            console.log("Can't find it");
            const const2 = { field, names: [event.target.value.value] };
            const request = new ServerRequest('/syndicate/constant', {
                body: JSON.stringify(const2),
                headers: { 'content-type': 'application/json' },
                method: 'POST',
            });
            (async () => {
                try {
                    const response = await download.jsonPlus(request, 'Error saving constant');
                    const newOptions = [...constantOptions];
                    console.log('Setting new Constant Id: %o', response.id);
                    setConstantId(response.id);
                    newOptions.push({ label: response.names[0], value: response.id });
                    setConstantOptions(newOptions);
                }
                catch (error) {
                    //
                }
            })();
        }
        else {
            console.log('Found it and changeing Id');
            setConstantId(event.target.value.value);
        }
    }, [download, constantOptions, field]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Dropdown, { addNewOptions: true, isDisabled: hasError, label: "Constants", onChange: changeConstant, options: constantOptions, value: constantOptions.find((o) => ('value' in o && o.value === constantId)) }),
        typeof constantId !== 'undefined' ? React.createElement(ConstantList, { constantId: constantId }) : null));
};
export default ConstantViewer;
