class TransactionWrapper {
    company;
    confirm;
    id;
    name;
    firstUpdate;
    lastUpdate;
    sector;
    region;
    types;
    constructor({ company, confirm, id, name, firstUpdate, lastUpdate, sector, region, types, }) {
        this.company = company;
        this.confirm = confirm;
        this.id = id;
        this.name = name;
        this.firstUpdate = new Date(firstUpdate);
        this.lastUpdate = new Date(lastUpdate);
        this.sector = sector;
        this.region = region;
        this.types = types;
    }
}
export default TransactionWrapper;
