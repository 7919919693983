import * as React from 'react';
import { Button, Dropdown, Icon, } from '@symphony-ui/uitoolkit-components';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import { getOptions } from 'utils/change';
import './EsmaForm.css';
import EsmaQuery from '../../../models/query/EsmaQuery';
import FilterItem from '../../../models/FilterItem';
import EsmaSearch from '../../../models/search/EsmaSearch';
import NameValuePair from '../../name-value-pair/NameValuePair';
const EsmaForm = ({ search, setSearch }) => {
    const { download } = React.useContext(DownloadContext);
    const [selector, setSelector] = React.useState();
    const [nameValueList, setNameValueList] = React.useState([]);
    const [nameValueFields, setNameValueFields] = React.useState([]);
    const [nameValueOptions, setNameValueOptions] = React.useState([]);
    const [selectorDisabled, setSelectorDisabled] = React.useState(selector === null);
    const changeSelect = React.useCallback((event) => {
        const { value } = event.target;
        setSelector(value.value);
        setSelectorDisabled(value.value === null);
    }, []);
    const clickAdd = React.useCallback(() => {
        const newSearch = { ...search };
        if (selector === 'tags') {
            const { filter } = search || {};
            filter.tags = FilterItem.NEW;
            newSearch.filter = filter;
        }
        else if (selector !== undefined) {
            const query = search.query || new EsmaQuery();
            const newList = query.textCriteria ? [...query.textCriteria] : [];
            newList.push({ name: selector, values: [] });
            query.textCriteria = newList;
            newSearch.query = query;
        }
        setSearch(Object.assign(new EsmaSearch(), newSearch));
        setSelector(undefined);
    }, [search, selector, setSearch]);
    const remove = React.useCallback((textCriteria) => () => {
        const { query } = search;
        const newList = query.textCriteria
            ? query.textCriteria.filter((l) => l.name !== textCriteria.name) : [];
        query.textCriteria = newList;
        setSearch(Object.assign(new EsmaSearch(), search, { query }));
    }, [search, setSearch]);
    const removeFilter = React.useCallback((field) => () => {
        const { filter } = search;
        delete filter[field];
        setSearch(Object.assign(new EsmaSearch(), search, { filter }));
    }, [search, setSearch]);
    const setValues = React.useCallback((textCriteria) => (value) => {
        const { query } = search;
        //     console.log('value set to: %o, initial list: %o, N: %o', val, query.textCriteria, n);
        const index = query.textCriteria.findIndex((l) => l.name === textCriteria.name);
        //    console.log('INDEX: %o', index);
        const newTextCriteria = { ...textCriteria, values: value };
        const textCriterias = [...query.textCriteria];
        textCriterias[index] = newTextCriteria;
        query.textCriteria = textCriterias;
        setSearch(Object.assign(new EsmaSearch(), search, { query }));
    }, [search, setSearch]);
    const setValuesFilter = React.useCallback((name) => (value) => {
        const { filter } = search;
        if (name in filter) {
            const tags = filter[name];
            if (tags !== undefined) {
                tags.values = value;
            }
            setSearch(Object.assign(new EsmaSearch(), search, { filter }));
        }
    }, [search, setSearch]);
    const optionNotDisabled = React.useCallback((option) => {
        const criteria = search.query.textCriteria;
        if (criteria) {
            const chosenOptions = search.query.textCriteria.map((t) => t.name);
            return !chosenOptions.includes(option.value);
        }
        return true;
    }, [search.query.textCriteria]);
    React.useEffect(() => {
        (async () => {
            const request = new ServerRequest('/nit/search/esma/name-fields');
            try {
                const fields = await download.jsonPlus(request, 'Error downloading Name Fields');
                setNameValueFields(fields);
                const options = fields.map((o) => ({ label: o.description, value: o.name }));
                const leiOption = options.find((o) => o.value === 'lei');
                if (leiOption !== undefined) {
                    leiOption.label = 'Issuer Name';
                }
                const cfiOption = options.find((o) => o.value === 'gnr_cfi_code');
                if (cfiOption !== undefined) {
                    cfiOption.label = 'Category';
                }
                const currencyOption = options.find((o) => o.value === 'bnd_nmnl_value_curr_code');
                if (currencyOption !== undefined) {
                    currencyOption.label = 'Currency';
                }
                options.push({ label: 'Tags', value: 'tags' });
                setNameValueOptions(options);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    // React.useEffect(() => {
    //   setSearch(data);
    //   if (data.query.textCriteria) {
    //     const chosenOptions = data.query.textCriteria.map((t) => t.name);
    //     const newNVOptions = nameValueOptions
    //       .map((o: LabelValue) => ({ ...o, disabled: chosenOptions.includes(o.value) }));
    //     setNameValueOptions(newNVOptions);
    //   }
    //   console.log('Trying this here??');
    // }, [data, nameValueOptions, setSearch]);
    React.useEffect(() => {
        const elements = search.query.textCriteria ? search.query.textCriteria.map((n) => {
            const field = nameValueFields.find((f) => f.name === n.name);
            const type = field ? field.type : '';
            return (React.createElement(NameValuePair, { description: field ? field.description : '', key: n.name, name: n.name, remove: remove(n), setValues: setValues(n), type: type, values: n.values }));
        }) : [];
        if (search.filter) {
            Object.entries(search.filter).forEach(([key, value]) => {
                elements.push(React.createElement(NameValuePair, { description: "", key: key, name: key, remove: removeFilter(key), setValues: setValuesFilter(key), type: "FILTER", values: value }));
            });
        }
        setNameValueList(elements);
    }, [remove, removeFilter, search, setValues, setValuesFilter, nameValueFields]);
    return (React.createElement("div", null,
        React.createElement("div", { className: "base-form" }, nameValueList),
        React.createElement("div", { className: "co-dropdown-row co-item" },
            React.createElement(Dropdown, { className: "co-dropdown", label: "Add a field for the search", onChange: changeSelect, options: nameValueOptions.filter(optionNotDisabled), value: getOptions(selector, nameValueOptions.filter(optionNotDisabled)) }),
            React.createElement(Button, { className: "co-button", disabled: selectorDisabled, iconButton: true, onClick: clickAdd },
                React.createElement(Icon, { iconName: "plus" })))));
};
export default EsmaForm;
