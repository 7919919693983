class Order {
    createdBy;
    createdDate;
    lastModifiedBy;
    lastModifiedDate;
    id;
    amount;
    permissions;
    investorName;
    newIssueId;
    trancheId;
    updatePermissions;
}
export default Order;
