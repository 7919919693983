import * as React from 'react';
import InfiniteTable from 'components/custom-table/InfiniteTable';
import TableContext from 'components/custom-table/TableContext';
import ExpanderCell from './ExpanderCell';
import TransactionCheckbox from './TransactionCheckbox';
import CollapseCell from './CollapseCell';
const TransactionTable = ({ actionElements, dataTransform, subComponent, }) => {
    const { resetRowExpansion } = React.useContext(TableContext);
    const onClickCollapse = React.useCallback(() => {
        if (resetRowExpansion !== undefined) {
            resetRowExpansion([]);
        }
    }, [resetRowExpansion]);
    const test = React.useCallback(() => (React.createElement(CollapseCell, { onClick: onClickCollapse })), [onClickCollapse]);
    const columns = React.useMemo(() => [
        {
            cell: (ExpanderCell), header: test, id: 'expander', size: 28,
        },
        {
            cell: TransactionCheckbox, header: () => null, id: 'transactionTickbox', size: 28,
        },
        {
            accessorKey: 'name', header: 'Name', id: 'name', size: 9999,
        },
        {
            accessorFn: (t) => t.timestamp.toLocaleString(),
            enableColumnFilter: false,
            header: 'Last Update',
            id: 'lastUpdate',
            size: 200,
            //      sortingFn: sortLastUpdate,
        },
    ], [test]);
    return (React.createElement(InfiniteTable, { actionElements: actionElements, columns: columns, dataTransform: dataTransform, emptyMessage: "You have no transactions yet", errorMessage: "Unable to connect to server to load the data. Please check connection.", fetchSize: 100, name: "transactions", subComponent: subComponent, urlPage: "/syndicate/transactions/page", urlTotal: "/syndicate/transactions/total" }));
};
export default TransactionTable;
