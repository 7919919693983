import * as React from 'react';
import MyCard from '../MyCard';
import { localIconUrl as nitIconUrl } from '../../../../modules/nit/utils/iconUrls';
import { localIconUrl as conductoIconUrl } from '../../../../modules/conducto/utils/iconUrls';
import './Cards.scss';
const Cards = () => {
    const size = 32;
    const certifiedImg = (React.createElement("img", { className: "my-image", alt: "certification logo", src: "/assets/front/SY2-Developer-Badges-FINAL-BOT-sml2.png", width: size, height: size }));
    const nitImg = (React.createElement("img", { alt: "NIT logo", className: "my-image", src: nitIconUrl(size), width: size, height: size }));
    const conductoImg = (React.createElement("img", { alt: "Conducto logo", className: "my-image", src: conductoIconUrl(size), width: size, height: size }));
    return (React.createElement("div", { id: "main-cards" },
        React.createElement(MyCard, { header: "Certified Developers", icon: certifiedImg, text: "All our developers are Symphony / Certified Developers and Symphony / Certified Bot Developers." }),
        React.createElement(MyCard, { header: "Conducto", icon: conductoImg, text: "Our New Issue Tracker notifies you of the latest securities issued." }),
        React.createElement(MyCard, { header: "New Issue Tracker", icon: nitImg, text: "Our New Issue Tracker notifies you of the latest securities issued." })));
};
export default Cards;
