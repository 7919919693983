import * as React from 'react';
import { Button, Card, Icon, TextField, Typography, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ViewContext from 'contexts/ViewContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import ContentModal from '../../regexes/ContentModal';
import EmailTable from '../EmailTable';
const EmailOverview = ({ setEmailId }) => {
    const { download } = React.useContext(DownloadContext);
    const { setView } = React.useContext(ViewContext);
    const [messageId, setMessageId] = React.useState();
    const [email, setEmail] = React.useState();
    const [showContentModal, setShowContentModal] = React.useState(false);
    const [textId, setTextId] = React.useState('');
    const changeId = React.useCallback((event) => {
        setTextId(event.target.value);
    }, []);
    const clickId = React.useCallback(() => {
        setEmailId(textId);
        localStorage.setItem('emailId', textId);
        setView('MANAGER');
    }, [setEmailId, setView, textId]);
    React.useEffect(() => {
        if (messageId) {
            (async () => {
                const url = new ServerURL('/syndicate/emails');
                url.searchParams.append('messageId', messageId);
                const request = new ServerRequest(url);
                const body = await download.jsonPlus(request, `Unable to find email with id ${messageId}`);
                setEmail(body);
            })();
        }
    }, [download, messageId]);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement("div", { className: "co-header-main" },
            React.createElement(Typography, { type: "h1" }, "Emails"),
            React.createElement("div", null,
                React.createElement(Button, { name: "stats-button", onClick: () => setView('STATS'), size: "small" }, "STATS"))),
        typeof email !== 'undefined'
            ? (React.createElement(ContentModal, { update: email, hideModal: () => setShowContentModal(false), show: showContentModal })) : null,
        React.createElement(EmailTable, { actionElements: [
                (React.createElement(TextField, { onChange: changeId, placeholder: "email ID", value: textId })),
                (React.createElement(Button, { iconButton: true, onClick: clickId, size: "small", style: { marginLeft: '0.5rem', marginTop: '0.25rem' } },
                    React.createElement(Icon, { iconName: "search" })))
            ], setEmailId: setEmailId, setMessageId: setMessageId, setShowModal: setShowContentModal })));
};
export default EmailOverview;
