export var App;
(function (App) {
    App["SYND"] = "SYND";
    App["NIT"] = "NIT";
    App["IPO"] = "IPO";
    App["MACRO"] = "MACRO";
    App["MTN"] = "MTN";
    App["BOOK"] = "BOOK";
    App["ADMIN"] = "ADMIN";
})(App || (App = {}));
export const appFrom = (name) => {
    switch (name.toLowerCase()) {
        case 'synd': return App.SYND;
        case 'nit': return App.NIT;
        case 'ipo': return App.IPO;
        case 'macro': return App.MACRO;
        case 'mtn': return App.MTN;
        case 'book': return App.BOOK;
        case 'admin': return App.ADMIN;
        default: throw new Error(`No app with name ${name}`);
    }
};
export const appFromNumber = (index) => {
    switch (index) {
        case '0': return App.SYND;
        case '1': return App.NIT;
        case '2': return App.IPO;
        case '3': return App.MACRO;
        case '4': return App.MTN;
        case '5': return App.BOOK;
        case '6': return App.ADMIN;
        default: throw new Error(`No app with index ${index}`);
    }
};
