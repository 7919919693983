import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import ItemRegex from '../../../models/regexes/ItemRegex';
import SubjectRegex from '../../../models/regexes/SubjectRegex';
import KeyValueRegex from '../../../models/regexes/KeyValueRegex';
import LineRegex from '../../../models/regexes/LineRegex';
import RegexTable from './regex-table/RegexTable';
import KeyValueRegexForm from './key-value-regex-form/KeyValueRegexForm';
import RegexCrudService from '../RegexCrudService';
const RegexViewer = ({ category, keyValueRegex, lineRegex, myKey, regexes, setKeyValueRegex, setLineRegex, setMyKey, setRegexes, type, }) => {
    const { download } = React.useContext(DownloadContext);
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const service = React.useMemo(() => new RegexCrudService({ download, type }), [download, type]);
    const loadRegexes = React.useCallback(() => {
        if (type === 'keyvalues' && keyValueRegex === undefined) {
            //
        }
        else {
            setLoading(true);
            const url = new ServerURL(`/syndicate/regex/${type}`);
            if (type === 'keyvalues') {
                url.searchParams.append('id', keyValueRegex?.id ?? '');
            }
            else if (type === 'paragraphs') {
                url.searchParams.append('category', category);
            }
            const request = new ServerRequest(url);
            (async () => {
                try {
                    const downloadedRegex = await download.jsonPlus(request, 'Error downloading regexes');
                    switch (type) {
                        case 'keyvalues':
                            setKeyValueRegex(new KeyValueRegex(downloadedRegex));
                            break;
                        case 'listitems':
                            setRegexes(downloadedRegex.map((r) => new ItemRegex(r))
                                .sort((r0, r1) => (r0.index ?? 0) - (r1.index ?? 0)).map((i) => i.toIndexedRegex()));
                            break;
                        case 'paragraphs':
                            setLineRegex(new LineRegex(downloadedRegex));
                            break;
                        case 'subject':
                            setRegexes(downloadedRegex.map((r) => new SubjectRegex(r))
                                .sort((r0, r1) => (r0.index ?? 0) - (r1.index ?? 0)).map((i) => i.toIndexedRegex()));
                            break;
                        default:
                            throw new Error('Unknown type');
                    }
                }
                catch (error) {
                    setError(true);
                }
                finally {
                    setLoading(false);
                }
            })();
        }
    }, [category, download, keyValueRegex, setKeyValueRegex, setLineRegex, setRegexes, type]);
    const deleteRegex = React.useCallback(({ row }) => {
        const id = Number.parseInt(row.id, 10);
        (async () => {
            switch (type) {
                case 'keyvalues':
                    if (keyValueRegex !== undefined) {
                        keyValueRegex.remove(id);
                        const updatedKeyValueRegex = await service.remove(keyValueRegex, id);
                        setKeyValueRegex(KeyValueRegex.create(updatedKeyValueRegex));
                        document.dispatchEvent(new Event('reloaddata'));
                    }
                    break;
                case 'listitems':
                    {
                        const itemRegex = ItemRegex.createFromIndexedRegex(row.original);
                        await service.delete(itemRegex);
                        const clonedRegexes = [...regexes];
                        clonedRegexes.splice(id, 1);
                        setRegexes(clonedRegexes);
                        document.dispatchEvent(new Event('reloaddata'));
                    }
                    break;
                case 'paragraphs':
                    if (lineRegex !== undefined) {
                        const updatedLineRegex = await service.remove(lineRegex, id);
                        setLineRegex(new LineRegex(updatedLineRegex));
                        document.dispatchEvent(new Event('reloaddata'));
                    }
                    break;
                case 'subject': {
                    const subjectRegex = SubjectRegex.createFromIndexedRegex(row.original);
                    await service.delete(subjectRegex);
                    const clonedRegexes = [...regexes];
                    clonedRegexes.splice(id, 1);
                    setRegexes(clonedRegexes);
                    document.dispatchEvent(new Event('reloaddata'));
                    break;
                }
                default:
            }
        })();
    }, [keyValueRegex, lineRegex, regexes, service,
        setKeyValueRegex, setLineRegex, setRegexes, type]);
    const callbackCreate = (updatedKeyValueRegex) => {
        if (myKey !== undefined) {
            setMyKey({ ...myKey, id: updatedKeyValueRegex.id });
        }
    };
    const callbackAdd = () => {
        // ignore
    };
    const isFormDisabled = React.useMemo(() => (keyValueRegex !== undefined && myKey !== undefined && keyValueRegex.id === myKey.id), [keyValueRegex, myKey]);
    const resetData = React.useCallback((newData) => {
        (async () => {
            switch (type) {
                case 'keyvalues':
                    if (keyValueRegex !== undefined) {
                        const newKeyValueRegex = keyValueRegex.updateRegexes(newData);
                        const updated = await service.saveAny(newKeyValueRegex);
                        setKeyValueRegex(new KeyValueRegex(updated));
                    }
                    break;
                case 'listitems':
                    {
                        const ids = newData.map((r) => r.id);
                        const url = new ServerURL('/syndicate/regex/listitems/reorder');
                        const request = new ServerRequest(url, { body: JSON.stringify(ids), headers: { 'Content-Type': 'application/json' }, method: 'POST' });
                        try {
                            await download.noContentPlus(request, 'Unable to reorder List Items');
                        }
                        catch (error) {
                            //
                        }
                        setRegexes(newData);
                    }
                    break;
                case 'paragraphs':
                    if (lineRegex !== undefined) {
                        const newLineRegex = lineRegex.updateRegexes(newData);
                        const updated = await service.saveAny(newLineRegex);
                        setLineRegex(new LineRegex(updated));
                    }
                    break;
                case 'subject':
                    {
                        const ids = newData.map((r) => r.id);
                        const url = new ServerURL('/syndicate/regex/subject/reorder');
                        const request = new ServerRequest(url, { body: JSON.stringify(ids), headers: { 'Content-Type': 'application/json' }, method: 'POST' });
                        try {
                            await download.noContentPlus(request, 'Unable to reorder Subjects');
                        }
                        catch (error) {
                            //
                        }
                        setRegexes(newData);
                    }
                    break;
                default:
            }
        })();
    }, [download, keyValueRegex, lineRegex, service, setKeyValueRegex, setLineRegex, setRegexes,
        type]);
    React.useEffect(() => {
        if (type === 'paragraphs' && lineRegex !== null && typeof lineRegex !== 'undefined') {
            setRegexes(lineRegex.toIndexedRegexes());
        }
    }, [lineRegex, setRegexes, type]);
    React.useEffect(() => {
        if (type === 'keyvalues' && keyValueRegex !== null) {
            setRegexes([]);
        }
        else {
            loadRegexes();
        }
    }, [keyValueRegex, loadRegexes, setRegexes, type]);
    React.useEffect(() => {
        if (type === 'keyvalues' && keyValueRegex === undefined) {
            setRegexes([]);
        }
        else if (type === 'keyvalues' && keyValueRegex !== undefined) {
            setRegexes(keyValueRegex.toIndexedRegexes());
        }
        else {
            loadRegexes();
        }
    }, [keyValueRegex, loadRegexes, setRegexes, type]);
    return (React.createElement(React.Fragment, null,
        type === 'keyvalues' ? (React.createElement("div", { style: { gridArea: 'regexForm' } },
            React.createElement(KeyValueRegexForm, { callbackAdd: callbackAdd, callbackCreate: callbackCreate, isFormDisabled: isFormDisabled, keyValueRegex: keyValueRegex, name: myKey !== undefined ? myKey.name : undefined, setKeyValueRegex: setKeyValueRegex }))) : null,
        React.createElement("div", { style: { gridArea: 'regexTable' } },
            React.createElement(RegexTable, { deleteRegex: deleteRegex, emptyMessage: "No regexes found", errorMessage: "Error downloading regexes", hasError: hasError, isLoading: isLoading, regexes: regexes, resetData: resetData }))));
};
export default RegexViewer;
