import * as React from 'react';
import PageWrapper from 'pages/PageWrapper';
import TermSheets from './term-sheets/TermSheets';
import Workflows from './workflows/Workflows';
const MTNApp = () => (React.createElement(PageWrapper, { pages: [{
            auth: 'ADMIN', defaultView: 'TABLE', element: (React.createElement(Workflows, null)), label: 'Workflows',
        }, {
            auth: 'ADMIN', defaultView: 'TABLE', element: (React.createElement(TermSheets, null)), label: 'Term Sheets',
        },
    ] }));
export default MTNApp;
