import * as React from 'react';
import { Card, Typography } from '@symphony-ui/uitoolkit-components';
import ServerRequest from 'models/ServerRequest';
import AppViewContext from 'contexts/AppViewContext';
import DownloadContext from 'contexts/DownloadContext';
import { App } from 'utils/appUtils';
import UserInfo from 'models/UserInfo';
import BaseCurrency from '../components/settings/BaseCurrency';
import './NitSettings.css';
import { Source } from '../sources/sources';
import CredentialsInput from '../components/settings/CredentialsInput';
const NITSettingsExport = () => {
    const empty = React.useMemo(() => ({ password: '', username: '' }), []);
    const { userManager } = React.useContext(AppViewContext);
    const { download } = React.useContext(DownloadContext);
    const [data, setData] = React.useState({ ANNA: empty, EUROCLEAR: empty });
    const [userInfo, setUserInfo] = React.useState(null);
    React.useEffect(() => {
        (async () => {
            const user = await userManager?.getUser();
            const t = user?.profile;
            setUserInfo(user == null ? null : new UserInfo(user.state));
        })();
    }, [userManager]);
    React.useEffect(() => {
        (async () => {
            const request = new ServerRequest('/nit/credentials');
            try {
                const body = await download.jsonPlus(request, 'Unable to download credentials');
                setData({
                    ANNA: body.ANNA || empty,
                    EUROCLEAR: body.EUROCLEAR || empty,
                });
            }
            catch (error) {
                //
            }
        })();
    }, [download, empty]);
    return (React.createElement(React.Fragment, null,
        React.createElement(BaseCurrency, null),
        React.createElement(Card, { className: "co-card-stack", key: "NIT-credentials" },
            React.createElement("div", { className: "co-item" },
                React.createElement(Typography, { type: "h1" }, "Credentials")),
            userInfo?.hasAuthority(App.NIT, 'DEMO') ? (React.createElement("div", { className: "co-item" },
                React.createElement(Typography, { type: "body" }, "You have demo access. You can use your own credentials , or if you do not save these your access will fall back to demo credentials."))) : undefined,
            React.createElement("div", { className: "co-item" },
                React.createElement(CredentialsInput, { initial: data.EUROCLEAR, source: Source.EUROCLEAR, title: "Euroclear" }),
                React.createElement(CredentialsInput, { initial: data.ANNA, source: Source.ANNA, title: "ANNA" })))));
};
export default NITSettingsExport;
