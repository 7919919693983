import * as React from 'react';
import { useNavigate } from 'react-router-dom';
const LogoutHandler = ({ setAuth, userManager, }) => {
    console.log('Start LogoutHandler.');
    const navigate = useNavigate();
    React.useEffect(() => {
        (async () => {
            console.log('Removing user, with settings: %o', userManager.settings);
            try {
                await userManager.removeUser();
                console.log('Remove User successful');
            }
            catch (error) {
                console.error('Error when trying to remove user in the LogoutHandler.  Error %o', error);
            }
            finally {
                setAuth(false);
                navigate('/', { replace: true });
            }
        })();
    }, [navigate, setAuth, userManager]);
    return (React.createElement("div", null, "Loading"));
};
export default LogoutHandler;
