import { Dropdown, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import './KeyValueRegexForm.css';
const KeyValueRegexPicker = ({ isDisabled, keyValueRegexId, options, setKeyValueRegexId, }) => {
    const onChangeKey = React.useCallback((event) => {
        setKeyValueRegexId(event.target.value.value);
    }, [setKeyValueRegexId]);
    return (React.createElement("div", { className: "co-keyvalueregex-picker" },
        React.createElement(Dropdown, { isDisabled: isDisabled, label: "Key Value Regex", name: "key", onChange: onChangeKey, value: keyValueRegexId !== undefined ? options.find((o) => (o.value === keyValueRegexId)) : null, options: options })));
};
export default KeyValueRegexPicker;
