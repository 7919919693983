import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import TagInput from 'components/tag-input/TagInput';
import ServerRequest from 'models/ServerRequest';
const CompanyTagsModal = ({ companyTags, hideModal, updateTags, tagOptions, }) => {
    const [data, setData] = React.useState(companyTags);
    const [loading, setLoading] = React.useState(false);
    const [options, setOptions] = React.useState(tagOptions);
    React.useEffect(() => {
        const newOptions = [...tagOptions];
        (data.tags || []).forEach((tag) => {
            if (!newOptions.some((o) => o.value === tag)) {
                newOptions.push({ label: tag, value: tag });
            }
        });
        setOptions(newOptions);
    }, [data, tagOptions]);
    const onChangeDropdown = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? [...new Set(value.map((v) => v.value))] : value.value;
        const newObject = Object.assign(data, { [name]: newValue });
        setData(newObject);
    }, [data]);
    const onClickSave = React.useCallback(() => {
        setLoading(true);
        const request = new ServerRequest('/nit/companies/tags', {
            body: JSON.stringify(data),
            headers: { 'content-type': 'application/json' },
            method: ('id' in data ? 'PATCH' : 'POST'),
        });
        (async () => {
            await fetch(request);
            setLoading(false);
            updateTags(data);
            hideModal();
        })();
    }, [data, hideModal, updateTags]);
    return (React.createElement(Modal, { show: true, closeButton: true, onClose: hideModal, parentNode: document.body, size: "medium" },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, "Tag Company")),
        React.createElement(ModalBody, { className: "co-fix-modal-dropdown-overflow" },
            React.createElement(TagInput, { label: "Input tags", onChange: onChangeDropdown('tags'), tagOptions: options, tags: data.tags })),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { loading: loading, onClick: onClickSave }, "Save"))));
};
export default CompanyTagsModal;
