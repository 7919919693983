import * as React from 'react';
import { DropdownMenu, DropdownMenuDivider, DropdownMenuItem } from '@symphony-ui/uitoolkit-components';
import AppViewContext from 'contexts/AppViewContext';
import { App } from 'utils/appUtils';
const MenuItems = ({ closeMenu, setShowSettings, show }) => {
    const { userManager, setAppView } = React.useContext(AppViewContext);
    const [userInfo, setUserInfo] = React.useState(null);
    const onClickApp = React.useCallback((key) => () => {
        closeMenu();
        setAppView(key);
        localStorage.setItem('app', key.toString());
    }, [closeMenu, setAppView]);
    React.useEffect(() => {
        (async () => {
            const user = await userManager?.getUser();
            setUserInfo(user == null ? null : user.state);
        })();
    }, [userManager]);
    const onClickLogout = React.useCallback(() => {
        closeMenu();
        console.log('Log out clicked');
        //    const request: ServerRequest = new ServerRequest('/logout', { method: 'POST' });
        if (userManager !== undefined) {
            (async () => {
                try {
                    await userManager.signoutRedirect();
                    //          await download.noContentPlus(request, 'Unable to log out');
                    //         localStorage.removeItem('user');
                    //        window.location.reload();
                }
                catch (error) {
                    // ignore in case no access to localStorage
                }
            })();
        }
    }, [closeMenu, userManager]);
    const onClickSettings = React.useCallback(() => {
        closeMenu();
        setShowSettings(true);
    }, [closeMenu, setShowSettings]);
    const menuItems = React.useMemo(() => {
        const items = [
            (React.createElement(DropdownMenuItem, { key: "settings", onClick: onClickSettings }, "Settings")),
            (React.createElement(DropdownMenuDivider, { key: "divider1" })),
        ];
        if (userInfo != null) {
            userInfo.appAccess().forEach((key) => {
                items.push((React.createElement(DropdownMenuItem, { key: key, onClick: onClickApp(key) }, App[key])));
            });
        }
        items.push((React.createElement(DropdownMenuDivider, { key: "divider2" })));
        items.push((React.createElement(DropdownMenuItem, { key: "log-out", onClick: onClickLogout }, "Log Out")));
        return items;
    }, [userInfo, onClickApp, onClickLogout, onClickSettings]);
    return (React.createElement(DropdownMenu, { className: "co-menu-dropdown", show: show }, menuItems));
};
export default MenuItems;
