/* eslint-disable react/function-component-definition */
import * as React from 'react';
import { getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getSortedRowModel, useReactTable, flexRender, } from '@tanstack/react-table';
import { useInfiniteQuery, useQueryClient, } from '@tanstack/react-query';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Loader, Typography } from '@symphony-ui/uitoolkit-components';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import DownloadContext from 'contexts/DownloadContext';
import RenderingContext from 'contexts/RenderingContext';
import DraggableColumnHeader from './DraggableColumnHeader';
import ColumnPicker from './ColumnPicker';
import ExportButton from './buttons/ExportButton';
import SettingsButton from './buttons/SettingsButton';
import { printDate } from './dateFormat';
import TableContext from './TableContext';
import './CustomTable.scss';
import createDataRequest from './infinite-data-request';
const startingPage = 0;
const sizingDefault = {
    columnSizingStart: [],
    deltaOffset: null,
    deltaPercentage: null,
    isResizingColumn: false,
    startOffset: 0,
    startSize: 150,
};
const InfiniteTable = function InfiniteTableElement({ columns, dataTransform, emptyMessage, errorMessage, fetchSize, meta, name, nameStorage = undefined, urlPage, urlTotal, setLoading, subComponent, actionElements = [], }) {
    const { download } = React.useContext(DownloadContext);
    const { setTableUpdateFn, setResetRowExpansion, setResetRowSelection, setSelection, } = React.useContext(TableContext);
    const tableContainerRef = React.useRef(null);
    const [columnFilters, setColumnFilters] = React.useState(() => {
        const stored = localStorage.getItem(`${nameStorage ?? name}-table-settings`);
        return stored !== null ? JSON.parse(stored).columnFilters : [];
    });
    const [columnOrder, setColumnOrder] = React.useState(() => {
        const stored = localStorage.getItem(`${nameStorage ?? name}-table-settings`);
        return stored !== null ? JSON.parse(stored).columnOrder : [];
    });
    const [columnPinning, setColumnPinning] = React.useState(() => {
        const stored = localStorage.getItem(`${nameStorage ?? name}-table-settings`);
        return stored !== null ? JSON.parse(stored).columnPinning : {};
    });
    const [columnSizing, setColumnSizing] = React.useState(() => {
        const stored = localStorage.getItem(`${nameStorage ?? name}-table-settings`);
        return stored !== null ? JSON.parse(stored).columnSizing : {};
    });
    const [columnSizingInfo, setColumnSizingInfo] = React.useState(() => {
        const stored = localStorage.getItem(`${nameStorage ?? name}-table-settings`);
        return stored !== null ? JSON.parse(stored).columnSizingInfo : sizingDefault;
    });
    const [columnVisibility, setColumnVisibility] = React.useState(() => {
        const stored = localStorage.getItem(`${nameStorage ?? name}-table-settings`);
        return stored !== null ? JSON.parse(stored).columnVisibility : {};
    });
    const [expanded, setExpanded] = React.useState(() => {
        const stored = localStorage.getItem(`${nameStorage ?? name}-table-settings`);
        return stored !== null ? JSON.parse(stored).expanded : {};
    });
    const [globalFilter, setGlobalFilter] = React.useState(() => {
        const stored = localStorage.getItem(`${nameStorage ?? name}-table-settings`);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return stored !== null ? JSON.parse(stored).globalFilter : undefined;
    });
    const [grouping, setGrouping] = React.useState(() => {
        const stored = localStorage.getItem(`${nameStorage ?? name}-table-settings`);
        return stored !== null ? JSON.parse(stored).grouping : [];
    });
    const [pagination, setPagination] = React.useState(() => {
        const stored = localStorage.getItem(`${nameStorage ?? name}-table-settings`);
        return stored !== null ? JSON.parse(stored).pagination : { pageIndex: 0, pageSize: 10000 };
    });
    const [rowPinning, setRowPinning] = React.useState(() => {
        const stored = localStorage.getItem(`${nameStorage ?? name}-table-settings`);
        return stored !== null ? JSON.parse(stored).rowPinning : {};
    });
    const [rowSelection, setRowSelection] = React.useState(() => {
        const stored = localStorage.getItem(`${nameStorage ?? name}-table-settings`);
        return stored !== null ? JSON.parse(stored).rowSelection : {};
    });
    const [sorting, setSorting] = React.useState(() => {
        const stored = localStorage.getItem(`${nameStorage ?? name}-table-settings`);
        return stored !== null ? JSON.parse(stored).sorting : [];
    });
    const [dateFormat, setDateFormat] = React.useState(() => {
        const stored = localStorage.getItem(`${nameStorage ?? name}-table-settings`);
        return stored !== null ? JSON.parse(stored).dateFormat : 'normal';
    });
    const [exportHeaders, setExportHeaders] = React.useState(() => {
        const stored = localStorage.getItem(`${nameStorage ?? name}-table-settings`);
        return stored !== null ? JSON.parse(stored).exportHeaders : false;
    });
    const [groupData, setGroupData] = React.useState(() => {
        const stored = localStorage.getItem(`${nameStorage ?? name}-table-settings`);
        return stored !== null ? JSON.parse(stored).groupData : false;
    });
    const [tableStateName, setTableStateName] = React.useState(() => {
        const stored = localStorage.getItem(`${nameStorage ?? name}-table-settings`);
        return stored !== null ? JSON.parse(stored).name : undefined;
    });
    const [tableStateId, setTableStateId] = React.useState(() => {
        const stored = localStorage.getItem(`${nameStorage ?? name}-table-settings`);
        return stored !== null ? JSON.parse(stored).id : undefined;
    });
    const [showColumnPicker, setShowColumnPicker] = React.useState(false);
    const [totalDBRowCount, setTotalDBRowCount] = React.useState(-1);
    const [hasError, setError] = React.useState(false);
    const [initialPageParam, setInitialPageParam] = React.useState(startingPage);
    const [renderValue, setRenderValue] = React.useState({
        dateFormat,
        printDate: (date) => printDate(date, dateFormat),
        printNumber: (value) => Intl.NumberFormat().format(value),
    });
    /** SCROLL DETAILS START */
    const bodyRef = React.useRef(null);
    const headRef = React.useRef(null);
    const [scrollY, setScrollY] = React.useState();
    React.useEffect(() => {
        if (columnOrder.some((c) => c === null || c === undefined)) {
            setColumnOrder(columns.map((column) => column.id));
        }
    }, [columnOrder, columns]);
    React.useEffect(() => {
        const tableState = {
            columnFilters,
            columnOrder,
            columnPinning,
            columnSizing,
            columnSizingInfo,
            columnVisibility,
            dateFormat,
            expanded,
            exportHeaders,
            globalFilter,
            groupData,
            grouping,
            id: tableStateId,
            name: tableStateName,
            pagination,
            rowPinning,
            rowSelection,
            sorting,
            table: name,
        };
        // first stringify and then save, as otherwise the text becomes recursive (seems like a bug)
        const stringified = JSON.stringify(tableState);
        localStorage.setItem(`${nameStorage ?? name}-table-settings`, stringified);
    }, [columnFilters, columnOrder, columnPinning, columnSizing, columnSizingInfo,
        columnVisibility, dateFormat, expanded, exportHeaders, globalFilter, groupData, grouping, pagination, rowPinning, rowSelection,
        sorting, name, nameStorage, tableStateId, tableStateName]);
    const resetTableState = React.useCallback(() => {
        setColumnFilters([]);
        setColumnOrder([]);
        setColumnPinning({});
        setColumnSizing({});
        setColumnSizingInfo(sizingDefault);
        setColumnVisibility({});
        setDateFormat('normal');
        setExpanded({});
        setGlobalFilter(undefined);
        setGrouping([]);
        setPagination({ pageIndex: 0, pageSize: 10000 });
        setRowPinning({});
        setRowSelection({});
        setSorting([]);
        setTableStateId(undefined);
        setTableStateName(undefined);
        localStorage.removeItem(`${nameStorage ?? name}-table-settings`);
    }, [name, nameStorage]);
    const loadTableState = React.useCallback((state) => {
        setColumnFilters(state.columnFilters);
        setColumnOrder(state.columnOrder);
        setColumnPinning(state.columnPinning);
        setColumnSizing(state.columnSizing);
        setColumnSizingInfo(state.columnSizingInfo);
        setColumnVisibility(state.columnVisibility);
        setDateFormat(state.dateFormat);
        setExpanded(state.expanded);
        setExportHeaders(state.exportHeaders);
        setGlobalFilter(state.globalFilter);
        setGrouping(state.grouping);
        setPagination(state.pagination);
        setRowPinning(state.rowPinning);
        setRowSelection(state.rowSelection);
        setSorting(state.sorting);
        setTableStateId(state.id);
        setTableStateName(state.name);
    }, []);
    const saveTableState = React.useCallback((stateName, stateId) => {
        const tableState = {
            columnFilters,
            columnOrder,
            columnPinning,
            columnSizing,
            columnSizingInfo,
            columnVisibility,
            dateFormat,
            expanded,
            exportHeaders,
            globalFilter,
            groupData,
            grouping,
            id: stateId,
            name: stateName,
            pagination,
            rowPinning,
            rowSelection,
            sorting,
            table: name,
        };
        (async () => {
            const init = { body: JSON.stringify(tableState), headers: { 'Content-Type': 'application/json' }, method: 'POST' };
            const request = new ServerRequest('/syndicate/table', init);
            try {
                await download.noContentPlus(request, 'problems saving');
            }
            catch (error) {
                //
            }
        })();
    }, [columnFilters, columnOrder, columnPinning, columnSizing, columnSizingInfo, columnVisibility,
        dateFormat, download, expanded, exportHeaders, globalFilter, groupData, grouping, name, pagination,
        rowPinning, rowSelection, sorting]);
    const queryClient = useQueryClient();
    const fetchData = React.useCallback(async (params) => {
        const request = createDataRequest(urlPage, params);
        try {
            if (setLoading !== undefined) {
                setLoading(true);
            }
            const body = await download.jsonPlus(request, 'Error downloading');
            return dataTransform !== undefined ? dataTransform(body) : body.map((u) => u);
        }
        catch (error) {
            setError(true);
            throw error;
        }
        finally {
            if (setLoading !== undefined) {
                setLoading(false);
            }
        }
    }, [download, dataTransform, setLoading, urlPage]);
    React.useEffect(() => {
        if (urlTotal !== undefined) {
            (async () => {
                const url = new ServerURL(urlTotal);
                if (columnFilters.length > 0) {
                    url.searchParams.append('filterId', columnFilters.map((s) => s.id).join(','));
                    url.searchParams.append('filterValue', columnFilters.map((f) => (Array.isArray(f.value) ? f.value.join('|') : f.value)).join(','));
                }
                const request = new ServerRequest(url);
                try {
                    const body = await download.jsonPlus(request, 'Error downloading options');
                    setTotalDBRowCount(body);
                }
                catch (error) {
                    //
                }
            })();
        }
    }, [columnFilters, download, urlTotal]);
    const queryKey = React.useMemo(() => [name, sorting, columnFilters, initialPageParam], [name, sorting, columnFilters, initialPageParam]);
    React.useEffect(() => {
        if (setTableUpdateFn !== null && setTableUpdateFn !== undefined) {
            const myTable = (queryUpdateFn) => {
                console.log('Setting query data for %o with %o', queryKey, queryUpdateFn);
                queryClient.setQueryData(queryKey, queryUpdateFn);
            };
            setTableUpdateFn(() => myTable);
        }
    }, [queryClient, queryKey, setTableUpdateFn]);
    // react-query has an useInfiniteQuery hook just for this situation!
    const { data, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage, isFetchingNextPage, isFetchingPreviousPage, isLoading, } = useInfiniteQuery({
        queryKey, // adding sorting state as key causes table to reset and fetch from new beginning upon sort
        queryFn: ({ pageParam }) => (async () => {
            const start = pageParam * fetchSize;
            return fetchData({
                start, fetchSize, sorting, columnFilters,
            });
        })(),
        getNextPageParam: (_lastGroup, groups, lastPageParam) => {
            if (lastPageParam === Math.floor((totalDBRowCount - 1) / fetchSize)) {
                return undefined;
            }
            return lastPageParam + 1;
        },
        getPreviousPageParam: (_lastGroup, groups, lastPageParam) => {
            if (lastPageParam === 0) {
                return undefined;
            }
            return lastPageParam - 1;
        },
        initialPageParam,
    });
    // we must flatten the array of arrays from the useInfiniteQuery hook
    const flatData = React.useMemo(() => data?.pages.filter((p) => p !== null).flatMap((page) => page) ?? [], [data]);
    React.useEffect(() => {
        if (setSelection !== undefined) {
            const selectedData = Object.keys(rowSelection)
                .map((index) => {
                const indexNumber = Number.parseInt(index, 10);
                return flatData[indexNumber];
            });
            setSelection(selectedData);
        }
    }, [flatData, rowSelection, setSelection]);
    const extraColumnSizing = React.useCallback((s) => {
        setColumnSizing(s);
    }, []);
    const table = useReactTable({
        columnResizeMode: 'onChange',
        columns,
        data: flatData,
        getCoreRowModel: getCoreRowModel(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFilteredRowModel: getFilteredRowModel(),
        getRowCanExpand: () => (subComponent !== undefined),
        getSortedRowModel: getSortedRowModel(),
        manualFiltering: true,
        manualSorting: true,
        meta,
        onColumnFiltersChange: setColumnFilters,
        onColumnOrderChange: setColumnOrder,
        onColumnPinningChange: setColumnPinning,
        onColumnSizingChange: extraColumnSizing,
        onColumnSizingInfoChange: setColumnSizingInfo,
        onColumnVisibilityChange: setColumnVisibility,
        onExpandedChange: setExpanded,
        onGlobalFilterChange: setGlobalFilter,
        onGroupingChange: setGrouping,
        onPaginationChange: setPagination,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        state: {
            columnFilters,
            columnOrder,
            columnPinning,
            columnSizing,
            columnSizingInfo,
            columnVisibility,
            expanded,
            globalFilter,
            grouping,
            pagination,
            rowSelection,
            sorting,
        },
    });
    React.useLayoutEffect(() => {
        document.querySelectorAll('td').forEach((elem) => {
            if (elem.offsetWidth < elem.scrollWidth && elem.textContent !== null) {
                elem.setAttribute('title', elem.textContent);
            }
        });
    }, [isLoading]);
    /**
  * The values of the row selection can be changed programmatically, using React Table's
  * table.resetRowSelection. However, when the value of the checkbox is changed programatically,
  * the tick does NOT disappear. This is because the row doesn't change - only the result of
  * row.getIsSelected(), which is stored in separate variables in the background.
  * As the tick only disappears when clicking, we mimic the click for each row that is selected.
  */
    React.useEffect(() => {
        if (setResetRowSelection !== undefined) {
            setResetRowSelection(() => () => {
                if (bodyRef.current !== null) {
                    const rowElements = bodyRef.current.children;
                    table.getSelectedRowModel().rows.forEach((row) => {
                        const rowElement = rowElements.namedItem((`row-${row.index}`));
                        if (rowElement !== null) {
                            const checkbox = rowElement.getElementsByClassName('tk-checkbox__input')[0];
                            checkbox.click();
                        }
                    });
                }
            });
        }
    }, [setResetRowSelection, table]);
    /**
  * Set the function to reset the row expansion. Either specific rows are collapsed, or if an
  * empty string is used as parameter, then all expanded rows will be collapsed.
  */
    React.useEffect(() => {
        if (setResetRowExpansion !== undefined) {
            setResetRowExpansion(() => (items) => {
                table.getRowModel().rows.forEach((row) => {
                    if (items?.length === 0 && row.getIsExpanded()) {
                        row.toggleExpanded(false);
                    }
                    else if (items?.some((item) => item === row.original)) {
                        row.toggleExpanded(false);
                    }
                });
            });
        }
    }, [setResetRowExpansion, table]);
    const rowVirtualizer = useVirtualizer({
        count: totalDBRowCount,
        getScrollElement: () => tableContainerRef.current,
        getItemKey: (index) => startingPage * fetchSize + index,
        estimateSize: () => 34,
        indexAttribute: 'test',
        initialOffset: 500,
        overscan: 20,
        paddingStart: startingPage * fetchSize * 34,
    });
    React.useEffect(() => {
        if (totalDBRowCount > 0) {
            const body = tableContainerRef.current;
            //      console.log('Initial jump to page %o', startingPage);
            if (body !== null) {
                body.scroll(0, startingPage * fetchSize * 34);
            }
        }
    }, [totalDBRowCount, fetchSize]);
    const getParentNode = React.useCallback((element, tagName) => {
        if (element.tagName === tagName.toUpperCase()) {
            return element;
        }
        let parent = element;
        while (parent.parentElement !== null) {
            parent = parent.parentElement;
            if (parent.tagName === tagName.toUpperCase()) {
                return parent;
            }
        }
        return null;
    }, []);
    const keyDownEvent = React.useCallback((cell) => (event) => {
        // console.log('EVENT: %o,when focus is on %o', event, cell);
        // console.warn('tagname: %o, Has input: %o', cell.tagName, cell.querySelector('input') === null);
        if (cell.tagName !== 'INPUT' && cell.querySelector('input') === null) {
            const td = getParentNode(cell, 'TD');
            if (td !== null) {
                const tr = td.parentElement;
                if (tr !== null) {
                    const nodeList = tr.children;
                    if (event.key === 'ArrowDown') {
                        if (tr.nextElementSibling !== null) {
                        }
                        event.preventDefault();
                    }
                    if (event.key === 'ArrowUp') {
                        event.preventDefault();
                    }
                    if (event.key === 'ArrowLeft') {
                        event.preventDefault();
                    }
                    if (event.key === 'ArrowRight') {
                        event.preventDefault();
                    }
                }
            }
        }
    }, []);
    const onCellFocus = React.useCallback((event) => {
        console.log('Cell focussed: %o', event);
        const cell = event.target;
        cell.style.backgroundColor = 'green';
        document.addEventListener('keydown', keyDownEvent(cell));
    }, [keyDownEvent]);
    const onCellBlur = React.useCallback((event) => {
        console.log('Cell focussed: %o', event);
        const cell = event.target;
        cell.style.backgroundColor = '';
        document.removeEventListener('keydown', keyDownEvent(cell));
    }, [keyDownEvent]);
    React.useEffect(() => {
        document.onkeydown = () => {
        };
    }, []);
    React.useEffect(() => {
        document.onkeyup = () => {
            //      console.log('Virtual Items: %o', rowVirtualizer.getVirtualItems());
            //      console.log('Flat Data: %o, Data: %o', flatData, data);
            // rowVirtualizer.scrollToIndex(0);
        };
    }, [data, flatData, rowVirtualizer]);
    React.useEffect(() => {
        //    console.log('Checking if we need to fetch next or previous page:\nVirtual Items: %o\n', rowVirtualizer.getVirtualItems());
        const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();
        if (!lastItem) {
            return;
        }
        if (lastItem.index >= flatData.length - 1
            && hasNextPage
            && !isFetchingNextPage) {
            //      console.log('Getting next page');
            (async () => fetchNextPage())();
        }
        if (rowVirtualizer.getVirtualItems()[0].index === 0 && hasPreviousPage && !isFetchingPreviousPage) {
            //      console.log('There is a previous page but we are not getting it');
            //      (async () => fetchPreviousPage())();
        }
    }, [
        hasNextPage,
        hasPreviousPage,
        fetchNextPage,
        fetchPreviousPage,
        flatData.length,
        isFetchingNextPage,
        isFetchingPreviousPage,
        rowVirtualizer,
    ]);
    const onScroll = React.useCallback((event) => {
        const { currentTarget } = event;
        //  console.log('After Scroll ScrollTop becomes: %o, previous value: %o', currentTarget.scrollTop, scrollY);
        if (scrollY !== undefined) {
            if (Math.abs(scrollY - currentTarget.scrollTop) > 4 * fetchSize * 34) {
                //      console.log('Resetting initial page as scroll jumped too far (more than 4 pages): %o', rowVirtualizer.options);
                const { options } = rowVirtualizer;
                const newPage = Math.round(currentTarget.scrollTop / (34 * fetchSize));
                options.paddingStart = newPage * 34 * fetchSize;
                rowVirtualizer.setOptions(options);
                setInitialPageParam(newPage);
            }
            else {
                //     console.log('We scrolled, and are now checking if we need to get more data');
                const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();
                if (!lastItem) {
                    //          console.log('There were no virtual items so we ignore.');
                    return;
                }
                if (lastItem.index >= flatData.length - 1 && hasNextPage && !isFetchingNextPage) {
                    //        console.log('Fetching next page after scroll. Before fetch the first page is %o.\nThe VirtualItems are: %o', startingPage, rowVirtualizer.getVirtualItems());
                    (async () => fetchNextPage())();
                }
                // if (rowVirtualizer.getVirtualItems()[0].index === 0 && hasPreviousPage && !isFetchingPreviousPage && (scrollY > currentTarget.scrollTop)) {
                //   console.log('Fetching previous page after scroll. Before fetch the first page is %o.\nThe VirtualItems are: %o', firstPage, rowVirtualizer.getVirtualItems());
                //   (async () => fetchPreviousPage())();
                // }
            }
        }
        setScrollY(currentTarget.scrollTop);
    }, [
        hasNextPage,
        // hasPreviousPage,
        fetchNextPage,
        // fetchPreviousPage,
        fetchSize,
        flatData.length,
        isFetchingNextPage,
        // isFetchingPreviousPage,
        rowVirtualizer,
        scrollY,
    ]);
    React.useEffect(() => {
        setRenderValue({
            dateFormat,
            printDate: (date) => printDate(date, dateFormat),
            printNumber: (value) => Intl.NumberFormat().format(value),
        });
    }, [dateFormat]);
    /** SCROLL DETAILS END */
    return (
    // <DndProvider backend={HTML5Backend}>
    React.createElement(RenderingContext.Provider, { value: renderValue },
        showColumnPicker
            ? React.createElement(ColumnPicker, { onClose: () => setShowColumnPicker(false), table: table }) : null,
        React.createElement("div", { style: {
                display: 'flex', flexDirection: 'column', overflowY: 'auto', height: '100%',
            } },
            React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' } },
                React.createElement("div", { style: { display: 'flex' } }, actionElements),
                React.createElement("div", { style: { display: 'flex' } },
                    React.createElement(SettingsButton, { dateFormat: dateFormat, exportHeaders: exportHeaders, groupData: groupData, loadTableState: loadTableState, name: name, setDateFormat: setDateFormat, setExportHeaders: setExportHeaders, setGroupData: setGroupData, resetState: resetTableState, saveState: saveTableState, table: table }),
                    React.createElement(ExportButton, { exportHeaders: exportHeaders, groupData: groupData, table: table }))),
            React.createElement("div", { className: "co-table-container tk-theme-condensed", onScroll: onScroll, ref: tableContainerRef },
                React.createElement("div", { style: { height: `${rowVirtualizer.getTotalSize() + 60}px` } },
                    isLoading || table.getRowModel().rows.length === 0
                        ? (React.createElement("div", { style: { marginLeft: '200px', marginTop: '10px', position: 'fixed' } }, isLoading ? React.createElement(Loader, null) : React.createElement(Typography, { type: "h1" }, hasError ? errorMessage : emptyMessage)))
                        : null,
                    React.createElement("table", { className: `co-table ${meta?.className ?? ''}` },
                        React.createElement("thead", { ref: headRef }, table.getHeaderGroups().map((headerGroup) => (React.createElement("tr", { className: "co-header-row", key: headerGroup.id }, headerGroup.headers.map((header) => (React.createElement(DraggableColumnHeader, { header: header, isNumber: typeof table.getRowModel().flatRows[0]?.getValue(header.column.id) === 'number', key: header.id, table: table }))))))),
                        React.createElement("tbody", { ref: bodyRef, style: { position: 'relative' } },
                            !isLoading && rowVirtualizer.getVirtualItems().length > 0
                                ? (React.createElement("tr", { style: { height: `${rowVirtualizer.getVirtualItems()[0].start}px` } })) : null,
                            !isLoading
                                ? rowVirtualizer.getVirtualItems().map((virtualRow) => {
                                    const row = table.getRowModel().rows[virtualRow?.index];
                                    return row !== undefined ? (React.createElement(React.Fragment, { key: virtualRow?.index },
                                        React.createElement("tr", { className: `co-row${meta?.rowClassFn !== undefined ? meta.rowClassFn(row) : ''}`, key: row.id, id: `row-${row?.index}`, style: {
                                                height: `${virtualRow.size}px`,
                                                // transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
                                            } }, row.getVisibleCells().map((cell) => (React.createElement("td", { className: `co-table-cell ${cell.column.columnDef.meta?.className ?? ''}`, onBlur: onCellBlur, onFocus: onCellFocus, key: cell.id, style: cell.column.columnDef.meta?.style }, flexRender(cell.column.columnDef.cell, cell.getContext()))))),
                                        row.getIsExpanded() && typeof subComponent === 'function' ? subComponent(row, table) : null)) : React.createElement("tr", { className: "co-row", key: virtualRow?.index },
                                        React.createElement("td", null, "LOADING..."));
                                }) : null)))))));
};
export default InfiniteTable;
