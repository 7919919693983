import * as React from 'react';
import ConnectivityAlert from 'pages/ConnectivityAlert';
import CookieConsent from './CookieConsent';
import Footer from './footer/Footer';
import RightPanel from './right-panel/RightPanel';
import Cards from './cards/Cards';
import './front-page.scss';
import LeftPanel from './left-panel/LeftPanel';
import BottomPanel from './bottom-panel/BottomPanel';
const FrontPage = () => (React.createElement("div", { id: "front-page" },
    React.createElement(ConnectivityAlert, null),
    React.createElement("div", { id: "top-card" },
        React.createElement(LeftPanel, null),
        React.createElement(RightPanel, null)),
    React.createElement(Cards, null),
    React.createElement(BottomPanel, null),
    React.createElement(Footer, null),
    !window.jwt ? React.createElement(CookieConsent, null) : ''));
export default FrontPage;
