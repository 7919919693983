import * as React from 'react';
import { Navigate } from 'react-router-dom';
import UserInfo from 'models/UserInfo';
import { userinfoEndpoint } from './pkceAuthConfig';
const CallbackHandler = ({ authenticated, setAuth, setUserInfo, userManager, }) => {
    console.log('Start CallbackHandler. Authenticated: %o', authenticated);
    const [isLoading, setLoading] = React.useState(true);
    React.useEffect(() => {
        (async () => {
            console.log('Started CallbackHandler. Setting user using SigninRedirectCallback');
            try {
                const newUser = await userManager.signinCallback();
                console.log('New user coming back: %o', newUser);
                if (newUser && !(newUser.state instanceof UserInfo)) {
                    const response = await fetch(userinfoEndpoint, {
                        headers: {
                            Authorization: `Bearer ${newUser.access_token}`,
                        },
                    });
                    const userInfoNew = await response.json();
                    const userInfo = new UserInfo(userInfoNew);
                    console.warn('UserInfo coming back: %o', userInfoNew);
                    await userManager.storeUser(Object.assign(newUser, { state: userInfo }));
                    setUserInfo(userInfo);
                    setAuth(true);
                    console.log('Authenticated set to true. User obtained is: %o', newUser);
                }
                else if (newUser) {
                    console.warn('We already have user info: %o', newUser.state);
                }
                else {
                    console.warn('No new user came back');
                }
            }
            catch (error) {
                console.error('Error when trying to get access token in the CallbackHandler.  Error %o', error);
                await userManager.removeUser();
                setAuth(false);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [setAuth, setUserInfo, userManager]);
    console.log('End CallbackHandler - Authenticated: %o. Is Loading: %o', authenticated, isLoading);
    if (isLoading) {
        return (React.createElement("div", null, "Loading"));
    }
    return (React.createElement(Navigate, { to: "/" }));
};
export default CallbackHandler;
