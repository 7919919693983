import * as React from 'react';
import * as ReactDom from 'react-dom/client';
import { handleOutline } from '../modules/core/utils/helpers/help-functions';
import MyRoutes from '../modules/core/pages/routes';
import '../modules/core/public/sass/main.scss';
import initServiceWorker from './init-service-worker';
handleOutline(); // Accessibility
const appWrapper = () => {
    let theme = { themeV2: { name: 'light', size: 'normal' } };
    try {
        const storedTheme = localStorage.getItem('theme');
        if (storedTheme)
            theme = JSON.parse(storedTheme);
    }
    catch (error) {
        // No access to localStorage so no theme
    }
    const rootElement = document.getElementById('root');
    if (rootElement !== null) {
        const root = ReactDom.createRoot(rootElement);
        root.render((React.createElement(MyRoutes, { theme: theme })));
    }
};
appWrapper();
window.addEventListener('load', initServiceWorker, false);
