import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import ViewContext from 'contexts/ViewContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import CrudService from 'services/CrudService';
import ReleaseTable from './ReleaseTable';
import ReleaseForm from './ReleaseForm';
import Release from '../models/Release';
const Releases = () => {
    const { download } = React.useContext(DownloadContext);
    const { view, setView } = React.useContext(ViewContext);
    const [details, setDetails] = React.useState();
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const [releases, setReleases] = React.useState();
    const [source, setSource] = React.useState();
    const [sourceOptions, setSourceOptions] = React.useState();
    const service = React.useMemo(() => new CrudService({ download, name: 'Release', path: '/bb/release' }), [download]);
    React.useEffect(() => {
        (async () => {
            const request = new ServerRequest('/bb/release/options');
            const body = await download.jsonPlus(request, 'Error getting source options');
            setSourceOptions(body);
        })();
    }, [download]);
    React.useEffect(() => {
        if (source !== undefined) {
            (async () => {
                const url = new ServerURL('/bb/release');
                url.searchParams.append('source', source);
                const request = new ServerRequest(url);
                try {
                    const body = await download.jsonPlus(request, 'unable to download');
                    setReleases(body);
                }
                catch (error) {
                    setError(true);
                }
                setLoading(false);
            })();
        }
    }, [download, source]);
    const create = React.useCallback(() => {
        setDetails(new Release());
        setView('FORM');
    }, [setView]);
    const deleteFn = React.useCallback(({ row: { original: release } }) => {
        try {
            (async () => {
                await service.delete(release);
                const filteredReleases = releases?.filter((u) => u.id !== release.id);
                setReleases(filteredReleases);
            })();
        }
        catch (error) {
            //
        }
    }, [releases, service]);
    const edit = React.useCallback(({ row: { original: release } }) => {
        setDetails(release);
        setView('FORM');
    }, [setView]);
    const save = React.useCallback((data) => async () => {
        try {
            const body = await (data.id ? service.updatePlus(data) : service.createPlus(data));
            const updatedRelease = Object.assign(new Release(), body);
            const updatedReleases = CrudService.updateData(releases ?? [], updatedRelease);
            setReleases(updatedReleases);
            setView('TABLE');
        }
        catch (error) {
            //
        }
    }, [releases, service, setView]);
    switch (view) {
        case 'FORM':
            return (React.createElement(ReleaseForm, { release: details, save: save, sourceOptions: sourceOptions ?? [] }));
        default:
            return (React.createElement(ReleaseTable, { create: create, deleteFn: deleteFn, edit: edit, hasError: hasError, isLoading: isLoading, releases: releases ?? [], setSource: setSource, sourceOptions: sourceOptions ?? [] }));
    }
};
export default Releases;
