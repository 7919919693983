export const realDateSort = (date1, date2) => {
    if (date1 === 'Perpetual' && date2 === 'Perpetual') {
        return 0;
    }
    if (date1 === 'Perpetual' && date2 !== 'Perpetual') {
        return 1;
    }
    if (date1 !== 'Perpetual' && date2 === 'Perpetual') {
        return -1;
    }
    if (date1 === '' && date2 === '') {
        return 0;
    }
    if (date1 === '' && date2 !== '') {
        return -1;
    }
    if (date1 !== '' && date2 === '') {
        return 1;
    }
    if (date1 > date2) {
        return 1;
    }
    if (date2 > date1) {
        return -1;
    }
    return 0;
};
export const realNumberSort = (number1, number2) => {
    if (number1 > number2) {
        return 1;
    }
    if (number2 > number1) {
        return -1;
    }
    return 0;
};
export const printEquivalentAmount = (currency, amount, currencies) => {
    let baseCurrency = null;
    try {
        baseCurrency = localStorage.getItem('base-currency');
    }
    catch (error) {
        //
    }
    const baseRate = baseCurrency ? currencies.get(baseCurrency) ?? 1 : 1;
    const fxRate = currencies.get(currency);
    return fxRate !== undefined ? new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2 }).format((amount * baseRate) / fxRate) : '-';
};
export const printAddress = (address) => address.map((f) => (f.personal ? `${f.personal} <${f.value}>` : f.value)).join(',');
export const printTimestamp = (timestamp) => new Date(timestamp * 1000).toLocaleString();
export const printTimestampString = (timestamp) => new Date(timestamp).toLocaleString();
export const optionSort = (o1, o2) => {
    if (o1.label > o2.label) {
        return 1;
    }
    if (o1.label < o2.label) {
        return -1;
    }
    return 0;
};
