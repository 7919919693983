import ActionButton from 'components/custom-table/action/ActionButton';
import InfiniteTable from 'components/custom-table/InfiniteTable';
import * as React from 'react';
import { printAddress, printTimestampString } from 'utils/table';
import ServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import ViewContext from 'contexts/ViewContext';
import DocumentTextModal from './DocumentTextModal';
const EmailTable = ({ actionElements, setEmailId, setMessageId, setShowModal, }) => {
    const { download } = React.useContext(DownloadContext);
    const { setView } = React.useContext(ViewContext);
    const [content, setContent] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [show, setShow] = React.useState(false);
    const clickView = React.useCallback(({ row }) => {
        setMessageId(row.original.messageId);
        setShowModal(true);
    }, [setMessageId, setShowModal]);
    const clickManager = React.useCallback(({ row }) => {
        setEmailId(row.original.messageId);
        localStorage.setItem('emailId', row.original.messageId);
        setView('MANAGER');
    }, [setEmailId, setView]);
    const clickFile = React.useCallback((index) => ({ row }) => {
        const file = row.original?.files[index];
        // Create a new link
        const anchor = document.createElement('a');
        const url = new ServerURL('/syndicate/emails/attachment');
        url.searchParams.append('id', file.id);
        anchor.href = url.toString();
        anchor.download = file.name;
        // Append to the DOM
        document.body.appendChild(anchor);
        // Trigger `click` event
        anchor.click();
        // Remove element from DOM
        document.body.removeChild(anchor);
    }, []);
    const openText = React.useCallback((id) => ({ row }) => {
        const file = row.original?.files.find((f) => f.id === id);
        if (file !== undefined) {
            (async () => {
                const url = new ServerURL('/syndicate/emails/text');
                url.searchParams.append('id', id);
                const request = new ServerRequest(url);
                const body = await download.textPlus(request, `Unable to find attachment with id ${id}`);
                setContent(body);
                setTitle(file.name);
                setShow(true);
            })();
        }
    }, [download]);
    const actionFunction = React.useCallback((context) => {
        const actionsMenu = [
            { callback: clickView, label: 'View' },
            { callback: clickManager, label: 'Manage' },
        ];
        context.row.original?.files?.forEach((f, index) => actionsMenu.push({
            callback: clickFile(index), label: f.name,
        }, {
            callback: openText(f.id),
            label: 'as text',
        }));
        return (React.createElement(ActionButton, { context: context, actionsMenu: actionsMenu }));
    }, [clickFile, clickManager, clickView, openText]);
    const sortTimestampFn = (rowA, rowB) => new Date(rowA.original.timestamp).getTime() - new Date(rowB.original.timestamp).getTime();
    const columns = React.useMemo(() => [
        {
            cell: actionFunction,
            id: 'actions',
            size: 28,
        },
        {
            accessorFn: (t) => t?.subject ?? 'none',
            enableColumnFilter: true,
            header: 'Subject',
            id: 'subject',
            size: 9999,
        },
        {
            accessorFn: (t) => (t !== undefined ? printAddress(t.from) : 'NONE'), enableColumnFilter: true, header: 'From', id: 'from', size: 9999,
        },
        {
            accessorFn: (t) => (t !== undefined ? t?.files?.length : 'NONE'), enableColumnFilter: false, header: '# attachments', id: 'noAttachments', size: 28,
        },
        {
            accessorFn: (t) => printTimestampString(t?.timestamp), enableColumnFilter: false, header: 'Last Update', id: 'timestamp', size: 200, sortingFn: sortTimestampFn,
        },
    ], [actionFunction]);
    //
    return (React.createElement(React.Fragment, null,
        React.createElement(DocumentTextModal, { content: content, onClose: () => setShow(false), show: show, title: title }),
        React.createElement(InfiniteTable, { actionElements: actionElements, columns: columns, errorMessage: "", emptyMessage: "", fetchSize: 50, name: "email", urlPage: "/syndicate/emails/all", urlTotal: "/syndicate/emails/total" })));
};
export default EmailTable;
