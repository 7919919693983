import * as React from 'react';
import { Card } from '@symphony-ui/uitoolkit-components';
import AppViewContext from 'contexts/AppViewContext';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import { checkEditable } from 'components/metadata/utils';
import CardHeaderTable from 'components/card-header/CardHeaderTable';
import CustomTable from 'components/custom-table/CustomTable';
import ActionButton from 'components/custom-table/action/ActionButton';
const OrderTable = ({ create, deleteFn, edit, hasError, isLoading, orders, }) => {
    const { download } = React.useContext(DownloadContext);
    const { userManager } = React.useContext(AppViewContext);
    const [newIssues, setNewIssues] = React.useState([]);
    const actionCell = React.useCallback((context) => {
        const isEditable = userManager != null ? checkEditable(context.row.original, userManager) : false;
        const actionsMenu = [
            { callback: edit, label: isEditable ? 'Edit' : 'View', type: 'info' },
            { callback: deleteFn, label: 'Delete', type: 'error' },
        ];
        return (React.createElement(ActionButton, { actionsMenu: actionsMenu, context: context }));
    }, [deleteFn, edit, userManager]);
    const columns = React.useMemo(() => [
        { cell: actionCell, id: 'actions', size: 20 },
        {
            accessorFn: (t) => t.original.investorName,
            header: 'Investor',
        },
        {
            accessorFn: (t) => newIssues.find((n) => n.id === t.original.newIssueId),
            header: 'New Issue',
        },
        {
            accessorFn: (t) => new Intl.NumberFormat().format(t.original.amount),
            header: 'Amount',
        },
        {
            accessorFn: (t) => new Date(t.original.createdDate).toLocaleString(),
            header: 'Created',
        },
        {
            accessorFn: (t) => new Date(t.original.lastModifiedDate).toLocaleString(),
            header: 'Last Modified',
        },
    ], [actionCell, newIssues]);
    React.useEffect(() => {
        (async () => {
            const request = new ServerRequest('/synd/new-issues/map');
            try {
                const foundNewIssues = await download.jsonPlus(request, 'Error downloading new issues from server.');
                setNewIssues(foundNewIssues);
            }
            catch (error) {
                //
            }
        })();
    }, [download]);
    return (React.createElement(Card, null,
        React.createElement(CardHeaderTable, { create: create, disabled: hasError, title: "Orders" }),
        React.createElement(CustomTable, { columns: columns, data: orders, emptyMessage: "You have no Orders yet", errorMessage: "Error downloading Orders", hasError: hasError, isLoading: isLoading, name: "order" })));
};
export default OrderTable;
