import * as React from 'react';
const Popup = ({ children, close, style, }) => {
    const popupRef = React.useRef(null);
    const closeOutsidePopup = React.useCallback((event) => {
        const testHasParent = (eventTarget, element) => {
            let parent = eventTarget;
            while (parent !== element && (parent.parentElement !== null)) {
                parent = parent.parentElement;
            }
            return parent === element;
        };
        const testContains = (element) => {
            const rect = element.getBoundingClientRect();
            return (event.isTrusted && event.clientX > rect.left && event.clientX < rect.right
                && event.clientY > rect.top && event.clientY < rect.bottom) || (event.target !== null && testHasParent(event.target, element));
        };
        if (popupRef.current !== null) {
            if (!testContains(popupRef.current)) {
                document.removeEventListener('click', closeOutsidePopup);
                close();
            }
        }
    }, [close]);
    React.useEffect(() => {
        if (popupRef.current !== null) {
            setTimeout(() => document.addEventListener('click', closeOutsidePopup), 100);
        }
    }, [closeOutsidePopup]);
    React.useEffect(() => {
        const config = { attributes: true, childList: true, subtree: true };
        const callback = (mutationList) => {
            mutationList.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    mutation.removedNodes.forEach((n) => {
                        if (n.nodeType === Node.ELEMENT_NODE) {
                            const element = n;
                            if (element.tagName === 'DIV' && element.classList.contains('co-popup')) {
                                document.removeEventListener('click', closeOutsidePopup);
                            }
                        }
                    });
                }
            });
        };
        const observer = new MutationObserver(callback);
        const targetNode = document.body;
        if (targetNode !== null) {
            // Start observing the target node for configured mutations
            observer.observe(targetNode, config);
        }
    });
    return (React.createElement("div", { ref: popupRef, className: "co-popup", style: style }, children));
};
export default Popup;
