import * as React from 'react';
import { Loader } from '@symphony-ui/uitoolkit-components';
import { UserManager, WebStorageStateStore, } from 'oidc-client-ts';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import UserInfo from 'models/UserInfo';
import { APP_URL } from 'utils/system/envs-constants';
import FrontPage from '../../../site/pages/main-page/FrontPage';
import LocationRouter from './location-router';
import AppViewContext from '../contexts/AppViewContext';
import pkceAuthConfig, { userinfoEndpoint } from './pkceAuthConfig';
import '@symphony-ui/uitoolkit-styles/uitoolkit.scss';
import './generic.scss';
import CallbackHandler from './CallbackHandler';
import CallbackSilentHandler from './CallbackSilentHandler';
import LogoutHandler from './LogoutHandler';
const MyRoutes = ({ jwt, theme }) => {
    const [appView, setAppView] = React.useState(null);
    const [offline, setOffline] = React.useState(false);
    const [imitate, setImitate] = React.useState(false);
    const [authenticated, setAuthenticated] = React.useState(null);
    const [userInfo, setUserInfo] = React.useState(null);
    const userManager = React.useMemo(() => {
        const newStore = new WebStorageStateStore({ store: window.localStorage });
        const settings = { userStore: newStore, ...pkceAuthConfig, silent_redirect_uri: `${APP_URL}/silent` };
        return new UserManager(settings);
    }, []);
    const changeTheme = React.useCallback((newTheme) => {
        const { themeV2 } = newTheme;
        document.body.className = `symphony-external-app ${themeV2.name} ${themeV2.size} co-body`;
        window.themeColor = themeV2.name.toUpperCase();
        window.themeSize = themeV2.size;
    }, []);
    const handleLoginRequest = React.useCallback(async () => {
        await userManager.signinRedirect({ state: '6c2a55953db34a86b876e9e40ac2a202' });
    }, [userManager]);
    console.log('Authenticated at start of Routes: %o', authenticated);
    // React.useEffect(() => {
    //   (async () => {
    //     try {
    //       const myUser = await userManager.getUser();
    //       console.log('Checking in App for User: %o', myUser);
    //       if (myUser) {
    //         const response = await fetch(userinfoEndpoint, {
    //           headers: {
    //             Authorization: `Bearer ${myUser.access_token}`,
    //           },
    //         });
    //         if (response.status === 401) {
    //           //  await userManager.signinCallback();
    //           //            setAuthenticated(false);
    //           // await userManager.removeUser();
    //         } else {
    //           const userInfoNew = await response.json() as UserInfoResponse;
    //           console.warn('UserInfo coming back: %o', userInfoNew);
    //           await userManager.storeUser(Object.assign(myUser, { state: new UserInfo(userInfoNew) }));
    //           if ('error' in userInfoNew) {
    //             setAuthenticated(false);
    //             console.error('AN ERROR CAME BACK');
    //           } else {
    //             setAuthenticated(true);
    //             setUserInfo(new UserInfo(userInfoNew));
    //             console.log('We just set the userinfo');
    //           }
    //         }
    //       } else {
    //         console.log('Setting authenticated to false as user is null');
    //         setAuthenticated(false);
    //       }
    //     } catch (error) {
    //       console.error('Error in App with set up %o', error);
    //     }
    //   })();
    // }, [userManager]);
    React.useEffect(() => {
        (async () => {
            let myUser = null;
            try {
                myUser = await userManager.getUser();
            }
            catch (error) {
                console.error('Error getting User using the UserManger', error);
            }
            console.log('Checking in App for User: %o', myUser);
            if (myUser !== null) {
                let response;
                try {
                    response = await fetch(userinfoEndpoint, {
                        headers: {
                            Authorization: `Bearer ${myUser.access_token}`,
                        },
                    });
                }
                catch (error) {
                    console.error('Error getting the UserInfo from the Auth server', error);
                }
                if (response && response.status !== 401) {
                    let userInfoNew;
                    try {
                        userInfoNew = await response.json();
                    }
                    catch (error) {
                        console.error('Unable to get new user Info', error);
                    }
                    if (userInfoNew) {
                        console.warn('UserInfo coming back: %o', userInfoNew);
                        await userManager.storeUser(Object.assign(myUser, { state: new UserInfo(userInfoNew) }));
                        setUserInfo(new UserInfo(userInfoNew));
                    }
                    setAuthenticated(true);
                }
                else {
                    try {
                        await userManager.removeUser();
                    }
                    catch (error) {
                        console.error('Unable to remove user after 401 error', error);
                    }
                    finally {
                        setAuthenticated(false);
                    }
                }
            }
            else {
                console.log('Setting authenticated to false as user is null');
                setAuthenticated(false);
            }
        })();
    }, [userManager]);
    //   if (appView !== null) {
    //     try {
    //       localStorage.setItem('app', appView.toString());
    //     } catch (error) {
    //       // unable to store settings
    //     }
    //   }
    // }, [appView]);
    React.useEffect(() => {
        changeTheme(theme);
    }, [changeTheme, theme]);
    // React.useEffect(() => {
    //   if (user === undefined) {
    //     const savedUser = localStorage.getItem('user');
    //     if (savedUser !== null) {
    //       const authArray = JSON.parse(savedUser) as CoreUserResponse;
    //       setUser(new CoreUser(authArray));
    //       const storedView: string | null = localStorage.getItem('app');
    //       setAppView(storedView !== null ? appFrom(storedView) : null);
    //     } else {
    //       setUser(null);
    //       setAppView(null);
    //     }
    //   } else {
    //     const storedView: string | null = localStorage.getItem('app');
    //     console.log('User is %o and stroed view is %o', user, storedView);
    //     setAppView(storedView !== null ? appFrom(storedView) : null);
    //   }
    // }, [user]);
    React.useEffect(() => {
        if (window.SYMPHONY != null) {
            (async () => {
                const uiService = await window.SYMPHONY.services.subscribe('ui');
                if (typeof uiService.listen === 'function') {
                    uiService.listen('themeChangeV2', () => {
                        (async () => {
                            try {
                                const newTheme = await window.SYMPHONY.remote.hello();
                                changeTheme(newTheme);
                            }
                            catch (error) {
                                // none
                            }
                        })();
                    });
                }
            })();
        }
    }, [changeTheme]);
    const appViewValue = React.useMemo(() => ({
        appView: appView ?? null,
        imitate: imitate ?? false,
        jwt,
        offline,
        setAppView,
        setImitate,
        setOffline,
        userInfo,
        userManager,
    }), [appView, imitate, jwt, offline, setAppView, setImitate, setOffline, userInfo, userManager]);
    if (authenticated === null) {
        return (React.createElement("div", { style: { height: 'calc(100vh - 1rem)' } },
            React.createElement(Loader, { className: "co-loader", variant: "primary" })));
    }
    console.log('MAIN: App view value: %o', appViewValue);
    return (React.createElement(BrowserRouter, null,
        React.createElement(Routes, null,
            React.createElement(Route, { path: "/", element: (React.createElement(AppViewContext.Provider, { value: appViewValue }, authenticated ? React.createElement(LocationRouter, null)
                    : React.createElement(FrontPage, { handleLoginRequest: handleLoginRequest }))) }),
            React.createElement(Route, { path: "/callback", element: (React.createElement(CallbackHandler, { authenticated: authenticated, setAuth: setAuthenticated, setUserInfo: setUserInfo, userManager: userManager })) }),
            React.createElement(Route, { path: "/sign-out", element: (React.createElement(LogoutHandler, { authenticated: authenticated, setAuth: setAuthenticated, setUserInfo: setUserInfo, userManager: userManager })) }),
            React.createElement(Route, { path: "/silent", element: (React.createElement(CallbackSilentHandler, { authenticated: authenticated, setAuth: setAuthenticated, userManager: userManager })) }))));
};
export default MyRoutes;
