import * as React from 'react';
import { Dropdown, Switch, TextField, Validation, } from '@symphony-ui/uitoolkit-components';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';
import DownloadContext from 'contexts/DownloadContext';
import { passwordRequirements } from 'utils/passwords';
import ServerRequest from 'models/ServerRequest';
import { errorMessage } from 'utils/initialValidation';
import CustomValidators from 'components/CustomValidators';
import FormWrapper from 'components/form-wrapper/FormWrapper';
import User from 'models/User';
import SelectionStatus from '@symphony-ui/uitoolkit-components/components/selection/SelectionStatus';
const UserForm = ({ save, user = new User({}) }) => {
    const { download } = React.useContext(DownloadContext);
    const [data, setData] = React.useState(user);
    const [errorState, setErrorState] = React.useState({});
    const [isUser, setUser] = React.useState(user?.username !== undefined);
    const [options, setOptions] = React.useState({ authorities: [] });
    const [validators, setValidators] = React.useState({});
    const passwordInfo = React.useMemo(() => passwordRequirements.map((t) => `* ${t}`).join(''), []);
    React.useEffect(() => {
        const validatorsNew = {
            displayName: Validators.Required,
            emailAddress: [Validators.Email, Validators.Required],
            firstName: Validators.Required,
            lastName: Validators.Required,
        };
        if (isUser) {
            validatorsNew.username = [Validators.Required, Validators.MaxLength(40)];
            validatorsNew.password = CustomValidators.Password;
        }
        setValidators(validatorsNew);
    }, [isUser]);
    React.useEffect(() => {
        (async () => {
            const request = new ServerRequest('/admin/users/options');
            try {
                const body = await download.jsonPlus(request, 'Error downloading authorities.');
                const option = { authorities: body };
                setOptions(option);
            }
            catch (error) {
                //
            }
        })();
    }, [download, user]);
    React.useEffect(() => {
        document.querySelectorAll('input[type="password"]').forEach((element) => {
            const passwordElement = element;
            passwordElement.onfocus = () => {
                const updatedData = data.updateField('password', undefined);
                setData(updatedData);
                passwordElement.onfocus = null;
            };
        });
    }, [data]);
    const onChangeInput = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const updatedData = data.updateField(name, value);
        setData(updatedData);
    }, [data]);
    const onChangeAuthorities = React.useCallback((event) => {
        const updatedData = data.updateAuthorities(event.target.value);
        setData(updatedData);
    }, [data]);
    const onValidationChanged = React.useCallback((name) => (isValid) => {
        const newErrorState = { ...errorState };
        newErrorState[name] = isValid;
        setErrorState(newErrorState);
    }, [errorState]);
    const getAuthoritiesValue = () => {
        if (data.authorityMap === undefined) {
            return [];
        }
        return Object.entries(data.authorityMap).flatMap(([key, values]) => values.map((v) => ({
            data: key,
            label: `${key.replace('cmo.', '')}: ${v.replace('ROLE_', '')}`,
            value: v,
        })));
    };
    return (React.createElement(FormWrapper, { data: data, errorState: errorState, name: "User", save: save(isUser), setErrorState: setErrorState, validators: validators },
        React.createElement("div", { className: "base-form" },
            React.createElement(Validation, { validator: validators.firstName, errorMessage: errorMessage, onValidationChanged: onValidationChanged('firstName') },
                React.createElement(TextField, { label: "First Name", onChange: onChangeInput('firstName'), showRequired: true, value: data.firstName })),
            React.createElement(Validation, { validator: validators.lastName, errorMessage: errorMessage, onValidationChanged: onValidationChanged('lastName') },
                React.createElement(TextField, { label: "Last Name", onChange: onChangeInput('lastName'), showRequired: true, value: data.lastName })),
            React.createElement(Validation, { validator: validators.displayName, errorMessage: errorMessage, onValidationChanged: onValidationChanged('displayName') },
                React.createElement(TextField, { label: "Display Name", onChange: onChangeInput('displayName'), showRequired: true, value: data.displayName })),
            React.createElement(Validation, { validator: validators.emailAddress, errorMessage: errorMessage, onValidationChanged: onValidationChanged('emailAddress') },
                React.createElement(TextField, { label: "Email Address", onChange: onChangeInput('emailAddress'), showRequired: true, value: data.emailAddress })),
            React.createElement(TextField, { label: "Title", onChange: onChangeInput('title'), value: data.title }),
            React.createElement(TextField, { label: "Job Function", onChange: onChangeInput('jobFunction'), value: data.jobFunction }),
            React.createElement(TextField, { label: "Company", onChange: onChangeInput('company'), value: data.company }),
            React.createElement(TextField, { label: "Location", onChange: onChangeInput('location'), value: data.location }),
            React.createElement(TextField, { label: "Department", onChange: onChangeInput('department'), value: data.department }),
            React.createElement(TextField, { label: "Division", onChange: onChangeInput('division'), value: data.division }),
            React.createElement(TextField, { label: "Mobile Phone Number", onChange: onChangeInput('mobilePhoneNumber'), value: data.mobilePhoneNumber }),
            React.createElement(TextField, { label: "Work Phone Number", onChange: onChangeInput('workPhoneNumber'), value: data.workPhoneNumber })),
        React.createElement("div", { className: "form-buttons" },
            React.createElement(Switch, { label: "User", name: "switch", onChange: () => setUser(!isUser), status: isUser ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: isUser ? 'true' : 'false' })),
        isUser
            ? (React.createElement("div", { className: "base-form" },
                React.createElement(Validation, { validator: validators.username, errorMessage: errorMessage, onValidationChanged: onValidationChanged('username') },
                    React.createElement(TextField, { label: "User Name", onChange: onChangeInput('username'), showRequired: true, value: data.username })),
                React.createElement(Validation, { validator: validators.password, errorMessage: errorMessage, onValidationChanged: onValidationChanged('password') },
                    React.createElement(TextField, { isMasked: true, label: "Password", onChange: onChangeInput('password'), showRequired: true, tooltip: passwordInfo, value: data.password })),
                React.createElement(Dropdown, { isMultiSelect: true, label: "Authorities", name: "authorities", onChange: onChangeAuthorities, options: options.authorities, value: getAuthoritiesValue() }))) : React.createElement("div", null)));
};
export default UserForm;
