import * as React from 'react';
import { Loader } from '@symphony-ui/uitoolkit-components';
import { UserManager, WebStorageStateStore, } from 'oidc-client-ts';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { APP_URL } from 'utils/system/envs-constants';
import FrontPage from '../../../site/pages/main-page/FrontPage';
import LocationRouter from './location-router';
import AppViewContext from '../contexts/AppViewContext';
import pkceAuthConfig from './pkceAuthConfig';
import '@symphony-ui/uitoolkit-styles/uitoolkit.scss';
import './generic.scss';
import CallbackHandler from '../handlers/CallbackHandler';
import SilentCallbackHandler from '../handlers/CallbackSilentHandler';
import LogoutHandler from '../handlers/LogoutHandler';
const MyRoutes = ({ jwt, theme }) => {
    const [appView, setAppView] = React.useState(null);
    const [offline, setOffline] = React.useState(false);
    const [imitate, setImitate] = React.useState(false);
    const [authenticated, setAuthenticated] = React.useState(null);
    const userManager = React.useMemo(() => {
        const newStore = new WebStorageStateStore({ store: window.localStorage });
        const settings = { userStore: newStore, ...pkceAuthConfig, silent_redirect_uri: `${APP_URL}/silent` };
        return new UserManager(settings);
    }, []);
    const changeTheme = React.useCallback((newTheme) => {
        const { themeV2 } = newTheme;
        document.body.className = `symphony-external-app ${themeV2.name} ${themeV2.size} co-body`;
        window.themeColor = themeV2.name.toUpperCase();
        window.themeSize = themeV2.size;
    }, []);
    const handleLoginRequest = React.useCallback(async () => {
        await userManager.signinRedirect({ state: '6c2a55953db34a86b876e9e40ac2a202' });
    }, [userManager]);
    console.log('Authenticated at start of Routes: %o', authenticated);
    React.useEffect(() => {
        (async () => {
            let myUser = null;
            try {
                myUser = await userManager.getUser();
            }
            catch (error) {
                console.error('Error getting User using the UserManger', error);
            }
            finally {
                console.log('User from UserManager is %o', myUser);
                console.log('Setting authenticated to %o', myUser !== null);
                setAuthenticated(myUser !== null);
            }
        })();
    }, [userManager]);
    React.useEffect(() => {
        changeTheme(theme);
    }, [changeTheme, theme]);
    React.useEffect(() => {
        if (window.SYMPHONY != null) {
            (async () => {
                const uiService = await window.SYMPHONY.services.subscribe('ui');
                if (typeof uiService.listen === 'function') {
                    uiService.listen('themeChangeV2', () => {
                        (async () => {
                            try {
                                const newTheme = await window.SYMPHONY.remote.hello();
                                changeTheme(newTheme);
                            }
                            catch (error) {
                                // none
                            }
                        })();
                    });
                }
            })();
        }
    }, [changeTheme]);
    const appViewValue = React.useMemo(() => ({
        appView: appView ?? null,
        imitate: imitate ?? false,
        jwt,
        offline,
        setAppView,
        setImitate,
        setOffline,
        userManager,
    }), [appView, imitate, jwt, offline, setAppView, setImitate, setOffline, userManager]);
    if (authenticated === null) {
        return (React.createElement("div", { style: { height: 'calc(100vh - 1rem)' } },
            React.createElement(Loader, { className: "co-loader", variant: "primary" })));
    }
    console.log('MAIN: App view value: %o', appViewValue);
    return (React.createElement(BrowserRouter, null,
        React.createElement(Routes, null,
            React.createElement(Route, { path: "/", element: (React.createElement(AppViewContext.Provider, { value: appViewValue }, authenticated ? React.createElement(LocationRouter, null)
                    : React.createElement(FrontPage, { handleLoginRequest: handleLoginRequest }))) }),
            React.createElement(Route, { path: "/callback", element: (React.createElement(CallbackHandler, { setAuth: setAuthenticated, userManager: userManager })) }),
            React.createElement(Route, { path: "/sign-out", element: (React.createElement(LogoutHandler, { setAuth: setAuthenticated, userManager: userManager })) }),
            React.createElement(Route, { path: "/silent", element: (React.createElement(SilentCallbackHandler, { setAuth: setAuthenticated, userManager: userManager })) }))));
};
export default MyRoutes;
