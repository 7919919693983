/* eslint-disable react/function-component-definition */
import * as React from 'react';
import { DropdownMenuItem } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import PopupButton from '../action/PopupButton';
import ColumnPicker from '../ColumnPicker';
import TableStateSaveModal from '../TableStateSaveModal';
import TableSettings from '../TableSettings';
import TableSettingsModal from '../TableSettingsModal';
const SettingsButton = function SettingsButtonElement({ dateFormat, exportHeaders, groupData, loadTableState, name, resetState, saveState, setDateFormat, setExportHeaders, setGroupData, table, }) {
    const { download } = React.useContext(DownloadContext);
    const [showColumnPicker, setShowColumnPicker] = React.useState(false);
    const [showLoadTableState, setLoadShowTableState] = React.useState(false);
    const [showSaveTableState, setSaveShowTableState] = React.useState(false);
    const [showTableSettingsModal, setShowTableSettingsModal] = React.useState(false);
    const [parentNode, setParentNode] = React.useState();
    const [settingsId, setSettingsId] = React.useState();
    const [tableStateOptions, setTableStateOptions] = React.useState([]);
    const popupRef = React.useRef(null);
    React.useEffect(() => {
        (async () => {
            const url = new ServerURL('/syndicate/table/options');
            url.searchParams.append('name', name);
            const request = new ServerRequest(url);
            try {
                const downloadedOptions = await download.jsonPlus(request, 'unable to download table options');
                setTableStateOptions(downloadedOptions);
            }
            catch (error) {
                //
            }
        })();
    }, [download, name]);
    const stateName = React.useMemo(() => tableStateOptions.find((o) => o.value === settingsId)?.label, [settingsId, tableStateOptions]);
    const updateState = React.useCallback(() => {
        if (stateName !== undefined) {
            saveState(stateName, settingsId);
        }
    }, [saveState, settingsId, stateName]);
    const childFn = React.useCallback((close) => {
        const list = [
            (React.createElement(DropdownMenuItem, { key: "columnpicker", onClick: () => {
                    setShowColumnPicker(true);
                    close();
                } }, "Select Columns")),
            (React.createElement(DropdownMenuItem, { key: "resetstate", onClick: () => {
                    resetState();
                    close();
                } }, "Reset to Default"))
        ];
        if (settingsId === undefined) {
            list.push((React.createElement(DropdownMenuItem, { key: "savestate", onClick: () => {
                    setSaveShowTableState(true);
                    close();
                } }, "Save curent state")));
        }
        else {
            list.push((React.createElement(DropdownMenuItem, { key: "savestate", onClick: () => {
                    setSaveShowTableState(true);
                    close();
                } }, "Save as New")));
            list.push((React.createElement(DropdownMenuItem, { key: "updatestate", onClick: () => {
                    updateState();
                    close();
                } }, "Update state")));
        }
        list.push((React.createElement(DropdownMenuItem, { key: "loadstate", onClick: () => {
                setLoadShowTableState(true);
                close();
            } }, "Load saved state")));
        list.push((React.createElement(DropdownMenuItem, { key: "formats", onClick: () => {
                setShowTableSettingsModal(true);
                close();
            } }, "Formatting")));
        return list;
    }, [resetState, settingsId, updateState]);
    React.useLayoutEffect(() => {
        if (popupRef.current !== null) {
            let parent = popupRef.current;
            while (!parent.classList.contains('base-box') && parent.parentElement !== null) {
                parent = parent.parentElement;
            }
            if (parent !== null) {
                setParentNode(parent);
            }
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        showColumnPicker
            ? React.createElement(ColumnPicker, { onClose: () => setShowColumnPicker(false), table: table }) : null,
        showSaveTableState
            ? React.createElement(TableStateSaveModal, { close: () => setSaveShowTableState(false), saveState: saveState }) : undefined,
        showLoadTableState
            ? (React.createElement(TableSettings, { onClose: () => setLoadShowTableState(false), loadState: loadTableState, settingsId: settingsId, setSettingsId: setSettingsId, setTableStateOptions: setTableStateOptions, tableStateOptions: tableStateOptions })) : undefined,
        showTableSettingsModal
            ? (React.createElement(TableSettingsModal, { close: () => setShowTableSettingsModal(false), dateFormat: dateFormat, exportHeaders: exportHeaders, groupData: groupData, setDateFormat: setDateFormat, setExportHeaders: setExportHeaders, setGroupData: setGroupData })) : undefined,
        settingsId !== undefined ? (React.createElement("div", { style: { fontWeight: 600, marginTop: '0.375rem' } }, stateName)) : undefined,
        React.createElement(PopupButton, { childFn: childFn, iconName: "settings", parentNode: parentNode, ref: popupRef })));
};
export default SettingsButton;
