import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import UserContext from 'contexts/UserContext';
import AuthService from 'services/AuthService';
import MainRoute from '../../../site/MainRoute';
import '@symphony-ui/uitoolkit-styles/uitoolkit.scss';
import './generic.scss';
import CallbackHandler from '../handlers/CallbackHandler';
import SilentCallbackHandler from '../handlers/CallbackSilentHandler';
import LogoutHandler from '../handlers/LogoutHandler';
const MyRoutes = () => {
    const userViewValue = React.useMemo(() => ({
        authService: new AuthService(),
    }), []);
    return (React.createElement(UserContext.Provider, { value: userViewValue },
        React.createElement(BrowserRouter, null,
            React.createElement(Routes, null,
                React.createElement(Route, { path: "/", element: (React.createElement(MainRoute, null)) }),
                React.createElement(Route, { path: "/callback", element: (React.createElement(CallbackHandler, null)) }),
                React.createElement(Route, { path: "/sign-out", element: (React.createElement(LogoutHandler, null)) }),
                React.createElement(Route, { path: "/silent", element: (React.createElement(SilentCallbackHandler, null)) })))));
};
export default MyRoutes;
