import * as React from 'react';
import { Banner } from '@symphony-ui/uitoolkit-components';
import { BannerType } from '@symphony-ui/uitoolkit-components/components/banner/interfaces';
import AppViewContext from '../contexts/AppViewContext';
import ServerRequest from '../models/ServerRequest';
import { envs } from '../utils/system/envs-constants';
const ConnectivityAlert = () => {
    const SERVER_ERROR = React.useMemo(() => ({ content: 'You are working offline. Cannot connect to the Server', variant: BannerType.ERROR }), []);
    const NETWORK_ERROR = React.useMemo(() => ({ content: 'You are working offline. Cannot connect to the Network', variant: BannerType.ERROR }), []);
    const NETWORK_ERROR_DEV = React.useMemo(() => ({ content: 'You are working offline. Some third party assets are unavailable', variant: BannerType.WARNING }), []);
    const NETWORK_RECOVERY = React.useMemo(() => ({ content: 'Network access restored!', variant: BannerType.SUCCESS }), []);
    const { offline } = React.useContext(AppViewContext);
    const [message, setMessage] = React.useState();
    const testServer = async () => {
        try {
            const request = new ServerRequest('/check');
            await fetch(request);
            return true;
        }
        catch (error) {
            return false;
        }
    };
    const restart = () => {
        const currLocation = window.location;
        window.location = currLocation;
    };
    const setTimedRetries = React.useCallback((retry) => {
        setTimeout(() => {
            (async () => {
                const serverAccess = await testServer();
                if (serverAccess) {
                    //    restart();
                }
                else {
                    setTimedRetries(10000);
                }
            })();
        }, retry);
    }, []);
    /**
     *
     * At start up:
     *
     * In case of DEV:
     *
     * as we are offline IF AND ONLY IF the server is down, this is efficient.
     *
     * In case of PROD:
     *
     * it is possible that the network is down, but the server up. in that case we should simply
     * wait for the network is back up and THEN check if we have server access.
     */
    React.useEffect(() => {
        if (offline) {
            if (process.env.currEnv === envs.DEV
                || (process.env.currEnv === envs.PROD && navigator.onLine === true)) {
                setTimedRetries(10000);
                setMessage(SERVER_ERROR);
            }
            else {
                setMessage(NETWORK_ERROR);
            }
        }
        else if (process.env.currEnv === envs.DEV && navigator.onLine === false) {
            setMessage(NETWORK_ERROR_DEV);
        }
    }, [NETWORK_ERROR, NETWORK_ERROR_DEV, SERVER_ERROR, offline, setTimedRetries]);
    /**
   * DEV:
   * navi     server
   * off      on       ==> not offline (partly)  ==> NETWORK_ERROR_DEV
   * on       off      ==> offline               ==> SERVER_ERROR
   * off      off      ==> offline               ==> SERVER_ERROR
   * on       on       ==> not offline
  
    *            on      (server)             off
    *   on                                SERVER_ERROR
    *  (navi)
    *   off   NETWORK_ERROR_DEV           SERVER_ERROR
    *
  
   * PROD:
   * navi     server
   * off      on       ==> offline                ==> NETWORK_ERROR
   * on       off      ==> offline                ==> SERVER_ERROR
   * off      off      ==> offline                ==> NETWORK_ERROR
   * on       on       ==> not offline
  *
  *            on      (server)             off
  *   on                                SERVER_ERROR
  *  (navi)
  *   off    NETWORK_ERROR              NETWORK_ERROR
  *
  
  */
    /**
     * If we go offline, the only remedy for that is to go back online.
     *
     * For DEV it is (mostly) irrelevant, apart from e.g. FX Rates. If we do not have Server Access
     * yet, then we keep that message. If we were fine before, then we send a warning.
     *
     * For PROD we just need to wait for it to come online, and only then we check again
     * if we have Server access.
     */
    window.addEventListener('offline', () => {
        if (process.env.currEnv === envs.DEV && message === null) {
            // In DEV, we do not override the SERVER_ERROR message. Only when there is no message yet we
            // add the warning.
            setMessage(NETWORK_ERROR_DEV);
        }
        else if (process.env.currEnv === envs.PROD) {
            setMessage(NETWORK_ERROR);
        }
    });
    /**
     * Back online. We would now like to:
     * - check if you have access to the server. If yes, show a
     */
    window.addEventListener('online', () => {
        setMessage(NETWORK_RECOVERY);
        (async () => {
            const serverAccess = await testServer();
            if (!serverAccess) {
                setTimedRetries(10000);
            }
            else if (process.env.currEnv === envs.PROD) {
                // in this case we reload the whole app, as we suddenly have access again.
                // setTimeout(() => {
                //   restart();
                // }, 3000);
            }
            setTimeout(() => setMessage(serverAccess ? undefined : SERVER_ERROR), 3000);
        })();
    });
    const onClose = () => {
        setMessage(undefined);
    };
    return message !== undefined ? (React.createElement(Banner, { content: message.content || '', isClosable: true, onClose: onClose, show: 'content' in message, variant: message.variant })) : null;
};
export default ConnectivityAlert;
