import * as React from 'react';
import { Banner } from '@symphony-ui/uitoolkit-components';
import MessageContext from 'contexts/MessageContext';
import ViewContext from 'contexts/ViewContext';
import AppViewContext from 'contexts/AppViewContext';
import DownloadContext from 'contexts/DownloadContext';
import Download from 'services/Download';
import TabContext from 'contexts/TabContext';
import UserContext from 'contexts/UserContext';
import ServiceWorkerMessageHandler from '../../syndicate/notification-click-handlers/ServiceWorkerMessageHandler';
const TabContainer = ({ children, defaultView, }) => {
    const { setOffline } = React.useContext(AppViewContext);
    const { authService } = React.useContext(UserContext);
    const { setTabId } = React.useContext(TabContext);
    const [message, setMessage] = React.useState();
    const [view, setView] = React.useState(localStorage.getItem('view') ?? defaultView ?? '');
    const onClose = React.useCallback(() => setMessage(undefined), []);
    const logOut = React.useCallback(() => {
        localStorage.removeItem('user');
        //  setUser(undefined);
    }, []);
    const setTabAndView = React.useCallback((tabId, newView) => {
        setView(newView);
        setTabId(tabId);
    }, [setTabId]);
    React.useEffect(() => {
        if (view != null) {
            localStorage.setItem('view', view);
        }
        else {
            localStorage.removeItem('view');
        }
    }, [view]);
    const viewValue = React.useMemo(() => ({ setTabAndView, setView, view }), [setTabAndView, view]);
    const messageValue = React.useMemo(() => ({ setMessage }), []);
    const downloadValue = React.useMemo(() => {
        return {
            download: new Download({
                logOut, setMessage, setOffline, authService,
            }),
        };
        throw new Error('Cannot set up download without UserManager');
    }, [logOut, setMessage, setOffline, authService]);
    return (React.createElement(ViewContext.Provider, { value: viewValue },
        React.createElement(MessageContext.Provider, { value: messageValue },
            React.createElement(DownloadContext.Provider, { value: downloadValue },
                React.createElement(ServiceWorkerMessageHandler, null),
                React.createElement("div", { className: "base-box" },
                    message !== undefined ? (React.createElement(Banner, { content: message?.content ?? 'no content', isClosable: true, onClose: onClose, show: true, variant: message?.variant })) : undefined,
                    children)))));
};
export default TabContainer;
