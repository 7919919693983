import * as React from 'react';
import { Card, Typography } from '@symphony-ui/uitoolkit-components';
import './MyCard.scss';
const MyCard = ({ header, icon, text }) => (React.createElement(Card, { className: "my-card" },
    React.createElement("div", { className: "card-box" },
        icon,
        React.createElement("div", { className: "card-title" },
            React.createElement(Typography, { type: "h1" }, header))),
    React.createElement("div", { className: "card-text" },
        React.createElement(Typography, null, text))));
export default MyCard;
