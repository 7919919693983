import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from 'contexts/UserContext';
const SilentCallbackHandler = () => {
    const { authService } = React.useContext(UserContext);
    console.log('Start SilentCallbackHandler.');
    const navigate = useNavigate();
    React.useEffect(() => {
        (async () => {
            try {
                const user = await authService.signinCallback();
                console.log('User coming back from SigninCallback: %o. Now navigating to /', user);
                navigate('/', { replace: true });
            }
            catch (error) {
                console.error('Error when trying to get user using SilentCallbackHandler.  Error %o', error);
            }
        })();
    }, [authService, navigate]);
    return (React.createElement("div", null, "Loading"));
};
export default SilentCallbackHandler;
