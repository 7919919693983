import * as React from 'react';
import ActionCell from 'components/custom-table/action/ActionCell';
import InfiniteTable from 'components/custom-table/InfiniteTable';
import TickCell from './TickCell';
import ValueCell from './ValueCell';
import './tick-icon/TickIcon.css';
const DataTable = ({ create, dataTransform, emptyMessage, errorMessage, fetchSize, name, view: open, setLoading, urlPage, urlTotal, update, }) => {
    const columns = React.useMemo(() => ([
        {
            cell: ActionCell,
            id: 'actions',
            meta: {
                actionsMenu: [
                    { callback: create, label: 'Create' },
                    { callback: open, label: 'View' },
                    { callback: update, label: 'Update' },
                ],
            },
            size: 20,
        },
        {
            accessorKey: 'parsable',
            cell: TickCell,
            enableColumnFilter: false,
            enableSorting: true,
            header: 'Parse?',
            size: 5,
        }, {
            accessorFn: (row) => row.getValue(),
            cell: ValueCell,
            enableColumnFilter: true,
            enableSorting: true,
            header: 'Value',
            id: 'value',
            minSize: 800,
        },
    ]), [create, open, update]);
    return (React.createElement(InfiniteTable, { columns: columns, dataTransform: dataTransform, errorMessage: errorMessage, emptyMessage: emptyMessage, fetchSize: fetchSize, name: name, nameStorage: "regex-data", setLoading: setLoading, urlPage: urlPage, urlTotal: urlTotal }));
};
export default DataTable;
