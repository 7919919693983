/* eslint-disable react/function-component-definition */
import { Button, DropdownMenu, Icon, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import Popup from './Popup';
import './Popup.scss';
const PopupButton = React.forwardRef(({ childFn, iconName, parentNode }, ref) => {
    const [show, setShow] = React.useState(false);
    const [style, setStyle] = React.useState();
    const close = React.useCallback(() => {
        setShow(false);
    }, []);
    const onClick = React.useCallback(() => {
        setShow(true);
    }, []);
    const menuRef = React.useRef(null);
    const subRef = React.useRef(null);
    const [observer, setObserver] = React.useState();
    const resetMargin = React.useCallback(() => {
        if (subRef.current !== null && parentNode !== undefined) {
            const { x, y, height, width, } = subRef.current.getBoundingClientRect();
            const rect = parentNode.getBoundingClientRect();
            if (rect !== undefined) {
                const bottomSpill = Math.max(y + height - rect.bottom, 0);
                const topSpill = rect.top - y;
                const leftSpill = rect.left - x;
                const rightSpill = Math.max(x + width - rect.right, 0);
                const verticalSpill = bottomSpill > 0 && bottomSpill > topSpill;
                const horizontalSpill = rightSpill > 0 && rightSpill > leftSpill;
                const newStyle = style !== undefined ? { ...style } : {};
                if (verticalSpill || horizontalSpill) {
                    if (verticalSpill) {
                        newStyle.marginTop = `-${height}px`;
                    }
                    if (horizontalSpill) {
                        newStyle.marginLeft = `-${width}px`;
                    }
                    setStyle(newStyle);
                }
            }
        }
    }, [parentNode, style]);
    const callback = React.useCallback((mutationList) => {
        mutationList.forEach((mutation) => {
            if (mutation.type === 'childList') {
                if (mutation.addedNodes.length > 0) {
                    resetMargin();
                }
            }
        });
    }, [resetMargin]);
    React.useEffect(() => {
        setObserver(new MutationObserver(callback));
    }, [callback]);
    React.useEffect(() => {
        if (style !== undefined && observer !== undefined) {
            observer.disconnect();
        }
    }, [observer, style]);
    React.useEffect(() => {
        if (show && observer !== undefined && parentNode !== undefined) {
            const config = { attributes: true, childList: true, subtree: true };
            const targetNode = menuRef.current;
            if (targetNode !== null) {
                // Start observing the target node for configured mutations
                observer.observe(targetNode, config);
            }
            resetMargin();
        }
    }, [observer, parentNode, resetMargin, show, style]);
    return (React.createElement("div", { ref: ref, style: { textAlign: 'center' } },
        React.createElement(Button, { className: "co-table-button", iconButton: true, onClick: onClick, variant: "tertiary" },
            React.createElement(Icon, { className: "co-table-icon", iconName: iconName })),
        React.createElement("div", { ref: menuRef }, show
            ? (React.createElement(Popup, { close: close, style: style },
                React.createElement("div", { ref: subRef },
                    React.createElement(DropdownMenu, null, childFn(close))))) : null)));
});
export default PopupButton;
