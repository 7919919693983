import { BannerType } from '@symphony-ui/uitoolkit-components/components/banner/interfaces';
import ServerRequest from 'models/ServerRequest';
class Download {
    logOut;
    authService;
    setError;
    setMessage;
    setOffline;
    constructor({ setMessage, setOffline, logOut, authService, }) {
        this.logOut = logOut;
        this.setMessage = setMessage;
        this.setOffline = setOffline;
        this.authService = authService;
        this.setError = (error, request) => {
            if ((error instanceof TypeError && error.message === 'Failed to fetch')) {
                this.setOffline(true);
            }
            else {
                this.setMessage({ content: `${error.name} - ${error.message} in ${request.url.toString()}`, variant: BannerType.ERROR });
            }
        };
    }
    async fetchWithCsrf(request) {
        if (this.authService === undefined) {
            throw new Error('Unable to fetch without UserManager');
        }
        const authorizationHeader = await this.authService.authorizationHeader();
        if (authorizationHeader !== null) {
            request.headers.append('Authorization', authorizationHeader);
        }
        try {
            const response = await fetch(request);
            if (response.status === 401) {
                const logoutEvent = new Event('logout');
                window.dispatchEvent(logoutEvent);
            }
            return response;
        }
        catch (error) {
            console.error('ERROR IS: %o', error);
            throw error;
        }
    }
    async noContentPlus(request, errorMessage) {
        try {
            const response = await this.fetchWithCsrf(request);
            if (response.status === 204) {
                // need to read empty body to avoid console error 'Fetch failed loading'
                await response.text();
            }
            else if (response.status === 401) {
                this.logOut();
            }
            else {
                const problemDetail = await response.json();
                this.displayServerErrorMessage(problemDetail, errorMessage);
                throw new Error(errorMessage);
            }
        }
        catch (error) {
            this.setError(error, request);
            throw error;
        }
    }
    async jsonPlus(request, errorMessage) {
        try {
            const response = await this.fetchWithCsrf(request);
            if (response.status === 200) {
                return await response.json();
            }
            if (response.status === 401) {
                this.logOut();
            }
            else {
                const problemDetail = await response.json();
                this.displayServerErrorMessage(problemDetail, errorMessage);
            }
        }
        catch (error) {
            this.setError(error, request);
            throw error;
        }
        throw new Error(typeof errorMessage === 'undefined' ? 'Download Error' : errorMessage);
    }
    async textPlus(request, errorMessage) {
        try {
            const response = await this.fetchWithCsrf(request);
            if (response.status === 200) {
                return await response.text();
            }
            if (response.status === 401) {
                this.logOut();
            }
            else {
                const problemDetail = await response.json();
                this.displayServerErrorMessage(problemDetail, errorMessage);
            }
        }
        catch (error) {
            this.setError(error, request);
            throw error;
        }
        throw new Error(errorMessage);
    }
    displayServerErrorMessage(problemDetail, errorMessage) {
        this.setMessage({ content: `${errorMessage} ${problemDetail.title}: (${problemDetail.detail} - ${problemDetail.type} - ${problemDetail.status} - ${problemDetail.instance})`, variant: BannerType.ERROR });
    }
    async options(path, errorMessage, optionsSetter) {
        const request = new ServerRequest(path);
        const body = await this.jsonPlus(request, errorMessage);
        optionsSetter(body);
    }
}
export default Download;
