import ViewContext from 'contexts/ViewContext';
import * as React from 'react';
import DailyEmailCreator from './DailyEmailCreator';
import EmailTrackerTable from './EmailTrackerTable';
import Interprice from './Interprice';
const DailyEmails = () => {
    const { view } = React.useContext(ViewContext);
    switch (view) {
        case 'TRACKER':
            return (React.createElement(EmailTrackerTable, null));
        case 'INTERPRICE':
            return (React.createElement(Interprice, null));
        default:
            return (React.createElement(DailyEmailCreator, null));
    }
};
export default DailyEmails;
