const releaseSources = {
    BLS: [
        { accessorKey: 'summary', header: 'Summary' },
    ],
    BOE: [
        { accessorKey: 'category', header: 'Category' },
        { accessorKey: 'type', header: 'Type' },
    ],
    ECB: [
        { accessorKey: 'name', header: 'Name' },
        { accessorKey: 'dataSet', header: 'Data Set' },
    ],
    IFO: [
        { accessorKey: 'name', header: 'Name' },
    ],
    MARKIT: [
        { accessorKey: 'name', header: 'Name' },
    ],
};
export default releaseSources;
