import * as React from 'react';
import ViewContext from 'contexts/ViewContext';
import User from 'models/User';
import DownloadContext from 'contexts/DownloadContext';
import LoadingPage from 'components/loading-page/LoadingPage';
import UserTable from './UserTable';
import UserForm from './UserForm';
import CrudService from '../../services/CrudService';
const Users = () => {
    const { download } = React.useContext(DownloadContext);
    const { view, setView } = React.useContext(ViewContext);
    const [user, setUser] = React.useState();
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const [users, setUsers] = React.useState([]);
    const service = React.useMemo(() => new CrudService({ download, name: 'User', path: '/admin/users' }), [download]);
    React.useEffect(() => {
        (async () => {
            try {
                const body = await service.getPlus();
                const downloadedUsers = body.map((u) => new User(u));
                setUsers(downloadedUsers);
            }
            catch (error) {
                setError(true);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [service]);
    const create = React.useCallback(() => {
        setUser(User.NEW);
        setView('FORM');
    }, [setView]);
    const deleteFn = React.useCallback(({ row: { original: newUser } }) => {
        (async () => {
            try {
                await service.delete(newUser);
                const filteredUsers = users !== undefined ? users.filter((u) => u.id !== newUser.id) : [];
                setUsers(filteredUsers);
            }
            catch (error) {
                //
            }
        })();
    }, [service, users]);
    const edit = React.useCallback(({ row: { original: newUser } }) => {
        setUser(newUser);
        setView('FORM');
    }, [setView]);
    const save = React.useCallback((isUser) => async (data) => {
        const userNew = data;
        if (!isUser && user !== undefined) {
            delete user.authorityMap;
            delete user.username;
            delete user.password;
        }
        try {
            const response = await (data.id ? service.updatePlus(userNew) : service.createPlus(userNew));
            const updatedUser = new User(response);
            const updatedUsers = CrudService.updateData(users ?? [], updatedUser);
            setUsers(updatedUsers);
            setView('TABLE');
        }
        catch (error) {
            //
        }
    }, [service, setView, user, users]);
    React.useEffect(() => {
        if (view !== 'FORM' && view !== 'TABLE') {
            setView('TABLE');
        }
    }, [setView, view]);
    switch (view) {
        case 'FORM':
            return (React.createElement(UserForm, { save: save, user: user }));
        case 'TABLE':
            return (React.createElement(UserTable, { create: create, deleteFn: deleteFn, edit: edit, hasError: hasError, isLoading: isLoading, users: users }));
        default:
            setView('TABLE');
            return (React.createElement(LoadingPage, null));
    }
};
export default Users;
