/* eslint-disable react/function-component-definition */
import * as React from 'react';
import { Button, Typography } from '@symphony-ui/uitoolkit-components';
const TrancheOverview = function TrancheOverviewElement({ tranche }) {
    return (React.createElement("div", null,
        React.createElement("div", { className: "co-item" },
            React.createElement(Typography, { variant: "bold" }, `${tranche.currency} - ${tranche.term ? tranche.term : tranche.maturity} years`)),
        React.createElement("div", { className: "co-item" },
            React.createElement(Typography, null,
                'Spread Guidance: ',
                React.createElement("b", null, tranche.spreadGuidance),
                ` over ${tranche.benchmark}`),
            React.createElement(Button, null, "Revise spread guidance")),
        React.createElement("div", { className: "co-item" },
            React.createElement(Typography, null, "Size ordersbook: "))));
};
export default TrancheOverview;
