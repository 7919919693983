import * as React from 'react';
import { Button, Modal, ModalFooter, Typography, } from '@symphony-ui/uitoolkit-components';
import CookiePolicy from './CookiePolicy';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import './Footer.scss';
const Footer = () => {
    const [modalContent, setModalContent] = React.useState();
    const [show, setShow] = React.useState(false);
    const [height, setHeight] = React.useState(0);
    const footerRef = React.useRef(null);
    const resetFooterHeight = React.useCallback(() => {
        const footerElement = footerRef.current;
        if (footerElement !== undefined && footerElement !== null) {
            const { top } = footerElement.getBoundingClientRect();
            const rem = Number.parseFloat(getComputedStyle(document.documentElement).fontSize);
            const h = Math.max(window.innerHeight - top - 2 * rem, 32);
            setHeight(h);
        }
    }, []);
    React.useEffect(() => {
        resetFooterHeight();
        window.onresize = resetFooterHeight;
    }, [resetFooterHeight]);
    const clickCookiePolicy = React.useCallback(() => {
        setModalContent(React.createElement(CookiePolicy, null));
        setShow(true);
    }, []);
    const clickOk = React.useCallback(() => {
        setModalContent(undefined);
        setShow(false);
    }, []);
    const clickPrivacyPolicy = React.useCallback(() => {
        setModalContent(React.createElement(PrivacyPolicy, null));
        setShow(true);
    }, []);
    const clickTermsOfService = React.useCallback(() => {
        setModalContent(React.createElement(TermsOfService, null));
        setShow(true);
    }, []);
    return (React.createElement("div", { ref: footerRef, style: { height } },
        React.createElement(Modal, { closeButton: true, parentNode: document.body, show: show, size: "full-width" },
            modalContent,
            React.createElement(ModalFooter, null,
                React.createElement(Button, { onClick: clickOk }, "OK"))),
        React.createElement("div", { id: "co-footer-buttons" },
            React.createElement(Button, { onClick: (clickPrivacyPolicy), variant: "tertiary" }, "Privacy Policy"),
            React.createElement(Button, { onClick: clickCookiePolicy, variant: "tertiary" }, "Cookie policy"),
            React.createElement(Button, { onClick: clickTermsOfService, variant: "tertiary" }, "Terms of Service")),
        React.createElement("div", { id: "co-copyright" },
            React.createElement(Typography, { type: "small" }, "\u00A9 2023 Capital Markets Online Ltd. All rights reserved."))));
};
export default Footer;
